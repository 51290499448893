import './Radios.css'
import React          from 'react'
import {
  FormControl,
   FormLabel,
   RadioGroup,
   FormControlLabel,
   Radio,
   FormHelperText,
   makeStyles
  }                   from '@material-ui/core'

interface RadioOwnProps {}
interface RadioProps extends RadioOwnProps {}
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
}))

const Radios = (props:RadioProps) => {
  const classes = useStyles()
  const [value, setValue] = React.useState('female')
  const handleChange = (event:any) => {
    setValue(event.target.value)
  }

	return (
		<div className="Radio">
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend"></FormLabel>
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          <FormControlLabel value="female" control={<Radio />} label="DealerBreacher" />
          <FormControlLabel value="male" control={<Radio />} label="TRFFK" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
          <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="(Disabled option)"
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend"></FormLabel>
        <RadioGroup aria-label="gender" name="gender2" value={value} onChange={handleChange}>
          <FormControlLabel
            value="female"
            control={<Radio color="primary" />}
            label="DealerBreacher"
            labelPlacement="start"
          />
          <FormControlLabel
            value="male"
            control={<Radio color="primary" />}
            label="TRFFK"
            labelPlacement="start"
          />
          <FormControlLabel
            value="other"
            control={<Radio color="primary" />}
            label="Other"
            labelPlacement="start"
          />
          <FormControlLabel
            value="disabled"
            disabled
            control={<Radio />}
            label="(Disabled option)"
            labelPlacement="start"
          />
        </RadioGroup>
        <FormHelperText></FormHelperText>
      </FormControl>
		</div>
	)
}
export default Radios
