import { makeStyles, Theme } from "@material-ui/core";
import { commanderTheme }    from "../../../../Theme/Theme";

const useStyles = makeStyles((theme:Theme)=>({
  tooltip: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tooltipLeft: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  commanderOrange: {
    color: commanderTheme.orange,
    fontWeight: 'bold',
  },
  info: {
    color: theme.palette.warning.light
  },
}))

export default useStyles
