import {
  makeStyles,
  Theme,
  createStyles,
}                         from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeDiv : {
      display : 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height : '100vh',
      width : '100%',
    },
    title : {
      marginBottom : 50,
      marginTop: 100,
      display : 'flex',
      alignItems : 'center',
      fontWeight : 'bold',
      fontSize : 35,
      color : theme.palette.primary.main,
    },
    button : {
      margin : 3,
      padding : 25,
      width : 305,
    }
  })
)

export default useStyles
