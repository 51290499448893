import {
  makeStyles,
  Theme,
  createStyles
}                   from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactInfo : {
      padding : 20,
      minWidth : 800,
    },
    title : {
      fontSize : 20,
    },
    form : {
      marginTop : 10,
      display : 'flex',
      flexDirection : 'column'
    },
    input : {
      color:"#000"
    },
    formControl: {
      margin: theme.spacing(1.5),
      minWidth: 120,
    },
    adornment : {
      width:50,
      display:'flex',
      justifyContent:'center',
    }
  })
)

export default useStyles
