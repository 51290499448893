import "./TempAppContent.css"
import * as React             from "react"
import {connect}              from "react-redux"
import {ThunkDispatch}        from "redux-thunk"
import {State}                from "../redux/reducers/"
import {Action}               from "../model/constant/actions"
import {Account}              from "../model/accounts/Account"
import requiresAgencyAccounts from "../components/loaders/loadAgencyAccounts"
import Home                   from "../pages/Home"

interface TempAppContentOwnProps{
  accounts : Account[]
}

const mapStateToProps = (state:State, ownProps:TempAppContentOwnProps)=>{
  return {}
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:TempAppContentOwnProps)=>{
  return {}
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:TempAppContentOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type TempAppContentProps = ReturnType<typeof mergeProps>
const TempAppContent = (props:TempAppContentProps) => {
  return (
    <div className="TempAppContent">
      <Home />
    </div>
  )
}

export default requiresAgencyAccounts()(connect(mapStateToProps,mapDispatchToProps,mergeProps)(TempAppContent))
