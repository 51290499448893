import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  TableCell,
  TableRow
}                     from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overallByMonth : {
      display : 'flex',
      justifyContent : 'center'
    },
    table :  {
      borderRadius : 5,
      marginTop : 20,
      maxWidth : '90vw'
    },
    headerFirstCell : {
      width : '15%'
    },
    baseManagementFee : {
      textDecoration: 'line-through'
    },
    discountedManagementFee : {
      color:'#0e6b0e!important',
    },
    discount : {
      color : '#FF0000'
    },
    totalBilled : {
      fontWeight : 'bold'
    }
  })
)
export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight : 'bold',
    },
    body: {
      backgroundColor : '#C0C0C0'
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.action.hover,
    },
  }),
)(TableRow)
export const TotalTableCell = withStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight : 'bold',
    border : 'none'
  }})
)(TableCell)
export default useStyles
