import * as React               from "react"
import {
  CircularProgress,
  Grid,
  Typography
}                               from "@material-ui/core"
import useStyles                from "./CircularLoadingStyles"
import { Translate }            from "react-localize-redux"

interface CircularLoadingProps {
  doTranslate ?: boolean
  timeOut ?: number,
  loadingMessage: string
}

const CircularLoading = (props:CircularLoadingProps) => {
  const classes = useStyles()
  const [rendered,setRendered] = React.useState(!Boolean(props.timeOut))
  if(Boolean(props.timeOut) && !rendered) {
    setTimeout(() => {
      setRendered(true)
    }, props.timeOut)
  }
  if(!rendered){return null}
  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{height: "100%"}}>
       <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={175}
          thickness={4}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={175}
          thickness={4}
          {...props}
        />
      </div>
      <Typography variant="h6">
        {props.doTranslate ? <Translate id={props.loadingMessage}/> : props.loadingMessage}
      </Typography>
    </Grid>
  )
}
export default CircularLoading
