import './Buttons.css'
import React      from 'react'
import {
  Button,
  Fab,
  createStyles,
  makeStyles,
  Theme,
}                 from '@material-ui/core'
import AddIcon    from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

interface ButtonsOwnProps {
  className : string
 }
interface ButtonsProps extends ButtonsOwnProps { }

const BUTTONS_SIZE = ['small', 'medium', 'large'] as ('small' | 'medium' | 'large')[]
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    }
  })
)

const Buttons = (props: ButtonsProps) => {
  const classes = useStyles()
  return (
    <div className={props.className}>
      <div className="Row">
        <div>
          {BUTTONS_SIZE.map(size=>(
            <Fab className={classes.margin} size={size} color="primary" variant="extended">
              <AddIcon  className={classes.icon} /> Add
            </Fab>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size=>(
            <Fab className={classes.margin} size={size} color="secondary" variant="extended">
              <DeleteIcon  className={classes.icon} /> Delete
            </Fab>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size=>(
            <Fab className={classes.margin} size={size} color="primary" variant="round">
              <AddIcon />
            </Fab>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size=>(
            <Fab className={classes.margin} size={size} color="secondary" variant="round">
              <DeleteIcon />
            </Fab>
          ))}
        </div>
      </div>
      <div className="Row">
        <div>
          {BUTTONS_SIZE.map(size => (
            <Button className={classes.margin} size={size} color="primary" variant="text"> Primary </Button>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size => (
            <Button className={classes.margin} size={size} color="primary" variant="outlined"> Primary </Button>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size => (
            <Button className={classes.margin} size={size} variant="contained"> Primary </Button>
          ))}
        </div>
      </div>
      <div className="Row">
        <div>
          {BUTTONS_SIZE.map(size => (
            <Button className={classes.margin} size={size} color="secondary" variant="text"> Secondary </Button>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size => (
            <Button className={classes.margin} size={size} color="secondary" variant="outlined"> Secondary </Button>
          ))}
        </div>
        <div>
          {BUTTONS_SIZE.map(size => (
            <Button className={classes.margin} size={size} color="secondary" variant="contained"> Secondary </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Buttons
