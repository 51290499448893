import './Checkboxes.css'
import React              from 'react'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
}                         from '@material-ui/core'
import FavoriteBorder     from '@material-ui/icons/FavoriteBorder'
import Favorite           from '@material-ui/icons/Favorite'

interface CheckBoxOwnProps {}
interface CheckBoxProps extends CheckBoxOwnProps {}

const CheckboxComponent = (props:CheckBoxProps) => {
  const [checked,setChecked] = React.useState(false)
  const handleChange = () => {
    setChecked(prevState => !prevState)
  }
  return (
    <>
    <FormGroup className="Checkboxes">
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={handleChange} value="checkedA" />
        }
        label="Secondary"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            value="checkedB"
            color="primary"
          />
        }
        label="Primary"
      />
      <FormControlLabel control={<Checkbox value="checkedC" />} label="Uncontrolled" />
      <FormControlLabel disabled control={<Checkbox value="checkedD" />} label="Disabled" />
    </FormGroup>
      <FormGroup>
      <FormControlLabel disabled control={<Checkbox checked value="checkedE" />} label="Disabled" />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            value="checkedF"
            indeterminate
          />
        }
        label="Indeterminate"
      />
      <FormControlLabel
        control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} value="checkedH" />}
        label="Custom icon"
      />
    </FormGroup>
    </>
  )
}

export default CheckboxComponent
