import React                  from 'react'
import {Paper}                from '@material-ui/core'
import mainTheme              from '../../../Theme/Main'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line
}                             from 'recharts'
import { DailyTrends }        from '../../../model/store/statistics/PacingStats'
import { CustomTick }         from './CustomChartComponents/CustomTick'
import CustomTooltipTrend     from './CustomChartComponents/CustomTooltipTrend'
import { commanderTheme }     from '../../../Theme/Theme'


interface DailyTrendOwnProps {
  data: DailyTrends[]
}

const formatData = (data: DailyTrends[]): DailyTrends[] => {
  return data.map( x => ({
    ...x,
    date : x.date.slice(-2),
  }))
}

const DailyTrendsChart = (props: DailyTrendOwnProps) => {
  const formattedData = formatData(props.data)

  return (
    <Paper elevation={0} style={{ height: 400, marginLeft: "-60px"}}>
      <ResponsiveContainer width={"99%"}>
        <LineChart
          data={formattedData}
          margin={{top: 90, bottom: 10, left: 0, right: 20}}
          barCategoryGap={50}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            padding={{left: 80, right: 80}}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            tick={{ stroke: mainTheme.palette.grey[500], strokeWidth: .8, fontSize: 12}}
          />
          <YAxis
            dataKey={"pacingThatDay"}
            padding={{top: 0, bottom: 0}}
            axisLine={false}
            tickLine={false}
            ticks={[0, 25, 50, 75, 100, 125]}
            domain={[0, 150]}
            allowDataOverflow={true}
            tick={<CustomTick />}
          />
          <Tooltip content={<CustomTooltipTrend />}/>
          <Line
            dataKey="pacingThatDay"
            stroke={commanderTheme.orange}
            strokeWidth={6}
            dot={false}
            type="natural"
          />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default DailyTrendsChart
