import {combineReducers} from "redux"
import Agency            from "./Agency"
import Dealer            from "./Dealer"

const Login = combineReducers({
  Agency,
  Dealer
})

export default Login
