import React                          from 'react'
import useStyles                      from './ContainerStyles'
import BudgetCalculator               from '.'
import {
  Typography,
  useTheme,
  Button,
  Card,
  Icon
}                                     from '@material-ui/core'
import {
  withLocalize,
  LocalizeContextProps
}                                     from 'react-localize-redux'
import { connect }                    from 'react-redux'
import {
  createSelectDealer,
  createUpdateDraftSummary,
  createResetDraftSummary
}                                     from '../../actions/creators'
import { push }                       from 'connected-react-router'
import { DispatchableAction }         from '../../model/constant/actions'
import { State }                      from '../../redux/reducers'
import { ThunkDispatch }              from 'redux-thunk'
import Breadcrumbs                    from "../../components/breadcrumbs/Breadcrumbs"
import Table                          from 'material-table'
import {
  Status,
  createSummaryTemplate,
  SummaryWithPresentationByAccountId,
  SummaryTemplateWithPresentation
}                                     from '../../model/budgetEstimator/Summary'
import requiresAgencyAccounts         from '../../components/loaders/loadAgencyAccounts'
import moment                         from 'moment'
import PostAddIcon                    from '@material-ui/icons/PostAdd'
import requiresAccountsSummary        from '../../components/loaders/Sales/loadAccountsSummary'
import { getBreakdown }               from '../../actions/Trffk-salestool/Summary'
import useLoadSystemPermissions       from '../../components/hooks/useLoadSystemPermissions'
import {System, SalestoolPermissions} from '../../model/users/Permissions'
import NoPermissionError from '../../components/loaders/ErrorComponents/NoPermissionError'

interface ContainerOwnProps extends LocalizeContextProps {
  summaryList : SummaryWithPresentationByAccountId
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:ContainerOwnProps)=>{
    const agency        = state.Agencies.List.find(a => a.id === state.Selection.Agency)
    const currentAgency = state.Selection.Agency

    return {
      accounts            : state.Accounts.Agency[currentAgency],
      selectedAgencyName  : agency && agency.name,
      history             : state.router.location.pathname,
      draftSummary        : state.Sales.DraftSummary,
      user                : state.Login.User.user.firstName + " " + state.Login.User.user.lastName
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:ContainerOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args)),
    selectDealer : (...args:Parameters<typeof createSelectDealer>)=>dispatch(createSelectDealer(...args)),
    updateDraftSummary : (...args:Parameters<typeof createUpdateDraftSummary>)=>dispatch(createUpdateDraftSummary(...args)),
    resetDraftSummary : (...args:Parameters<typeof createResetDraftSummary>)=>dispatch(createResetDraftSummary(...args)),
    getBreakdown : (...args:Parameters<typeof getBreakdown>)=>dispatch(getBreakdown(...args)),
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:ContainerOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

export type ContainerProps = ReturnType<typeof mergeProps>

const Container = (props:ContainerProps) => {
  const {hasPermission} = useLoadSystemPermissions(System.COMMANDER,[SalestoolPermissions["salestool-beta"]])
  const classes = useStyles()
  const theme = useTheme()
  const summaries = Object.keys(props.summaryList).reduce<SummaryTemplateWithPresentation[]>((acc,id)=>{
    return [
      ...acc,
      ...props.summaryList[id].map(summary=>(
        {
          ...summary,
          signedOn:summary.status === Status.ACTIVE ? moment().format() : 'N/A'
        }
      ))
    ]
  },[])
  const onSelect = (event:any,rowData?:SummaryTemplateWithPresentation) => {
    if(rowData) {
      const summary = props.summaryList[rowData.salesforceAccountId].find(summary=>summary.summaryId === rowData.summaryId)
      if(summary) {
        props.updateDraftSummary({summary})
        props.getBreakdown(summary)
      }
    }
  }
  const onCreate = () => {
    props.updateDraftSummary({summary:createSummaryTemplate(props.user)})
    props.getBreakdown(createSummaryTemplate(props.user))
  }
  const goBackToList = () => {
    props.resetDraftSummary({})
  }
  if(!hasPermission) {
    return <NoPermissionError />
  }
  if(props.draftSummary !== null) {
    return (
      <BudgetCalculator
        goBackToList={goBackToList}
      />
    )
  }
  return (
    <div className={classes.container}>
      <Breadcrumbs
        agency={props.selectedAgencyName ? props.selectedAgencyName : ""}
        section="Budget Calculator"
      />
      <Typography
        variant="h6"
        className={classes.title}
      >
        {"Contract List"}
      </Typography>
      <Table
        onRowClick={onSelect}
        components={{
          Container: props => <Card style={{padding:10}}>{props.children}</Card>,
        }}
        actions={[
          {
            icon : 'file_copy',
            tooltip : 'Duplicate',
            onClick:(event,rowData)=>{
              const summary = props.summaryList[(rowData as SummaryTemplateWithPresentation).salesforceAccountId].find(summary=>summary.summaryId === (rowData as SummaryTemplateWithPresentation).summaryId)
              if(summary) {
                const newSummary ={...summary,summaryId:-1}
                props.updateDraftSummary({summary:newSummary})
                props.getBreakdown(newSummary)
              }
            },
            position : 'row'
          },
          {
            icon : 'add',
            tooltip : 'Create a new contract',
            onClick:onCreate,
            isFreeAction:true,
          }
        ]}
        data={summaries}
        columns={[
          {
            title : '',
            field : 'salesforceAccountId',
            cellStyle: {
              fontWeight : 'bold',
              overflow : 'hidden',
              textOverflow : 'ellipsis',
              whiteSpace : 'nowrap',
              height: 30,
              color : '#666',
              textAlign:'center'
            },
          },
          {
            title : 'Dealer name',
            field:'dealerName',
          },
          {
            title: "Created on",
            field:'createdOn',
            render : (params) => moment(params.createdOn).format("YYYY-MM-DD hh:mm:ss")

          },
          {
            title: 'Account director',
            field:'repName',
          },
          {
            title: 'Signed on',
            field:'signedOn',
          },
          {
            title: 'Status',
            field:'status',
            render : (params)=>{
              const color = params.status === Status.ACTIVE ? 'green' : params.status === Status.OUTDATED ? "#FFA500" :'#999'
              const icon = params.status === Status.ACTIVE ? <Icon>checkCircleOutlined</Icon> : params.status === Status.OUTDATED ? <Icon>history</Icon> : ''
              return (
              <span style={{color,display:'flex',alignItems:'center',fontStyle:'italic'}}>
                {params.status} {icon}
              </span>)}
          },
          {
            title: 'Presentation',
            field:'presentation',
            render : (params) => (
              <Button
                size="large"
                style={{marginLeft:18}}
                onClick={(event)=>{event.stopPropagation();window.open(params.presentation,"_blank")}}
                color="primary"
              >
                <Icon>article</Icon>
              </Button>
              )
          },
        ]}
        title={<Button className={classes.addButton} size="large" variant="contained" onClick={onCreate}><PostAddIcon />Create new contract</Button>}
        options={{
          emptyRowsWhenPaging: false,
          headerStyle :{
            backgroundColor : '#EEEEEE',
            color : theme.palette.primary.main,
            fontWeight: "bold",
            fontSize : '1.1em',
            marginTop : 10,
            boxShadow : '5px',
          },
          actionsCellStyle : {
            backgroundColor : theme.palette.primary.main,
            color : 'white',
          },
          rowStyle:{},
          searchFieldStyle: {
            fontWeight: "bold",
          },
          showTitle : true,
          pageSize : 25,
          draggable : false,
        }}
        localization={{
          pagination: {
            labelRowsSelect: props.translate(`accounts.index.table.rows`) as string,
            labelDisplayedRows: `{from}-{to} ${props.translate('accounts.index.table.of') as string} {count}`
          },
          toolbar: {
            searchPlaceholder: `${props.translate(`accounts.index.table.search`)}`
          },
          header : {
            actions : ''
          }
        }}
      />
    </div>
  )
}

export default requiresAgencyAccounts()(
  requiresAccountsSummary()(
    withLocalize(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(Container)
    )
  )
)
