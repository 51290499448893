import React                  from 'react'
import {
  Card,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  AppBar,
  Tab,
  Box,
}                             from '@material-ui/core'
import SummaryTemplate, {
  Breakdown,
  profitCenterArray,
  ProfitCenter
}                             from '../../../model/budgetEstimator/Summary'
import useStyles, {
  StyledTableRow,
  StyledTableCell,
  StyledTabs
}                             from './styles'
import { formatMoney }        from '../../../utils/MoneyFormatting'
import MONTHS                 from '../../../model/constant/Months'
import OverallByMonth         from './OverviewByMonth'
import {
  getTotalManagementFeeByMonth,
  getTotalBilledByMonth,
  getBaseManagementFeeByMonth,
  getExtraDiscountByMonth,
  getCampaignDiscountByMonth,
  getManagementFeeOfMonthByProfitCenter
}                             from '../../../model/budgetEstimator/Calculations/BudgetCalculations'
import OverviewByProducts     from './OverviewByProducts'
import OverviewByChannels     from './OverviewByChannel'
import { State }              from '../../../redux/reducers'
import { ThunkDispatch }      from 'redux-thunk'
import { DispatchableAction } from '../../../model/constant/actions'
import { connect }            from 'react-redux'


interface Overall {
  breakdown   : Breakdown
  newTargets  : number
  usedTargets : number
  summary     : SummaryTemplate
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
const makeMapStateToProps = () => {
  return (state:State, ownProps:Overall)=>{
    return {
      creating : state.Sales.Creating.summary
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:Overall)=>{
  return {}
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:Overall)=>{
  return {...SP,...DP,...ownProps}
}

type AllProps = ReturnType<typeof mergeProps>

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div key={index}>{children}</div>
        </Box>
      )}
    </div>
  )
}

const Overall = (props:AllProps) => {
  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState<number>(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }
  const breakdownByProfitCenter = profitCenterArray.reduce<Record<ProfitCenter,{mediaSpend:number,totalBilled:number,totalManagementFee:number,totalCampaignDiscount:number,totalExtraDiscount:number,totalBaseManagementFee:number}>>((acc,profitCenter)=>{
    let mediaSpend = 0
    let totalBilled = 0
    let totalManagementFee = 0
    let totalCampaignDiscount = 0
    let totalExtraDiscount = 0
    let totalBaseManagementFee = 0
    for(const month of MONTHS) {
      for(const product of Object.keys(props.breakdown.amounts[month])) {
        if(props.breakdown.amounts[month][product].profitCenter === profitCenter) {
          mediaSpend              = mediaSpend + props.breakdown.amounts[month][product].mediaSpend
          totalBilled             = totalBilled + props.breakdown.amounts[month][product].totalBilled
          totalManagementFee      = totalManagementFee + props.breakdown.amounts[month][product].managementFeeCurrency
          totalCampaignDiscount   = totalCampaignDiscount + props.breakdown.amounts[month][product].discounts['campaignDiscount']
          totalExtraDiscount      = totalExtraDiscount + props.breakdown.amounts[month][product].discounts['extraDiscount']
          totalBaseManagementFee  = totalBaseManagementFee + props.breakdown.amounts[month][product].discounts['extraDiscount'] + props.breakdown.amounts[month][product].discounts['campaignDiscount'] +props.breakdown.amounts[month][product].managementFeeCurrency
        }
      }
    }
    return {
      ...acc,
      [profitCenter] : {
        mediaSpend,
        totalBilled,
        totalManagementFee,
        totalCampaignDiscount,
        totalExtraDiscount,
        totalBaseManagementFee
      }
    }
  },{
    [ProfitCenter.NEW] : {
      mediaSpend : 0,
      totalBilled : 0,
      totalManagementFee : 0,
      totalCampaignDiscount : 0,
      totalExtraDiscount :0,
      totalBaseManagementFee:0,
    },[ProfitCenter.USED] : {
      mediaSpend : 0,
      totalBilled : 0,
      totalManagementFee : 0,
      totalCampaignDiscount : 0,
      totalExtraDiscount :0,
      totalBaseManagementFee:0,
    },[ProfitCenter.NONE] : {
      mediaSpend : 0,
      totalBilled : 0,
      totalManagementFee : 0,
      totalCampaignDiscount : 0,
      totalExtraDiscount :0,
      totalBaseManagementFee:0,
    }
  })
  const totalMediaSpend = profitCenterArray.reduce((acc,curr)=>{
    return acc + breakdownByProfitCenter[curr].mediaSpend
  },0)
  const totalTargets = props.newTargets + props.usedTargets
  const totalManagementFeeByMonth = getTotalManagementFeeByMonth(props.breakdown)
  const totalBilledByMonth = getTotalBilledByMonth(props.breakdown)
  const totalCampaignDiscountByMonth = getCampaignDiscountByMonth(props.breakdown)
  const totalExtraDiscountByMonth = getExtraDiscountByMonth(props.breakdown)
  const baseManagementFeeByMonth = getBaseManagementFeeByMonth(props.breakdown)
  const managementFeeByMonth = getManagementFeeOfMonthByProfitCenter(props.breakdown)
  const managementFeeByProfitCenter = profitCenterArray.reduce<Record<ProfitCenter,number>>((acc,profitCenter)=>{
    return {
      ...acc,
      [profitCenter] : MONTHS.reduce((acc,month)=>{
        return acc + managementFeeByMonth(month,profitCenter)
      },0)
    }
  },{
    [ProfitCenter.NEW] : 0,
    [ProfitCenter.USED] : 0,
    [ProfitCenter.NONE] : 0
  })
  const totalManagementFee = MONTHS.reduce((acc,month)=>{
    return acc + totalManagementFeeByMonth(month)
  },0)
  const totalBilled = MONTHS.reduce((acc,month)=>{
    return acc + totalBilledByMonth(month)
  },0)
  const campaignDiscount = MONTHS.reduce((acc,month)=>{
    return acc + totalCampaignDiscountByMonth(month)
  },0)
  const extraDiscount = MONTHS.reduce((acc,month)=>{
    return acc + totalExtraDiscountByMonth(month)
  },0)
  const totalBaseManagementFee = MONTHS.reduce((acc,month)=>{
    return acc + baseManagementFeeByMonth(month)
  },0)
  return (
    <Card className={classes.overall}>
      <AppBar elevation={0} position="static">
        <StyledTabs indicatorColor="primary" textColor="primary" value={tabValue} onChange={handleTabChange}>
          <Tab style={{marginRight:5}} label="Budget & Management Fee" />
          <Tab style={{marginRight:5}} label="Profit center" />
          <Tab style={{marginRight:5}} label="By channel" />
          <Tab style={{marginRight:5}} label="By products" />
        </StyledTabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <div>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="center">Annual</StyledTableCell>
                  <StyledTableCell align="center">Per car</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>Media Spend</StyledTableCell>
                  <StyledTableCell align="center">{formatMoney(totalMediaSpend)}</StyledTableCell>
                  <StyledTableCell align="center">{formatMoney(totalMediaSpend / totalTargets)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.baseManagmentFee} >Base management fee </StyledTableCell>
                  <StyledTableCell className={classes.baseManagmentFee} align="center">{formatMoney(totalBaseManagementFee)}</StyledTableCell>
                  <StyledTableCell className={classes.baseManagmentFee} align="center">{formatMoney(totalBaseManagementFee/totalTargets)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discount}>Campaign discount</StyledTableCell>
                  <StyledTableCell className={classes.discount} align="center">-{formatMoney(campaignDiscount)}</StyledTableCell>
                  <StyledTableCell className={classes.discount} align="center">-{formatMoney(campaignDiscount/totalTargets)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discount}>Extra discount</StyledTableCell>
                  <StyledTableCell className={classes.discount} align="center">-{formatMoney(extraDiscount)}</StyledTableCell>
                  <StyledTableCell className={classes.discount} align="center">-{formatMoney(extraDiscount/totalTargets)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountText}>Management Fee </StyledTableCell>
                  <StyledTableCell className={classes.discountText} align="center">{formatMoney(totalManagementFee)}</StyledTableCell>
                  <StyledTableCell className={classes.discountText} align="center">{formatMoney(totalManagementFee/totalTargets)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.totalBilled}>Total billed </StyledTableCell>
                  <StyledTableCell className={classes.totalBilled} align="center">{formatMoney(totalBilled)}</StyledTableCell>
                  <StyledTableCell className={classes.totalBilled} align="center">{formatMoney(totalBilled/totalTargets)}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography style={{marginTop:20}} variant="button" color="primary" className={classes.title}>Overview by profit center</Typography>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell style={{width:'40%'}}></StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">NEW</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">USED</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">OTHER</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">TOTAL</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell style={{width:'40%'}}>Campaigns & Products allocation</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">{(breakdownByProfitCenter[ProfitCenter.NEW].mediaSpend/totalMediaSpend*100).toFixed(2)}%</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">{(breakdownByProfitCenter[ProfitCenter.USED].mediaSpend/totalMediaSpend*100).toFixed(2)}%</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">{(breakdownByProfitCenter[ProfitCenter.NONE].mediaSpend/totalMediaSpend*100).toFixed(2)}%</StyledTableCell>
                <StyledTableCell style={{width:'15%'}} align="center">{'100'}%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Media Spend per Car</StyledTableCell>
                <StyledTableCell align="center">{formatMoney((breakdownByProfitCenter[ProfitCenter.NEW].mediaSpend/ props.newTargets) || 0) }</StyledTableCell>
                <StyledTableCell align="center">{formatMoney((breakdownByProfitCenter[ProfitCenter.USED].mediaSpend / props.usedTargets) || 0) }</StyledTableCell>
                <StyledTableCell align="center">N/A</StyledTableCell>
                <StyledTableCell align="center">{formatMoney((totalMediaSpend / totalTargets || 0))}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Annual media spend</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(breakdownByProfitCenter[ProfitCenter.NEW].mediaSpend||0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(breakdownByProfitCenter[ProfitCenter.USED].mediaSpend ||0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(breakdownByProfitCenter[ProfitCenter.NONE].mediaSpend||0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(totalMediaSpend)}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Management fee</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(managementFeeByProfitCenter[ProfitCenter.NEW] || 0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(managementFeeByProfitCenter[ProfitCenter.USED] || 0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(managementFeeByProfitCenter[ProfitCenter.NONE] || 0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(Object.keys(managementFeeByProfitCenter).reduce((acc,x)=>acc + managementFeeByProfitCenter[x as ProfitCenter],0))}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Annual media spend + management fee</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(breakdownByProfitCenter[ProfitCenter.NEW].totalBilled || 0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(breakdownByProfitCenter[ProfitCenter.USED].totalBilled || 0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(breakdownByProfitCenter[ProfitCenter.NONE].totalBilled || 0)}</StyledTableCell>
                <StyledTableCell align="center">{formatMoney(totalBilled)}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <OverallByMonth
          breakdownByProfitCenter={breakdownByProfitCenter}
          summary={props.summary}
          breakdown={props.breakdown}
          totalMediaSpend={totalMediaSpend}
          newTargets={props.newTargets}
          usedTargets={props.usedTargets}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2} >
        <OverviewByChannels breakdown={props.breakdown}/>
      </TabPanel>
      <TabPanel value={tabValue} index={3} >
        <OverviewByProducts breakdown={props.breakdown} />
      </TabPanel>
    </Card>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(Overall)
