// TODO TYPE THIS --- tried, GL
const round = (number:number) => Math.round(number * 100) / 100

const monitorReducerEnhancer = (createStore:any) => (
  reducer:any,
  initialState:any,
  enhancer:any
) => {
  const monitoredReducer = (state:any, action:any) => {
    const start = performance.now()
    const newState = reducer(state, action)
    const end = performance.now()
    const diff = round(end - start)
    const logger = (diff>1000 ? console.warn : console.log)
    logger('reducer process time:', diff, 'ms')
    return newState
  }

  return createStore(monitoredReducer, initialState, enhancer)
}

export default monitorReducerEnhancer
