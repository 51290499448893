import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"

const initialState = {googleTrends:false,inventoryStats:false,trackingStats:false}

const Retrieving = createReducer<{googleTrends:boolean,inventoryStats:boolean,trackingStats:boolean}>(initialState, {
  [ActionTypes.RETRIEVING_GOOGLE_TRENDS_STATS] : (state, action)=>{
    return {
      ...state, googleTrends:action.payload
    }
  },
  [ActionTypes.RETRIEVING_INVENTORY_STATS] : (state,action) => {
    return {
      ...state, inventoryStats : action.payload
    }
  },
  [ActionTypes.RETRIEVING_TRACKING_STATS] : (state,action) => {
    return {
      ...state, trackingStats : action.payload
    }
  }
})

export default Retrieving
