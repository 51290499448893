import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import AccountInfo       from "../../../model/Facebook/AccountInfo"

export interface FacebookAccountInfo {
  [accountId:number] : AccountInfo | "NON_EXISTING"
}

const AdTemplates = createReducer<FacebookAccountInfo>({},{
  [ActionTypes.GET_ACCOUNT_INFO_FIRESTONE] : (state,action) => {
    return {...state,
      [action.payload.accountId] : action.payload.accountInfo
    }
  }
})

export default AdTemplates
