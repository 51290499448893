import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"
import {Account}       from "../../../model/accounts/Account"

interface AccountsByAgency {
  [agencyId:string] : Account[]
}

const Agency = createReducer<AccountsByAgency>({}, {
  [ActionTypes.GET_AGENCY_ACCOUNTS] : (state, action)=>{
    const {agencyId, accounts} = action.payload
    return {
      ...state,
      [agencyId] : [
        ...accounts
      ]
    }
  },
})

export default Agency
