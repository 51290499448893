import { combineReducers } from "redux"
import Accounts            from './Accounts'
import Contacts            from './Contacts'

const Salesforce = combineReducers({
 Accounts,
 Contacts,
})

export default Salesforce
