import * as React                             from "react"
import {connect}                              from "react-redux"
import {ThunkDispatch}                        from "redux-thunk"
import {State}                                from "../../../redux/reducers"
import {Action}                               from "../../../model/constant/actions"
import Loading                                from "../../loadings/CircularLoading"
import { translationPaths }                   from "../../../translations/translations"
import { getSalesforceAccounts }              from "../../../actions/Salesforce-etl/Accounts"
import SalesforceAccount                      from "../../../model/Salesforce/Account"

interface SalesforceAccountsLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  salesforceAccounts        : SalesforceAccount[]
  loadingSalesforceAccounts : boolean
}

const mapStateToProps = (state:State, ownProps:SalesforceAccountsLoaderOwnProps)=>{
  return {
    salesforceAccounts    : state.Sales.Salesforce.Accounts,
    retrieving            : state.Sales.Retrieving.salesforceAccounts,
    failedRetrieving      : state.Sales.Failed.salesforceAccounts,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:SalesforceAccountsLoaderOwnProps)=>{
  return {
    getSalesforceAccounts : (...args:Parameters<typeof getSalesforceAccounts>)=>dispatch(getSalesforceAccounts(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:SalesforceAccountsLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type LoaderProps = ReturnType<typeof mergeProps>

const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingSalesforceAccounts = false
  if(props.retrieving && !props.letThrough) {
    return <Loading loadingMessage="Loading Salesforce accounts"/>
  }
  if(props.failedRetrieving.status) {
    return <div> {props.failedRetrieving.message} </div>
  }
  if(!props.salesforceAccounts){
    loadingSalesforceAccounts = true
    if(!props.retrieving){
      props.getSalesforceAccounts()
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.accountFeeds.loading} doTranslate />
    }
  }
  return props.render({loadingSalesforceAccounts, salesforceAccounts:props.salesforceAccounts})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresSalesforceAccounts:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresSalesforceAccounts
