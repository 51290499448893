import { createReducer }              from "../../../utils/Reducers"
import ActionTypes                    from "../../../model/constant/actions"
import {Success as IdentityVariants}  from "../../../model/http/Headstone/GetFacebookIdentityVariants"

interface FacebookIdentityVariants {
  [accountId:number] : IdentityVariants[]
}

const AdTemplates = createReducer<FacebookIdentityVariants>({},{
  [ActionTypes.GET_FACEBOOK_IDENTITY_VARIANTS] : (state,action) => {
    return {...state,
      [action.payload.accountId] : action.payload.identityVariants
    }
  }
})

export default AdTemplates
