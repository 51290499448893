import './index.css'
import React                 from 'react'
import Buttons               from './Buttons/Buttons'
import Typography            from './Typography/Typography'
import CustomInput           from './Input/CustomInput'
import {
  Paper,
}                            from '@material-ui/core'
import CheckboxComponent     from './Checkbox/Checkbox'
import Radios                from './Radio/Radios'
import SwitchComponent       from './Switch/Switch'
import ProgressBars          from './ProgressBars'
import CircularProgressCompo from './CircularProgress'
import Sliders               from './Sliders'
import Selects               from './Selects'
import MuiTable              from './MuiTable'
import Toasters              from './Toasters'
import OtherTypo             from './Typography/OtherTypo'
import DatePickers           from './DatePickers'
import MuiModal              from '../../components/modals/ConfirmationModal'
import WrappedButton         from '../../components/buttons/InteractiveSavingButton'

interface DesignSystemOwnProps {}
interface DesignSystemProps extends DesignSystemOwnProps {}


const DesignSystem = (props:DesignSystemProps) => {
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [fail, setFail] = React.useState(false)

  const handleButtonClick = () => {
    setFail(false)
    setSuccess(false)
    setLoading(true)
    setTimeout(() => {
      //setFail(true)
      setSuccess(true)
      setLoading(false)
    }, 2000)
  }
  return (
    <Paper className="DesignSystem">
      <div className="TypographyContainer">
        <div className="Titles">
          <Typography className="Typography" />
        </div>
        <div className="Other">
          <OtherTypo />
        </div>
      </div>
      <div className="ButtonsContainer">
        <Buttons className="Buttons" />
      </div>
      <div className="CustomInputContainer">
        <CustomInput />
      </div>
      <div className="OtherInputsContainer">
        <Radios />
        <SwitchComponent />
        <CheckboxComponent />
      </div>
      <div className="Extras">
        <Sliders />
        <Selects />
      </div>
      <div className="DatePickers">
        <DatePickers />
      </div>
      <div className="Loaders">
        <WrappedButton
          buttonText="Save"
          success={success}
          loading={loading}
          failed={fail}
          handleButtonClick={handleButtonClick}
        />
        <CircularProgressCompo />
        <ProgressBars />
      </div>
      <div className="Table">
        <MuiTable />
      </div>
      <div className="Card">
        <MuiModal
          primaryButtonClick={()=>{}}
          secondaryButtonClick={()=>{}}
          open={false}
          variant="primary"
          title="Primary action modal"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non vestibulum tortor, eget laoreet orci."
          secondaryButtonText="Cancel"
          primaryButtonText="Confirm"
        />
        <MuiModal
          primaryButtonClick={()=>{}}
          secondaryButtonClick={()=>{}}
          open={false}
          variant="secondary"
          title="Secondary action modal"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non vestibulum tortor, eget laoreet orci."
          secondaryButtonText="Cancel"
          primaryButtonText="Delete"
        />
      </div>
      <div className="CardsAndAlerts">
        <div className="Alerts">
          <Toasters />
        </div>
      </div>
    </Paper>
  )
}

export default DesignSystem
