import React from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'

interface ProgressBarsOwnProps {}
interface ProgressBarsProps extends ProgressBarsOwnProps {}

const useStyles = makeStyles(theme => ({
  root: {
    width: '30%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const ProgressBars = (props:ProgressBarsProps) => {
  const classes = useStyles()
  const [completed, setCompleted] = React.useState(0)

  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldCompleted + diff, 100)
      })
    }

    const timer = setInterval(progress, 500)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <div className={classes.root}>
      <LinearProgress variant="indeterminate"  />
      <LinearProgress color="secondary" value={completed} variant="determinate" />
    </div>
  )
}

export default ProgressBars
