import { withStyles, Theme, createStyles, TableCell, TableRow, makeStyles } from "@material-ui/core"

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight : 'bold',
      width: '25%'
    },
    body: {
      fontSize: 14,
      width: '25%'
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.action.hover,
    },
  }),
)(TableRow)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otherExpenses : {
      padding : 20,
      width : '100%',
    },
    title : {
      fontSize : 20,
    },
    managementFee : {
      display : 'flex',
      marginTop : 30,
      alignItems :'center',
    },
    table : {
      marginTop : 20
    }
  })
)

export default useStyles
