import React from 'react'
import { Typography, makeStyles } from '@material-ui/core';

interface OtherTypoOwnProps {}
interface OtherTypoProps extends OtherTypoOwnProps {}

const useStyles = makeStyles(theme=>({
  typo : {
    marginTop:20
  },
  capOv : {
    marginTop : 40
  },
  tommy : {
    backgroundColor : theme.palette.primary.main
  }
}))

const OtherTypo = (props:OtherTypoProps) => {
  const classes = useStyles()
  return (
    <div className='OtherTypo'>
      <Typography className={classes.typo} variant="body1">
        <b>BODY 1 : </b>Etiam molestie purus a dictum molestie. Morbi ornare eleifend porttitor. Sed eu sollicitudin erat. Fusce at purus eu nisi accumsan suscipit.
      </Typography>
      <Typography  className={classes.typo} variant="body2" >
        <b>BODY 2 : </b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at ante volutpat urna fringilla porttitor. Donec nec feugiat tortor.
      </Typography>
      <Typography color="secondary" className={classes.typo} variant="subtitle1">
        <b>SUBTITLE 1 : </b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at ante volutpat urna fringilla porttitor.
      </Typography>
      <Typography color="secondary" className={classes.typo} variant="subtitle2">
        <b>SUBTITLE 2 : </b>  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at ante volutpat urna fringilla porttitor.
      </Typography>
      <div className={classes.capOv}>
      <Typography  className={classes.typo}variant="caption">
        <b>CAPTION : </b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at ante volutpat urna fringilla porttitor.
      </Typography>
      <br />
      <Typography className={classes.typo} variant="overline">
        <b>OVERLINE : </b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at ante volutpat urna fringilla porttitor.
      </Typography>
      </div>
    </div>
  )
}

export default OtherTypo
