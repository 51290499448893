import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import PagesInterface    from "../../../model/Facebook/Pages"

interface FacebookPages {
  [businessId:number] : PagesInterface[]
}

const Pages = createReducer<FacebookPages>({},{
  [ActionTypes.GET_FACEBOOK_PAGES] : (state,action) => {
    return {...state,
      [action.payload.businessId] : action.payload.pages
    }
  }
})

export default Pages
