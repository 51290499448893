import {
  makeStyles,
  Theme,
  createStyles
}                         from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText : {
      display:'flex',
      flexDirection : 'column',
      width:'100%',
      justifyContent:'space-around',
      marginRight:20,
    },
    icon : {
      fontSize : 40
    },
    languages : {
      display : 'flex',
      width : '100%',
      justifyContent: 'space-around',
      marginTop: 10,
    },
    language : {
      display : 'flex',
      alignItems : 'center',
    },
    divider : {
      marginTop:10
    }
  })
)

export default useStyles
