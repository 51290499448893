import SummaryTemplate, { ManagementFee } from "../Summary"

export const handleManagementFeesChange = (value:ManagementFee,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    managementFee : value
  }
}
export const handleDiscountChange = (value:number,summary:SummaryTemplate):SummaryTemplate => {
  if(value > 100) {
    return {
      ...summary,
      discount : 100
    }
  }
  else {
    return {
      ...summary,
      discount : Math.abs(value)
    }
  }
}
