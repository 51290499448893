import {
  makeStyles,
  Theme,
  createStyles
}                 from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    insights : {
      marginLeft : 60,
      marginRight : 60,
      textAlign : 'center',
    },
    header : {
      display : 'flex',
      justifyContent : 'space-between',
      alignItems : 'center'
    },
    pickers : {
      display : 'flex',
      flexDirection : 'row',
      alignContent : 'space-between',
      alignItems : 'center',
      width : '50%'
    },
    button : {
      marginTop : 8,
      height : 56
    },
    error : {
      display : 'flex',
      width : '100%',
      height : 50,
      alignItems : 'center',
      justifyContent : 'center',
      backgroundColor : theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      fontWeight : 'bold',
      marginTop : 20,
      borderTopLeftRadius : 10,
      borderTopRightRadius : 10
    },
    errorMessage : {
      height : 25,
      fontWeight : 'bold',
      marginLeft : 10,
      marginTop: 5
    },
    pickersComponent : {
      display : 'flex',
      padding : 5,
      alignItems : 'flex-end',
    },
    modal : {
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
    },
    hidden : {
      visibility : 'hidden'
    },
    datePicker : {
      position : 'absolute',
      top : 100,
      right : 20,
      zIndex : 1000
    }
  })
)

export default useStyles
