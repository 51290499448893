import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import SummaryTemplate   from "../../../model/budgetEstimator/Summary"


const DraftSummary = createReducer<SummaryTemplate|null>(null,{
  [ActionTypes.UPDATE_DRAFT_SUMMARY] : (state,action) => {
    return action.payload.summary
  },
  [ActionTypes.RESET_DRAFT_SUMMARY] : (state,action) => null
})

export default DraftSummary
