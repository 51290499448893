import "./index.css"
import * as React               from "react"
import {connect}                from "react-redux"
import {ThunkDispatch}          from "redux-thunk"
import {State}                  from "../../redux/reducers/"
import { DispatchableAction }   from '../../model/constant/actions'
import {push}                   from "connected-react-router"
import AppBar                   from "@material-ui/core/AppBar"
import Toolbar                  from "@material-ui/core/Toolbar"
import IconButton               from "@material-ui/core/IconButton"
import MenuIcon                 from "@material-ui/icons/Menu"
import appBarStyle              from "./Styles"
import {
  CssBaseline,
}                               from "@material-ui/core"
import clsx                     from "clsx"
import DrawerMenu               from "../drawers/DrawerMenu"
import ProfileAndNotifications  from "./ProfileAndNotifications"
import MENU                     from './Menu'
import Logo                     from '../../assets/images/Commander_logo.png'
import {
  System,
  SalestoolPermissions
}                               from "../../model/users/Permissions"
import useLoadSystemPermissions from "../hooks/useLoadSystemPermissions"
import {
  withLocalize,
  LocalizeContextProps,
}                               from "react-localize-redux"
import { translationPaths }     from "../../translations/translations"


interface ApplicationBarOwnProps {}

const mapStateToProps = (state:State, ownProps:ApplicationBarOwnProps)=>{
  return {
    selectedAgency : state.Selection.Agency,
    allAgencies    : state.Agencies.List,
    user           : state.Login.User
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:ApplicationBarOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:ApplicationBarOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type ApplicationBarProps = ReturnType<typeof mergeProps> & {children:any} & LocalizeContextProps


const ApplicationBar = (props:ApplicationBarProps) => {
  const {hasPermission} = useLoadSystemPermissions(System.COMMANDER,[SalestoolPermissions["salestool-beta"]])
  const classes = appBarStyle() //Styles in Styles.ts
  // Fred wants the button gone for now, code is still here, just commented
  //const [openModal,setOpenModal] = React.useState<boolean>(false)
  const [open, setOpen] = React.useState<boolean>(false)
  const selectedAgency = props.allAgencies ? props.allAgencies.find(agency=>agency.id === props.selectedAgency) : null
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  /*const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }*/
  const responsiveClassName = clsx({
    [classes.contentShift]  : window.innerWidth > 1200 && open,
  })
  const handleProfileOnClick = () => {
    props.push(`/User` as any)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="default"
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={open ? classes.menuButtonOpened : classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
            <div className={classes.logoContainer}>
              <img
                alt="Commander logo"
                className={classes.logo}
                src={Logo}
                onClick={()=>{
                  selectedAgency && props.push(`/Agency/${selectedAgency.id}/` as any)
                }}
              />
            </div>
          {/*selectedAgency &&
            <Button
              disabled={props.allAgencies.length === 1}
              color="primary"
              variant="contained"
              onMouseDown={handleOpenModal}
              className={classes.agencyButton}
            >
              {selectedAgency.name}
            </Button>
          */}

          <div className={classes.grow} />
          {/*<div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon color="primary" />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          */}
          <ProfileAndNotifications onProfileClick={handleProfileOnClick} />
        </Toolbar>
      </AppBar>
      <DrawerMenu selectedAgency={selectedAgency ? selectedAgency.name : props.translate(translationPaths.agencySelection.title) as string} open={open} menu={MENU({agencyId:props.selectedAgency, user:props.user,hideSalestool:!hasPermission})} />
      <main
        className={clsx(classes.content,responsiveClassName)}
      >
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
      {/*<AgencySelectionModal open={openModal} onClose={handleCloseModal} onSelection={handleCloseModal} />*/}
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(
  withLocalize(ApplicationBar as React.ComponentType<ApplicationBarProps>)
)
