import "./OverlayLoading.css"
import * as React       from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid             from "@material-ui/core/Grid"

interface OverlayLoadingProps{
  open         ?: boolean
  overlayColor ?: string
  children      : React.ReactElement
}

const OverlayLoading = (props:OverlayLoadingProps) => {
  return (
    <div className="OverlayLoading" style={{position: "relative"}}>
      {props.open &&
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 2,
            backgroundColor: props.overlayColor||"rgba(0, 0, 0, 0.2)"
          }}
        >
          <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
            <CircularProgress size={100}/>
          </Grid>
        </div>
      }
      {props.children}
    </div>
  )
}

export default OverlayLoading
