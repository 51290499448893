import SummaryTemplate, {
  BudgetType, ProfitCenter
}                             from "../Summary"

export const handleBudgetTypeChange = (budgetType:BudgetType,summary:SummaryTemplate ):SummaryTemplate => {
  return {
    ...summary,
    budgetType : budgetType,
    budget : {
      ...summary.budget,
      NEW : 0,
      USED :0,
    }
  }
}

export const handleDealerBudgetChange = (value:number,summary:SummaryTemplate,profitCenter:ProfitCenter):SummaryTemplate => {
  return {
    ...summary,
    budget : {
      ...summary.budget,
      [profitCenter] : Math.abs(value) || 0
    }
  }
}

export const handleShareNewChange = (value:number,summary:SummaryTemplate):SummaryTemplate => {
  if(value > 100) {
    return {
      ...summary,
      budgetShare : {
        ...summary.budgetShare,
        NEW : 100
      }
    }
  }
  else {
    return {
      ...summary,
      budgetShare : {
        ...summary.budgetShare,
        NEW : Math.abs(value) || 0
      }
    }
  }
}
export const handleShareUsedChange = (value:number,summary:SummaryTemplate):SummaryTemplate => {
  if(value > 100) {
    return {
      ...summary,
      budgetShare : {
        ...summary.budgetShare,
        USED : 100
      }
    }
  }
  else {
    return {
      ...summary,
      budgetShare : {
        ...summary.budgetShare,
        USED : Math.abs(value) || 0
      }
    }
  }
}

export const handleIncludeFeeChange = (value:boolean,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    includeFee : value
  }
}
