import {createReducer}             from "../../../utils/Reducers"
import ActionTypes                 from "../../../model/constant/actions"
import InventoryStats              from '../../../model/store/insights/InventoryStats'

const InventoryStatsReducer = createReducer<{[agencyId:string]:InventoryStats[]}>({}, {
  [ActionTypes.GET_INVENTORY_STATS] : (state, action)=>{
    const compare = (a:InventoryStats,b:InventoryStats) => {
      if (a.date>b.date) return 1
      if (b.date>a.date) return -1
      return 0
    }
    const copiedStatsForSorting = action.payload.inventoryStats
    const sortedStats: InventoryStats[] = copiedStatsForSorting.sort(compare)
    return {
      ...state,
      [action.payload.agencyId] : [
        ...sortedStats
      ]
    }
  }
})

export default InventoryStatsReducer
