import React                       from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Button,
  FormHelperText,
  LinearProgress,
}                                  from '@material-ui/core'
import Feed                        from '../../../../../model/feeds/Feed'
import { translationPaths }        from '../../../../../translations/translations'
import CheckCircleIcon             from '@material-ui/icons/CheckCircle'
import { Translate }               from 'react-localize-redux'
import Languages                   from '../../../../../model/constant/Languages'
import { State }                   from '../../../../../redux/reducers'
import { ThunkDispatch }           from 'redux-thunk'
import { Action }                  from 'redux'
import requiresAccountFeeds        from '../../../../../components/loaders/loadAccountFeeds'
import { connect }                 from 'react-redux'
import { createClearAccountFeeds } from '../../../../../actions/creators'


interface HyperfeedOwnProps {
  feeds               : {
    [agencyId:string] : {
      [accountId:number] : Feed[]
    }
  }
  language                : Languages
  setHyperfeedActivation  : (active:boolean) => void
  loadingFeeds            : boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hyperfeed : {
      marginTop : 10,
      display : 'flex',
      alignItems : 'center',
    },
  })
)

const mapStateToProps = (state:State, ownProps:HyperfeedOwnProps)=>{
  return {
    selectedAccount : state.Selection.Dealer,
    selectedAgency  : state.Selection.Agency,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:HyperfeedOwnProps)=>{
  return {
    clearFeeds : (agencyId:string,accountId:number)=>dispatch(createClearAccountFeeds({accountId,agencyId}))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:HyperfeedOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type HyperfeedProps = HyperfeedOwnProps & ReturnType<typeof mergeProps>

const Hyperfeed = (props:HyperfeedProps) => {
  const classes = useStyles()
  if(props.loadingFeeds) {
    return <div style={{height:50,paddingTop:30}}><LinearProgress/></div>
  }
  const facebookFeed = props.feeds[props.selectedAgency][props.selectedAccount].filter(feed=>feed.stub.includes('FACEBOOK'))
  const isFacebookHyperFeedActivated = facebookFeed.length > 0 && facebookFeed.some(x=>x.stub.toUpperCase().includes(props.language))
  props.setHyperfeedActivation(isFacebookHyperFeedActivated)
  return (
    <div className={classes.hyperfeed}>
      {!isFacebookHyperFeedActivated ?
        <div className={classes.hyperfeed}>
          <Typography style={{marginRight:20}} color="secondary">
            <Translate id={translationPaths.accounts.accountsDetails.activations.hyperfeedNotSet} />
          </Typography>
          <Button onClick={()=>props.clearFeeds(props.selectedAgency,props.selectedAccount)} variant="contained" color="primary" size="small">
            <Translate id={translationPaths.general.refresh}/>
          </Button>
        </div>
        :
        <FormHelperText className={classes.hyperfeed} style={{color:'green'}}>
          <Translate id={translationPaths.accounts.accountsDetails.activations.hyperfeedSet}/>&nbsp;<CheckCircleIcon />
        </FormHelperText>
      }
    </div>
    )
}

export default requiresAccountFeeds({letThrough:true})(
  connect(mapStateToProps,mapDispatchToProps,mergeProps)(Hyperfeed)
)
