import {createReducer}                                          from "../../../utils/Reducers"
import ActionTypes                                              from "../../../model/constant/actions"
import PacingStats                                              from "../../../model/http/Reporting/PacingStats"
import {
  CompiledPacingStats as CompiledPacingStatsInterface,
  PacingStatsByMonth,
  StatPerChannelPerAccount,
  CompiledPacingStatsGroupByYearMonth
}                                                               from "../../../model/store/statistics/PacingStats"
import {Account}                                                from '../../../model/accounts/Account'

interface CompiledStatsPerChannelForAccount {
  accountId   : number
  accountName : string
  province    : string
  channels    : {
    [x:string] : {invested: number, spent: number}
  }
}
const compileStatsPerChannelPerAccount = (yearMonth:string, pacingPerMonth:PacingStatsByMonth, accounts: Account[]):StatPerChannelPerAccount[] => {
  const numbersPerChannelPerAccount:{[accountId:number]:CompiledStatsPerChannelForAccount} = {}
  for(const stat of pacingPerMonth[yearMonth]){
    const statChannel = stat.channel.toLocaleLowerCase()
    if(!numbersPerChannelPerAccount[stat.accountId]) {
      numbersPerChannelPerAccount[stat.accountId] = {
        accountId: stat.accountId,
        accountName: "",
        province: "",
        channels: {
          search: {
            invested: 0,
            spent: 0
          },
          display: {
            invested: 0,
            spent: 0
          },
          social: {
            invested: 0,
            spent: 0
          },
          video: {
            invested: 0,
            spent: 0
          },
        }
      }
    }
    numbersPerChannelPerAccount[stat.accountId].channels[statChannel].invested += stat.invested
    numbersPerChannelPerAccount[stat.accountId].channels[statChannel].spent += stat.spent
  }

  for (const account of accounts) {
    if (numbersPerChannelPerAccount[account.id]) {
      numbersPerChannelPerAccount[account.id].accountName = account.name
      numbersPerChannelPerAccount[account.id].province = account.province
    }
  }

  return Object.values(numbersPerChannelPerAccount).map((accountObj) => {
    return {
      accountId: accountObj.accountId,
      accountName: accountObj.accountName,
      province: accountObj.province,
      channels : [
        {
          channel: 'Search',
          spent : accountObj.channels.search.spent ,
          budget : accountObj.channels.search.invested ,
          pacing: isFinite(accountObj.channels.search.spent/accountObj.channels.search.invested*100)
                  ? (accountObj.channels.search.spent/accountObj.channels.search.invested*100)
                  : 0,
        },
        {
          channel: 'Display',
          spent : accountObj.channels.display.spent ,
          budget : accountObj.channels.display.invested ,
          pacing: isFinite(accountObj.channels.display.spent/accountObj.channels.display.invested*100)
                  ? (accountObj.channels.display.spent/accountObj.channels.display.invested*100)
                  : 0,
        },
        {
          channel: 'Social',
          spent : accountObj.channels.social.spent ,
          budget : accountObj.channels.social.invested ,
          pacing: isFinite(accountObj.channels.social.spent/accountObj.channels.social.invested*100)
                  ? (accountObj.channels.social.spent/accountObj.channels.social.invested*100)
                  : 0,
        },
        {
          channel: 'Video',
          spent : accountObj.channels.video.spent ,
          budget : accountObj.channels.video.invested ,
          pacing: isFinite(accountObj.channels.video.spent/accountObj.channels.video.invested*100)
                  ? (accountObj.channels.video.spent/accountObj.channels.video.invested*100)
                  : 0,
        },
      ]
    }
  })
}

const CompiledPacingStats = createReducer<CompiledPacingStatsInterface>({}, {
  [ActionTypes.CREATE_PACING_DATA_TO_COMPILE] : (state, action)=>{
    //
    // Might be better to split this huge reducer in smaller functions for readability
    // - Christo
    //
    return {
      ...state,
      [action.payload.agencyId] : {
        ...(state[action.payload.agencyId]||{}),
        ...Object.keys(action.payload.pacingReport).reduce<CompiledPacingStatsGroupByYearMonth>((perYearMonth,yearMonth)=>{
          let totalBudget:number = 0
          let totalSpent:number = 0
          let statsPerAccount : {[accountId:string]:PacingStats[]} = {}
          for(const stat of action.payload.pacingReport[yearMonth]){
            statsPerAccount[stat.accountId+""] = statsPerAccount[stat.accountId+""] || []
            statsPerAccount[stat.accountId+""].push(stat)
          }
          const accountsForYearMonth = action.payload.accounts.map(account=>{
            let budget:number = 0
            let spent:number = 0

            for(const stat of (statsPerAccount[account.id+""])||[]){
              budget += stat.invested
              spent  += stat.spent
            }

            totalBudget += budget
            totalSpent  += spent
            return {
              accountId  : account.id,
              dealer     : account.name,
              budget     : budget,
              spent      : spent,
              pacing     : isFinite(spent/budget*100) ? spent/budget*100 : 0,
              region     : account.province,
            }
          })

          // Stats per region
          const regions: string[] = []
          for (const account of accountsForYearMonth) {
            if (account.budget !== 0 && account.region !== "" && regions.indexOf(account.region) === -1) {
              regions.push(account.region)
            }
          }

          const statsPerRegion = regions.map((region) => {
            let dealerCount:number = 0
            let pacingTotal:number = 0

            accountsForYearMonth.forEach((account) => {
              if (account.region === region && account.budget !== 0) {
                  dealerCount ++
                  pacingTotal += account.pacing
              }
            })

            return {
              region: region,
              averagePacing: parseInt((pacingTotal/dealerCount).toFixed(2))
            }
          })

          // Stats per channel
          const channels: string[] = ["Search","Display","Social","Video"]
          const statsPerChannel = channels.map(channel =>{
            let budget:number = 0, spent:number = 0
            for(const stat of action.payload.pacingReport[yearMonth]){
              if (stat.channel === channel.toUpperCase()) {
                budget += stat.invested
                spent  += stat.spent
              }
            }
            return {
              channel : channel,
              spent : spent ,
              budget : budget,
              pacing  : isFinite(spent/budget*100) ? (spent/budget*100) : 0,
            }
          })

          // Stats per channel per account

          const statsPerChannelPerAccount = compileStatsPerChannelPerAccount(yearMonth, action.payload.pacingReport, action.payload.accounts)


          // Daily trends
          const dailyStats : {[date:string]:PacingStats[]} = {}
          for (const stat of action.payload.pacingReport[yearMonth]){
            dailyStats[stat.date+""] = dailyStats[stat.date+""] || []
            dailyStats[stat.date+""].push(stat)
          }
          let investedInMonthSoFar:number = 0, spentInMonthSoFar:number = 0
          const dailyTrends = Object.keys(dailyStats).map(day=>{
            let investedThatDay:number = 0, spentThatDay:number = 0
            for(const stat of dailyStats[day]){
                investedThatDay += stat.invested
                spentThatDay += stat.spent
                investedInMonthSoFar += stat.invested
                spentInMonthSoFar += stat.spent
            }
            return {
              date                      : day,
              totalInvestedInMonthSoFar : investedInMonthSoFar,
              totalSpentInMonthSoFar    : spentInMonthSoFar,
              pacingInMonthSoFar        : isFinite(spentInMonthSoFar/investedInMonthSoFar*100) ? (spentInMonthSoFar/investedInMonthSoFar*100) : 0,
              investedThatDay           : investedThatDay,
              spentThatDay              : spentThatDay,
              pacingThatDay             : isFinite(spentThatDay/investedThatDay*100) ? (spentThatDay/investedThatDay*100) : 0,
            }
          })


          return {
            ...perYearMonth,
            [yearMonth] : {
              statsPerAccount           : accountsForYearMonth,
              statsPerRegion            : statsPerRegion,
              statsPerChannel           : statsPerChannel,
              statsPerChannelPerAccount : statsPerChannelPerAccount,
              dailyTrends               : dailyTrends,
              totalBudget,
              totalSpent,
              pacing : totalSpent / totalBudget * 100 || 0
            }
          }
        },{})
      }
    }
  }
})

export default CompiledPacingStats
