import {headstone}  from './index'
import VehicleState from '../../constant/VehicleState'
import Languages    from '../../constant/Languages'
import AudienceGoal from '../../constant/AudienceGoal'

export default (accountId:number) => {
  return headstone+"/"+accountId+"/Facebook/Strategy"
}

export interface Success {
  active : boolean
  startedOn : {
    date : string
    timezone_type : number
    timezone : string
  }
  lang : Languages
  state :  VehicleState
  audienceGoal : AudienceGoal
  feedUrl : string
  radius : number
  lastModifiedBy : number
  lastModifiedOn : {
    date : string
    timezone_type : number
    timezone : string
  }
}
