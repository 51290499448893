import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Updating {
  identityVariants : boolean
  adTemplates : boolean
  strategy : boolean
}

const Updating = createReducer<Updating>({identityVariants:false,adTemplates:false,strategy:false},{
  [ActionTypes.UPDATING_FACEBOOK_AD_TEMPLATES] : (state,action) => ({
    ...state,
    adTemplates : action.payload
  }),
  [ActionTypes.UPDATING_FACEBOOK_IDENTITY_VARIANTS] : (state,action) => ({
    ...state,
    identityVariants : action.payload
  }),
  [ActionTypes.UPDATING_FACEBOOK_STRATEGY] : (state,action) => ({
    ...state,
    strategy : action.payload
  }),
})

export default Updating
