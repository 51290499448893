import { createReducer }       from "../../../utils/Reducers"
import ActionTypes             from "../../../model/constant/actions"
import { FacebookAdTemplates } from "../../../model/store/templates/FacebookAdTemplates"

interface DefaultAdSet {
  [accountId:string] :{
    [state:string] : {
      [lang:string] : FacebookAdTemplates[]
    }
  }
}

const FacebookDefaultAdSets = createReducer<DefaultAdSet>({},{
  [ActionTypes.GET_FACEBOOK_DEFAULT_ADSET] : (state,action) => {
    if(state[action.payload.accountId] && state[action.payload.accountId][action.payload.state]) {
      return {...state,
        [action.payload.accountId] : {
          ...state[action.payload.accountId],
          [action.payload.state] : {
            ...(state[action.payload.accountId][action.payload.state]),
            [action.payload.lang] : action.payload.AdTemplates
          }
        }
      }
    }
    if(state[action.payload.accountId]) {
      return {...state,
        [action.payload.accountId] : {
          ...state[action.payload.state],
          [action.payload.state] : {
            [action.payload.lang] : action.payload.AdTemplates
          }
        }
      }
    }
    return {...state,
      [action.payload.accountId] : {
        [action.payload.state] : {
          [action.payload.lang] : action.payload.AdTemplates
        }
      }
    }
  }
})

export default FacebookDefaultAdSets
