import React                 from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
}                            from '@material-ui/core'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Area
}                            from 'recharts'
import { SelectValue }       from '../InventoryCount'
import {
  withLocalize,
  LocalizeContextProps
}                            from 'react-localize-redux'
import {translationPaths}    from '../../../translations/translations'

interface GraphDataFormat {
  date : string
  usedCars : number
  newCars : number
  dealersCount : number
}

interface InventoryCountGraphGraphOwnProps extends LocalizeContextProps{
  data : GraphDataFormat[]
  usedCarsBarColor : string
  newCarsBarColor : string
  inventoryColor : string
  dataToDisplay : SelectValue
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inventoryCountGraphGraph : {
      marginTop : 50,
      marginBottom : 50
    }
  })
)

const InventoryCountGraph = (props:InventoryCountGraphGraphOwnProps) => {
  const classes = useStyles()
  return (
    <div className={classes.inventoryCountGraphGraph}>
      <ResponsiveContainer height={400} width="99%" >
        <ComposedChart
          data={props.data}
          margin={{
            top: 20, right: 20, bottom: 20, left: 20,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis tickMargin={15} stroke="#999" dataKey="date" />
          <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="left" orientation="left"/>
          <YAxis  tick={{fill:'#999'}} stroke="#fff" yAxisId="right" orientation="right"/>
          <Tooltip />
          { props.dataToDisplay === 'used' || props.dataToDisplay === 'both' ?
            <Area
              type="monotoneX"
              fillOpacity={0.7}
              stackId="1"
              name={props.translate(translationPaths.marketInsights.inventoryCountGraph.used) as string}
              yAxisId="right"
              dataKey="usedCars"
              fill={props.usedCarsBarColor}
              stroke={props.usedCarsBarColor}
            />
            : null
          }
          { props.dataToDisplay === 'new' || props.dataToDisplay === 'both' ?
            <Area
              type="natural"
              fillOpacity={0.7}
              stackId="1"
              name={props.translate(translationPaths.marketInsights.inventoryCountGraph.new) as string}
              yAxisId="right"
              dataKey="newCars"
              fill={props.newCarsBarColor}
              stroke={props.newCarsBarColor}
            />
            : null
          }
          <Line
            strokeOpacity="1"
            name={props.translate(translationPaths.marketInsights.inventoryCountGraph.dealersCount) as string}
            dot={false}
            strokeWidth={6}
            yAxisId="left"
            type="natural"
            dataKey="dealersCount"
            stroke={props.inventoryColor}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default withLocalize(InventoryCountGraph)
