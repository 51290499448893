import React                      from 'react'
import FacebookIdSetup, {
  AdAccountOptionType,
  FacebookPageOptionType
}                                 from './FacebookIdSetup'
import { State }                  from '../../../../redux/reducers'
import { ThunkDispatch }          from 'redux-thunk'
import { DispatchableAction }     from '../../../../model/constant/actions'
import {
  getFacebookUnusedAdAccounts,
  getFacebookPages,
  submitFacebookId
}                                 from '../../../../actions/Firestone/facebook'
import { connect }                from 'react-redux'
import { Card }                   from '@material-ui/core'

interface FacebookIdControllerOwnProps {
  handleClose : () => void
  adAccountIdAuth ?: number
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:FacebookIdControllerOwnProps)=>{
    return {
      selectedAccount : state.Selection.Dealer
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:FacebookIdControllerOwnProps)=>{
  return {
    getFacebookUnusedAdAccounts : (...args:Parameters<typeof getFacebookUnusedAdAccounts>)=>dispatch(getFacebookUnusedAdAccounts(...args)),
    getFacebookPages : (...args:Parameters<typeof getFacebookPages>)=>dispatch(getFacebookPages(...args)),
    submitFacebookId : (...args:Parameters<typeof submitFacebookId>)=>dispatch(submitFacebookId(...args))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:FacebookIdControllerOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type FacebookControllerProps = FacebookIdControllerOwnProps & ReturnType<typeof mergeProps>

const FacebookIdController = (props:FacebookControllerProps) => {
  const [selectedBusinessId,setSelectedBusinessId] = React.useState<number|null>(null)
  const [selectedAdAccount,setSelectedAdAccount] = React.useState<number|null>(null)
  const [selectedPageId,setSelectedPageId] = React.useState<number|null>(null)

  const handleBusinessIdChange = (event:React.ChangeEvent<{value:unknown}>) => {
    if(typeof(event.target.value) === "number") {
      const value = event.target.value as number
      setSelectedBusinessId(value)
      props.getFacebookUnusedAdAccounts(value)
      props.getFacebookPages(value)
    }
  }
  const handleAdAccountChange = (adAccount:AdAccountOptionType | null ) => {
    if(adAccount) {
      setSelectedAdAccount(adAccount.id)
    }
  }
  const handlePageIdChange = (facebookPage : FacebookPageOptionType | null) => {
    if(facebookPage) {
      setSelectedPageId(facebookPage.id)
    }
  }
  const onConfirm =() => {
    if(selectedBusinessId && selectedAdAccount && selectedPageId) {
      props.submitFacebookId(selectedBusinessId as number,selectedAdAccount as number ,selectedPageId as number,props.selectedAccount)
    }
  }
  return (
    <Card style={{padding:20}}>
      <FacebookIdSetup
        selectedBusinessId={selectedBusinessId}
        selectedAdAccountId={selectedAdAccount}
        selectedPageId={selectedPageId}
        onBusinessIdChange={handleBusinessIdChange}
        onAdAccountChange={handleAdAccountChange}
        onPageIdChange={handlePageIdChange}
        onClose={props.handleClose}
        onConfirm={onConfirm}
        disabled={!selectedBusinessId || !selectedAdAccount || !selectedPageId || ( Boolean(props.adAccountIdAuth) && selectedAdAccount !== props.adAccountIdAuth) }
        showAdAccountMessage={Boolean(props.adAccountIdAuth) && selectedAdAccount !== props.adAccountIdAuth}
        adAccountIdAuth={props.adAccountIdAuth}
      />
    </Card>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(FacebookIdController)
