import {User}  from "../../users/User"
import auth2   from "./"

export interface Success extends User{
  rememberMeToken ?: string
}

export default auth2+"/Login"


