import base from "./"

export const ReportingReports = {
  PACY_PACING : {
    version : 1,
    prefix : "DB"
  },
  AGENCY_PACING_STATS : {
    version : 1,
    prefix : 'AGENCY'
  },
  GOOGLE_TRENDS_STATS : {
    version : 0,
    prefix : 'AGENCY_NO_ACCOUNT'
  },

  AGENCY_T2_TRENDS : {
    version : 0,
    prefix : 'AGENCY'
  },
  AGENCY_INVENTORY_TRENDS : {
    version : 0,
    prefix : 'AGENCY'
  }
}

export const buildCompiledReportUrl = (
  id:string|number,
  report:keyof typeof ReportingReports,
):string => {
  return [
    base,
    "compiledReport",
    report,
    "v"+ReportingReports[report].version,
    ReportingReports[report].prefix,
    id,
  ].join("/")
}

