import {
  useSelector,
  useDispatch
}                                from "react-redux"
import { State }                 from "../../redux/reducers"
import { getAccountPermissions } from "../../actions/Auth2/Permissions"
import { AccountPermission }     from "../../model/users/Permissions"

const useLoadAccountPermissions = (accountId:number,accountPermissions:Array<AccountPermission>) => {
  const retrieving = useSelector((state:State)=>state.Login.Retrieving.accountPermissions)
  const permissions = useSelector((state:State)=>state.Login.AccountPermissions)
  const dispatch = useDispatch()
  let hasPermission = false
  if((!permissions || !permissions[accountId]) && !retrieving){
    dispatch(getAccountPermissions(accountId))
  }
  if(permissions && permissions[accountId]) {
    hasPermission = accountPermissions.every((val) => permissions[accountId].includes(val))
  }
  return {
    hasPermission,
    loadingPermissions : !permissions || !permissions[accountId] || retrieving
  }
}
export default useLoadAccountPermissions

