import {
  StandardThunk,
  createRetrievingSalesforceAccounts,
  createGetSalesforceAccounts,
  createFailedRetrievingSalesforceAccounts
}                                          from "../creators"
import getAccountsUrl, {
  Success
}                                          from "../../model/http/Salesforce-etl/GetAccounts"
import axios                               from 'axios'

export const getSalesforceAccounts = ():StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingSalesforceAccounts(true))
  axios.get<Success>(getAccountsUrl(),{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response=>{
    dispatch(createGetSalesforceAccounts(response.data))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingSalesforceAccounts({
      failed:true,
      message : 'Failed retrieving salesforce account contacts'
    }))
  })
  .then(()=>{
    dispatch(createRetrievingSalesforceAccounts(false))
  })
}
