import { makeStyles, Theme, createStyles, withStyles, TableCell, TableRow } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container : {

    },
    baseManagementFee : {
      textDecoration : 'line-through'
    },
    discountOnManagementFee : {
      color : '#FF0000!important'
    },
    discountedManagementFee : {
      color:'#0e6b0e!important',
      fontWeight:'bold'
    },
    totalBilled : {
      fontWeight : 'bold'
    }
  })
)
export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight : 'bold'
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)
export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow)
export const TotalTableCell = withStyles((theme: Theme) => createStyles({
  body: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight : 'bold',
    border : 'none'
  },
}),
)(TableCell)
export default useStyles
