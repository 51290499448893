import styles                 from "./indexStyles"
import * as React             from "react"
import {connect}              from "react-redux"
import {ThunkDispatch}        from "redux-thunk"
import {State}                from "../../redux/reducers/"
import {Action}               from "../../model/constant/actions"
import {
  Typography,
  Icon
}                             from '@material-ui/core'
import Overview               from "./Overview"
import ChartsTabs             from "./ChartsTabs"
import PacingTable            from "./PacingTable"
import requiresPacingStats    from "../../components/loaders/loadAgencyPacingStats"
import PacingStats            from "../../model/http/Reporting/PacingStats"
import requiresAgencyAccounts from "../../components/loaders/loadAgencyAccounts"
import MonthYearPicker        from "../../components/pickers/MonthYearPicker"
import Breadcrumbs            from "../../components/breadcrumbs/Breadcrumbs"
import { getPacingStats }     from "../../actions/Reporting/pacing"
import clsx                   from 'clsx'
import CircularLoading        from "../../components/loadings/CircularLoading"
import getPreviousYearMonth   from "../../utils/getPreviousYearMonth"
import { Translate }          from "react-localize-redux"
import { translationPaths}    from '../../translations/translations'
import {
  withLocalize,
  LocalizeContextProps
}                            from "react-localize-redux"

interface PacingOwnProps extends LocalizeContextProps{
  loadingPacingStats  : boolean
  pacingStats         : PacingStats[]
}

const mapStateToProps = (state:State, ownProps:PacingOwnProps)=>{
  const agency = state.Agencies.List.find(a => a.id === state.Selection.Agency)
  return {
    agencyAccounts          : state.Accounts.Agency,
    selectedAgency          : state.Selection.Agency,
    compiledPacingStats     : state.Statistics.CompiledPacingStats,
    loadedMonthsByAgencyId  : state.Statistics.CurrentLoadedMonths,
    allPacingStats          : state.Statistics.PacingStats,
    selectedAgencyName      : agency && agency.name
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:PacingOwnProps)=>{
  return {
    getPacingStats : (...args:Parameters<typeof getPacingStats>)=>dispatch(getPacingStats(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:PacingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type PacingProps = ReturnType<typeof mergeProps>
  const Pacing = (props:PacingProps) => {
  const classes = styles()
  const loadedMonthsForSelectedAgency = props.loadedMonthsByAgencyId[props.selectedAgency]
  const [yearMonth,setYearMonth] = React.useState<string>(loadedMonthsForSelectedAgency[0])
  const [previousYearMonth, setPreviousYearMonth] = React.useState<string>(getPreviousYearMonth(yearMonth))
  const changeDate = (yearMonth:string) => {
    const _previousYearMonth = getPreviousYearMonth(yearMonth)
    if(!loadedMonthsForSelectedAgency.includes(yearMonth) || !loadedMonthsForSelectedAgency.includes(_previousYearMonth)) {
      props.getPacingStats(props.selectedAgency,[yearMonth, _previousYearMonth])
    }
    setPreviousYearMonth(_previousYearMonth)
    setYearMonth(yearMonth)
  }
  if(!props.allPacingStats[props.selectedAgency][yearMonth]) {
    return <CircularLoading loadingMessage={props.translate(translationPaths.pacingOverview.loader) as string} />
  }
  const disabled =  clsx({
    [classes.disabled] : props.allPacingStats[props.selectedAgency][yearMonth].length === 0
  })

  return (
    <div className={classes.pacing}>
      <Breadcrumbs
        agency={props.selectedAgencyName ? props.selectedAgencyName : ""}
        section="Reports"
        page="Pacing Overview"
      />
      <div className={classes.header}>
        <Typography color="textPrimary" className={classes.title} variant="h6"><Translate id={translationPaths.pacingOverview.title} /></Typography>
        <MonthYearPicker onConfirm={changeDate} currentDate={yearMonth} />
      </div>
      {
        props.allPacingStats[props.selectedAgency][yearMonth].length === 0 &&
        <div className={classes.noData}>
          <Icon>warning</Icon>
          <Typography className={classes.noDataMessage} color="inherit">
            <Translate id={translationPaths.pacingOverview.errorMessages.noData}/>
          </Typography>
        </div>
      }
      <div className={disabled}>
        <div>
          <Overview
            statsPerChannel={props.compiledPacingStats[props.selectedAgency][yearMonth].statsPerChannel}
            thisMonthData={props.compiledPacingStats[props.selectedAgency][yearMonth]}
            previousMonthData={props.compiledPacingStats[props.selectedAgency][previousYearMonth]}
          />
          <ChartsTabs data={props.compiledPacingStats[props.selectedAgency][yearMonth]} />
          <PacingTable
            data={props.compiledPacingStats[props.selectedAgency][yearMonth].statsPerAccount}
            statsPerChannelPerAccount={props.compiledPacingStats[props.selectedAgency][yearMonth].statsPerChannelPerAccount}
          />
        </div>
      </div>
    </div>
  )
}

export default withLocalize(requiresAgencyAccounts()(requiresPacingStats()(connect(mapStateToProps,mapDispatchToProps,mergeProps)(Pacing))))
