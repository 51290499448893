import * as React                             from "react"
import {connect}                              from "react-redux"
import {ThunkDispatch}                        from "redux-thunk"
import {State}                                from "../../../redux/reducers"
import {Action}                               from "../../../model/constant/actions"
import Loading                                from "../../loadings/CircularLoading"
import { translationPaths }                   from "../../../translations/translations"
import SalesforceContact                      from "../../../model/Salesforce/Contact"

interface SalesforceAccountContactsLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  salesforceAccountContacts        : SalesforceContact[]
  loadingSalesforceAccountContacts : boolean
}

const mapStateToProps = (state:State, ownProps:SalesforceAccountContactsLoaderOwnProps)=>{
  return {
    salesforceAccountContacts    : state.Sales.Salesforce.Contacts,
    retrieving                   : state.Sales.Retrieving.salesforceAccountContacts,
    failedRetrieving             : state.Sales.Failed.salesforceAccountContacts,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:SalesforceAccountContactsLoaderOwnProps)=>{
  return {
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:SalesforceAccountContactsLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type LoaderProps = ReturnType<typeof mergeProps>

const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingSalesforceAccountContacts = false
  if(props.retrieving && !props.letThrough) {
    return <Loading loadingMessage="Loading Contact"/>
  }
  if (props.failedRetrieving.status) {
    return <div> {props.failedRetrieving.message} </div>
  }
  if(props.retrieving){
    loadingSalesforceAccountContacts = true
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.accountFeeds.loading} doTranslate />
    }
  }
  return props.render({loadingSalesforceAccountContacts, salesforceAccountContacts:props.salesforceAccountContacts})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresSalesforceAccountContacts:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresSalesforceAccountContacts
