import * as React                 from "react"
import {connect}                  from "react-redux"
import {ThunkDispatch}            from "redux-thunk"
import {State}                    from "../../redux/reducers"
import {Action}                   from "../../model/constant/actions"
import Loading                    from "../loadings/CircularLoading"
import { getFacebookBusinesses }  from "../../actions/Firestone/facebook"
import ErrorPage                  from "../ErrorPage"
import Business                   from "../../model/Facebook/Business"
import { translationPaths }       from "../../translations/translations"


interface AccountsDataLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  loadingBusinesses : boolean
  businesses : Business[]
}

const mapStateToProps = (state:State, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    failedRetrieving        : state.Facebook.Failed.businesses,
    retrieving              : state.Facebook.Retrieving.businesses,
    businesses              : state.Facebook.Businesses
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    getFacebookBusinesses : (...args:Parameters<typeof getFacebookBusinesses>)=>dispatch(getFacebookBusinesses(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>

const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingBusinesses = false
  if(props.failedRetrieving && !props.letThrough) {
    return <ErrorPage errorMessage={translationPaths.loaders.facebookBusinesses.failed} doTranslate={true} />
  }
  if(!props.businesses){
    loadingBusinesses = true
    if(!props.retrieving){
      props.getFacebookBusinesses()
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.facebookBusinesses.loading} doTranslate={true}/>
    }
  }
  return props.render({loadingBusinesses,businesses:props.businesses})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresFacebookBusinesses:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresFacebookBusinesses
