import React                from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  Typography,
  Select,
  MenuItem
}                           from '@material-ui/core'
import InventoryCountGraph  from './graphs/InventoryCountGraph'
import InventoryStats       from '../../model/http/Reporting/InventoryStats'
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                           from 'react-localize-redux'
import {translationPaths}   from '../../translations/translations'

interface InventoryCountOwnProps extends LocalizeContextProps{
  stats : InventoryStats[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inventoryCount : {
      padding : 30,
      marginBottom : 30
    },
    header : {
      display : 'flex',
      justifyContent : 'space-between'
    },
    unavailable : {
      height : 300,
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
    }
  })
)

interface DataByDate {
  new : number
  used: number
  dealers : Set<number>
}

interface Data {
  [date:string] : DataByDate
}

export type SelectValue = 'both' | 'new' | 'used'

const InventoryCount = (props:InventoryCountOwnProps) => {
  const [select,setSelect] = React.useState<SelectValue>("both")
  const handleSelect = (event:React.ChangeEvent<{name?: string | undefined;value: unknown}>) => {
    const temp = event.target.value as SelectValue
    setSelect(temp)
  }
  const temp:Data = props.stats.reduce<Data>((acc,curr)=>{
    if(!acc[curr.date]){
      acc[curr.date] = {
        new : 0,
        used : 0,
        dealers : new Set()
      }
    }
    acc[curr.date][curr.state.toLowerCase() as 'new' | 'used'] += curr.numberOfActiveVehicles
    acc[curr.date].dealers.add(curr.accountId)
    return acc
  },{})

  const data = Object.keys(temp).map(datum=>{
    return {date:datum,usedCars:temp[datum].used,newCars:temp[datum].new,dealersCount:temp[datum].dealers.size}
  })
  const classes = useStyles()

  return (
    <Card className={classes.inventoryCount}>
      <div className={classes.header}>
        <Typography variant="h6">{props.translate(translationPaths.marketInsights.inventoryCountGraph.title)}</Typography>
        <Select
          variant='outlined'
          value={select}
          onChange={handleSelect}
        >
          <MenuItem value="both">{props.translate(translationPaths.marketInsights.inventoryCountGraph.newAndUsed)}</MenuItem>
          <MenuItem value="new">{props.translate(translationPaths.marketInsights.inventoryCountGraph.new)}</MenuItem>
          <MenuItem value="used">{props.translate(translationPaths.marketInsights.inventoryCountGraph.used)}</MenuItem>
        </Select>
      </div>
      {
        props.stats.length === 0
        ? <div className={classes.unavailable}><Typography><Translate id={translationPaths.marketInsights.unavailableData} /></Typography></div>
        : <InventoryCountGraph dataToDisplay={select} data={data} usedCarsBarColor="#bc5090" newCarsBarColor="#58508d" inventoryColor="#cc8400" />
      }
    </Card>
  )
}

export default withLocalize(InventoryCount)
