import {
  makeStyles,
  Theme,
  withStyles,
  createStyles,
  TableCell,
  TableRow
}                            from "@material-ui/core"

const useStyles = makeStyles((theme:Theme)=>({
  user : {
    padding : 20,
    width:800,
  },
  container : {
    padding : 10,
    display:'flex',
    justifyContent:'space-around',
    alignItems : 'center'
  },
  table : {

  }
}))

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    root : {
      '&:nth-of-type(odd)' : {
        backgroundColor : theme.palette.action.hover
      }
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
    },
  }),
)(TableRow);

export default useStyles
