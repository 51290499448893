import React                          from 'react'
import useStyles, {
  StyledTableCell,
  StyledTableRow,
  TotalTableCell
}                                     from './OverviewByMonthStyles'
import SummaryTemplate, {
  Breakdown,
  profitCenterArray,
  ProfitCenter
}                                     from '../../../model/budgetEstimator/Summary'
import  { Month, sortMonths }         from '../../../model/constant/Months'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography
}                                     from '@material-ui/core'
import { formatMoney }                from '../../../utils/MoneyFormatting'
import {
  getMediaSpendOfMonthByProfitCenter,
  getManagementFeeOfMonthByProfitCenter,
  getBaseManagementFeeOfMonthByProfitCenter,
  getCampaignDiscountOfMonthByProfitCenter,
  getExtraDiscountOfMonthByProfitCenter,
  getTotalBilledByMonthByProfitCenter
}                                     from '../../../model/budgetEstimator/Calculations/BudgetCalculations'

interface OverallByMonthOwnProps {
  breakdown       : Breakdown
  totalMediaSpend : number
  summary         : SummaryTemplate
  breakdownByProfitCenter : Record<ProfitCenter,{
    mediaSpend              : number,
    totalBilled             : number
    totalManagementFee      : number
    totalCampaignDiscount   : number
    totalExtraDiscount      : number
    totalBaseManagementFee  : number
  }>
  newTargets  : number
  usedTargets : number
}


const OverallByMonth = (props:OverallByMonthOwnProps) => {
  const classes = useStyles()
  const {totalMediaSpend,breakdown,breakdownByProfitCenter,newTargets,usedTargets} = {...props}
  const totalTargets = newTargets + usedTargets
  const mediaSpendByMonth = getMediaSpendOfMonthByProfitCenter(breakdown)
  const managementFeeByMonth = getManagementFeeOfMonthByProfitCenter(breakdown)
  const baseManagementFeeByMonth = getBaseManagementFeeOfMonthByProfitCenter(breakdown)
  const campaignDiscountByMonth = getCampaignDiscountOfMonthByProfitCenter(breakdown)
  const extraDiscountByMonth = getExtraDiscountOfMonthByProfitCenter(breakdown)
  const totalBilledByMonth = getTotalBilledByMonthByProfitCenter(breakdown)
  const monthsSorted = sortMonths(props.breakdown.startingMonth)
  const totalMediaSpendByMonth = (month:Month) => {
    return mediaSpendByMonth(month,ProfitCenter.NEW) + mediaSpendByMonth(month,ProfitCenter.USED) + mediaSpendByMonth(month,ProfitCenter.NONE)
  }
  const totalBilledSumByMonth = (month:Month) => {
    return totalBilledByMonth(month,ProfitCenter.NEW) + totalBilledByMonth(month,ProfitCenter.USED) + totalBilledByMonth(month,ProfitCenter.NONE)
  }
  const totalDiscountByMonth = (month:Month) => {
    return campaignDiscountByMonth(month,ProfitCenter.NEW) + campaignDiscountByMonth(month,ProfitCenter.USED) + campaignDiscountByMonth(month,ProfitCenter.NONE) + extraDiscountByMonth(month,ProfitCenter.NEW) + extraDiscountByMonth(month,ProfitCenter.USED) + extraDiscountByMonth(month,ProfitCenter.NONE)
  }
  const totalManagementFeeByMonth = (month:Month) => {
    return managementFeeByMonth(month,ProfitCenter.NEW) + managementFeeByMonth(month,ProfitCenter.USED) + managementFeeByMonth(month,ProfitCenter.NONE)
  }
  return (
    <div className={classes.overallByMonth}>
      <TableContainer className={classes.table}>
        <Table>
          {profitCenterArray.map(profitCenter=>{
            return (
              <React.Fragment key={profitCenter}>
                <TableHead key={profitCenter}>
                  <TableRow>
                    <StyledTableCell className={classes.headerFirstCell}></StyledTableCell>
                    {monthsSorted.map(month=>{
                      return <StyledTableCell key={month} align="center">{month.toUpperCase()}</StyledTableCell>
                    })}
                    <StyledTableCell align="center">Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell><Typography color="primary" variant="button">{profitCenter}</Typography></StyledTableCell>
                    {monthsSorted.map(month=>{
                      return <StyledTableCell key={month} align="center"></StyledTableCell>
                    })}
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sales Target</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} align="center">{props.summary.targets[profitCenter][month]}</TableCell>
                    })}
                    <TableCell align="center">{newTargets}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sales %</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} align="center">{((props.summary.targets[profitCenter][month]/totalTargets*100)||0).toFixed(2)}%</TableCell>
                    })}
                    <TableCell align="center">{((breakdownByProfitCenter[ProfitCenter[profitCenter]].mediaSpend/totalMediaSpend*100)|| 0).toFixed(2)}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Media spend</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} align="center">{formatMoney(mediaSpendByMonth(month,ProfitCenter[profitCenter])||0)}</TableCell>
                    })}
                    <TableCell align="center">{formatMoney(
                      monthsSorted.reduce((acc,month)=>acc+mediaSpendByMonth(month,ProfitCenter[profitCenter]),0)
                    )}</TableCell>
                  </TableRow>
                  <StyledTableRow >
                    <TableCell className={classes.baseManagementFee}>Base management Fee</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} className={classes.baseManagementFee} align="center">{formatMoney(baseManagementFeeByMonth(month,ProfitCenter[profitCenter])||0)}</TableCell>
                    })}
                    <TableCell className={classes.baseManagementFee} align="center">{formatMoney(
                      monthsSorted.reduce((acc,month)=>acc+baseManagementFeeByMonth(month,ProfitCenter[profitCenter]),0)
                    )}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell className={classes.discount}>Campaign Discount</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} className={classes.discount} align="center">{formatMoney(campaignDiscountByMonth(month,ProfitCenter[profitCenter])||0)}</TableCell>
                    })}
                    <TableCell className={classes.discount} align="center">{formatMoney(
                      monthsSorted.reduce((acc,month)=>acc+campaignDiscountByMonth(month,ProfitCenter[profitCenter]),0)
                    )}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell className={classes.discount}>Extra discount</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} className={classes.discount} align="center">{formatMoney(extraDiscountByMonth(month,ProfitCenter[profitCenter])||0)}</TableCell>
                    })}
                    <TableCell className={classes.discount} align="center">{formatMoney(
                      monthsSorted.reduce((acc,month)=>acc+extraDiscountByMonth(month,ProfitCenter[profitCenter]),0)
                    )}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell className={classes.discountedManagementFee}>Management Fee</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} className={classes.discountedManagementFee} align="center">{formatMoney(managementFeeByMonth(month,ProfitCenter[profitCenter])||0)}</TableCell>
                    })}
                    <TableCell className={classes.discountedManagementFee} align="center">{formatMoney(
                      monthsSorted.reduce((acc,month)=>acc+managementFeeByMonth(month,ProfitCenter[profitCenter]),0)
                    )}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell className={classes.totalBilled}>Total billed</TableCell>
                    {monthsSorted.map(month=>{
                      return <TableCell key={month} className={classes.totalBilled} align="center">{formatMoney(totalBilledByMonth(month,ProfitCenter[profitCenter])||0)}</TableCell>
                    })}
                    <TableCell className={classes.totalBilled} align="center">{formatMoney(
                      monthsSorted.reduce((acc,month)=>acc+totalBilledByMonth(month,ProfitCenter[profitCenter]),0)
                    )}</TableCell>
                  </StyledTableRow>
                </TableBody>
              </React.Fragment>
            )
          })}
          <TableBody>
            <TableRow>
              <TotalTableCell>Total media spend</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalMediaSpendByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>acc+totalMediaSpendByMonth(month),0))}</TotalTableCell>
            </TableRow>
            <TableRow>
              <TotalTableCell>Total management Fee</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalManagementFeeByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>acc+totalManagementFeeByMonth(month),0))}</TotalTableCell>
            </TableRow>
            <TableRow>
              <TotalTableCell>Total discount</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalDiscountByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>acc+totalDiscountByMonth(month),0))}</TotalTableCell>
            </TableRow>
            <TableRow>
              <TotalTableCell>Total billed</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalBilledSumByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>acc+totalBilledSumByMonth(month),0))}</TotalTableCell>
          </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}

export default OverallByMonth
