import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Creating {
  identityVariants      : boolean
  adTemplates           : boolean
  strategy              : boolean
  facebookAdAccountId   : boolean
  facebookConfiguration : boolean
}

const Creating = createReducer<Creating>(
  {
    identityVariants      : false,
    adTemplates           : false,
    strategy              : false,
    facebookAdAccountId   : false,
    facebookConfiguration : false,
  },
  {
    [ActionTypes.CREATING_FACEBOOK_AD_TEMPLATES] : (state,action) => ({
      ...state,
      adTemplates : action.payload
    }),
    [ActionTypes.CREATING_FACEBOOK_IDENTITY_VARIANTS] : (state,action) => ({
      ...state,
      identityVariants : action.payload
    }),
    [ActionTypes.CREATING_FACEBOOK_STRATEGY] : (state,action) => ({
      ...state,
      strategy : action.payload
    }),
    [ActionTypes.SUBMITTING_FACEBOOK_ACCOUNT_ID] : (state,action) => ({
      ...state,
      facebookAdAccountId : action.payload
    }),
    [ActionTypes.SUBMITTING_FACEBOOK_CONFIGURATION] : (state,action) => ({
      ...state,
      facebookConfiguration : action.payload
    }),
})

export default Creating
