import * as React             from "react"
import { connect }            from "react-redux"
import { State }              from "../../redux/reducers"
import { ThunkDispatch }      from "redux-thunk"
import { DispatchableAction } from "../../model/constant/actions"
import {
  Typography,
  Button,
}                             from "@material-ui/core"
import useStyles              from "./indexStyles"
import {push}                 from "connected-react-router"
import { Translate }          from "react-localize-redux"
import { translationPaths }   from "../../translations/translations"
interface HomeOwnProps{
}
const makeMapStateToProps = () => {
  return (state:State, ownProps:HomeOwnProps)=>{
    return {
      selectedAgency : state.Selection.Agency
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:HomeOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:HomeOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type HomeProps = ReturnType<typeof mergeProps>

const Home = (props:HomeProps) => {
  const classes = useStyles()
  return (
    <div className={classes.homeDiv}>
      <Typography className={classes.title} variant="h3">
        <Translate id={translationPaths.homePage.welcome}/>
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={()=>{
          props.push(`/Agency/${props.selectedAgency}/Report/Pacing` as any)
        }}
      >
        <Translate id={translationPaths.homePage.pacing}/>
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={()=>{
          props.push(`/Agency/${props.selectedAgency}/Insights` as any)
        }}
      >
        <Translate id={translationPaths.homePage.insights}/>
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={()=>{
          props.push(`/Performance` as any)
        }}
      >
        <Translate id={translationPaths.homePage.performance}/>
      </Button>
    </div>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(Home)
