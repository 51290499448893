enum BUTTONS {
  PROFILE                       = "PROFILE",
  SETTINGS                      = "SETTINGS",
  AGENCY_PROFILE                = "AGENCY_PROILE",
  ACCOUNTS                      = "ACCOUNTS",
  ACTIVATION                    = "ACTIVATION",
  MANAGE                        = "MANAGE",
  REPORTING                     = "REPORTING",
  PACING                        = "PACING",
  INSIGHTS                      = "INSIGHTS",
  SUPPORT                       = "SUPPORT",
  BILLING                       = "BILLING",
  USERS                         = "USERS",
  DESIGN_SYSTEM                 = "DESIGN_SYSTEM",
  HOME                          = "HOME",
  BUDGET_CALCULATOR             = "BUDGET_CALCULATOR"
}
export default BUTTONS
