import { combineReducers } from "redux"
import Retrieving          from './Retrieving'
import FailedRetrieving    from './FailedRetrieving'
import Feeds               from './Feeds'

const Accounts = combineReducers({
  Feeds,
  FailedRetrieving,
  Retrieving,
})

export default Accounts
