import React                        from 'react'
import styles                       from './indexStyles'
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Fade,
  Grow,
  Slide,
}                                   from '@material-ui/core'
import { connect }                  from 'react-redux'
import Breadcrumbs                  from '../../components/breadcrumbs/Breadcrumbs'
import { State }                    from '../../redux/reducers'
import { ThunkDispatch }            from 'redux-thunk'
import { Action }                   from 'redux'
import Targets                     from './Targets'
import InformationAndBudget         from './ContactInfo'
import Discount                     from './Discount'
import Overall                      from './Overview'
import BudgetAllocation             from './Budget'
import Products                     from './Products'
import SummaryTemplate, {
  Status, ProfitCenter,
}                                   from '../../model/budgetEstimator/Summary'
import {
  getBreakdown,
  createSummary,
  updateSummary
}                                   from '../../actions/Trffk-salestool/Summary'
import { createUpdateDraftSummary } from '../../actions/creators'
import requiresSalesCampaigns       from '../../components/loaders/Sales/loadSalesCampaigns'
import requiresSalesProducts        from '../../components/loaders/Sales/loadSalesProducts'
import SalesCampaign                from '../../model/budgetEstimator/SalesCampaign'
import CircularLoading              from '../../components/loadings/CircularLoading'
import SalesProduct                 from '../../model/budgetEstimator/SalesProduct'
import { calculateStateAllocation } from '../../model/budgetEstimator/Calculations/ProductsCalculations'

interface BudgetCalculatorOwnProps {
  goBackToList    : () => void
  salesCampaigns  : SalesCampaign[]
  salesProducts   : SalesProduct[]
}

const mapStateToProps = (state:State, ownProps:BudgetCalculatorOwnProps)=>{
  const agency = state.Agencies.List.find(a => a.id === state.Selection.Agency)
  return {
    selectedAgencyName      : agency && agency.name,
    breakdown               : state.Sales.Breakdown,
    summary                 : state.Sales.DraftSummary as SummaryTemplate,
    creating                : state.Sales.Creating.summary,
    updating                : state.Sales.Updating.summary
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:BudgetCalculatorOwnProps) => {
  return {
    getBreakdown : (...args:Parameters<typeof getBreakdown>)=>dispatch(getBreakdown(...args)),
    updateDraftSummary : (...args:Parameters<typeof createUpdateDraftSummary>)=>dispatch(createUpdateDraftSummary(...args)),
    createSummary : (...args:Parameters<typeof createSummary>) => dispatch(createSummary(...args)),
    updateSummary : (...args:Parameters<typeof updateSummary>) => dispatch(updateSummary(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:BudgetCalculatorOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type BudgetCalculatorProps = ReturnType<typeof mergeProps>

function getSteps() {
  return ['Contact information', "Budget & Targets","Products",'Discount']
}

const BudgetCalculator = (props:BudgetCalculatorProps) => {

  const {summary,breakdown} ={...props}
  const [activeStep, setActiveStep] = React.useState<Record<string,number>>({current:0,previous:0})
  const [creatingProduct,setCreatingProduct] = React.useState<boolean>(false)

  if(!breakdown) {
    props.getBreakdown(props.summary)
  }

  const disabled = props.summary.status === Status.ACTIVE
  const classes = styles()
  const steps = getSteps()

  const handleCreatingProduct = (creating:boolean) => {
    setCreatingProduct(creating)
  }
  const handleGetBreakdown = (summary:SummaryTemplate) => {
    props.getBreakdown(summary)
  }
  const handleUpdateSummary = (summary:SummaryTemplate) => {
    props.updateDraftSummary({summary})
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return {current:prevActiveStep.current + 1,previous:prevActiveStep.current}
    })
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      return {current:prevActiveStep.current - 1,previous:prevActiveStep.current}
    })
  }
  const handleReset = () => {
    setActiveStep(prevActiveState=>({...prevActiveState,current:0,previous:0}))
  }

  //Summary Handler
  const submitSummary = () => {
    if(summary.summaryId !== -1) {
      console.log('I AM UPDATING')
      props.updateSummary(summary,summary.summaryId+'')
    }
    else {
      console.log('I AM CREATING')
      props.createSummary(summary)
    }
  }

  //Allocations
  const totalNewAllocation = calculateStateAllocation(summary.products,ProfitCenter.NEW)
  const totalUsedAllocation = calculateStateAllocation(summary.products,ProfitCenter.USED)
  const totalAdditionalBudgetAllocation = calculateStateAllocation(summary.products,ProfitCenter.NONE)

  const budgetError = breakdown?.amountsSummary ?
    (summary.budget.NEW && (breakdown.amountsSummary.totalTargets.NEW === 0 || !summary.budgetShare.NEW))   ||
    (summary.budget.USED && (breakdown.amountsSummary.totalTargets.USED === 0 || !summary.budgetShare.USED))||
    (!summary.budget.NEW && breakdown.amountsSummary.totalTargets.NEW !==   0)                       ||
    (!summary.budget.USED && breakdown.amountsSummary.totalTargets.USED !== 0)                       ||
    (!summary.budget.NEW && !summary.budget.USED && !breakdown.amountsSummary.totalTargets.NONE)
    : false
  const productsError = breakdown?.amountsSummary ? (summary.budget.NEW ? totalNewAllocation !== 100 : totalNewAllocation > 0) ||
    (summary.budget.USED ? totalUsedAllocation !== 100 : totalUsedAllocation > 0)                  ||
    (breakdown.amountsSummary.totalTargets.NONE ? totalAdditionalBudgetAllocation !== 100 : totalAdditionalBudgetAllocation > 0)
  : false
  const productNotAllocated=props.summary.products.find(p=>{
    return p.allocation===0
  })
  const managementFeeError = !Boolean(summary.managementFee.slug)
  const disableButton = (activeStep.current === 2 && (productsError || productNotAllocated !== undefined || creatingProduct ) )
  || (activeStep.current === 1 && (budgetError || managementFeeError))
  || (activeStep.current === 0 && (!props.summary.contactId || !props.summary.salesforceAccountId))

  function getStepContent(stepIndex: number,previousStep:number) {
    switch (stepIndex) {
      case 0:
        return (
          <Fade in={true} >
            <div className={classes.informationAndBudget}>
              <InformationAndBudget/>
            </div>
          </Fade>
        )
      case 1:
        return (
          <Slide in direction={previousStep<stepIndex ? "left" : "right"}>
          <div>
            {budgetError && <Typography style={{width:'100%',display:'flex',justifyContent:'flex-end'}} variant="caption" color="secondary">Make sure you assign at least a budget and targets if needed</Typography>}
            <div className={classes.vehiclesAndBudget}>
              <BudgetAllocation
                campaigns={props.salesCampaigns}
                disabled={disabled}
                summary ={summary}
                totalBudget={breakdown.amountsSummary.totalBudget}
                totalManagementFee={breakdown.amountsSummary.totalManagementFee}
                totalMediaSpend={breakdown.amountsSummary.totalMediaSpend}
                handleGetBreakdown={handleGetBreakdown}
                handleUpdateSummary={handleUpdateSummary}
              />
              <Targets
                disabled={disabled}
                summary={summary}
                usedTarget={breakdown.amountsSummary.totalTargets.USED}
                newTarget={breakdown.amountsSummary.totalTargets.NEW}
                additionalBudget={breakdown.amountsSummary.totalTargets.NONE}
                handleGetBreakdown={handleGetBreakdown}
                handleUpdateSummary={handleUpdateSummary}
              />
            </div>
            {budgetError && <Typography style={{width:'100%',display:'flex',justifyContent:'flex-end'}} variant="caption" color="secondary">Make sure you assign at least a budget and targets if needed</Typography>}
          </div>
          </Slide>
        )
      case 2:
        return (
          <div>
          <Slide in direction={previousStep<stepIndex ? "left" : "right"}>
            <div className={classes.products}>
              <Products
                breakdown={breakdown}
                disabled={disabled}
                summary={summary}
                products={props.salesProducts}
                handleGetBreakdown={handleGetBreakdown}
                handleUpdateSummary={handleUpdateSummary}
                totalNewAllocation={totalNewAllocation}
                totalUsedAllocation={totalUsedAllocation}
                totalAdditionalBudgetAllocation={totalAdditionalBudgetAllocation}
                handleCreatingProductError={handleCreatingProduct}
              />
            </div>
          </Slide>
          </div>
       )
        case 3:
          return (
            <Slide in direction={previousStep<stepIndex ? "left" : "right"}>
              <div className={classes.thirdSection}>
                <Discount
                  disabled={disabled}
                  summary = {summary}
                  totalDiscount={breakdown.amountsSummary.totalExtraDiscount}
                  handleGetBreakdown={handleGetBreakdown}
                  handleUpdateSummary={handleUpdateSummary}
                />
              </div>
            </Slide>
        )
    }
  }
  if(props.creating) {
    return <CircularLoading loadingMessage="Creating sales proposal and generating deck"  />
  }
  if(props.updating) {
    return <CircularLoading loadingMessage="Updating sales proposal and generating deck" />
  }
  return (
    <div className={classes.budgetCalculator}>
      <Breadcrumbs
        agency={props.selectedAgencyName ? props.selectedAgencyName : ""}
        section="Budget Calculator"
      />
      <Typography className={classes.title} variant="h6">Budget Calculator</Typography>
      <Stepper activeStep={activeStep.current} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep.current === steps.length ? (
          <div>
            <div style={{display:'flex',justifyContent : 'flex-end',margin:20}}>
              <Button
                color="primary"
                disabled={activeStep.current === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleReset}>Start again</Button>
              <Button onClick={submitSummary}>Save</Button>
            </div>
            <Grow in>
            <div className={classes.thirdSection}>
              <Overall
                breakdown={breakdown}
                newTargets={breakdown.amountsSummary.totalTargets.NEW}
                usedTargets={breakdown.amountsSummary.totalTargets.USED}
                summary={summary}
              />
            </div>
            </Grow>
            <div style={{display:'flex',justifyContent : 'flex-end',margin:20}}>
              <Button
                color="primary"
                disabled={false}
                onClick={()=>{
                  activeStep.current === 0 ? props.goBackToList() : handleBack()
                }}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button disabled={disableButton} color="primary" variant="contained" onClick={handleReset}>Start again</Button>
              <Button onClick={submitSummary}>Save</Button>
            </div>
          </div>
        ) : (
          <div>
            <div style={{display:'flex',justifyContent : 'flex-end',margin:20}}>
              <Button
                color="primary"
                disabled={false}
                onClick={()=>{
                  activeStep.current === 0 ? props.goBackToList() : handleBack()
                }}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button disabled={disableButton} variant="contained" color="primary" onClick={handleNext}>
                {activeStep.current === steps.length - 1 ? 'Overview' : 'Next'}
              </Button>
            </div>
            <div className={classes.instructions}>{getStepContent(activeStep.current,activeStep.previous)}</div>
            <div style={{display:'flex',justifyContent : 'flex-end',margin:20}}>
              <Button
                color="primary"
                disabled={false}
                onClick={()=>{
                  activeStep.current === 0 ? props.goBackToList() : handleBack()
                }}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button disabled={disableButton} variant="contained" color="primary" onClick={handleNext}>
                {activeStep.current === steps.length - 1 ? 'Overview' : 'Next'}
              </Button>
            </div>
          </div>
        )}
    </div>
  )
}
export default requiresSalesCampaigns()(
  requiresSalesProducts()(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(
      BudgetCalculator
    )
  )
)
