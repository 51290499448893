import styles             from './indexStyles'
import React              from 'react'
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
}                          from '@material-ui/core'
import {
  StatPerAccount,
  StatPerChannelPerAccount
}                          from '../../../model/store/statistics/PacingStats'
import DealerTable         from './DealerTable'
import theme               from '../../../Theme/Main'
import {
  LocalizeContextProps,
  withLocalize
}                          from 'react-localize-redux'
import {translationPaths}  from '../../../translations/translations'

interface PacingTableOwnProps extends LocalizeContextProps {
  data                      : StatPerAccount[]
  statsPerChannelPerAccount : StatPerChannelPerAccount[]
}

interface TabPanelProps {
  children?: React.ReactNode
  index    : number
  value    : number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index} = props

  return (
    <Typography
      component="div"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

const PacingTable = (props:PacingTableOwnProps) => {
  const classes = styles()
  const [value, setValue] = React.useState<number>(0)
  const data = [...props.data].sort((a,b)=>{
    if(a.budget>b.budget) return -1
    if(a.budget<b.budget) return 1
    return 0
  })

  // To be changed when dealer group data arrives
  const groupsData = [...props.data].sort((a,b)=>{
    if(a.budget>b.budget) return -1
    if(a.budget<b.budget) return 1
    return 0
  })

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Card className={classes.container}>
      <div>
        <Tabs
          className={classes.tabsParent}
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            "style": {
              backgroundColor: theme.palette.primary.main,
              height: 5,
            }
          }}
        >
          <Tab label={props.translate(translationPaths.pacingOverview.table.DEALERS)} />
          <Tab label={props.translate(translationPaths.pacingOverview.table.DEALERGROUPS)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <DealerTable data={data} statsPerChannelPerAccount={props.statsPerChannelPerAccount} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DealerTable data={groupsData} statsPerChannelPerAccount={props.statsPerChannelPerAccount} />
      </TabPanel>
    </Card>
  )
}

export default withLocalize(PacingTable)
