import {combineReducers}        from "redux"
import List                     from "./List"
import Retrieving               from "./Retrieving"
import Failed                   from "./Failed"

const Agencies = combineReducers({
  List,
  Retrieving,
  Failed
})

export default Agencies
