import React          from 'react'
import {
  Button,
  Card,
  CardHeader,
  Typography,
  CardContent,
  CardActions,
  Theme,
  Modal
}                     from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

interface MuiModalProps {
  open                 : boolean
  title                : string
  content              : string
  primaryButtonText    : string
  secondaryButtonText  : string
  variant              : 'primary' | 'secondary'
  secondaryButtonClick : () => void
  primaryButtonClick   : () => void
}

const useStyles = makeStyles((theme:Theme)=>({
  root : {
    maxWidth : 600,
  },
  buttons : {
    display : 'flex',
    justifyContent : 'flex-end'
  },
  cancelButton : {
    color : "#666"
  },
  modal : {
    display:'flex',
    alignItems : 'center',
    justifyContent:'space-around'
  }
}))

const MuiModal = (props:MuiModalProps) => {
  const classes = useStyles()
  return (
    <Modal className={classes.modal} open={props.open}>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Typography color={props.variant} variant="h6">
              {props.title}
            </Typography>
          }
        />
        <CardContent>
          {props.content}
        </CardContent>
        <CardActions className={classes.buttons}>
          <Button
            className={classes.cancelButton}
            onClick={props.secondaryButtonClick}
          >
            {props.secondaryButtonText}
          </Button>
          <Button
            color={props.variant}
            variant="contained"
            onClick={props.primaryButtonClick}
          >
            {props.primaryButtonText}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  )
}

export default MuiModal
