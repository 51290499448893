import React                   from 'react'
import {
  Card,
  Typography,
}                              from '@material-ui/core'

import SummaryTemplate         from '../../../model/budgetEstimator/Summary'
import useStyles               from './BudgetAllocationStyles'
import SalesCampaign           from '../../../model/budgetEstimator/SalesCampaign'
import BudgetInputs            from './BudgetInputs'
import ManagementFee           from './ManagementFee'


interface BudgetAllocation {
  summary                       : SummaryTemplate
  totalBudget                   : number
  totalManagementFee            : number
  totalMediaSpend               : number
  disabled                      : boolean
  campaigns                     : SalesCampaign[]
  handleGetBreakdown            : (sunnary:SummaryTemplate)=>void
  handleUpdateSummary           : (summary:SummaryTemplate)=>void
}

const BudgetAllocation = (props:BudgetAllocation) => {
  const classes = useStyles()
  const {disabled,handleGetBreakdown,handleUpdateSummary,summary,totalMediaSpend,totalManagementFee,campaigns,totalBudget} = {...props}

  return (
    <Card className={classes.BudgetAllocation}>
      <Typography variant="button" color="primary" className={classes.title}>Budget</Typography>
      <div className={classes.form}>
        <BudgetInputs
          disabled={disabled}
          handleGetBreakdown={handleGetBreakdown}
          handleUpdateSummary={handleUpdateSummary}
          summary={summary}
        />
        <ManagementFee
          disabled={disabled}
          handleGetBreakdown={handleGetBreakdown}
          handleUpdateSummary={handleUpdateSummary}
          summary={summary}
          totalMediaSpend={totalMediaSpend}
          totalManagementFee={totalManagementFee}
          campaigns={campaigns}
          totalBudget={totalBudget}
        />
      </div>
    </Card>
  )
}

export default BudgetAllocation
