import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Creating {
  summary : boolean
}
const initial = {
  summary : false
}

const Creating = createReducer<Creating>(initial,{
  [ActionTypes.CREATING_SUMMARY] : (state,action) => {
    return {
      ...state,
      summary : action.payload
    }
  }
})

export default Creating
