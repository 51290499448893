import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  TableCell,
  TableRow,
  Tabs,
}                 from "@material-ui/core"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overall : {
      padding : 20,
      width : '100%',
    },
    title : {
      fontSize : 20,
    },
    managementFee : {
      display : 'flex',
      marginTop : 30,
      alignItems :'center',
    },
    table : {
      marginTop : 20,
      borderRadius : 5
    },
    textLineThrough : {
      color : 'red',
      textDecoration : 'line-through'
    },
    discountText : {
      color:'green',
      textWeight : 'bold'
    },
    discount : {
      color : 'red',
    },
    baseManagmentFee : {
      color: 'black',
      textDecoration : 'line-through'
    },
    totalBilled : {
      fontWeight : 'bold',
    }
  })
)

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      width: '33%',
      fontWeight : 'bold'
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)
export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow)
export const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor : 'white',
  },
  indicator: {
  },
})(Tabs)

export default useStyles
