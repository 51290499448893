import React                             from 'react'
import useStyles                         from './ContactInfoStyles'
import {
  FormControl,
  TextField,
  InputAdornment
}                                        from '@material-ui/core'
import { AccountCircle }                 from '@material-ui/icons'
import MailIcon                          from '@material-ui/icons/Mail'
//import HttpIcon                        from '@material-ui/icons/Http'
import PhoneIcon                         from '@material-ui/icons/Phone'
import PeopleIcon                        from '@material-ui/icons/People'
import SalesforceAccount                 from '../../../model/Salesforce/Account'
import SalesforceContact                 from '../../../model/Salesforce/Contact'
import requiresSalesforceAccountContacts from '../../../components/loaders/Sales/loadSalesforceAccountContacts'
import Autocomplete, {
  createFilterOptions
}                                        from '@material-ui/lab/Autocomplete'
import SummaryTemplate                   from '../../../model/budgetEstimator/Summary'

interface DealerContactOwnProps {
  selectedAccount      : SalesforceAccount | null
  onContactSelect      : (contactId:string) => void
  summary              : SummaryTemplate
  contactInfos        ?: SalesforceContact[]
  selectedDealerGroup ?: string
}

const DealerContact = (props:DealerContactOwnProps) => {
  const classes = useStyles()
  const summaryContact = props.contactInfos?.find(c=>c.SalesforceId === props.summary.contactId)
  const [selectedContact,setSelectedContact] = React.useState<SalesforceContact|null>(summaryContact || null)
  const onContactSelect = (event: any, newValue: SalesforceContact | null) => {
    if(newValue) {
      props.onContactSelect(newValue.SalesforceId)
      setSelectedContact(newValue)
    }
  }
  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          disabled
          className={classes.input}
          label="Dealer Group Name"
          variant="outlined"
          value={props.selectedDealerGroup || ""}
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.adornment} position="start">
                <PeopleIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {props.contactInfos && (
        <>
          <FormControl className={classes.formControl}>
            <Autocomplete
              value={selectedContact}
              onChange={onContactSelect}
              filterOptions={createFilterOptions({
                limit : 200
              })}
              options={props.contactInfos}
              getOptionLabel={(option) => option.Name}
              renderInput={(params) => <TextField fullWidth {...params} label="Contact Name" variant="outlined" />}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              disabled
              className={classes.input}
              label="Contact Name"
              variant="outlined"
              value={selectedContact?.Name || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.adornment} position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              disabled
              className={classes.input}
              label="Contact Phone"
              variant="outlined"
              value={selectedContact?.Phone || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.adornment} position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              disabled
              className={classes.input}
              label="Contact Email"
              variant="outlined"
              value={selectedContact?.Email || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment className={classes.adornment} position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </>)
        }
      {/* <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          className={classes.input}
          label="Primary URL"
          variant="outlined"
          value="https://www.johnsmithurl.com"
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.adornment} position="start">
                <HttpIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl> */}
    </>
  )
}

export default requiresSalesforceAccountContacts()(DealerContact)
