import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import Feed              from "../../../model/feeds/Feed"

interface FeedsReducer {
  [agencyId:string] : {
    [accountId:number] : Feed[]
  }
}

const Feeds = createReducer<FeedsReducer>({},{
  [ActionTypes.GET_ACCOUNT_FEEDS] : (state,action) => {
    if(state[action.payload.agencyId]) {
      return {
        ...state,
        [action.payload.agencyId] : {
          ...state[action.payload.agencyId],
          [action.payload.accountId] : action.payload.feeds
        }
      }
    }
    else {
      return {
        ...state,
        [action.payload.agencyId] : {
          [action.payload.accountId] : action.payload.feeds
        }
      }
    }
  },
  [ActionTypes.CLEAR_ACCOUNT_FEEDS] : (state,action) => {
    if(state[action.payload.agencyId]) {
      const newState = {
        ...state,
        [action.payload.agencyId] : {...state[action.payload.agencyId]}
      }
      delete newState[action.payload.agencyId][action.payload.accountId]
      return newState
    }
    return state
  }
})

export default Feeds
