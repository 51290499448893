
import React                         from 'react'
import { TickDataString }            from '../../../../model/graphs/CustomComponents'
import theme                         from '../../../../Theme/Main'
import {
  withLocalize,
  LocalizeContextProps
}                                    from 'react-localize-redux'


interface CustomTickChannelsX extends TickDataString, LocalizeContextProps {}

const CustomTickChannelsX = (props: CustomTickChannelsX) => {
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text
        y={0}
        x={0}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: theme.palette.primary.light,
          fontSize: 12,
          fontWeight: "bold",
          strokeWidth: '.8',
        }}
      >
        {
          props.payload &&
          props.translate(`pacingOverview.pacingPerChannel.${[(props.payload.value).toLowerCase()]}`)
        }
        </text>
    </g>
  )
}

export default withLocalize(CustomTickChannelsX)
