export enum Month {
 'january'    = 'january',
 'february'   = 'february',
 'march'      = 'march',
 'april'      = 'april',
 'may'        = 'may',
 'june'       = 'june',
 'july'       = 'july',
 'august'     = 'august',
 'september'  = 'september',
 'october'    = 'october',
 'november'   = 'november',
 'december'   = 'december',
}
const MONTHS:Month[] = [
  Month.january,
  Month.february,
  Month.march,
  Month.april,
  Month.may,
  Month.june,
  Month.july,
  Month.august,
  Month.september,
  Month.october,
  Month.november,
  Month.december
]
const months:Record<Month,number> = {
  'january' : 0,
  'february' : 1,
  'march' : 2,
  'april' : 3,
  'may' : 4,
  'june': 5,
  'july' : 6,
  'august' : 7,
  'september' : 8,
  'october' : 9,
  'november' : 10,
  'december' : 11
}

export const sortMonths = (startingMonth:Month) => {
  const month = months[startingMonth]
  return [...MONTHS].sort((a,b)=>{
    let n1 = months[a]
    let n2 = months[b]
    if(n1<month) {
      n1 =  n1+12
    }
    if (n2 < month)
    {
      n2 = n2 + 12
    }
  return n1 - n2
  })
}

export default MONTHS
