import React              from 'react'
import {
  Menu,
  MenuItem,
  Divider,
  makeStyles,
  Theme,
  createStyles
}                         from '@material-ui/core'
import {
  LocalizeContextProps,
  withLocalize,
}                         from 'react-localize-redux'

interface LanguageMenuOwnProps extends LocalizeContextProps {
  anchor       : null | HTMLElement
  open         : boolean
  handleClose  : () => void
  onLangChange ?: (event:React.MouseEvent<HTMLElement>,languages:string) => void
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      justifyContent: 'center',
    }
  })
)

const LanguageMenu = (props:LanguageMenuOwnProps) => {
  const classes = styles()
  const handleLanguageChange = (event:React.MouseEvent<HTMLElement>) => {
    props.setActiveLanguage(event.currentTarget.title)
    props.handleClose()
    if(props.onLangChange) {
      props.onLangChange(event,event.currentTarget.title)
    }
  }
  return (
    <Menu
      transformOrigin={{vertical:'top',horizontal:'left'}}
      anchorEl={props.anchor}
      keepMounted
      open={props.open}
      onClose={props.handleClose}
  >
    <MenuItem className={classes.menuItem} onClick={handleLanguageChange} title="en">en</MenuItem>
    <Divider/>
    <MenuItem className={classes.menuItem} onClick={handleLanguageChange} title="fr">fr</MenuItem>
  </Menu>
  )
}

export default withLocalize(LanguageMenu)
