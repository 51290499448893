import BUTTONS  from '../model/constant/menuButtons'
import {Channel} from '../model/constant/channels'

type Translations = {
  [keys:string] : string[] | Translations
}
type TranslationPaths<T extends Translations> = {
  [K in keyof T] : T[K] extends Translations ? TranslationPaths<T[K]> : string
}

const recursivelyFindPathsInTranslations = <T extends Translations>(object:T, previousKeys:string[]=[]):TranslationPaths<T> => {
  const copy:any = {...object}
  for(const key of Object.keys(copy)){
    const keys = [...previousKeys, key]
    if(Array.isArray(copy[key])){
      copy[key] = keys.join(".")
    }
    else{
      copy[key] = recursivelyFindPathsInTranslations(copy[key], keys)
    }
  }
  return copy
}

const translations = {
  // I haven't figured out how to implement translations in the loaders correctly, but here's some of the job
  // -Christopher Sarao
    'permissionError' : {
      'accessForbidden' : [
        "Access forbidden",
        "Accès interdit"
      ],
      'helpMessage' : [
        "Check with the administrator of the system if you think you should have access to that page",
        "Vérifiez auprès de l'administrateur du système si vous pensez que vous devriez avoir accès à cette page"
      ]
    },
    'general' : {
      'accounts' : [
        'Accounts',
        'Comptes'
      ],
      'names' : [
        'Names',
        'Noms'
      ],
      'on' : [
        "On",
        "Activé"
      ],
      'off' : [
        "Off",
        "En pause"
      ],
      'french' : [
        "French",
        "Français"
      ],
      'english' : [
        "English",
        "Anglais"
      ],
      'refresh' : [
        "Refresh",
        "Actualiser"
      ],
      'campaigns' : [
        "Campaigns",
        "Campagnes"
      ],
      'setUpNow' : [
        "Set up now",
        "Configurer maintenant"
      ],
      'permissions' : {
        'readOnly' : [
          "Read only",
          "Lecture seulement"
        ]
      }
    },
    'user' : {
      'profile' : [
        "Profile",
        "Profil"
      ],
      'firstName' : [
        "First name",
        "Prénom"
      ],
      'lastName' : [
        "Last name",
        "Nom",
      ],
      'cellPhone' : [
        "Cell phone",
        "Téléphone portable",
      ],
      'officePhone' : [
        "Office phone",
        "Téléphone de bureau"
      ],
      'email' : [
        "Email",
        "Courriel"
      ],
      'copied' : [
        "Copied!",
        "Copié!"
      ]
    },
    'loaders' : {
      'accountFeeds' : {
        'loading' : [
          "Loading feeds",
          "Chargement des flux"
        ]
      },
      'accountsData' : {
        'loading' : [
          "Loading accounts data",
          "Chargement des données des comptes"
        ],
        'failed' : [
          "FAILED LOADING ACCOUNTS DATA",
          "ERREUR DE CHARGEMENT DES DONNEES DES COMPTES"
        ],
      },
      'facebookDefaultAdSets' : {
        'failed' : [
          'FAILED LOADING FACEBOOK DEFAULT AD SETS',
          "ECHEC DE CHARGEMENT DES ENSEMBLES D'ANNONCES PAR DEFAUT DE FACEBOOK"
        ],
        'loading' : [
          "Loading Facebook default ad sets",
          "Chargement des ensembles d'annonces par defaut de Facebook"
        ]
      },
      'facebookAdTemplates' : {
        'failed' : [
          'FAILED LOADING FACEBOOK AD TEMPLATES',
          "ECHEC DE CHARGEMENT DE MODELES D'ANNONCES FACEBOOK"
        ],
        'loading' : [
          "Loading Facebook Ad templates ",
          "Chargement de modèles d'annonces facebook "
        ]
      },
      'facebookIdentityVariants' : {
        'failed' : [
          'FAILED LOADING FACEBOOK IDENTITY VARIANTS',
          "ECHEC DE CHARGEMENT DES VARIANTES D'IDENTITÉ FACEBOOK"
        ],
        'loading' : [
          "Loading Facebook identity variants ",
          "Chargement des variantes d'identité Facebook "
        ]
      },
      'facebookPages' : {
        'failed' : [
          'FAILED LOADING FACEBOOK PAGES',
          "ECHEC DE CHARGEMENT DES PAGES FACEBOOK"
        ],
        'loading' : [
          "Loading Facebook pages ",
          "Chargement des pages Facebook "
        ]
      },
      'facebookBusinesses' : {
        'failed' : [
          'FAILED LOADING FACEBOOK BUSINESSES',
          "ECHEC DE CHARGEMENT DES ENTREPRISES FACEBOOK"
        ],
        'loading' : [
          "Loading Facebook businesses ",
          "Chargement des entreprises Facebook "
        ]
      },
      'facebookStrategies' : {
        'failed' : [
          'FAILED LOADING FACEBOOK STRATEGIES',
          "ECHEC DE CHARGEMENT DES STRATEGIES FACEBOOK"
        ],
        'loading' : [
          "Loading Facebook strategies ",
          "Chargement des strategies Facebook "
        ]
      },
      'facebookUnusedAdAccounts' : {
        'failed' : [
          'FAILED LOADING FACEBOOK UNUSED AD ACCOUNTS',
          "ECHEC DE CHARGEMENT DES COMPTES PUBLICITAIRES FACEBOOK INUTILISÉS"
        ],
        'loading' : [
          "Loading Facebook unused Ad accounts ",
          "Chargement des Comptes publicitaires Facebook inutilisés "
        ]
      },
      'agencies' : {
        'failed' : [
          'FAILED LOADING AGENCIES',
          'ÉCHEC DU CHARGEMENT DES AGENCES'
        ],
        'loading' : [
          'Loading agencies',
          'Chargement des agences '
        ]
      },
      'accounts' : {
        'failed': [
          'FAILED RETRIEVING AGENCY ACCOUNTS',
          'ÉCHEC DU CHARGEMENT DES COMPTES'
        ],
        'loading' : [
          'Loading agency accounts ',
          'Chargement des comptes '
        ]
      },
      'pacingStats' : {
        'loading' : [
          'Loading agency pacing stats ',
          'Chargement des stats de pacing '
        ]
      },
      'googleTrends' : {
        'loading' : [
          'Loading Google Trends ',
          'Chargement des tendances Google '
        ]
      },
      'inventoryStats' : {
        'loading' : [
          'Loading Inventory Stats ',
          `Chargement des stats d'inventaire `
        ]
      },
      'trackingStats' : {
        'loading' : [
          'Loading Tracking Stats ',
          `Chargement des stats de tracking `
        ]
      },
    },
    'agencySelection' : {
      'title' : [
        'Select an agency',
        'Choisissez une agence'
      ],
      'loadingAgencies' : [
        'Loading agencies',
        'Chargement des agences '
      ]
    },
    'appBar' : {
      'profileAndNotifications' : {
        'logOut' : [
          'Log Out',
          'Déconnexion'
        ]
      }
    },
    'pacingOverview' : {
      'loader' : [
        'Loading data ',
        'Chargement des données'
      ],
      'title' : [
        'Pacing Overview',
        'Aperçu du Pacing'
      ],
      'pacingLabel' : [
        'Pacing',
        'Pacing'
      ],
      'Budget' : [
        'Budget',
        'Budget'
      ],
      'Spent' : [
        'Spent',
        'Dépensé'
      ],
      'table' : {
        'DEALERS' : [
          'DEALERS',
          'REVENDEURS'
        ],
        'DEALERGROUPS' : [
          'DEALERGROUPS',
          'GROUPES DE CONCESSIONNAIRES'
        ],
        'BUDGET' : [
          'BUDGET',
          'BUDGET'
        ],
        'SPENT' : [
          'SPENT',
          'DÉPENSÉ'
        ],
        'PACING' : [
          'PACING',
          'PACING'
        ],
        'TOTAL' : [
          'TOTAL',
          'TOTAL'
        ],
        'rows' : [
          'rows',
          'lignes'
        ],
        'of' : [
          'of',
          'de'
        ],
        'search' : [
          'Search',
          'Recherche'
        ],
      },
      'tooltips' : {
        'Day' : [
          'Day',
          'Jour'
        ],
        'Region' : [
          'Region',
          'Région'
        ],
        'Pacing' : [
          'Pacing',
          'Pacing'
        ],
        'Budget' : [
          'Budget',
          `Budget`
        ]
      },
      'pacingPerChannel' : {
        [Channel.search] : [
          'Search',
          'Recherche'
        ],
        [Channel.display] : [
          'Display',
          'Bannière'
        ],
        [Channel.social] : [
          'Social',
          'Social'
        ],
        [Channel.video] : [
          'Video',
          'Vidéo'
        ],
      },
      'graphs' : {
        'budgetTier' : [
          'BUDGET TIER',
          'NIVEAU BUDGÉTAIRE'
        ],
        'channels' : [
          'CHANNELS',
          'CANAUX'
        ],
        'regions' : [
          'REGIONS',
          'RÉGIONS'
        ],
        'dailyTrend' : [
          'DAILY TREND',
          'TENDANCE QUOTIDIENNE'
        ]
      },
      'errorMessages' : {
        'noData' : [
          'NO DATA AVAILABLE FOR THE CURRENT SELECTED DATE',
          'PAS DE DONNÉES POUR LA DATE CHOISIE'
        ]
      }
    },
    'accounts' : {
      'index' : {
        'title' : [
          'Accounts List',
          'Liste des Comptes'
        ],
        'errors': {
          'permissionDenied': [
            'Permission denied',
            'Permission refusée'
          ],
          'permissionDeniedSubtitle': [
            "You don't have access to this account",
            "Vous n'avez pas accès à ce compte"
          ],
          'back': [
            'Back',
            'Retour'
          ],
          'notAvailable' : [
            "Google and Bing activation feature is not available right now.",
            "La fonctionnalité d'activation de Google et Bing n'est pas disponible pour le moment."
          ],
          'facebookAdsId' : [
            "You need to select a Facebook Ads ID before activating Facebook campaigns.",
            "Vous devez définir un ID Facebook Ads avant d'activer les campagnes Facebook."
          ]
        },
        'table' : {
          'DEALERS' : [
            'DEALERS',
            'CONCESSIONAIRES'
          ],
          'REGION' : [
            'REGION',
            'RÉGION'
          ],
          'TEAM' : [
            'TEAM',
            'ÉQUIPE'
          ],
          'STRATEGIST' : [
            'STRATEGIST',
            'STRATÈGE'
          ],
          'STATUS' : [
            'STATUS',
            'STATUT'
          ],
          'search' : [
            'Search',
            'Recherche'
          ],
          'rows' : [
            'rows',
            'lignes'
          ],
          'of' : [
            'of',
            'de'
          ],
        },
      },
      'accountsDetails' : {
        'loaders' : {
          'submitId' : [
            "Submitting Facebook Account ID",
            "Envoi de l'ID de compte Facebook"
          ],
          'failedSubmitId' : [
            "Failed submitting Facebook Account ID",
            "Échec de l'envoi de l'ID de compte Facebook"
          ]
        },
        'basicInformation' : {
          'title' : [
            'Account Information',
            'Informations sur le compte'
          ],
          'dealerName' : [
            'Dealer Name',
            'Nom du concessionnaire'
          ],
          'websiteUrl' : [
            'Website URL',
            'URL de site web'
          ],
          'dealerAddress' : [
            'Dealer Address',
            'Adresse du concessionnaire'
          ],
          'city' : [
            'City',
            'Ville'
          ],
          'stateOrProvince' : [
            'State / Province',
            'État / Province'
          ],
          'postalCode' : [
            'Postal Code',
            'Code Postal'
          ],
        },
        'adsData' : {
          'title' : [
            'Ad Accounts Information',
            'Informations sur les comptes publicitaires'
          ],
          'googleAdsId' : [
            'Google Ads ID',
            'ID Google Ads'
          ],
          'facebookAdsId' : [
            'Facebook Ads ID',
            'ID Facebook Ads'
          ],
          'callrailId' : [
            'CallRail ID',
            'ID CallRail'
          ],
          'googleAnalyticsId' : [
            'Google Analytics ID',
            'ID Google Analytics'
          ],
          'microsoftAdsId' : [
            'Microsoft Ads ID',
            'ID Microsoft Ads'
          ],
          'displayVideo360Id' : [
            'Display Video 360 ID',
            'ID Display Video 360'
          ],
          'gtm' : [
            'GTM',
            'GTM'
          ]
        },
        'activations' : {
          'searchableById' : [
            "Search by id available",
            "Recherche par id disponible"
          ],
          'currentlyExistingAccountId' : [
            "Currently assigned Ad account in Reporting :",
            "Compte publicitaire actuellement attribué dans Reporting:"
          ],
          'sameAdAccountId' : [
            "You must assign the same ad account used for reporting",
            "Vous devez attribuer le même compte publicitaire que celui utilisé pour Reporting"
          ],
          'failedCreating' : [
            "Failed creating a Facebook strategy",
            "Erreur de creation de strategie Facebook"
          ],
          'alreadyExistingIdentityVariants' : [
            "Identity variants have already been set",
            "Les variantes d'identité ont déjà été définies"
          ],
          'createPermission' : [
            "You are not allowed to create strategies",
            "Vous n'êtes pas autorisé à créer des stratégies",
          ],
          'updatePermission' : [
            "You are not allowed to update strategies",
            "Vous n'êtes pas autorisé à modifier des stratégies"
          ],
          'updatingStrategy' : [
            "Updating Facebook strategy",
            "Mise à jour de la stratégie Facebook"
          ],
          'creatingStrategy' : [
            "Creating Facebook strategy",
            "Création d'une stratégie Facebook"
          ],
          'newProspecting' : [
            'PROSPECTING NEW',
            'PROSPECTING NEUF'
          ],
          'newRemarketing' : [
            'REMARKETING NEW',
            'REMARKETING NEUF'
          ],
          'usedProspecting' : [
            'PROSPECTING USED',
            'PROSPECTING OCCASION'
          ],
          'usedRemarketing' : [
            'REMARKETING USED',
            'REMARKETING OCCASION'
          ],
          'helpText' : [
            "Please make sure to fill the inputs, select ad templates and have your hyperfeed activated before enabling your campaign",
            "Veuillez vous assurer de remplir les entrées, de sélectionner des modèles d'annonces et d'activer votre hyperflux avant d'activer votre campagne"
          ],
          'namesError' : [
            "Names should not be empty or more than 512 characters",
            "Les noms ne doivent pas être vides ou contenir plus de 512 caractères"
          ],
          'makesError' : [
            "Makes should not be empty or more than 512 characters",
            "Les marques ne doivent pas être vides ou contenir plus de 512 caractères",
          ],
          'assignFacebookAccountId' : [
            "Assign a Facebook Account ID",
            "Choisir un Facebook Account ID"
          ],
          'title' : [
            'Campaigns type',
            'Type de campagnes'
          ],
          'newCarsGoogle' : [
            'New cars Google campaigns',
            'Campagnes Google de nouvelles voitures'
          ],
          'newCars' : [
            'New cars campaigns',
            'Campagnes de nouvelles voitures'
          ],
          'newRemarketingCarsFacebook' : [
            'New Remarketing cars Facebook campaigns',
            'Campagnes Remarketing Facebook pour les voitures neuves'
          ],
          'newProspectingCarsFacebook' : [
            'New Prospecting cars Facebook campaigns',
            'Campagnes Prospecting Facebook pour les voitures neuves'
          ],
          'usedCarsGoogle' : [
            'Used cars Google campaigns',
            "Campagnes Google pour voitures d'occasion"
          ],
          'usedCars' : [
            'Used cars campaigns',
            "Campagnes de voitures d'occasion"
          ],
          'usedRemarketingCarsFacebook' : [
            'Used Remarketing cars Facebook campaigns',
            "Campagnes Remarketing Facebook de voitures d'occasion"
          ],
          'usedProspectingCarsFacebook' : [
            'Used Prospecting cars Facebook campaigns',
            "Campagnes Prospecting Facebook de voitures d'occasion"
          ],
          'conquestGoogle' : [
            'Conquest Google campaigns',
            'Campagnes Google de conquête'
          ],
          'conquest' : [
            'Conquest campaigns',
            'Campagnes de conquête'
          ],
          'brandingGoogle' : [
            'Branding Google campaigns',
            'Campagnes Google de marque'
          ],
          'branding' : [
            'Branding campaigns',
            'Campagnes de marque'
          ],
          'activate' : [
            'Activate',
            'Activer'
          ],
          'activated' : [
            'Activated',
            'Activé'
          ],
          'separatedByComma' : [
            "Should be separated by commas.",
            "Doit être séparé par des virgules."
          ],
          'radiusRange' : [
            "Radius should be between 18 and 80",
            "Le rayon doit être compris entre 18 et 80"
          ],
          'radius' : [
            "Radius",
            "Rayon"
          ],
          'hyperfeedSet' : [
            "Hyperfeed correctly set",
            "Hyperfeed correctement réglé"
          ],
          'hyperfeedNotSet' : [
            "Unable to find Hyperfeed (please create a Facebook Feed for the selected language)",
            "Impossible de trouver Hyperfeed (veuillez créer un flux Facebook pour la langue sélectionnée)"
          ]
        }
      },
    },
    'abbreviatedMonths' : {
      'Jan' : [
        'Jan',
        'Janv'
      ],
      'Feb' : [
        'Feb',
        'Févr'
      ],
      'Mar' : [
        'Mar',
        'Mars'
      ],
      'Apr' : [
        'Apr',
        'Avril'
      ],
      'May' : [
        'May',
        'Mai'
      ],
      'Jun' : [
        'Jun',
        'Juin'
      ],
      'Jul' : [
        'Jul',
        'Juil'
      ],
      'Aug' : [
        'Aug',
        'Août'
      ],
      'Sep' : [
        'Sep',
        'Sept'
      ],
      'Oct' : [
        'Oct',
        'Oct'
      ],
      'Nov' : [
        'Nov',
        'Nov'
      ],
      'Dec' : [
        'Dec',
        'Déc'
      ],
    },
    'homePage' : {
      'welcome' : [
        'WELCOME TO COMMANDER',
        'BIENVENUE À COMMANDER'
      ],
      'pacing' : [
        'PACING REPORT',
        'RAPPORT DE PACING'
      ],
      'insights' : [
        'MARKET INSIGHTS',
        'APERÇU DU MARCHÉ'
      ],
      'performance' : [
        'PERFORMANCE REPORT',
        'RAPPORT DE PERFORMANCE'
      ],
    },
    'buttons' : {
      [BUTTONS.ACCOUNTS] : [
        "Accounts",
        "Comptes"
      ],
      [BUTTONS.ACTIVATION] : [
        "Activation",
        "Activation"
      ],
      [BUTTONS.AGENCY_PROFILE] : [
        "Agency profile",
        "Profil de l'agence"
      ],
      [BUTTONS.BILLING] : [
        "Billing",
        "Facturation"
      ],
      [BUTTONS.INSIGHTS] : [
        "Insights",
        "Aperçu"
      ],
      [BUTTONS.MANAGE] : [
        "Manage",
        "Gérer"
      ],
      [BUTTONS.PACING] : [
        "Pacing",
        "Pacing"
      ],
      [BUTTONS.PROFILE] : [
        "Profile",
        "Profil"
      ],
      [BUTTONS.REPORTING] : [
        "Reporting",
        "Rapports"
      ],
      [BUTTONS.SETTINGS] : [
        "Settings",
        "Paramètres"
      ],
      [BUTTONS.SUPPORT] : [
        "Support",
        "Support"
      ],
      [BUTTONS.USERS] : [
        "Users",
        "Utilisateurs"
      ],
      [BUTTONS.DESIGN_SYSTEM] : [
        "Design system",
        "Système de Design"
      ],
      [BUTTONS.HOME] : [
        "Home",
        "Accueil"
      ],
      [BUTTONS.BUDGET_CALCULATOR] : [
        "Budget Calculator",
        "Calculateur de Budget"
      ]
    },
    commonButtons : {
      'cancel' : [
        'Cancel',
        'Annuler'
      ],
      'save' : [
        "Save",
        "Sauvegarder"
      ]
    },
    login : {
      'emailAddress' : [
        "Email address",
        "Adresse email"
      ],
      'password' : [
        "Password",
        "Mot de passe"
      ],
      'forgotPassword' : [
        "Forgot password?",
        "Mot de passe oublié?"
      ],
      'rememberMe' : [
        "Remember me",
        "Se souvenir de moi"
      ],
      'login' : [
        "Login",
        "Connexion"
      ],
      'resetPasswordInstruction' : [
        "Submit your email address and follow the instructions received.",
        "Entrez l'adresse e-mail et suivez les instructions reçues"
      ],
      'submit' : [
        "Submit",
        "Confirmer"
      ],
      'passwordResetSent' : [
        "Password Reset Sent",
        "Réinitialisation du mot de passe envoyée"
      ],
      'passwordResetSentDescription' : [
        'An email has been sent to',
        "Un courriel a été envoyé à"
      ],
      'followInstructions' : [
        'Follow the directions in the email to reset your password.',
        "Suivez les instructions dans l'e-mail pour réinitialiser votre mot de passe."
      ],
      'done' : [
        'Done',
        'Terminer'
      ]
    },
    pacing : {
      'pacingTable' : {
        'exportAsCsv' : [
          'Export as CSV',
          'Exporter en tant que CSV'
        ]
      }
    },
    marketInsights : {
      'marketInsights' : [
        'Market Insights',
        'Aperçu du marché'
      ],
      'unavailableData' : [
        'Data is currently unavailable for selected range',
        'Aucunes données disponibles pour les dates sélectionnées'
      ],
      'pickDate' : [
        'Pick a date range',
        'Choisissez une plage de dates'
      ],
      'dateFrom' : [
        'From',
        'Du'
      ],
      'dateTo' : [
        'To',
        'Au'
      ],
      'refreshButton' : [
        'refresh',
        'rafraîchir'
      ],
      'dealersGraph' : {
        'title' : [
          'Dealers websites events',
          'Événements des sites de concessionnaires'
        ],
        'leads' : [
          'Leads',
          'Pistes'
        ],
        'visits': [
          'Visits',
          'Visites'
        ],
        'dealers': [
          'Dealers',
          'Concessionnaires'
        ]
      },
      'inventoryCountGraph' : {
        'title' : [
          'Inventory Count',
          'Recensement d\'inventaire'
        ],
        'newAndUsed' : [
          'New & Used',
          'Neuves & Usagées'
        ],
        'new' : [
          'New',
          'Neuves'
        ],
        'used' : [
          'Used',
          'Usagées'
        ],
        'dealersCount' : [
          'Dealers Count',
          'Nombre de concessionnaires'
        ]
      },
      'inventoryRemovedGraph' : {
        'title' : [
          'Inventory Removed',
          'Inventaire Retiré'
        ],
        'soldVehicles' : [
          'Sold vehicles',
          'Véhicules vendus'
        ]
      },
      'googleTrendsGraph' : {
        'title' : [
          'Google Trends',
          'Tendances Google'
        ],
        'trends' : [
          'Trends',
          'Tendances'
        ],
        'brands' : [
          'Brands',
          'Fabricants'
        ],
        'search' : [
          'Search',
          'Rechercher'
        ],
        'all' : [
          'ALL',
          'TOUS'
        ],
        'clear': [
          'CLEAR',
          'EFFACER'
        ]
      },
      'errorMessages' : {
        'startBeforeEnd' : [
          'START DATE SHOULD BE BEFORE END DATE',
          'LA DATE DE DE DÉPART DOIT ÊTRE AVANT LA DATE DE FIN'
        ],
        'noData' : [
          'MISSING DATA FOR SELECTED PERIOD',
          'DONNÉES MANQUANTES POUR LA PÉRIODE CHOISIE'
        ]
      }
    },
    dateRangePicker : {
      'currentMonth' : [
        'Current month',
        'Mois actuel'
      ],
      'lastMonth' : [
        'Last month',
        'Mois dernier'
      ],
      'yearToDate' : [
        'Year to date',
        'Année à ce jour'
      ],
      'lastYear' : [
        'Last year',
        'Année dernière'
      ],
      'last365Days' : [
        'Last 365 days',
        'Derniers 365 jours'
      ],
      'last90Days' : [
        'Last 90 days',
        'Derniers 90 jours'
      ],
      'last30Days' : [
        'Last 30 days',
        'Derniers 30 jours'
      ],
      'last7Days' : [
        'Last 7 days',
        'Derniers 7 jours'
      ],
    }
}

export const translationPaths = recursivelyFindPathsInTranslations(translations)
export default translations
