import {createReducer}            from "../../../utils/Reducers"
import ActionTypes                from "../../../model/constant/actions"
import {GoogleTrendsStats}        from '../../../model/store/insights/GoogleTrendsStats'

const GoogleTrendsStatsReducer = createReducer<{[agencyId:string]:GoogleTrendsStats[]}>({}, {
  [ActionTypes.GET_GOOGLE_TRENDS_STATS] : (state, action)=>{
    const compare = (a:GoogleTrendsStats,b:GoogleTrendsStats) => {
      if (a.date>b.date) return 1
      if (b.date>a.date) return -1
      return 0
    }
    const copiedStatsForSorting = action.payload.googleTrendsStats
    const sortedStats: GoogleTrendsStats[] = copiedStatsForSorting.sort(compare)
    return {
      ...state,
      [action.payload.agencyId] : [
        ...sortedStats
      ]
    }
  }
})

export default GoogleTrendsStatsReducer
