import {combineReducers}              from "redux"
import Agency                         from "./Agency"
import Retrieving                     from "./Retrieving"
import Failed                         from './Failed'
const Accounts = combineReducers({
  Agency,
  Retrieving,
  Failed
})

export default Accounts
