import React          from 'react'
import {
  Fab,
  CircularProgress,
  Button,
}                     from '@material-ui/core'
import CheckIcon      from '@material-ui/icons/Check'
import SaveIcon       from '@material-ui/icons/Save'
import ClearIcon      from '@material-ui/icons/Clear'
import clsx           from 'clsx'
import useStyles      from "./InteractiveSavingButtonStyles"


interface InteractiveSavingButton {
  buttonText        : string
  success           : boolean
  failed            : boolean
  loading           : boolean
  handleButtonClick : () => void
}



const InteractiveSavingButton = (props:InteractiveSavingButton) => {
  const classes = useStyles()
  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.success,
    [classes.buttonFail]   : props.failed
  })
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          onClick={props.handleButtonClick}
          className={buttonClassname}
        >
          {
            props.success ?
              <CheckIcon className={classes.fillIconColor} />
            :
            props.failed ?
              <ClearIcon className={classes.fillIconColor} />
            :
              <SaveIcon />
            }
        </Fab>
        {props.loading && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={buttonClassname}
          disabled={props.loading}
          onClick={props.handleButtonClick}
        >
          {props.buttonText}
        </Button>
      </div>
    </div>
  )
}

export default InteractiveSavingButton
