import {createReducer}          from "../../../utils/Reducers"
import ActionTypes              from "../../../model/constant/actions"
import { PacingStatsByMonth }   from "../../../model/store/statistics/PacingStats"

const PacingStats = createReducer<{[agencyId:string]:PacingStatsByMonth}>({}, {
  [ActionTypes.GET_PACING_STATS] : (state, action)=>{
    return {
      ...state,
      [action.payload.agencyId] : {
        ...state[action.payload.agencyId],
        ...action.payload.months.reduce((acc,curr)=>{
          return {
            ...acc,
            [curr] : action.payload.pacingStats.filter(stats=>{
              return stats.date.substr(0,7) === curr
            })
          }
        },{})
      }
    }
  },
})

export default PacingStats
