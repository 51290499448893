import { combineReducers }    from "redux"
import PacingStats            from './PacingStats'
import RetrievingPacingStats  from './RetrievingPacingStats'
import CompiledPacingStats    from './CompiledPacingStats'
import CurrentLoadedMonths    from './CurrentLoadedMonths'

const Statistics = combineReducers({
  PacingStats,
  RetrievingPacingStats,
  CompiledPacingStats,
  CurrentLoadedMonths
})

export default Statistics
