import {
  makeStyles,
  Theme,
  createStyles,
}                 from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  BudgetAllocation : {
    padding : 20,
    minWidth : 500,
  },
  title : {
    fontSize : 20,
  },
  form : {
    marginTop : 10,
    display : 'flex',
    flexDirection : 'column'
  },
  input : {
    marginBottom:10,
    width : '100%',
    marginTop : 10,
  },
  inputOutput : {
    display : 'flex'
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,
  },
  formControlBudget : {
    margin: theme.spacing(1.5),
    minWidth: 120,
    [theme.breakpoints.down(1530)] : {
      width : '50%'
    }
  },
  adornment : {
    width:50,
    display:'flex',
    justifyContent:'center',
  },
  budgetInputs : {
    display : "flex",
    width:'100%'
  },
  managementFee : {
    display : 'flex',
    padding:20,
    alignItems :'center',
    justifyContent : 'center'
  },
  mediaSpend : {
    borderRadius : 10
  },
  managementFeeOutput : {
    width:'100%',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    borderTop : '1px solid gray',
    borderBottom : '1px solid gray',
    padding : 10
  }
}))

export default useStyles
