import React                                       from 'react'
import AccountInformations                         from './AccountInformations'
import {
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab
}                                                  from '@material-ui/core'
import AdAccountsInformations                      from './AdAccountsInformations'
import Breadcrumbs                                 from "../../../components/breadcrumbs/Breadcrumbs"
import Activations                                 from './Activations'
import { connect }                                 from 'react-redux'
import { State }                                   from '../../../redux/reducers'
import { ThunkDispatch }                           from 'redux-thunk'
import { DispatchableAction }                      from '../../../model/constant/actions'
import requiresAdAccountsInformation               from '../../../components/loaders/loadAccountsInformation'
import requiresAgencyAccounts                      from '../../../components/loaders/loadAgencyAccounts'
import { push }                                    from 'connected-react-router'
import { Account }                                 from '../../../model/accounts/Account'
import { AdAccountsDataByAgencyId }                from '../../../redux/reducers/AdAccountsData/Data'
import {
  withLocalize,
  LocalizeContextProps
}                                                  from 'react-localize-redux'
import { translationPaths }                        from '../../../translations/translations'
import {
  createFailedSubmittingFacebookAccountId,
  createFailedSubmittingFacebookConfiguration
}                                                  from '../../../actions/creators'

interface DetailsOwnProps extends LocalizeContextProps {
  id                  : number
  accounts            : Account[]
  accountsData        : AdAccountsDataByAgencyId
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:DetailsOwnProps)=>{
    return {
      selectedAgency     : state.Selection.Agency,
      agencies           : state.Agencies.List,
      history            : state.router.location.pathname,
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:DetailsOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args)),
    resetFailedSubmitAccountId : () => dispatch(createFailedSubmittingFacebookAccountId({failed:false,message:''})),
    resetFailedCreateFacebookStrategy : () => dispatch(createFailedSubmittingFacebookConfiguration({failed:false,message:''}))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:DetailsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type DetailsProps = DetailsOwnProps & ReturnType<typeof mergeProps>

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const Details = (props:DetailsProps) => {
  const adAccount = props.accountsData[props.selectedAgency] && props.accountsData[props.selectedAgency][props.id] ? props.accountsData[props.selectedAgency][props.id] : undefined
  const account = props.accounts.find(x=>x.id === props.id)
  const selectedAgency = props.agencies.find(x=>x.id===props.selectedAgency)
  const [value, setValue] = React.useState<number>(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    )
  }
  return (
    <div>
      <Breadcrumbs
        agency={selectedAgency ? selectedAgency.name : ''}
        section={props.translate(translationPaths.general.accounts) as string}
        page={account ? account.name : ''}
      />
      <Typography style={{marginTop:20}} variant="h6">{account ? account.name : '' }</Typography>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Information"   />
          <Tab label="Activation"/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AccountInformations account={account} />
        <AdAccountsInformations resetFailedSubmitAccountId={props.resetFailedSubmitAccountId} account={account} adAccount={adAccount} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Activations
          resetFailedCreateStrategy={props.resetFailedCreateFacebookStrategy}
          resetFailedSubmitAccountId={props.resetFailedSubmitAccountId}
          accountId={props.id}
          account={account}
        />
      </TabPanel>
    </div>
  )
}

export default withLocalize(
  requiresAdAccountsInformation({letThrough:true})(
    requiresAgencyAccounts()(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(Details)
    )
  )
)
