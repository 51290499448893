import {
  makeStyles,
  Theme
}                         from "@material-ui/core"


const useStyles = makeStyles((theme:Theme)=>({
  login: {
    height: "100vh",
  },
  container: {
    display: "grid",
    height: "100vh",
    gridTemplateColumns: "60% 40%",
  },
  leftImage: {
    background: theme.palette.primary.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: '100%',
  },
  logoContainer: {
    maxWidth: 350,
    overflow: "hidden",
    height: 100,
  },
  logo: {
    transform: 'scale(1.6)',
    width: '100%',
    height: 'auto',
    marginLeft: -6,
    marginTop: -50,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: 450,
    width: 350,
    justifyContent: "space-around",
  },
  error: {
    height: 10,
    display: "flex",
    alignItems: "flex-end",
  },
  inputText: {
    letterSpacing: 0,
    '& fieldset': {
      border: "1px solid #7f7f7f",
    }
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  recoverPassword: {
    color: '#afb5c1',
    fontSize: 16,
    letterSpacing: 0,
    '&:focus' : {
      outline: "none",
    }
  },
  rememberText: {
    '& span': {
      fontSize: 12,
      color: theme.palette.primary.main,
    }
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: "12px!important",
    width: 130,
    '& span': {
      fontWeight: 600,
    },
  }
}))

export default useStyles
