
import styles                 from './componentStyles'
import React                  from 'react'
import { TooltipData }        from '../../../../model/graphs/CustomComponents'
import { Card, Typography }   from '@material-ui/core'
import {
  withLocalize,
  LocalizeContextProps
}                             from 'react-localize-redux'
import {translationPaths}     from '../../../../translations/translations'

interface CustomTooltipBudgetTierProps extends TooltipData, LocalizeContextProps {}


const CustomTooltipBudgetTier = (props: CustomTooltipBudgetTierProps) => {
  const classes = styles()
  return (
    <>
      {
        props.payload &&
        props.payload[0] &&
          <Card className={classes.tooltipLeft}>
            <Typography className={classes.commanderOrange} color="inherit" gutterBottom={true}>
              {props.payload[0].payload.dealer}
            </Typography>
            <Typography>
              {`${props.translate(translationPaths.pacingOverview.tooltips.Region)}: ${props.payload[0].payload.region}`}
            </Typography>
              {
                props.payload[0].payload.realPacing ?
                  <Typography className={classes.info} color='inherit'>
                    {props.translate(translationPaths.pacingOverview.tooltips.Pacing)}: {parseInt(props.payload[0].payload.realPacing).toLocaleString('en-CA', {maximumFractionDigits:0})}%
                  </Typography>
                  :
                  <Typography>
                    {props.translate(translationPaths.pacingOverview.tooltips.Pacing)}: {parseInt(props.payload[0].payload.pacing).toLocaleString('en-CA', {maximumFractionDigits:0})}%
                  </Typography>
              }
            <Typography>
              {`${props.translate(translationPaths.pacingOverview.tooltips.Budget)}: $${parseInt(props.payload[0].payload.budget).toLocaleString('en-CA', {maximumFractionDigits:0})}`}
            </Typography>
          </Card>
      }
    </>
  )
}

export default withLocalize(CustomTooltipBudgetTier)
