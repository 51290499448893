import { ProfitCenter, SummaryProduct } from "../Summary"

export const calculateStateAllocation = (products:SummaryProduct[],state:ProfitCenter):number => {
  return products.reduce((acc,curr)=>{
    if(curr.state === state) {
      acc = acc + (curr.allocation || 0)
    }
    return acc
  },0)
}
