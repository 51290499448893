import {
  makeStyles,
  Theme,
  createStyles
}                    from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  container : {

  },
  title : {
    height: 66.5,
    marginBottom : 10,
    display : 'flex',
    alignItems : 'center',
    fontWeight : 'normal',
    fontSize : 25,
    color : '#666'
  },
  dealerName : {
    fontWeight : 'bold',
    overflow : 'hidden',
    textOverflow : 'ellipsis',
    whiteSpace : 'nowrap',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    color : '#666'
  },
  addButton : {
    display : 'flex',
    alignItems : 'center',
    '& :nth-child(1)' : {
      marginRight : 10
    }
  },
  tableTitle : {
    fontSize : '1.6em',
    marginTop : 10,
  }
})
)

export default useStyles

