import {
  makeStyles,
  Theme,
  createStyles,
}               from "@material-ui/core"
export const drawerWidth = 300

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      background: theme.palette.primary.main,//`linear-gradient(-35deg, #B0B3BA, #7A818E, ${secondaryColor} 50%)`,
      autoWidth: 'false',
    },
    drawerHeader: {
      width : drawerWidth,
      marginTop: 64,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    expandIconContainer : {
      display : 'flex',
      justifyContent : 'flex-end',
    },
    expandIcon : {
      color : '#E5E5E5',
      transition: 'all .5s linear',
    },
    expandIconReversed : {
      transition: 'all .5s linear',
      transform : 'rotate(180deg)',
      color : '#E5E5E5'
    },
    icon : {
      color : '#E5E5E5'
    },
    subItem : {
      marginLeft:20,
    },
    subIcon : {
      color : '#E5E5E5'
    },
    homeButton : {
      justifyContent : 'space-between',
      width : drawerWidth,
    },
    avatar : {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: 2,
      marginRight: 25,
      color: '#454F63',
      backgroundColor: '#E5E5E5'
    },
    dividerColor: {
      backgroundColor: '#A0A5AD',
    },
  })
)

export default useStyles
