import React                              from 'react'
import useStyles                          from './BudgetAllocationStyles'
import {
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Typography,
  TableHead,
  Checkbox
}                                         from '@material-ui/core'
import MONTHS, { Month }                  from '../../../model/constant/Months'
import SummaryTemplate, {
  isManagementFeeFlat,
  isManagementFeeVariable
}                                         from '../../../model/budgetEstimator/Summary'
import {
  StyledTableRow,
  StyledTableCell
}                                         from '../Targets/styles'
import { formatMoney }                    from '../../../utils/MoneyFormatting'
import { handleManagementFeesChange }     from '../../../model/budgetEstimator/Handlers/FeeAndDiscountHandlers'
import { handleStartingMonthUpdate }      from '../../../model/budgetEstimator/Handlers/VehiclesAndAdditionalBudgetHandlers'
import {
  create4MonthsNadaPromo,
  create12MonthsNadaCampaign,
  createNoCommitmentCampaign,
  create6MonthsCommitmentCampaign,
  create12MonthsCommitmentCampaign
}                                         from '../../../model/budgetEstimator/Calculations/BudgetCalculations'
import SalesCampaign                      from '../../../model/budgetEstimator/SalesCampaign'
import { handleIncludeFeeChange }         from '../../../model/budgetEstimator/Calculations/BugetHandlers'

interface ManagementFeeOwnProps {
  disabled            : boolean
  summary             : SummaryTemplate
  handleUpdateSummary : (summary:SummaryTemplate)=>void
  handleGetBreakdown  : (sunnary:SummaryTemplate)=>void
  totalMediaSpend     : number
  totalManagementFee  : number
  totalBudget         : number
  campaigns           : SalesCampaign[]
}

const ManagementFee = (props:ManagementFeeOwnProps) => {
  const classes = useStyles()
  const {summary,handleUpdateSummary,handleGetBreakdown} = {...props}
  const baseManagementFee = props.totalBudget * 0.3

  //HANDLERS
  const handleStartingMonthChange = (event:React.ChangeEvent<{value:unknown}>) => {
    const month = event.target.value as Month
    console.log(month)
    if(isManagementFeeVariable(summary.managementFee)) {
      const tempSummary = handleManagementFeesChange(props.summary.managementFee.name === 'nada4' ? create4MonthsNadaPromo(month) : create12MonthsNadaCampaign(month),summary)
      const newSummary = handleStartingMonthUpdate(month,tempSummary)
      console.log(newSummary.managementFee)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)

    } else {
      const newSummary = handleStartingMonthUpdate(month,summary)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)
    }
  }
  const handleManagementFees = (event:React.ChangeEvent<{value:unknown}>) => {
    if(event.target.value === 'nada4') {
      const newSummary = handleManagementFeesChange(create4MonthsNadaPromo(summary.startingMonth),summary)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)
    }
    if(event.target.value === 'nada12') {
      const newSummary = handleManagementFeesChange(create12MonthsNadaCampaign(summary.startingMonth),summary)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)
    }
    if(event.target.value === 'nc') {
      const newSummary = handleManagementFeesChange(createNoCommitmentCampaign(),summary)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)
    }
    if(event.target.value === '6m') {
      const newSummary = handleManagementFeesChange(create6MonthsCommitmentCampaign(),summary)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)
    }
    if(event.target.value === '12m') {
      const newSummary = handleManagementFeesChange(create12MonthsCommitmentCampaign(),summary)
      handleUpdateSummary(newSummary)
      handleGetBreakdown(newSummary)
    }
  }
  const handleIncludeFee = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    const newSummary = handleIncludeFeeChange(value,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  return (
    <Card >
      <div className={classes.managementFee}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center">Starting month</TableCell>
              <TableCell align="center">
                <Select disabled={props.disabled} fullWidth onChange={handleStartingMonthChange} variant="outlined" style={{marginRight:20}} value={props.summary.startingMonth}>
                  {MONTHS.map((month,i)=>{
                    return <MenuItem key={i} value={month}>{month.toUpperCase()}</MenuItem>
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Agreement terms</TableCell>
              <TableCell align="center">
                <Select disabled={props.disabled} fullWidth onChange={handleManagementFees} variant="outlined" style={{marginRight:20}} value={props.summary.managementFee.slug || 0}>
                  <MenuItem disabled value={0}>Select..</MenuItem>
                  {props.campaigns.map((campaign,i)=><MenuItem key={i+100} value={campaign.slug}>{campaign.name}</MenuItem>)}
                </Select>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className={classes.managementFee}>
        {isManagementFeeFlat(props.summary.managementFee) && <Typography style={{marginRight:5}}>Management Fee : </Typography>}
        {isManagementFeeFlat(props.summary.managementFee) && <Typography style={{marginRight:20}}><strong>{props.summary.managementFee.amount}%</strong></Typography>}
        {props.summary.managementFee.name === 'nada4' ? (
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Management fee %</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>First month</StyledTableCell>
                <StyledTableCell align="center">10%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Next 2 Months</StyledTableCell>
                <StyledTableCell align="center">15%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Next 9 Months months</StyledTableCell>
                <StyledTableCell align="center">20%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>After 12 months</StyledTableCell>
                <StyledTableCell align="center">30%</StyledTableCell>
              </StyledTableRow>
            </TableBody>
            <caption>(See more details in overview section)</caption>
          </Table>
        ): props.summary.managementFee.name === 'nada12' ? (
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">Management fee %</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>First 3 months</StyledTableCell>
                <StyledTableCell align="center">10%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Next 3 months</StyledTableCell>
                <StyledTableCell align="center">12.5%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Next 6 months</StyledTableCell>
                <StyledTableCell align="center">15%</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>After 12 months</StyledTableCell>
                <StyledTableCell align="center">30%</StyledTableCell>
              </StyledTableRow>
            </TableBody>
            <caption>(See more details in overview section)</caption>
          </Table>
        )
          : null
        }
        {!props.summary.managementFee && <Typography style={{marginRight:10}} variant="caption" color="secondary">Please select an option</Typography>}
      </div>
      {props.totalManagementFee ? (
        <div className={classes.managementFeeOutput}>
          <Typography><strong>{formatMoney(props.totalManagementFee)}</strong></Typography>
          <Typography variant="caption" style={{marginLeft:5}} color="secondary"><strong>{props.summary.managementFee.slug !== 'nc' ? `(-${formatMoney(baseManagementFee - props.totalManagementFee)})` : null}</strong></Typography>
        </div>
      ) : null}
      <div className={classes.mediaSpend}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Include management fee in budget <Checkbox disabled={props.disabled} onChange={handleIncludeFee} checked={props.summary.includeFee} /></TableCell>
              <TableCell align="right">Media Spend : <strong>{formatMoney(props.totalMediaSpend || 0)}</strong></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </div>
    </Card>
  )
}

export default ManagementFee
