import React                    from 'react'
import {
  Card,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TextField,
  InputAdornment
}                               from '@material-ui/core'
import SummaryTemplate          from '../../../model/budgetEstimator/Summary'
import { formatMoney }          from '../../../utils/MoneyFormatting'
import useStyles , {
  StyledTableRow,
  StyledTableCell
}                               from './DiscountStyles'
import { handleDiscountChange } from '../../../model/budgetEstimator/Handlers/FeeAndDiscountHandlers'

interface OtherExpenses {
  summary               : SummaryTemplate
  totalDiscount         : number
  disabled              : boolean
  handleUpdateSummary   : (summary:SummaryTemplate)=>void
  handleGetBreakdown    : (summary:SummaryTemplate)=>void
}

const OtherExpenses = (props:OtherExpenses) => {
  const classes = useStyles()
  const {summary,handleUpdateSummary,handleGetBreakdown} = {...props}
  const handleDiscount = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value)
    const newSummary = handleDiscountChange(value||0,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  return (
    <Card className={classes.otherExpenses}>
      <Typography variant="button" color="primary" className={classes.title}>Discounts</Typography>
      <div className={classes.table}>
        <TableContainer style={{borderRadius:5}}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">Annual</StyledTableCell>
                {/* <StyledTableCell align="center">Per car</StyledTableCell> */}
                <StyledTableCell align="center" style={{minWidth:350}}>Description</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell align="center">Discount</StyledTableCell>
                <StyledTableCell align="center">
                  <TextField
                    disabled={props.disabled}
                    style={{width:120}}
                    value={props.summary.discount || ''}
                    variant="outlined"
                    onChange={handleDiscount}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <strong>%</strong>
                        </InputAdornment>
                      ),
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{formatMoney(props.totalDiscount)}</StyledTableCell>
                {/* <StyledTableCell align="center">{formatMoney((props.managementFee*props.summary.discount/100)/props.totalNumberOfCars) || 0}</StyledTableCell> */}
                <StyledTableCell align="center" style={{minWidth:350}}><TextField disabled={props.disabled} rows={3} fullWidth /></StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  )
}

export default OtherExpenses
