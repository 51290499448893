import {
  StandardThunk,
  createGetFacebookAccountStrategies,
  createFailedRetrievingFacebookAccountStrategies,
  createRetrievingFacebookAccountStrategies,
  createCreatingFacebookStrategy,
  createFailedCreatingFacebookStrategy,
  createUpdatingFacebookStrategy,
  createFailedUpdatingFacebookStrategy
}                                                     from '../creators'
import baseUrl,{Success}                              from "../../model/http/Headstone/GetFacebookStrategies"
import axios                                          from "axios"
import Languages                                      from '../../model/constant/Languages'
import VehicleState                                   from '../../model/constant/VehicleState'
import AudienceGoal                                   from '../../model/constant/AudienceGoal'


export const getFacebookStrategies = (accountId:number,agencyId:string):StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookAccountStrategies(true))
  axios.get<Success[]>(baseUrl(accountId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookAccountStrategies({
      agencyId,
      accountId,
      strategies : response.data
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookAccountStrategies(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookAccountStrategies(false))
  })
}

export const createFacebookStrategy = (accountId:number,active:boolean,lang:Languages,state:VehicleState,radius:number,agencyId:string,audienceGoal:AudienceGoal):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  const strategy = [{
    active,
    lang,
    state,
    radius,
    audienceGoal
  }]
  dispatch(createCreatingFacebookStrategy(true))
  axios.post(baseUrl(accountId),strategy,{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookStrategies(accountId,agencyId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedCreatingFacebookStrategy(true))
  })
  .then(()=>{
    dispatch(createCreatingFacebookStrategy(false))
  })
}

export const updateFacebookStrategy = (accountId:number,active:boolean,lang:Languages,state:VehicleState,radius:number,agencyId:string,audienceGoal:AudienceGoal):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createUpdatingFacebookStrategy(true))
  axios.put(baseUrl(accountId),{
    active,
    lang,
    state,
    radius,
    audienceGoal
  },{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookStrategies(accountId,agencyId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedUpdatingFacebookStrategy(true))
  })
  .then(()=>{
    dispatch(createUpdatingFacebookStrategy(false))
  })
}
