import {
  makeStyles,
  Theme,
  createStyles,
}                           from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
  	},
  	top: {
  	  color: theme.palette.secondary.main,
  	},
  	bottom: {
  		color: '#A2A4A7',
  		animationDuration: '850ms',
    	position: 'absolute',
    	left: 0,
  	},
    centered: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center'
    },
  })
)
export default useStyles
