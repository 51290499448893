import './index.css'
import styles                         from './indexStyles'
import React                          from 'react'
import ChannelsChart                  from '../charts/Channels'
import PacingByBudget                 from '../charts/PacingByBudget'
import PacingByRegionChart            from '../charts/PacingByRegion'
import AppBar                         from '@material-ui/core/AppBar'
import Tabs                           from '@material-ui/core/Tabs'
import Tab                            from '@material-ui/core/Tab'
import Typography                     from '@material-ui/core/Typography'
import Box                            from '@material-ui/core/Box'
import { CompiledStatsPerYearMonth }  from '../../../model/store/statistics/PacingStats'
import DailyTrendChart                from '../charts/DailyTrend'
import { Card }                       from '@material-ui/core'
import theme                          from '../../../Theme/Main'
import {
  withLocalize,
  LocalizeContextProps
}                                     from 'react-localize-redux'
import {translationPaths}             from '../../../translations/translations'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
interface ChartTabsOwnProps extends LocalizeContextProps {
  data : CompiledStatsPerYearMonth
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`chart-tabpanel-${index}`}
      aria-labelledby={`chart-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index: number) {
  return {
    id: `chart-tab-${index}`,
    'aria-controls': `chart-tabpanel-${index}`,
  }
}


function ChartTabs(props:ChartTabsOwnProps) {
  const classes = styles()
  const [value, setValue] = React.useState<number>(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Card className={classes.root}>
      <AppBar elevation={0} className={classes.appBar} position="static">
        <Tabs
          TabIndicatorProps={{
            "style": {
              backgroundColor: theme.palette.primary.main,
              height: 5,
            }
          }}
          value={value}
          onChange={handleChange}
          className={classes.tabsParent}
        >
          <Tab label={props.translate(translationPaths.pacingOverview.graphs.budgetTier)} {...a11yProps(0)} />
          <Tab label={props.translate(translationPaths.pacingOverview.graphs.channels)} {...a11yProps(1)} />
          <Tab label={props.translate(translationPaths.pacingOverview.graphs.regions)} {...a11yProps(2)} />
          <Tab label={props.translate(translationPaths.pacingOverview.graphs.dailyTrend)} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.panel}>
          <PacingByBudget data={props.data.statsPerAccount} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.panel}>
          <ChannelsChart data={props.data.statsPerChannel} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.panel}>
          <PacingByRegionChart data={props.data.statsPerRegion} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.panel}>
          <DailyTrendChart data={props.data.dailyTrends} />
        </div>
      </TabPanel>
    </Card>
  )
}

export default withLocalize(ChartTabs)
