import {
  useSelector,
  useDispatch
}                                from "react-redux"
import { State }                 from "../../redux/reducers"
import {
  getSystemsPermissions
}                                from "../../actions/Auth2/Permissions"
import { System }                from "../../model/users/Permissions"

const useLoadSystemPermissions = (system:System,systemPermission:Array<string>) => {
  const retrieving = useSelector((state:State)=>state.Login.Retrieving.systemPermissions)
  const permissions = useSelector((state:State)=>state.Login.SystemPermissions)
  const dispatch = useDispatch()
  let hasPermission:boolean = false
  //Obj.keys instead of !permissions because initial state is {}, we retrieve systems permission all at once and we store them in that obj by system
  const permissionsLoaded = Object.keys(permissions).length !== 0
  if((!permissionsLoaded) && !retrieving){
    dispatch(getSystemsPermissions())
  }
  if(permissionsLoaded&&permissions[system]){
    hasPermission = systemPermission.every((val) => Object.keys(permissions[system]).includes(val))
  }
  return {
    hasPermission : hasPermission,
    loadingPermissions : !permissionsLoaded || retrieving
  }
}
export default useLoadSystemPermissions
