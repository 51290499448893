import styles                 from './KPIStyles'
import React                  from 'react'
import {
  Typography,
  Card,
  CircularProgress,
}                             from '@material-ui/core'
import getPercentChange       from '../../utils/getPercentChange'
import {
  LocalizeContextProps,
  withLocalize
}                             from 'react-localize-redux'

interface KPIOwnProps extends LocalizeContextProps {
  number                 : number
  previousNumber         : number
  text                   : string
  icon                   : string
  positiveIsBad         ?: boolean
  displayDiff           ?: boolean
  retrievingPacingStats ?: boolean
  formatterFunction     ?: (value:number)=>string|number
}

const KPI = (props:KPIOwnProps) => {
  const classes = styles()
  let formattedText

  if (props.formatterFunction) {
    formattedText = props.formatterFunction(props.number)
  }

  let diff = "0"
  if (props.previousNumber) {
    diff = `${getPercentChange(props.number, props.previousNumber).toFixed(0)}%`
    if (parseInt(diff) > 0)
      diff = `+${diff}`
  }

  return (
    <Card className={classes.KPI}>
      {
        props.displayDiff &&
        <>
          <div
            className={
              props.positiveIsBad ?
              parseInt(diff) > 0 ? classes.triangleOverlayRed : classes.triangleOverlay
              :
              parseInt(diff) >= 0 ? classes.triangleOverlay : classes.triangleOverlayRed
            }
          />
          {
            props.retrievingPacingStats ?
              <CircularProgress className={classes.diff} size="18px" disableShrink={true} />
              :
              <Typography className={classes.diff} variant="body2">{diff}</Typography>
          }
        </>
      }
      <div className={classes.data}>
        <Typography className={classes.text} variant="h6">{formattedText}</Typography>
        <Typography className={classes.caption} variant="caption" >{props.translate(`pacingOverview.${props.text}`)}</Typography>
      </div>
    </Card>
  )
}

export default withLocalize(KPI)
