import {hyperfeed}  from './index'
import VehicleState from '../../constant/VehicleState'
import Languages    from '../../constant/Languages'

export default (accountId:number) => {
  return hyperfeed+"/Account/"+accountId+"/Feeds"
}

export interface Success {
  id   : string
  stub : string
  config : {
    key : string
    params : {
      type : string
      accountId : number
      lang : Languages
      vehicleType : string
      vehicleState : VehicleState
      inventoryLang : string
    }
  }
}
