import * as React           from "react"
import {connect}            from "react-redux"
import {ThunkDispatch}      from "redux-thunk"
import {State}              from "../../redux/reducers/"
import {DispatchableAction} from "../../model/constant/actions"
import {getAccounts}        from "../../actions/Auth2/accounts"
import Loading              from "../loadings/CircularLoading"
import AgencySelectionModal from "../../pages/AgencySelection/AgencySelectionModal"
import ErrorPage            from "../ErrorPage"
import { push }             from 'connected-react-router'
import { translationPaths } from "../../translations/translations"


interface AgencyAccountsOwnProps extends LoaderConfig{
  render     : (loaderProps:Injected)=>any
}

const mapStateToProps = (state:State, ownProps:AgencyAccountsOwnProps)=>{
  return {
    selectedAgency      : state.Selection.Agency,
    accounts            : state.Accounts.Agency,
    retrievingAccounts  : state.Accounts.Retrieving,
    failed              : state.Accounts.Failed,
    selectedDealer      : state.Selection.Dealer,
    history             : state.router.location.pathname,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:AgencyAccountsOwnProps)=>{
  return {
    getAccounts : (...args:Parameters<typeof getAccounts>)=>dispatch(getAccounts(...args)),
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AgencyAccountsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingAccounts = false
  if(props.selectedAgency === null){
    return (
      <AgencySelectionModal open={true} />
    )
  }
  if(props.failed) {
    return <ErrorPage errorMessage={translationPaths.loaders.accounts.failed} doTranslate={true} />
  }
  if(props.retrievingAccounts && !props.letThrough) {
    return <Loading loadingMessage={translationPaths.loaders.accounts.loading} doTranslate={true}/>
  }
  if(!props.accounts[props.selectedAgency]){
    loadingAccounts = true
    if(!props.retrievingAccounts){
      props.getAccounts(props.selectedAgency)
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.accounts.loading} doTranslate={true} />
    }
  }
  if(!props.accounts || !props.accounts[props.selectedAgency].some(account=>account.id === props.selectedDealer)){
    props.push(props.history.replace(`/${props.selectedDealer}`,"") as any)
  }
  return props.render({loadingAccounts, accounts: props.accounts[props.selectedAgency]})
})


interface LoaderConfig{
  letThrough : boolean,
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  accounts : State["Accounts"]["Agency"][0]
  loadingAccounts : boolean
}

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresAgencyAccounts:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}

export default requiresAgencyAccounts
