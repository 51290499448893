import {createReducer}                              from "../../../utils/Reducers"
import ActionTypes                                  from "../../../model/constant/actions"
import {CurrentLoadedPacingStatsMonthsByAgencyId }  from "../../../model/store/statistics/PacingStats"

const CurrentLoadedMonths = createReducer<CurrentLoadedPacingStatsMonthsByAgencyId>({}, {
  [ActionTypes.GET_PACING_STATS] : (state, action)=>{
    if(state[action.payload.agencyId]) {
      return {
        ...state,
        [action.payload.agencyId] : Array.from(new Set([...state[action.payload.agencyId],...action.payload.months]))
      }
    }
    else {
      return {
        ...state,
        [action.payload.agencyId] : action.payload.months
      }
    }
  },
})

export default CurrentLoadedMonths
