import { makeStyles, Theme, createStyles }  from "@material-ui/core"
import { diffColors }                       from "../../Theme/Theme"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    KPI : {
      display : 'flex',
      alignItems : 'center',
      justifyContent: 'flex-end',
      margin: 0,
      height: "48%",
      position: "relative",
    },

    // Via https://stackoverflow.com/a/48962278
    // Parent must be position: relative
    triangleOverlay: {
      borderWidth: '0 60px 60px 0',
      borderColor: `transparent transparent ${diffColors.good} transparent`,
      borderStyle: 'solid',
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
    triangleOverlayRed: {
      borderWidth: '0 60px 60px 0',
      borderColor: `transparent transparent ${diffColors.bad} transparent`,
      borderStyle: 'solid',
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
    diff : {
      position: "absolute",
      bottom: 5,
      left: 3,
      color: "white",
      letterSpacing: "-0.5px",
      fontSize: 14,
    },
    data : {
      padding : 20,
      paddingLeft: 80,
      display : 'flex',
      flexDirection : 'column',
      alignItems : 'center',
    },
    text : {
      fontWeight: "bolder",
    },
    caption : {
      width : '100%',
      display : 'flex',
      justifyContent : 'flex-end',
      color: theme.palette.grey[500],
      letterSpacing: 0,
      fontSize: 14,
    }
  })
)

export default useStyles
