import React                          from 'react'
import useStyles, {
  StyledTableRow ,
  StyledTableCell,
  TotalTableCell
}                                     from './OverviewByProductsStyles'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody
}                                     from '@material-ui/core'
import MONTHS, { sortMonths }         from '../../../model/constant/Months'
import { formatMoney }                from '../../../utils/MoneyFormatting'
import {
  Breakdown,
}                                     from '../../../model/budgetEstimator/Summary'
import {
  getMediaSpendOfMonth,
  getMediaSpendOfMonthByChannel,
  getMediaSpendOfMonthByProfitCenter,
  getTotalBilledByMonth,
  getTotalManagementFeeByMonth,
  getTotalDiscountByMonth
}                                     from '../../../model/budgetEstimator/Calculations/BudgetCalculations'
import { BreakpointTableCell }        from './OverviewByChannelsStyles'

interface OverviewByChannelsOwnProps {
  breakdown : Breakdown
}

const OverviewByChannels = (props:OverviewByChannelsOwnProps) => {
  const classes = useStyles()
  const mediaSpendByMonth = getMediaSpendOfMonth(props.breakdown)
  const mediaSpendByChannel = getMediaSpendOfMonthByChannel(props.breakdown)
  const mediaSpendByProfitCenter = getMediaSpendOfMonthByProfitCenter(props.breakdown)
  const totalBilledByMonth = getTotalBilledByMonth(props.breakdown)
  const totalManagementFeeByMonth = getTotalManagementFeeByMonth(props.breakdown)
  const totalDiscountByMonth = getTotalDiscountByMonth(props.breakdown)
  const productsList = Object.keys(props.breakdown.amounts.april).map(x=>{
    return {
      name         : x,
      profitCenter  : props.breakdown.amounts.april[x].profitCenter,
      channel       : props.breakdown.amounts.april[x].channel
    }
  })
  const monthsSorted = sortMonths(props.breakdown.startingMonth)
  const breakDownByProduct = productsList.reduce<Record<string,{mediaSpend:number,totalBilled:number,totalManagementFee:number,totalCampaignDiscount:number,totalExtraDiscount:number,totalBaseManagementFee:number}>>((acc,curr)=>{
    let mediaSpend              = 0
    let totalBilled             = 0
    let totalManagementFee      = 0
    let totalCampaignDiscount   = 0
    let totalExtraDiscount      = 0
    let totalBaseManagementFee  = 0
    for(const month of MONTHS) {
      if(props.breakdown.amounts[month][curr.name].profitCenter === curr.profitCenter && props.breakdown.amounts[month][curr.name].channel === curr.channel) {
        mediaSpend              = mediaSpend + props.breakdown.amounts[month][curr.name].mediaSpend
        totalBilled             = totalBilled + props.breakdown.amounts[month][curr.name].totalBilled
        totalManagementFee      = totalManagementFee + props.breakdown.amounts[month][curr.name].managementFeeCurrency
        totalCampaignDiscount   = totalCampaignDiscount + props.breakdown.amounts[month][curr.name].discounts['campaignDiscount']
        totalExtraDiscount      = totalExtraDiscount + props.breakdown.amounts[month][curr.name].discounts['extraDiscount']
        totalBaseManagementFee  = totalBaseManagementFee + props.breakdown.amounts[month][curr.name].discounts['extraDiscount'] + props.breakdown.amounts[month][curr.name].discounts['campaignDiscount'] +props.breakdown.amounts[month][curr.name].managementFeeCurrency
      }
    }
    return {
      ...acc,
      [curr.name] : {
        mediaSpend : mediaSpend,
        totalBilled : totalBilled,
        totalManagementFee,
        totalCampaignDiscount,
        totalExtraDiscount,
        totalBaseManagementFee
      }
    }
  },{})

  return (
    <div className={classes.container}>
      <TableContainer style={{borderRadius:5,marginTop:10,overflow:'auto',maxWidth:'90vw'}}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{minWidth:200}}>Product</StyledTableCell>
              {monthsSorted.map(month=><StyledTableCell key={month}>{month.toLocaleUpperCase()}</StyledTableCell>)}
              <StyledTableCell>Total</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {productsList.map(product=>(
              <React.Fragment key={product.name}>
                <StyledTableRow>
                  <BreakpointTableCell>{product.name} <strong>[{product.channel.toUpperCase()}] [{product.profitCenter}]</strong></BreakpointTableCell>
                  {monthsSorted.map(month=><BreakpointTableCell key={month}>{}</BreakpointTableCell>)}
                  <BreakpointTableCell></BreakpointTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Media spend</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month}>{formatMoney(props.breakdown.amounts[month][product.name].mediaSpend)}</StyledTableCell>)}
                  <StyledTableCell>{formatMoney(breakDownByProduct[product.name]["mediaSpend"])}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.baseManagementFee}>Base management fee</StyledTableCell>
                  {monthsSorted.map(month=>{
                    const baseManagementFee = (
                      props.breakdown.amounts[month][product.name].discounts["campaignDiscount"] +
                      props.breakdown.amounts[month][product.name].discounts["extraDiscount"] +
                      props.breakdown.amounts[month][product.name].managementFeeCurrency
                    )
                    return <StyledTableCell key={month} className={classes.baseManagementFee}>{formatMoney(baseManagementFee)}</StyledTableCell>
                  })}
                  <StyledTableCell className={classes.baseManagementFee}>{formatMoney(breakDownByProduct[product.name]["totalBaseManagementFee"])}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountOnManagementFee}>Campaign discount</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.discountOnManagementFee}>{formatMoney(props.breakdown.amounts[month][product.name].discounts["campaignDiscount"])}</StyledTableCell>)}
                  <StyledTableCell className={classes.discountOnManagementFee}>{formatMoney(breakDownByProduct[product.name]["totalCampaignDiscount"])}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountOnManagementFee}>Extra discount</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.discountOnManagementFee}>{formatMoney(props.breakdown.amounts[month][product.name].discounts["extraDiscount"])}</StyledTableCell>)}
                  <StyledTableCell className={classes.discountOnManagementFee}>{formatMoney(breakDownByProduct[product.name]["totalExtraDiscount"])}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountedManagementFee}>Management fee</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.discountedManagementFee}>{formatMoney(props.breakdown.amounts[month][product.name].managementFeeCurrency)}</StyledTableCell>)}
                  <StyledTableCell className={classes.discountedManagementFee}>{formatMoney(breakDownByProduct[product.name]["totalManagementFee"])}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Allocation versus budget</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month}>{(props.breakdown.amounts[month][product.name].mediaSpend / mediaSpendByMonth(month) * 100).toFixed(2)}%</StyledTableCell>)}
                  <StyledTableCell>{(monthsSorted.reduce((acc,month)=>{
                    return acc + props.breakdown.amounts[month][product.name].mediaSpend
                  },0) / monthsSorted.reduce((acc,month)=>{
                    return acc + mediaSpendByMonth(month)
                  },0) * 100).toFixed(2)}%</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Allocation versus campaign type</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month}>{((props.breakdown.amounts[month][product.name].mediaSpend / mediaSpendByProfitCenter(month,product.profitCenter) * 100)||0).toFixed(2)}%</StyledTableCell>)}
                  <StyledTableCell>{(monthsSorted.reduce((acc,month)=>{
                    return acc + (props.breakdown.amounts[month][product.name].mediaSpend || 0)
                  },0) / monthsSorted.reduce((acc,month)=>{
                    return acc + mediaSpendByProfitCenter(month,product.profitCenter)
                  },0) * 100).toFixed(2) || 0}%</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Allocation versus channel</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month}>{(props.breakdown.amounts[month][product.name].mediaSpend / mediaSpendByChannel(month,product.channel) * 100).toFixed(2)}%</StyledTableCell>)}
                  <StyledTableCell>{(monthsSorted.reduce((acc,month)=>{
                    return acc + (props.breakdown.amounts[month][product.name].mediaSpend)
                  },0) / monthsSorted.reduce((acc,month)=>{
                    return acc + mediaSpendByChannel(month,product.channel)
                  },0) * 100).toFixed(2)}%</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.totalBilled}>Total billed</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.totalBilled}>{formatMoney(props.breakdown.amounts[month][product.name].totalBilled)}</StyledTableCell>)}
                  <StyledTableCell className={classes.totalBilled}>{formatMoney(breakDownByProduct[product.name]["totalBilled"])}</StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
          <TableBody>
            <StyledTableRow>
              <TotalTableCell>Total Media Spend</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(mediaSpendByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>{
                return acc + mediaSpendByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TotalTableCell>Total Management Fee</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalManagementFeeByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>{
                return acc + totalManagementFeeByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TotalTableCell>Total discount</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalDiscountByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>{
                return acc + totalDiscountByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TotalTableCell>Total Billed</TotalTableCell>
              {monthsSorted.map(month=><TotalTableCell key={month}>{formatMoney(totalBilledByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>{
                return acc + totalBilledByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default OverviewByChannels
