import * as React                             from "react"
import {connect}                              from "react-redux"
import {ThunkDispatch}                        from "redux-thunk"
import {State}                                from "../../../redux/reducers"
import {Action}                               from "../../../model/constant/actions"
import Loading                                from "../../loadings/CircularLoading"
import SalesProduct                           from "../../../model/budgetEstimator/SalesProduct"
import { getSalesProducts }                   from "../../../actions/Trffk-salestool/Products"

interface SalesProductsLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  salesProducts   : SalesProduct[]
  loadingProducts : boolean
}

const mapStateToProps = (state:State, ownProps:SalesProductsLoaderOwnProps)=>{
  return {
    salesProducts    : state.Sales.Products,
    retrieving        : state.Sales.Retrieving.products,
    failedRetrieving  : state.Sales.Failed.products,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:SalesProductsLoaderOwnProps)=>{
  return {
    getSalesProducts : (...args:Parameters<typeof getSalesProducts>)=>dispatch(getSalesProducts(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:SalesProductsLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type LoaderProps = ReturnType<typeof mergeProps>

const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingProducts = false
  if(props.retrieving && !props.letThrough) {
    console.log('DOING THAT')
    return <Loading loadingMessage="Loading Sales products"/>
  }
  if (props.failedRetrieving.status) {
    return <div> {props.failedRetrieving.message} </div>
  }
  if(!props.salesProducts){
    loadingProducts = true
    if(!props.retrieving){
      props.getSalesProducts()
    }
    if(!props.letThrough){
      return <Loading loadingMessage={"Loading Sales products"} />
    }
  }
  return props.render({loadingProducts, salesProducts:props.salesProducts})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresSalesProducts:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresSalesProducts
