import React                    from 'react'
import {
  Paper,
}                               from '@material-ui/core'
import mainTheme                from '../../../Theme/Main'
import { StatPerRegion }        from '../../../model/store/statistics/PacingStats'
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar
}                               from 'recharts'
import { CustomTick }           from './CustomChartComponents/CustomTick'
import { CustomTooltipRegions } from './CustomChartComponents/CustomTooltipRegions'
import { commanderTheme }       from '../../../Theme/Theme'

interface PacingByRegionOwnProps {
  data: StatPerRegion[]
}

const PacingByRegionChart = (props: PacingByRegionOwnProps) => {
  return (
    <Paper elevation={0} style={{ height: 400, marginLeft: "-60px" }}>
      <ResponsiveContainer width={"99%"}>
        <BarChart
          data={props.data}
          margin={{top: 90, bottom: 10, left: 0, right: 20}}
          barCategoryGap={150/props.data.length}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="region"
            padding={{left: 80, right: 80}}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            tick={{ stroke: mainTheme.palette.grey[500], strokeWidth: .8, fontSize: 12}}
          />
          <YAxis
            dataKey="averagePacing"
            padding={{top: 0, bottom: 0}}
            axisLine={false}
            tickLine={false}
            ticks={[0, 25, 50, 75, 100, 125]}
            domain={[0, 150]}
            allowDataOverflow={true}
            scale="linear"
            tick={<CustomTick />}
          />
          <Tooltip cursor={{fill: "transparent"}} content={<CustomTooltipRegions />}/>
          <Bar radius={[7, 7, 7, 7]} dataKey="averagePacing" fill={commanderTheme.orange} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default PacingByRegionChart
