import {
  StandardThunk,
  createRetrievingAgencies,
  createGetAgencies,
  createFailedRetrievingAgencies
}                                 from "../creators"
import axios                      from "axios"
import baseUrl,{Success}          from '../../model/http/Auth2/Agencies'

export const getAgencies = ():StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingAgencies(true))
  axios.get<Success>(baseUrl,{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetAgencies(response.data))
  })
  .catch(error=>{
    console.log(error)
    dispatch(createFailedRetrievingAgencies(true))
  })
  .then(()=>{
    dispatch(createRetrievingAgencies(false))
  })
}
