import React                   from 'react'
import useStyles               from './BudgetAllocationStyles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
}                              from '@material-ui/core'
import SummaryTemplate, {
  BudgetType,
  ProfitCenter
}                              from '../../../model/budgetEstimator/Summary'
import {
  handleBudgetTypeChange,
  handleDealerBudgetChange,
  handleShareNewChange,
  handleShareUsedChange
}                              from '../../../model/budgetEstimator/Calculations/BugetHandlers'
import DriveEtaIcon            from '@material-ui/icons/DriveEta'
import CreditCardIcon          from '@material-ui/icons/CreditCard'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'

interface BudgetInputsOwnProps {
  disabled            : boolean
  summary             : SummaryTemplate
  handleUpdateSummary : (summary:SummaryTemplate)=>void
  handleGetBreakdown  : (sunnary:SummaryTemplate)=>void
}

const BudgetInputs = (props:BudgetInputsOwnProps) => {

  const classes = useStyles()
  const {summary,handleUpdateSummary,handleGetBreakdown} = {...props}
  const [newBudget,setNewBudget] = React.useState<number>(summary.budget.NEW)
  const [usedBudget,setUsedBudget] = React.useState<number>(summary.budget.USED)
  const [newBudgetShare,setNewBudgetShare] = React.useState(summary.budgetShare.NEW)
  const [usedBudgetShare,setUsedBudgetShare] = React.useState(summary.budgetShare.USED)
  // ICONS AND LABELS
  const newLabel = props.summary.budgetType === 'annual' ? "Dealer's budget for new inventory" : "Dealer's budget by new car"
  const usedLabel = props.summary.budgetType === 'annual' ? "Dealer's budget for used inventory" : "Dealer's budget By used car"
  const inputIcon = props.summary.budgetType === 'annual' ? <EmojiTransportationIcon /> : <DriveEtaIcon />

  // HANDLERS
  const handleBudgetType = (event:React.ChangeEvent<{value:unknown}>) => {
    const value = event.target.value as BudgetType
    const  a = handleDealerBudgetChange(0,summary,ProfitCenter.NEW)
    const  b = handleDealerBudgetChange(0,a,ProfitCenter.USED)
    const newSummary = handleBudgetTypeChange(value,b)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
    setNewBudget(0)
    setUsedBudget(0)
  }

  const handleNewBudgetDealerChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.abs(parseFloat(event.target.value))
    setNewBudget(value)
  }
  const handleNewBudgetShareDealerChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    let value = Math.abs(parseFloat(event.target.value))
    if(value>100 ) {value=100}
    setNewBudgetShare(value)
  }
  const handleUsedBudgetDealerChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.abs(parseFloat(event.target.value))
    setUsedBudget(value)
  }
  const handleUsedBudgetShareDealerChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    let value = Math.abs(parseFloat(event.target.value))
    if(value>100 ) {value=100}
    setUsedBudgetShare(value)
  }

  const handleDealerNewBudget = () => {
    const newSummary = handleDealerBudgetChange(newBudget || 0,summary,ProfitCenter.NEW)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  const handleDealerUsedBudget = () => {
    const newSummary = handleDealerBudgetChange(usedBudget || 0,summary,ProfitCenter.USED)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  const handleShareNew = () => {
    const newSummary = handleShareNewChange(newBudgetShare,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  const handleShareUsed = () => {
    const newSummary = handleShareUsedChange(usedBudgetShare,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  return (
    <>
      <FormControl disabled={props.disabled} variant="outlined" className={classes.formControl}>
        <InputLabel id="TrffkAdName"> Budget type</InputLabel>
        <Select
          labelId="TrffkAdName"
          value={props.summary.budgetType}
          onChange={handleBudgetType}
          label="Budget type"
        >
          <MenuItem value={'annual'}>Annual</MenuItem>
          <MenuItem value={'byCar'}>By vehicle</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.budgetInputs}>
        <FormControl variant="outlined" className={classes.formControlBudget}>
          <TextField
            disabled={props.disabled}
            className={classes.input}
            label={newLabel}
            variant="outlined"
            value={newBudget || ''}
            onChange={handleNewBudgetDealerChange}
            onBlur={handleDealerNewBudget}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.adornment} position="start">
                  {inputIcon}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment className={classes.adornment} position="end">
                  <strong>$</strong>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl  variant="outlined" className={classes.formControlBudget}>
          <TextField
            onBlur={handleDealerUsedBudget}
            disabled={props.disabled}
            className={classes.input}
            label={usedLabel}
            variant="outlined"
            value={usedBudget || ''}
            onChange={handleUsedBudgetDealerChange}
            type='number'
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.adornment} position="start">
                  {inputIcon}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment className={classes.adornment} position="end">
                  <strong>$</strong>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className={classes.budgetInputs}>
        <FormControl  variant="outlined" className={classes.formControlBudget}>
          <TextField
            onBlur={handleShareNew}
            disabled={props.disabled}
            className={classes.input}
            label="Estimated TRFFK share for New"
            variant="outlined"
            value={newBudgetShare || ''}
            onChange={handleNewBudgetShareDealerChange}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.adornment} position="start">
                  <CreditCardIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment className={classes.adornment} position="end">
                  <strong>%</strong>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl  variant="outlined" className={classes.formControlBudget}>
          <TextField
            onBlur={handleShareUsed}
            disabled={props.disabled}
            className={classes.input}
            label="Estimated TRFFK share for Used"
            variant="outlined"
            value={usedBudgetShare || ''}
            onChange={handleUsedBudgetShareDealerChange}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.adornment} position="start">
                  <CreditCardIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment className={classes.adornment} position="end">
                  <strong>%</strong>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
    </>
  )
}

export default BudgetInputs
