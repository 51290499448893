import {
  makeStyles,
  Theme
 }              from "@material-ui/core"

const useStyles = makeStyles((theme:Theme) => ({
  container: {
    height: 600,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    padding: 40,
    paddingLeft: 0,
    paddingBottom: 30,
    display: 'grid',
    gridTemplateRows: '3fr 1fr',
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    marginBottom: 10,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 25
  },
  button: {
    width: 100,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 70,
    color: theme.palette.primary.main,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export default useStyles
