import React         from 'react'
import {
  makeStyles,
  Theme,
  createStyles
}                    from '@material-ui/core'
import { Translate } from 'react-localize-redux'

interface ErrorPageOwnProps {
  doTranslate ?: boolean
  errorMessage : string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorPage : {
      color : 'red',
    }
  })
)
const ErrorPage = (props:ErrorPageOwnProps) => {
  const classes = useStyles()
  return (
    <div className={classes.errorPage}>
      {props.doTranslate ? <Translate id={props.errorMessage} /> : props.errorMessage}
    </div>
  )
}

export default ErrorPage
