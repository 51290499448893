import {createReducer}             from "../../../utils/Reducers"
import ActionTypes                 from "../../../model/constant/actions"
import { TrackingStats }           from "../../../model/store/insights/TrackingStats"

const TrackingStatsReducer = createReducer<{[agencyId:string]:TrackingStats[]}>({}, {
  [ActionTypes.GET_TRACKING_STATS] : (state, action)=>{
    const compare = (a:TrackingStats,b:TrackingStats) => {
      if (a.date>b.date) return 1
      if (b.date>a.date) return -1
      return 0
    }
    const copiedStatsForSorting = action.payload.trackingStats
    const sortedStats: TrackingStats[] = copiedStatsForSorting.sort(compare)
    return {
      ...state,
      [action.payload.agencyId] : [
        ...sortedStats
      ]
    }
  }
})

export default TrackingStatsReducer
