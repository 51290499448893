import {
  makeStyles,
  Theme,
  createStyles
}                   from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container : {
      width : 900,
      minHeight : 600,
    },
    title : {
      display:'flex',
      color:theme.palette.primary.contrastText,
      fontSize:'2em'
    },
    tabLabel : {
      display : 'flex',
      alignItems : 'center'
    }
  })
)

export default useStyles
