export enum AccountPermission{
  Keystone_Standard               = "Keystone_Standard",
  Keystone_CreateStrategy         = "Keystone_CreateStrategy",
  Inventory_Strategy_Edit         = "Inventory_Strategy_Edit",
  Headstone_ChangeIdentityVariant = "Headstone_ChangeIdentityVariant",
}
export enum System {
  COMMANDER = "COMMANDER"
}

export enum SalestoolPermissions {
  "salestool-beta" = "salestool-beta"
}

export interface PermissionsBySystem {
  [system:string] : Record<string,boolean>
}
export interface PermissionsByAccount {
  [accountId:number] : Array<AccountPermission>
}
