import {
  makeStyles,
  Theme,
  createStyles
}                 from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    monthYearPicker : {
      padding : 20
    },
    monthYearButton : {
      display : 'flex',
      alignItems : 'center',
      color : '#666'
    },
    icon : {
      marginRight : 10,
      fontSize : 20
    },
    year : {
      display : 'flex',
      justifyContent : 'space-between',
      alignItems : 'center',
      backgroundColor : theme.palette.primary.dark,
      color : theme.palette.primary.contrastText
    },
    datePicker : {
      width: 300
    },
    months : {
      display: 'flex',
      flexWrap : 'wrap',
      justifyContent : 'space-evenly',
      padding : 15,
      backgroundColor : "#f5f5f5"
    },
    month : {
      flex : '1 0 30%',
      padding : 15,
      borderRadius : 0,
    },
    input : {
      '&hover' : {
        cursor : 'pointer'
      }
    }
  })
)

export default useStyles
