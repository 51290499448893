import { createStyles }   from "@material-ui/styles"
import {
  makeStyles,
  Theme
}                         from "@material-ui/core"

const useStyles = makeStyles((theme:Theme)=>(
  createStyles({
    legendContainer: {
      height: "80%",
      paddingRight: 50,
    },
    pacingPerChannel : {
      height : '100%',
      display : 'grid',
      padding: 10,
      paddingRight: 30,
      paddingLeft: 30,
    },
    rowContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 10fr',
      width: '95%',
    },
    legends : {
      display : 'flex',
      alignItems : 'center',
      justifyContent: 'flex-start',
      marginBottom : 20,
      color: theme.palette.primary.light,
      letterSpacing: 0,
    },
    legend : {
      minWidth: 75,
    },
    graphs: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingTop: 10,
      width: '100%',
      gridTemplateColumns : '1fr 5fr',
      alignItems : 'center',
    },


    '@media (max-width: 1250px)': {
      legendContainer: {
        paddingRight: 25,
      },
    },

  })
))

export default useStyles
