import * as React                             from "react"
import {connect}                              from "react-redux"
import {ThunkDispatch}                        from "redux-thunk"
import {State}                                from "../../../redux/reducers"
import {Action}                               from "../../../model/constant/actions"
import Loading                                from "../../loadings/CircularLoading"
import SalesCampaign                          from "../../../model/budgetEstimator/SalesCampaign"
import { getSalesCampaigns }                  from "../../../actions/Trffk-salestool/Campaigns"

interface SalesCampaignsLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  salesCampaigns   : SalesCampaign[]
  loadingCampaigns : boolean
}

const mapStateToProps = (state:State, ownProps:SalesCampaignsLoaderOwnProps)=>{
  return {
    salesCampaigns    : state.Sales.Campaigns,
    retrieving        : state.Sales.Retrieving.campaigns,
    failedRetrieving  : state.Sales.Failed.campaigns,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:SalesCampaignsLoaderOwnProps)=>{
  return {
    getSalesCampaigns : (...args:Parameters<typeof getSalesCampaigns>)=>dispatch(getSalesCampaigns(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:SalesCampaignsLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type LoaderProps = ReturnType<typeof mergeProps>

const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingCampaigns = false
  if(props.retrieving && !props.letThrough) {
    return <Loading loadingMessage="Loading Sales Campaigns"/>
  }
  if (props.failedRetrieving.status) {
    return <div> {props.failedRetrieving.message} </div>
  }
  if(!props.salesCampaigns){
    loadingCampaigns = true
    if(!props.retrieving){
      props.getSalesCampaigns()
    }
    if(!props.letThrough){
      return <Loading loadingMessage={"Loading Sales Campaigns"} />
    }
  }
  return props.render({loadingCampaigns, salesCampaigns:props.salesCampaigns})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresSalesCampaigns:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresSalesCampaigns
