import {
  StandardThunk,
  createGetPacingStats,
  createRetrievingPacingStats,
}                                 from "../creators"
import axios                      from "axios"

import Papa                       from 'papaparse'
import { buildCompiledReportUrl } from "../../model/http/Reporting/CompiledReport"
import PacingStats                from "../../model/http/Reporting/PacingStats"
import moment                     from 'moment'


export const getPacingStats = (agencyId:string,months:string[]):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  const sortedMonths = [...months].sort()
  const lastDate = sortedMonths[sortedMonths.length-1]
  dispatch(createRetrievingPacingStats(true))
  axios.get(buildCompiledReportUrl(agencyId,"AGENCY_PACING_STATS"),{
    headers : {
      Authorization : `Bearer ${token}`,
    },
    params : {
      dateFrom : sortedMonths[0]+"-01",
      dateTo : lastDate+"-"+moment(lastDate).daysInMonth()
    }
  })
  .then(response=>{
    const parsedData:PacingStats[]  = Papa.parse(response.data.trim(),{header:true, dynamicTyping:true}).data
    dispatch(createGetPacingStats(
      {agencyId,pacingStats:parsedData,months:months}
    ))
  })
  .catch(error=>console.error(error))
  .then( ()=>{
    dispatch(createRetrievingPacingStats(false))
  })
}
