import * as React                 from "react"
import {connect}                  from "react-redux"
import {ThunkDispatch}            from "redux-thunk"
import {State}                    from "../../redux/reducers"
import {Action}                   from "../../model/constant/actions"
import Loading                    from "../loadings/CircularLoading"
import { getAccountsData }        from "../../actions/accountsData"
import { translationPaths }       from "../../translations/translations"
import ErrorPage                  from "../ErrorPage"

interface AccountsDataLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  accountsData        : State["AdAccountsData"]["Data"]
  loadingAccountsData : boolean
}

const mapStateToProps = (state:State, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    selectedDealer          : state.Selection.Dealer,
    accountsData            : state.AdAccountsData.Data,
    retrieving              : state.AdAccountsData.Retrieving,
    failedRetrieving        : state.AdAccountsData.Failed,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    getAccountsData : (...args:Parameters<typeof getAccountsData>)=>dispatch(getAccountsData(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingAccountsData = false
  if (props.failedRetrieving && !props.letThrough) {
    return <ErrorPage errorMessage={translationPaths.loaders.accountsData.failed} doTranslate  />
  }
  if(!props.accountsData[props.selectedAgency] ||
      (props.accountsData[props.selectedAgency] && !props.accountsData[props.selectedAgency][props.selectedDealer])
    ){
    loadingAccountsData = true
    if(!props.retrieving && !props.failedRetrieving){
      props.getAccountsData(props.selectedDealer,props.selectedAgency)
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.accountsData.loading} doTranslate={true} />
    }
  }
  return props.render({loadingAccountsData, accountsData: props.accountsData})
})


type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresAdAccountsInformation:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}

export default requiresAdAccountsInformation
