import {
  StandardThunk,
  createRetrievingAccountPermissions,
  createGetAccountPermissions,
  createRetrievingSystemsPermissions,
  createGetSystemsPermissions
}                                 from "../creators"
import axios                      from "axios"
import {
  AccountPermissionSuccess,
  AccountPermissionURL,
  SystemPermissionSuccess,
  SystemPermissionURL
}                                 from "../../model/http/Auth2/Permissions"
import { PermissionsBySystem } from "../../model/users/Permissions"

export const getAccountPermissions = (accountId:number):StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingAccountPermissions(true))
  axios.get<AccountPermissionSuccess>(AccountPermissionURL(accountId),{
    headers : {
      Authorization : `Bearer ${token}`,
    },
    params : {mode:"LIST"}
  })
  .then(response=>{
    dispatch(createGetAccountPermissions({accountId,permissions:response.data}))
  })
  .catch(error=>{
    console.log(error)
  })
  .then(()=>{
    dispatch(createRetrievingAccountPermissions(false))
  })
}
export const getSystemsPermissions = ():StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingSystemsPermissions(true))
  axios.get<SystemPermissionSuccess>(SystemPermissionURL,{
    headers : {
      Authorization : `Bearer ${token}`,
    },
    params : {mode:"LIST"}
  })
  .then(response=>{
    const systemPermissions = response.data.reduce((permBySystem:PermissionsBySystem, pair)=>{
      if(!permBySystem[pair[1]]){
          permBySystem[pair[1]] = {}
      }
      permBySystem[pair[1]][pair[0]] = true
      return permBySystem
  },{})
    dispatch(createGetSystemsPermissions(systemPermissions))
  })
  .catch(error=>{
    console.log(error)
  })
  .then(()=>{
    dispatch(createRetrievingAccountPermissions(false))
  })
}
