import { Month, sortMonths }                  from "../../constant/Months"
import {Channel}                              from "../../constant/channels"
import {
  VariableManagementFee,
  FlatManagementFee,
  Breakdown,
  ProfitCenter,
}                                             from "../Summary"


export const createNoCommitmentCampaign = ():FlatManagementFee => ({name:'No commitment',amount:30,slug:'nc'})
export const create6MonthsCommitmentCampaign = ():FlatManagementFee => ({name:'6 Months',amount:25,slug:'6m'})
export const create12MonthsCommitmentCampaign = ():FlatManagementFee => ({name:'12 Months',amount:20,slug:'12m'})

export const getMediaSpendOfMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].mediaSpend,0)
)
export const getTotalBilledByMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].totalBilled,0)
)
export const getTotalManagementFeeByMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].managementFeeCurrency,0)
)
export const getBaseManagementFeeByMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].discounts['campaignDiscount'] + breakdown.amounts[month][product].discounts['extraDiscount'] + breakdown.amounts[month][product].managementFeeCurrency ,0)
)
export const getTotalDiscountByMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].discounts['extraDiscount'] +  breakdown.amounts[month][product].discounts['campaignDiscount'] ,0)
)
export const getExtraDiscountByMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].discounts['extraDiscount'],0)
)
export const getCampaignDiscountByMonth = (breakdown:Breakdown) => (month:Month) : number => (
  Object.keys(breakdown.amounts[month]).reduce((acc,product)=> acc + breakdown.amounts[month][product].discounts['campaignDiscount'] ,0)
)
export const getMediaSpendOfMonthByChannel = (breakdown:Breakdown) => (month:Month, channel:Channel) : number => {
  let totalMediaSpend = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].channel === channel) {
      totalMediaSpend += breakdown.amounts[month][product].mediaSpend
    }
  }
  return totalMediaSpend
}
export const getManagementFeeOfMonthByChannel = (breakdown:Breakdown) => (month:Month, channel:Channel) : number => {
  let totalManagementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].channel === channel) {
      totalManagementFee += breakdown.amounts[month][product].managementFeeCurrency
    }
  }
  return totalManagementFee
}
export const getBaseManagementFeeOfMonthByChannel = (breakdown:Breakdown) => (month:Month, channel:Channel) : number => {
  let totalManagementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].channel === channel) {
      totalManagementFee += breakdown.amounts[month][product].discounts["campaignDiscount"] +
      breakdown.amounts[month][product].discounts["extraDiscount"] +
      breakdown.amounts[month][product].managementFeeCurrency
    }
  }
  return totalManagementFee
}
export const getCampaignDiscountOfMonthByChannel = (breakdown:Breakdown) => (month:Month, channel:Channel) : number => {
  let totalManagementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].channel === channel) {
      totalManagementFee += breakdown.amounts[month][product].discounts["campaignDiscount"]
    }
  }
  return totalManagementFee
}
export const getExtraDiscountOfMonthByChannel = (breakdown:Breakdown) => (month:Month, channel:Channel) : number => {
  let totalManagementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].channel === channel) {
      totalManagementFee += breakdown.amounts[month][product].discounts["extraDiscount"]
    }
  }
  return totalManagementFee
}
export const getTotalBilledOfMonthByChannel = (breakdown:Breakdown) => (month:Month, channel:Channel) : number => {
  let totalManagementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].channel === channel) {
      totalManagementFee += breakdown.amounts[month][product].totalBilled
    }
  }
  return totalManagementFee
}
export const getMediaSpendOfMonthByProfitCenter =  (breakdown:Breakdown) => (month:Month, profitCenter:ProfitCenter) : number => {
  let totalMediaSpend = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].profitCenter === profitCenter) {
      totalMediaSpend += breakdown.amounts[month][product].mediaSpend
    }
  }
  return totalMediaSpend
}
export const getManagementFeeOfMonthByProfitCenter =  (breakdown:Breakdown) => (month:Month, profitCenter:ProfitCenter) : number => {
  let managementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].profitCenter === profitCenter) {
      managementFee += breakdown.amounts[month][product].managementFeeCurrency
    }
  }
  return managementFee
}
export const getBaseManagementFeeOfMonthByProfitCenter =  (breakdown:Breakdown) => (month:Month, profitCenter:ProfitCenter) : number => {
  let managementFee = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].profitCenter === profitCenter) {
      managementFee += breakdown.amounts[month][product].managementFeeCurrency +
      breakdown.amounts[month][product].discounts['campaignDiscount'] +
      breakdown.amounts[month][product].discounts['extraDiscount']
    }
  }
  return managementFee
}
export const getCampaignDiscountOfMonthByProfitCenter =  (breakdown:Breakdown) => (month:Month, profitCenter:ProfitCenter) : number => {
  let campaignDiscount = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].profitCenter === profitCenter) {
      campaignDiscount += breakdown.amounts[month][product].discounts['campaignDiscount']
    }
  }
  return campaignDiscount
}
export const getExtraDiscountOfMonthByProfitCenter =  (breakdown:Breakdown) => (month:Month, profitCenter:ProfitCenter) : number => {
  let extraDiscount = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].profitCenter === profitCenter) {
      extraDiscount += breakdown.amounts[month][product].discounts['extraDiscount']
    }
  }
  return extraDiscount
}
export const getTotalBilledByMonthByProfitCenter =  (breakdown:Breakdown) => (month:Month, profitCenter:ProfitCenter) : number => {
  let totalBilled = 0
  for(const product in breakdown.amounts[month]) {
    if(breakdown.amounts[month][product].profitCenter === profitCenter) {
      totalBilled += breakdown.amounts[month][product].totalBilled
    }
  }
  return totalBilled
}


export const create4MonthsNadaPromo = (startingMonth:Month):VariableManagementFee => {
  const sortedMonths = sortMonths(startingMonth)
  const temp = sortedMonths.reduce((acc:VariableManagementFee,curr,i):VariableManagementFee=>{
    return {
      ...acc,
      schedule : {
        ...acc.schedule,
        [curr] : i === 0 ? 10 : i === 1 || i ===2 ? 15 : 20
      }
    }
  },{
    description : 'nada4',
    name : 'nada4',
    slug : 'nada4',
    schedule : {
      january : 0,
      february :  0,
      march : 0,
      april : 0,
      may : 0,
      june : 0,
      july : 0,
      august : 0,
      september : 0,
      october : 0,
      november : 0,
      december : 0,
    }
  })

  return temp
}

export const create12MonthsNadaCampaign = (startingMonth:Month):VariableManagementFee => {
  const sortedMonths = sortMonths(startingMonth)
  const temp = sortedMonths.reduce((acc:VariableManagementFee,curr,i):VariableManagementFee=>{
    return {
      ...acc,
      schedule : {
        ...acc.schedule,
        [curr] : i <= 2 ? 10 : i > 2 && i <= 6 ? 12.5 : 15
      }
    }
  },{
    description : 'nada12',
    name : 'nada12',
    slug : 'nada12',
    schedule : {
      january : 0,
      february :  0,
      march : 0,
      april : 0,
      may : 0,
      june : 0,
      july : 0,
      august : 0,
      september : 0,
      october : 0,
      november : 0,
      december : 0,
    }
  })

  return temp
}
