import React                        from 'react'
import {
  Card,
  Typography,
  Button,
  TextField,
  Popover,
  List,
  ListItem,
  Checkbox,
}                                   from '@material-ui/core'
import GoogleTrendsGraph            from './graphs/GoogleTrendsGraph'
import FilterListIcon               from '@material-ui/icons/FilterList'
import {GoogleTrendsStats}          from '../../model/store/insights/GoogleTrendsStats'
import useStyles                    from './GoogleTrendsStyle'
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                                   from 'react-localize-redux'
import {translationPaths}           from '../../translations/translations'

interface GoogleTrendsOwnProps extends LocalizeContextProps{
  stats : GoogleTrendsStats[]
}
const GoogleTrends = (props:GoogleTrendsOwnProps) => {
  const classes = useStyles()
  let makes = Array.from(new Set(props.stats.map(stat=>stat.make)))

  const [selectedMakes,setSelectedMake] = React.useState<string[]>(makes)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [searchText,setSearchText] = React.useState<string>('')

  const open = Boolean(anchorEl)

  const onTextChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const temp = event.target.value
    setSearchText(temp)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCheckbox =(event:React.ChangeEvent<HTMLInputElement>) => {
    const temp = event.target.value
    if(event.target.checked) {
      setSelectedMake(prevState=>{
        return [...prevState,temp]
      })
    }
    else {
      setSelectedMake(prevState=>{
        return prevState.filter(make=>{
          return make !== temp
        })
      })
    }
  }
  const handleClear = () => {
    setSelectedMake([])
  }
  const handleSelectAll = () => {
    setSelectedMake(makes)
  }
  const stats = props.stats.filter(stat=>{
    return selectedMakes.includes(stat.make)
  })
  let dates:string[] = []
  for(const i of stats) {
    if(!dates.includes(i.date)) {
      dates.push(i.date)
    }
  }
  const data = dates.map(date=>{
    let totalTrends = 0
    let count = 0
    for(const stat of stats) {
      if(stat.date === date) {
        totalTrends += stat.value
        count++
      }
    }
    return {date:date,totalTrends,count}
  }).map(temp=>{
    return {date:temp.date,trends:parseFloat((temp.totalTrends/temp.count).toFixed(2))}
  })
  makes = makes.filter(make=>{
    return make.toLowerCase().includes(searchText.toLowerCase())
  })
  return (
    <Card className={classes.googleTrends}>
      <div className={classes.header}>
        <Typography variant="h6">{props.translate(translationPaths.marketInsights.googleTrendsGraph.title)}</Typography>
        <Button size="large" onClick={handleClick} variant="outlined">
          <FilterListIcon style={{marginRight:15}} />
          {props.translate(translationPaths.marketInsights.googleTrendsGraph.brands)}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.brands}>
            <TextField onChange={onTextChange} variant="outlined" label={props.translate(translationPaths.marketInsights.googleTrendsGraph.search)}/>
            <List className={classes.makesList}>
              {makes.map(make=>
                <ListItem
                  key={make}
                  className={classes.listItem}
                >
                  <Checkbox value={make} checked={selectedMakes.includes(make)} onChange={handleCheckbox}/> {make}
                </ListItem>
              )}
            </List>
            <div className={classes.button}>
              <Button onClick={handleSelectAll} size="small">{props.translate(translationPaths.marketInsights.googleTrendsGraph.all)}</Button>
              <Button onClick={handleClear} size="small">{props.translate(translationPaths.marketInsights.googleTrendsGraph.clear)}</Button>
            </div>
          </div>
        </Popover>
      </div>
      {
        props.stats.length === 0
        ? <div className={classes.unavailable}><Typography><Translate id={translationPaths.marketInsights.unavailableData} /></Typography></div>
        : <GoogleTrendsGraph data={data} lineColor="#488633"/>
      }
    </Card>
  )
}

export default withLocalize(GoogleTrends)
