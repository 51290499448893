import {
  StandardThunk,
  createRetrievingFacebookDefaultAdSet,
  createGetFacebookDefaultAdSet,
  createFailedRetrievingFacebookDefaultAdSet
}                                               from "../creators"
import axios                                    from 'axios'
import {
  Success as DefaultAdTemplate,
}                                               from '../../model/http/Headstone/GetFacebookDefaultAdSets'
import AdSetUrl                                 from "../../model/http/Headstone/GetFacebookDefaultAdSets"
import Languages                                from "../../model/constant/Languages"
import VehicleState                             from "../../model/constant/VehicleState"
import AudienceGoal                             from "../../model/constant/AudienceGoal"

export const getDefaultAdSet = (accountId:number,lang:Languages,vehicleState:VehicleState,vehicleType:string,audienceGoal:AudienceGoal):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookDefaultAdSet(true))
  axios.get<DefaultAdTemplate[]>(AdSetUrl(accountId,lang,vehicleState,vehicleType,audienceGoal),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookDefaultAdSet({
      accountId,
      AdTemplates : response.data,
      lang,
      state : vehicleState
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookDefaultAdSet(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookDefaultAdSet(false))
  })
}
