import styles              from "./indexStyles"
import * as React          from "react"
import {connect}           from "react-redux"
import {ThunkDispatch}     from "redux-thunk"
import Cookies             from "js-cookie"
import {State}             from "../../redux/reducers/"
import {Action}            from "../../model/constant/actions"
import {
  login,
  rememberMeLogin
}                          from "../../actions/Auth2/authentication"
import TextField           from "@material-ui/core/TextField"
import Button              from "@material-ui/core/Button"
import CardContent         from "@material-ui/core/CardContent"
import FormControl         from "@material-ui/core/FormControl"
import OverlayLoading      from "../../components/loadings/OverlayLoading"
import Checkbox            from "@material-ui/core/Checkbox"
import FormControlLabel    from "@material-ui/core/FormControlLabel"
import {Box, Typography}   from "@material-ui/core"
import {Link}              from "react-router-dom"
import Logo                from '../../assets/images/Commander_logo.png'
import {
  withLocalize,
  TranslateFunction
}                          from "react-localize-redux"
import {translationPaths}  from "../../translations/translations"

interface LoginOwnProps{
  translate : TranslateFunction
}

const mapStateToProps = (state:State, ownProps:LoginOwnProps)=>{
  return {
    error : state.Login.Error,
    loggingIn : state.Login.LoggingIn
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:LoginOwnProps)=>{
  return {
    login : (...args:Parameters<typeof login>)=>dispatch(login(...args)),
    rememberMeLogin : (...args:Parameters<typeof rememberMeLogin>)=>dispatch(rememberMeLogin(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:LoginOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoginProps = ReturnType<typeof mergeProps>
const Login = (props:LoginProps) => {
  const classes = styles()
  const sendRef = React.createRef<HTMLButtonElement>()
  const [email, setEmail] = React.useState<string>("")
  const [pwd, setPwd] = React.useState<string>("")
  const [remember, setRemember] = React.useState<boolean>(false)

  if(!props.loggingIn && Cookies.get("email") && Cookies.get("UID")){
    props.rememberMeLogin(Cookies.get("email") || "", Cookies.get("UID") || "")
  }

  const onEnterPress = (e:React.KeyboardEvent<HTMLDivElement>)=>{
    if(e.key === "Enter" && sendRef && sendRef.current){
      sendRef.current.click()
    }
  }

  return (
    <Box className={classes.login}>
      <OverlayLoading open={props.loggingIn}>
        <div className={classes.container}>
          <div className={classes.leftImage}></div>
          <CardContent className={classes.contentContainer}>
            <div className={classes.content}>
              <div className={classes.logoContainer}>
                <img
                  alt="Commander logo"
                  className={classes.logo}
                  src={Logo}
                />
              </div>
              <div className={classes.error}>
                {props.error && props.error.length &&
                  <Typography
                    color="error"
                    variant="caption"
                  >
                    {props.error}
                  </Typography>
                }
              </div>
              <FormControl margin="dense" fullWidth>
                <TextField
                  className={classes.inputText}
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  color="primary"
                  variant="outlined"
                  label={props.translate(translationPaths.login.emailAddress)}
                  type="email"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <TextField
                  className={classes.inputText}
                  value={pwd}
                  onChange={(e)=>setPwd(e.target.value)}
                  onKeyDown={onEnterPress}
                  color="primary"
                  variant="outlined"
                  label={props.translate(translationPaths.login.password)}
                  type="password"
                />
              </FormControl>
              <Link
                to="/ForgotPassword"
                className={classes.recoverPassword}
              >
                {props.translate('login.forgotPassword')}
              </Link>
              <FormControl margin="none" >
                <FormControlLabel
                  className={classes.rememberText}
                  control={
                    <Checkbox
                      color="primary"
                      checked={remember}
                      onChange={(e)=>setRemember(e.target.checked)}
                    />
                  }
                  label={props.translate(translationPaths.login.rememberMe)}
                >
                </FormControlLabel>
              </FormControl>
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  ref={sendRef}
                  variant="contained"
                  color="primary"
                  onClick={()=>props.login(email, pwd, remember)}
                >
                  {props.translate(translationPaths.login.login)}
                </Button>
              </div>
            </div>
          </CardContent>
        </div>
      </OverlayLoading>
    </Box>
  )
}

export default withLocalize(connect(mapStateToProps,mapDispatchToProps,mergeProps)(Login))
