import React              from 'react'
import {
  Icon,
  IconButton,
  Paper,
  Dialog,
  Typography,
  Button,
  useTheme
}                         from '@material-ui/core'
import moment             from 'moment'
import                         'moment/locale/fr'
import useStyles          from './MonthYearPickerStyles'
import returnMonths       from './Months'
import {
  LocalizeContextProps,
  withLocalize
}                         from 'react-localize-redux'

interface MonthYearPickerOwnProps extends LocalizeContextProps {
  onConfirm   : (month:string) => void
  currentDate : string //FORMAT : YYYY-MM
}

const MonthYearPicker = (props:MonthYearPickerOwnProps) => {
  const [selectedYear,setSelectedYear] = React.useState<string>(props.currentDate.substr(0,4))
  const [open,setOpen] = React.useState(false)
  const classes = useStyles()
  const primary = useTheme().palette.primary.main
  const border = `1px solid ${primary}`
  const MONTHS = returnMonths(border)

  const increaseYear = () => {
    setSelectedYear(prev=>{
      return parseInt(prev)+1 + ""
    })
  }
  const decreaseYear = () => {
    setSelectedYear(prev=>{
      return parseInt(prev)-1 + ""
    })
  }
  const openPickDateModal = () => {
    setOpen(true)
  }
  const closePickDateModal = () => {
    setOpen(false)
  }
  const monthYear = moment(props.currentDate).locale(props.activeLanguage.code).format('MMMM, YYYY')
  return (
    <div className={classes.monthYearPicker}>
      <Button className={classes.monthYearButton} onClick={openPickDateModal}>
        <Icon className={classes.icon}>date_range</Icon>
        {monthYear}
      </Button>
      <Dialog onClose={closePickDateModal} open={open}>
        <Paper className={classes.datePicker}>
          <div className={classes.year}>
            <IconButton color="inherit" onClick={decreaseYear}>
              <Icon fontSize="large">keyboard_arrow_left</Icon>
            </IconButton>
            <Typography unselectable="on" color="inherit" variant="h6">{selectedYear}</Typography>
            <IconButton color="inherit" disabled={moment().format('YYYY') === selectedYear} onClick={increaseYear}>
              <Icon fontSize="large">keyboard_arrow_right</Icon>
            </IconButton>
          </div>
          <div className={classes.months}>
            {MONTHS.map((month,i)=>{
              // Most recent stats are yesterday's
              let areStatsReady = true
              if(month.value===moment().format('MM')){
                areStatsReady = parseInt(moment().format('D')) > 1
              }
              const disabled = moment().format('YYYY-MM') < selectedYear+"-"+month.value || !areStatsReady
              return (
                <Button
                  onClick={
                    ()=>{
                      props.onConfirm(selectedYear+"-"+month.value)
                      closePickDateModal()
                    }
                  }
                  key={month.name}
                  disabled={disabled}
                  style={month.style}
                  color="primary"
                  size="small"
                  value={month.value}
                  className={classes.month}
                >
                  {props.translate(`abbreviatedMonths.${month.name}`)}
                </Button>)
            })}
          </div>
        </Paper>
      </Dialog>
    </div>
  )
}

export default withLocalize(MonthYearPicker)
