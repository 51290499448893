import 'react-circular-progressbar/dist/styles.css'
import styles                       from './gaugeStyles'
import React                        from 'react'
import {
  Typography,
  CircularProgress
}                                   from '@material-ui/core'
import { commanderTheme }           from '../../Theme/Theme'
import {
  CircularProgressbarWithChildren,
  buildStyles
}                                   from 'react-circular-progressbar'
import {
  withLocalize,
  LocalizeContextProps
}                                   from 'react-localize-redux'
import {translationPaths}           from '../../translations/translations'
interface MuiGaugeOwnProps extends LocalizeContextProps {
  value                  : number
  previousValue          : number
  label                 ?: string
  customColors          ?: CustomGaugeColor[]
  positiveIsBad         ?: boolean
  displayDiff           ?: boolean
  retrievingPacingStats ?: boolean
}

interface CustomGaugeColor {
  threshold : number
  color     : string
}

const Gauge = (props:MuiGaugeOwnProps) => {
  const classes = styles()
  let color
  let diff = "0"

  if (props.previousValue) {
    diff = `${(props.value - props.previousValue).toFixed(0)}%`
    if (props.value - props.previousValue > 0)
      diff = `+${diff}`
  }

  // Picks the first threshold value from a custom colors array that our value is greater than,
  // while still less than the next item's threshold.
  if (props.customColors) {
    for (let i = 0; i < props.customColors.length; i++) {
      if (props.customColors[i+1] === undefined) {
        color = props.customColors[i].color
        break
      }
      if (props.customColors[i].threshold <= props.value && props.value <= props.customColors[i+1].threshold) {
        color = props.customColors[i].color
        break
      }
    }
  }
  else {
    color = commanderTheme.orange
  }

  return (
    <div className={classes.container}>
      {
        props.displayDiff &&
        <>
          <div
            className={
              props.positiveIsBad ?
                parseInt(diff) > 0 ? classes.triangleOverlayRed : classes.triangleOverlay
                :
                parseInt(diff) >= 0 ? classes.triangleOverlay : classes.triangleOverlayRed
            }
          ></div>
          {
            props.retrievingPacingStats ?
              <CircularProgress className={classes.difference} size="18px" disableShrink={true} />
              :
              <Typography className={classes.difference}>{diff}</Typography>
          }
        </>
      }

      <div className={classes.progress}>
        <CircularProgressbarWithChildren
          value={props.value}
          styles={buildStyles({
            pathColor: color
          })}
        >
          <div className={classes.contentText}>
            <Typography variant="h5" className={classes.percentage}>{`${props.value.toFixed(1)}%`}</Typography>
            <Typography variant="caption" className={classes.label}>{props.translate(translationPaths.pacingOverview.pacingLabel)}</Typography>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  )
}

export default withLocalize(Gauge)
