import {
  StandardThunk,
  createRetrievingInventoryStats,
  createGetInventoryStats,
  createFailedRetrievingInventoryStats,
}                                 from "../creators"
import axios                      from "axios"

import Papa                       from 'papaparse'
import { buildCompiledReportUrl } from "../../model/http/Reporting/CompiledReport"
import InventoryStats             from "../../model/http/Reporting/InventoryStats"


export const getInventoryStats = (agencyId:string,dateFrom:string,dateTo:string):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingInventoryStats(true))
  axios.get(buildCompiledReportUrl(agencyId,"AGENCY_INVENTORY_TRENDS"),{
    headers : {
      Authorization : `Bearer ${token}`,
    },
    params : {
      dateFrom : dateFrom,
      dateTo : dateTo
    }
  })
  .then( response => {
    const parsedData:InventoryStats[] = Papa.parse(response.data.trim(),{header:true, dynamicTyping:true}).data
    dispatch(createGetInventoryStats({agencyId,inventoryStats :parsedData}))
  })
  .catch(error=>{
    console.log(error)
    dispatch(createFailedRetrievingInventoryStats(true))
  })
  dispatch(createRetrievingInventoryStats(false))
}
