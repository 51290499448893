
import styles                        from './componentStyles'
import React                         from 'react'
import { TooltipData }               from '../../../../model/graphs/CustomComponents'
import { Card, Typography }          from '@material-ui/core'



export const CustomTooltipRegions = (data: TooltipData) => {
  const classes = styles()

  return (
    <Card className={classes.tooltip}>
      <Typography
        className={classes.commanderOrange}
        color="inherit"
      >
        {
          data.label &&
            `${data.label}`
        }
      </Typography>
      <Typography>
        {
          data.payload &&
            data.payload[0] &&
              `${data.payload[0].value}%`
        }
      </Typography>
    </Card>
  )
}
