import {createReducer} from "../../../utils/Reducers"
import ActionTypes              from "../../../model/constant/actions"
import { PermissionsByAccount } from "../../../model/users/Permissions"

const AccountPermissions = createReducer<PermissionsByAccount>({}, {
  [ActionTypes.GET_ACCOUNT_PERMISSIONS] : (state, action)=> {
    return {
      ...state,
      [action.payload.accountId] : action.payload.permissions
    }
  }
})

export default AccountPermissions
