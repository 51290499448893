import React                   from 'react'
import {
  Switch,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
  TableBody,
  FormHelperText,
  Button,
  Icon,
}                              from '@material-ui/core'
import { connect }             from 'react-redux'
import { State }               from '../../../../../redux/reducers'
import { ThunkDispatch }       from 'redux-thunk'
import { Action }              from 'redux'
import { getFeeds }            from '../../../../../actions/Hyperfeed/feeds'
import useStyles               from './FacebookParamsStyles'
import requiresDefaultAdSets   from '../../../../../components/loaders/loadFacebookDefaultAdSet'
import { getDefaultAdSet }     from "../../../../../actions/Headstone/FacebookDefaultAdSets"
import AdTemplate              from '../../../../../model/Facebook/AdTemplate'
import Strategy, {
  FacebookStrategyConfigs
}                              from '../../../../../model/Facebook/Strategy'
import IdentityVariants        from '../../../../../model/Facebook/IdentityVariants'
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                              from 'react-localize-redux'
import { translationPaths }    from '../../../../../translations/translations'
import Languages               from '../../../../../model/constant/Languages'
import VehicleState            from '../../../../../model/constant/VehicleState'
import Hyperfeed               from './Hyperfeed'
import AudienceGoal            from '../../../../../model/constant/AudienceGoal'
import CircularLoading         from '../../../../../components/loadings/CircularLoading'
import PermissionBanner        from '../../../../../components/Permission/PermissionBanner'
import ErrorPage               from '../../../../../components/ErrorPage'

interface NewParamsOwnProps extends LocalizeContextProps {
  language                : Languages
  vehicleState            : VehicleState
  audienceGoal            : AudienceGoal
  handleRadiusChange      : (lang:Languages,radius:number) => void
  handleMakesChange       : (lang:Languages,makes:string[]) => void
  handleNamesChange       : (lang:Languages,names:string[]) => void
  handleChangeAdTemplate  : (lang:Languages,adTemplate:AdTemplate) => void
  configs                 : FacebookStrategyConfigs
  strategy ?              : Strategy
  identityVariants ?      : IdentityVariants
  existingAdTemplates     : AdTemplate[] | undefined
  handleConfirm           : (lang:Languages) => void
  handleUpdate            : (lang:Languages,checked:boolean,radius:number,state:VehicleState,audienceGoal:AudienceGoal) => void
  updatePermission        : boolean
  createPermission        : boolean
}

const mapStateToProps = (state:State, ownProps:NewParamsOwnProps)=>{
  return {
    selectedAccount           : state.Selection.Dealer,
    selectedAgency            : state.Selection.Agency,
    adTemplates               : state.Facebook.FacebookDefaultAdSets,
    updatingStrategy          : state.Facebook.Updating.strategy,
    creatingStrategy          : state.Facebook.Creating.facebookConfiguration,
    failedCreating            : state.Facebook.Failed.facebookConfiguration
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:NewParamsOwnProps)=>{
  return {
    getFeeds : (...args:Parameters<typeof getFeeds>)=>dispatch(getFeeds(...args)),
    getDefaultAdSet : (...args:Parameters<typeof getDefaultAdSet>)=>dispatch(getDefaultAdSet(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:NewParamsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type NewParams = NewParamsOwnProps & ReturnType<typeof mergeProps>

const NewParams = (props:NewParams) => {

  const initialMakesError = props.configs.identityVariants.makes.join(',').length > 512 || props.configs.identityVariants.makes.length === 0
  const initialNamesError = props.configs.identityVariants.names.join(',').length > 512 || props.configs.identityVariants.names.length === 0
  const classes = useStyles()
  const [radiusError,setRadiusError] = React.useState(false)
  const [namesError,setNamesError] = React.useState<boolean>(initialNamesError)
  const [makesError,setMakesError] = React.useState<boolean>(!props.identityVariants?.makes && props.vehicleState === 'NEW' ? initialMakesError : false)
  const [isHyperFeedActivated,setIsHyperFeedActivated] = React.useState<boolean>(true)
  const disableAction = !props.strategy && (radiusError || namesError || makesError || (!props.strategy && props.configs.adTemplates.length === 0) || !isHyperFeedActivated)
  if(
    !props.strategy &&
    ( !props.adTemplates[props.selectedAccount] ||
      !props.adTemplates[props.selectedAccount][props.vehicleState] ||
      !props.adTemplates[props.selectedAccount][props.vehicleState][props.language.toUpperCase()] ||
      !props.adTemplates[props.selectedAccount][props.vehicleState][props.language.toUpperCase()].some(x=>x.audienceGoal === props.audienceGoal)
    ))
  {
    props.getDefaultAdSet(props.selectedAccount,props.language,props.vehicleState,'CAR',props.audienceGoal)
  }
  const handleRadiusChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setRadiusError(parseFloat(event.target.value)>80 || parseFloat(event.target.value) < 18 || !event.target.value)
    props.handleRadiusChange(props.language,parseFloat(event.target.value))
  }
  const handleMakesChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const makes = event.target.value.split(',')
    props.handleMakesChange(props.language,makes)
    setMakesError(event.target.value.trim().length > 512 || event.target.value.trim().length === 0)
  }
  const handleNamesChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const names = event.target.value.split(',')
    props.handleNamesChange(props.language,names)
    setNamesError(event.target.value.trim().length>512 || event.target.value.trim().length === 0)
  }
  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(props.strategy) {
      props.handleUpdate(props.language,event.target.checked,props.strategy.radius,props.strategy.state,props.audienceGoal)
    }
  }
  const handleConfirm = () => {
    props.handleConfirm(props.language)
  }

  if(props.failedCreating.failed) {
    return (
      <div style={{width:"100%",height:400,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <ErrorPage
          errorMessage={props.failedCreating.message || translationPaths.accounts.accountsDetails.activations.failedCreating}
          doTranslate={!props.failedCreating.message}
        />
      </div>
    )
  }

  if(props.updatingStrategy) {
    return (
      <div style={{height:400,display:'flex',alignItems:'center'}}>
        <CircularLoading loadingMessage={translationPaths.accounts.accountsDetails.activations.updatingStrategy} doTranslate />
      </div>
    )
  }
  if(props.creatingStrategy) {
    return (
      <div style={{height:400,display:'flex',alignItems:'center'}}>
        <CircularLoading loadingMessage={translationPaths.accounts.accountsDetails.activations.creatingStrategy} doTranslate/>
      </div>
    )
  }
  return (
    <div className={classes.usedParams}>
      {!props.strategy &&
        <Typography style={{display:'block'}} variant="caption"><Translate id={translationPaths.accounts.accountsDetails.activations.helpText}/></Typography>
      }
      {props.strategy ?
        <>
          <Switch
            checked={Boolean(props.strategy && props.strategy.active)}
            onChange={handleUpdate}
            disabled={disableAction || !props.updatePermission}
          />
          {props.strategy && props.strategy.active ? props.translate(translationPaths.general.on) : props.translate(translationPaths.general.off)}
        </>
        :
        <Button
        style={{marginTop:5,marginBottom:5,display:'flex',alignItems:'center'}}
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleConfirm}
        disabled={disableAction || !props.createPermission}
        >
          <Icon style={{marginRight:5}}>power</Icon>
          <Translate id={translationPaths.accounts.accountsDetails.activations.activate} />
        </Button>
      }
      <div className={classes.inputs}>
        <div>
          <TextField
            style={{marginRight:20}}
            onChange={handleNamesChange}
            variant="outlined"
            value={props.identityVariants?.names?.join(',') || props.configs.identityVariants.names}
            label={props.translate(translationPaths.general.names)}
            disabled={Boolean(props.strategy) || Boolean(props.identityVariants?.names) || !props.createPermission || !props.updatePermission}
            error={namesError}
            />
          <FormHelperText>{props.translate(translationPaths.accounts.accountsDetails.activations.separatedByComma)}</FormHelperText>
        </div>
        {props.vehicleState === 'NEW' && <div>
          <TextField
            autoFocus
            style={{marginRight:20}}
            onChange={handleMakesChange}
            variant="outlined"
            value={props.identityVariants?.makes?.join(',') || props.configs.identityVariants.makes}
            label="Makes"
            disabled={Boolean(props.strategy) || Boolean(props.identityVariants?.makes) || !props.createPermission || !props.updatePermission}
            error={makesError}
            />
          <FormHelperText>{props.translate(translationPaths.accounts.accountsDetails.activations.separatedByComma)}</FormHelperText>
        </div>}
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-radius">{props.translate(translationPaths.accounts.accountsDetails.activations.radius)}</InputLabel>
          <OutlinedInput
            disabled={Boolean(props.strategy)  || !props.createPermission || !props.updatePermission}
            onChange={handleRadiusChange}
            type="number"
            value={!props.strategy ? props.configs.strategy.radius : props.strategy.radius}
            id="outlined-adornment-radius"
            endAdornment={
              <InputAdornment position="end">
                KM
              </InputAdornment>
            }
            error={radiusError}
            labelWidth={55}
            />
        </FormControl>
      </div>
      {!props.strategy && props.identityVariants && <div className={classes.identityVariants}>
        <Icon style={{marginRight:3}}>check</Icon>
        {props.translate(translationPaths.accounts.accountsDetails.activations.alreadyExistingIdentityVariants)}
      </div>}
      <Hyperfeed
        setHyperfeedActivation={(active:boolean)=>setIsHyperFeedActivated(active)}
        language={props.language}
      />
      <div className={classes.table}>
        <Table>
          <TableBody>
        {
          !props.strategy &&
          props.adTemplates[props.selectedAccount] &&
          props.adTemplates[props.selectedAccount][props.vehicleState] &&
          props.adTemplates[props.selectedAccount][props.vehicleState][props.language.toUpperCase()] &&
          props.adTemplates[props.selectedAccount][props.vehicleState][props.language.toUpperCase()].filter(x=>x.audienceGoal === props.audienceGoal).map(x=>{
            return (
              <TableRow key={x.id}>
                <TableCell style={{color: (!props.createPermission || !props.updatePermission) ? 'grey' : 'inherit'}}>
                  {x.template}
                </TableCell>
                <TableCell>
                  <Checkbox
                    disabled={!props.createPermission || !props.updatePermission}
                    onChange={()=>props.handleChangeAdTemplate(props.language,x)}
                    checked={props.configs.adTemplates.includes(x)}
                  />
                </TableCell>
              </TableRow>
            )
          })
        }
        {
          props.strategy && props.existingAdTemplates &&
          props.existingAdTemplates.map(x=>{
            return (
              <TableRow key={x.id}>
                <TableCell>
                  {x.template}
                </TableCell>
              </TableRow>
            )
          })
        }
          </TableBody>
        </Table>
      </div>
      <div className={classes.errors}>
        {makesError && <FormHelperText style={{color:'red'}}>{props.translate(translationPaths.accounts.accountsDetails.activations.makesError)}</FormHelperText>}
        {namesError && <FormHelperText style={{color:'red'}}>{props.translate(translationPaths.accounts.accountsDetails.activations.namesError)}</FormHelperText>}
        {radiusError && <FormHelperText style={{color:'red'}}>{props.translate(translationPaths.accounts.accountsDetails.activations.radiusRange)}</FormHelperText>}
      </div>
      {!props.strategy && !props.createPermission && (
        <PermissionBanner message={props.translate(translationPaths.accounts.accountsDetails.activations.createPermission) as string} />
      )}
      {props.strategy && !props.updatePermission && (
        <PermissionBanner message={props.translate(translationPaths.accounts.accountsDetails.activations.updatePermission) as string} />
      )}
    </div>
  )
}

export default withLocalize(
  requiresDefaultAdSets()(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(NewParams)
  )
)
