import './CustomInput.css'
import React         from 'react'
import {
  TextField,
  createStyles,
  makeStyles,
  Theme,
  InputAdornment,
}                    from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'

interface CustomInputOwnProps {
}
interface CustomInputProps extends CustomInputOwnProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

const CustomInput = (props:CustomInputProps) => {
  const classes = useStyles()
  return (
    <div className="CustomInput">
      <TextField
        color="primary"
        className={classes.margin}
        label="Placeholder"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        className={classes.margin}
        label="Placeholder"
      />
      <TextField
        color="secondary"
        variant="outlined"
        className={classes.margin}
        label="Placeholder"
      />
      <TextField
        color="secondary"
        variant="outlined"
        className={classes.margin}
        label="Placeholder"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccountCircle color="secondary"/>
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

export default CustomInput
