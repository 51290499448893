import {
  ActionType,
  Action,
  ActionObject
}                from "../model/constant/actions"
import {Reducer} from "redux"

type ReducerCases<State> = {
  [T in ActionType]: (state:State, action:ActionObject<T>)=>State
}
export const createReducer = <State>(defaultState:State|null, cases:Partial<ReducerCases<State>>):Reducer<State, Action> => {
  return (state, action) => {
    const fn = cases[action.type]
    if(fn){
      return (fn as any)(state, action)
    }
    return state===undefined?defaultState:state
  }
}
