import styles                        from './componentStyles'
import React                         from 'react'
import { TooltipData }               from '../../../../model/graphs/CustomComponents'
import { Card, Typography }          from '@material-ui/core'
import {
  withLocalize,
  LocalizeContextProps
}                                    from 'react-localize-redux'


interface CustomTooltipChannelsProps extends TooltipData, LocalizeContextProps {}

const CustomTooltipChannels = (props: CustomTooltipChannelsProps) => {
  const classes = styles()

  return (
    <Card className={classes.tooltip}>
      <Typography
        className={classes.commanderOrange}
        color="inherit"
      >
        {
          props.label &&
            props.translate(`pacingOverview.pacingPerChannel.${props.label.toLowerCase()}`)
        }
      </Typography>
      <Typography>
        {
          props.payload &&
            props.payload[0] &&
              `${props.payload[0].value.toLocaleString('en-CA', {maximumFractionDigits:0})}%`
        }
      </Typography>
    </Card>
  )
}

export default withLocalize(CustomTooltipChannels)
