import React                from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  Typography,
  Switch,
  Button
}                           from '@material-ui/core'
import DealersWebsitesGraph from './graphs/DealersWebsitesGraph'
import { TrackingStats }    from '../../model/store/insights/TrackingStats'
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                           from 'react-localize-redux'
import {translationPaths}   from '../../translations/translations'

interface DealersWebsitesOwnProps extends LocalizeContextProps {
  trackingStats : TrackingStats[]
}

interface Data {
  date : string
  visits : number
  leads : number
  dealers : Set<number>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealersWebsites : {
      padding : 30,
      marginBottom : 30
    },
    header : {
      display : 'flex',
      justifyContent : 'space-between',
      alignItems : 'center'
    },
    unavailable : {
      height : 300,
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
    }
  })
)

const DealersWebsites = (props:DealersWebsitesOwnProps) => {
  const classes = useStyles()
  const [visits,setVisits] = React.useState<boolean>(true)
  const handleSwitchChange = () => {
    setVisits(prevState=>!prevState)
  }

  const perDate : {[date:string]:{date:string, visits:number, leads:number, dealers:Set<number>}}  = {}
  for(const row of props.trackingStats){
    perDate[row.date] = perDate[row.date]!==undefined ? perDate[row.date] : (
      {
        date:row.date,
        visits:0,
        leads:0,
        dealers:new Set<number>()
      }
    )
    perDate[row.date].visits += row.sessions
    perDate[row.date].leads += row.leads
    perDate[row.date].dealers.add(row.accountId)
  }
  const leadsData  = Object.values(perDate).map(x=>({date:x.date,value:x.leads, dealerCount:x.dealers.size}))
  const visitsData = Object.values(perDate).map(x=>({date:x.date,value:x.visits,dealerCount:x.dealers.size}))

  return (
    <Card className={classes.dealersWebsites}>
      <div className={classes.header}>
        <Typography variant="h6">
          {props.translate(translationPaths.marketInsights.dealersGraph.title)}
        </Typography>
        <Button variant="outlined" onClick={handleSwitchChange}>
          {props.translate(translationPaths.marketInsights.dealersGraph.leads)}
          <Switch checked={visits} color="default" />
          {props.translate(translationPaths.marketInsights.dealersGraph.visits)}
        </Button>
      </div>
      {
        props.trackingStats.length === 0
        ? <div className={classes.unavailable}><Typography><Translate id={translationPaths.marketInsights.unavailableData} /></Typography></div>
        : <DealersWebsitesGraph
            data={visits ? visitsData : leadsData}
            valueKeyName={visits ? props.translate(translationPaths.marketInsights.dealersGraph.visits) as string : props.translate(translationPaths.marketInsights.dealersGraph.leads) as string}
            dealersColor="#cc8400"
            inventoryColor="#4B086B"
          />
      }

    </Card>
  )
}

export default withLocalize(DealersWebsites)
