import React                from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
}                           from '@material-ui/core'
import {
  ResponsiveContainer,
  Line,
  Tooltip,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
}                           from 'recharts'
import {
  withLocalize,
  LocalizeContextProps
}                           from 'react-localize-redux'
import {translationPaths}   from '../../../translations/translations'

interface GraphDataFormat {
  date  : string
  inventory : number
  dealersCount : number
}

interface InventoryRemovedGraphOwnProps extends LocalizeContextProps{
  data : GraphDataFormat[]
  dealersColor : string
  inventoryColor : string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inventoryRemovedGraph : {
      marginTop : 50,
      marginBottom : 50
    }
  })
)
const InventoryRemovedGraph = (props:InventoryRemovedGraphOwnProps) => {
  const classes = useStyles()
  return (
    <div className={classes.inventoryRemovedGraph}>
      <ResponsiveContainer height={300} width="99%" >
        <LineChart
          data={props.data}
          margin={{top: 5, right: 30, left: 20, bottom: 5}}
        >
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis tickMargin={15} tick={{fill:'#999'}} stroke="#ccc" height={30} dataKey="date"/>
          <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="left" />
          <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="right" orientation="right" />
          <Tooltip/>
          <Line
            dot={false}
            strokeWidth={4}
            name={props.translate(translationPaths.marketInsights.dealersGraph.dealers) as string}
            yAxisId="left"
            type="natural"
            dataKey="dealersCount"
            stroke={props.dealersColor}
          />
          <Line
            dot={false}
            strokeWidth={4}
            name={props.translate(translationPaths.marketInsights.inventoryRemovedGraph.soldVehicles) as string}
            yAxisId="right"
            type="monotone"
            dataKey="inventory"
            stroke={props.inventoryColor}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default withLocalize(InventoryRemovedGraph)
