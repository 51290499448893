import {
  StandardThunk,
  createRetrievingFacebookIdentityVariants,
  createGetFacebookIdentityVariants,
  createFailedRetrievingFacebookIdentityVariants,
  createCreatingFacebookIdentityVariants,
  createFailedCreatingFacebookIdentityVariants,
  createUpdatingFacebookIdentityVariants,
  createFailedUpdatingFacebookIdentityVariants,
}                                                                from "../creators"
import axios                                                     from "axios"
import IdentityVariantsUrl,{Success as FacebookIdentityVariants} from "../../model/http/Headstone/GetFacebookIdentityVariants"
import IdentityVariants                                          from "../../model/Facebook/IdentityVariants"


export const getFacebookIdentityVariants = (accountId:number):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookIdentityVariants(true))
  axios.get<FacebookIdentityVariants[]>(IdentityVariantsUrl(accountId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookIdentityVariants({
      accountId,
      identityVariants : response.data,
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookIdentityVariants(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookIdentityVariants(false))
  })
}

export const createFacebookIdentityVariants = (accountId:number,identityVariants:IdentityVariants[]):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createCreatingFacebookIdentityVariants(true))
  axios.post(IdentityVariantsUrl(accountId),identityVariants,{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookIdentityVariants(accountId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedCreatingFacebookIdentityVariants(true))
  })
  .then(()=>{
    dispatch(createCreatingFacebookIdentityVariants(false))
  })
}

export const updateFacebookIdentityVariants = (accountId:number,identityVariants:IdentityVariants[]):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createUpdatingFacebookIdentityVariants(true))
  axios.put(IdentityVariantsUrl(accountId),identityVariants,{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookIdentityVariants(accountId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedUpdatingFacebookIdentityVariants(true))
  })
  .then(()=>{
    dispatch(createUpdatingFacebookIdentityVariants(false))
  })
}

