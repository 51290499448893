import './Switch.css'
import React from 'react'
import { Switch } from '@material-ui/core';

interface SwitchOwnProps {}
interface SwitchProps extends SwitchOwnProps {}

const SwitchComponent = (props:SwitchProps) => {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  })
  const handleChange = (name:any) => (event:any) => {
    setState({ ...state, [name]: event.target.checked })
  }
  return (
    <div className="Switch">
      <Switch
        checked={state.checkedA}
        onChange={handleChange('checkedA')}
        value="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="checkedB"
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Switch disabled value="checkedD" inputProps={{ 'aria-label': 'disabled checkbox' }} />
      <Switch disabled checked value="checkedE" inputProps={{ 'aria-label': 'primary checkbox' }} />
      <Switch
        defaultChecked
        value="checkedF"
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />
    </div>
  )
}


export default SwitchComponent
