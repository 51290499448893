import {
  createRetrievingSalesProducts,
  StandardThunk,
  createGetSalesProducts,
  createFailedRetrievingSalesProducts
}                                          from "../creators"
import getProductUrl, {
  Success
}                                          from "../../model/http/Trffk-salestool/GetProducts"
import axios                               from 'axios'

export const getSalesProducts = ():StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingSalesProducts(true))
  axios.get<Success>(getProductUrl(),{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response=>{
    dispatch(createGetSalesProducts(response.data))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingSalesProducts({
      failed:true,
      message : 'Error retrieving Summary'
    }))
  })
  .then(()=>{
    dispatch(createRetrievingSalesProducts(false))
  })
}
