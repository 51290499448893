import {
  makeStyles,
  Theme,
  createStyles
}                   from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    budgetCalculator : {
      marginRight : 40,
      marginLeft : 40
    },
    title : {
      marginBottom : 10,
      marginTop : 20,
      display : 'flex',
      alignItems : 'center',
      fontWeight : 'normal',
      fontSize : 25,
      color : '#666'
    },
    firstStep : {
      display : 'flex',
      width : '80%'
    },
    informationAndBudget : {
      display: 'flex',
      justifyContent : 'center',
    },
    vehiclesAndBudget : {
      display : 'flex',
      justifyContent : 'center',
      [theme.breakpoints.down(1530)] : {
        flexDirection : 'column',
      }
    },
    products : {
      width : '100%',
      display: 'flex',
      justifyContent : 'center'
    },
    thirdSection : {

    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)
export default useStyles
