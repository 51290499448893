import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Updating {
  summary : boolean
}
const initial = {
  summary : false
}

const Updating = createReducer<Updating>(initial,{
  [ActionTypes.UPDATING_SUMMARY] : (state,action) => {
    return {
      ...state,
      summary : action.payload
    }
  }
})

export default Updating
