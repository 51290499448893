import React                                from 'react'
import useStyles, { TotalTableCell }        from './OverviewByChannelsStyles'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody
}                                           from '@material-ui/core'
import { StyledTableRow, StyledTableCell }  from '../Targets/styles'
import MONTHS, { sortMonths, Month }        from '../../../model/constant/Months'
import { formatMoney }                      from '../../../utils/MoneyFormatting'
import { BreakpointTableCell }              from './OverviewByChannelsStyles'
import  {Breakdown}                         from '../../../model/budgetEstimator/Summary'
import { Channel }                          from '../../../model/constant/channels'
import {
  getMediaSpendOfMonthByChannel,
  getManagementFeeOfMonthByChannel,
  getBaseManagementFeeOfMonthByChannel,
  getCampaignDiscountOfMonthByChannel,
  getExtraDiscountOfMonthByChannel,
  getTotalBilledOfMonthByChannel,
}                                           from '../../../model/budgetEstimator/Calculations/BudgetCalculations'

interface OverviewByChannelsOwnProps {
  breakdown           : Breakdown
}

const OverviewByChannels = (props:OverviewByChannelsOwnProps) => {
  const channelList:Channel[] = []
  for(const i of Object.keys(props.breakdown.amounts[props.breakdown.startingMonth])) {
    if(!channelList.includes(props.breakdown.amounts[props.breakdown.startingMonth][i].channel)) {
      channelList.push(props.breakdown.amounts[props.breakdown.startingMonth][i].channel)
    }
  }
  const monthsSorted = sortMonths(props.breakdown.startingMonth)
  const mediaSpendByChannel = getMediaSpendOfMonthByChannel(props.breakdown)
  const totalManagementFeeByMonth = getManagementFeeOfMonthByChannel(props.breakdown)
  const totalBaseManagementFeeByMonth = getBaseManagementFeeOfMonthByChannel(props.breakdown)
  const totalCampaignDiscountByMonth = getCampaignDiscountOfMonthByChannel(props.breakdown)
  const totalExtraDiscountByMonth = getExtraDiscountOfMonthByChannel(props.breakdown)
  const totalBilledByMonth = getTotalBilledOfMonthByChannel(props.breakdown)
  const totalMediaSpendByMonth = (month:Month) => {
    return mediaSpendByChannel(month,Channel.search) + mediaSpendByChannel(month,Channel.display) + mediaSpendByChannel(month,Channel.social)
  }
  const totalBilledSumByMonth = (month:Month) => {
    return totalBilledByMonth(month,Channel.search) + totalBilledByMonth(month,Channel.display) + totalBilledByMonth(month,Channel.social)
  }
  const totalDiscountsSumByMonth = (month:Month) => {
    return totalCampaignDiscountByMonth(month,Channel.search) + totalCampaignDiscountByMonth(month,Channel.display) + totalCampaignDiscountByMonth(month,Channel.social) + totalExtraDiscountByMonth(month,Channel.search) + totalExtraDiscountByMonth(month,Channel.display) + totalExtraDiscountByMonth(month,Channel.social)
  }
  const totalManagementFeeSumByMonth = (month:Month) => {
    return totalManagementFeeByMonth(month,Channel.search) + totalManagementFeeByMonth(month,Channel.search) + totalManagementFeeByMonth(month,Channel.social)
  }
  const breakdownByChannel = channelList.reduce<Record<string,any>>((acc,channel)=>{
    let mediaSpend = 0
    let totalBilled = 0
    let totalManagementFee = 0
    let totalCampaignDiscount = 0
    let totalExtraDiscount = 0
    let totalBaseManagementFee = 0
    for(const month of MONTHS) {
      for(const product of Object.keys(props.breakdown.amounts[month])) {
        if(props.breakdown.amounts[month][product].channel === channel) {
          mediaSpend              = mediaSpend + props.breakdown.amounts[month][product].mediaSpend
          totalBilled             = totalBilled + props.breakdown.amounts[month][product].totalBilled
          totalManagementFee      = totalManagementFee + props.breakdown.amounts[month][product].managementFeeCurrency
          totalCampaignDiscount   = totalCampaignDiscount + props.breakdown.amounts[month][product].discounts['campaignDiscount']
          totalExtraDiscount      = totalExtraDiscount + props.breakdown.amounts[month][product].discounts['extraDiscount']
          totalBaseManagementFee  = totalBaseManagementFee + props.breakdown.amounts[month][product].discounts['extraDiscount'] + props.breakdown.amounts[month][product].discounts['campaignDiscount'] +props.breakdown.amounts[month][product].managementFeeCurrency
        }
      }
    }
    return {
      ...acc,
      [channel] : {
        mediaSpend,
        totalBilled,
        totalManagementFee,
        totalCampaignDiscount,
        totalExtraDiscount,
        totalBaseManagementFee
      }
    }
  },{})
  const totalMediaSpend = channelList.reduce((acc,channel)=>{
    return acc + breakdownByChannel[channel as any].mediaSpend
  },0)

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <TableContainer style={{borderRadius:5,marginTop:10,width:'90vw'}}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{width:'40%'}}></StyledTableCell>
              {channelList.map(channel=>{
                return <StyledTableCell key={channel} align="center">{channel.toUpperCase()}</StyledTableCell>
              })}
              <StyledTableCell align="center">TOTAL</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell style={{width:'40%'}}>Campaigns & Products allocation</StyledTableCell>
              {channelList.map(channel=>{
                return <StyledTableCell key={channel} align="center">{(breakdownByChannel[channel as any].mediaSpend / totalMediaSpend * 100).toFixed(2)}%</StyledTableCell>
              })}
              <StyledTableCell align="center">100%</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Annual media spend</StyledTableCell>
              {channelList.map(channel=>{
                return <StyledTableCell key={channel} align="center">{formatMoney(breakdownByChannel[channel as any].mediaSpend)}</StyledTableCell>
              })}
              <StyledTableCell align="center">{formatMoney(totalMediaSpend)}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>Annual media spend + management fee</StyledTableCell>
              {channelList.map(channel=>{
                return <StyledTableCell key={channel} align="center">{formatMoney(breakdownByChannel[channel as any].mediaSpend+breakdownByChannel[channel as any].totalManagementFee)}</StyledTableCell>
              })}
              <StyledTableCell align="center">{formatMoney(channelList.reduce((acc,channel)=>{
                return acc + breakdownByChannel[channel as any].mediaSpend+breakdownByChannel[channel as any].totalManagementFee
              },0))}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer style={{borderRadius:5,marginTop:10,width:'90vw'}}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Channel</StyledTableCell>
              {monthsSorted.map(month=><StyledTableCell key={month}>{month.toLocaleUpperCase()}</StyledTableCell>)}
              <StyledTableCell>Total</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {channelList.map(channel=>(
              <React.Fragment key={channel}>
                <StyledTableRow>
                  <BreakpointTableCell>{channel.toUpperCase()}</BreakpointTableCell>
                  {monthsSorted.map(month=><BreakpointTableCell key={month}></BreakpointTableCell>)}
                  <BreakpointTableCell></BreakpointTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>Media Spend</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month}>{formatMoney(mediaSpendByChannel(month,channel))}</StyledTableCell>)}
                  <StyledTableCell>{formatMoney(monthsSorted.reduce((acc,month)=>{
                    return acc + mediaSpendByChannel(month,channel)
                  },0))}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.baseManagementFee}>Base management fee</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.baseManagementFee}>{formatMoney(totalBaseManagementFeeByMonth(month,channel))}</StyledTableCell>)}
                  <StyledTableCell className={classes.baseManagementFee}>{formatMoney(
                    monthsSorted.reduce((acc,month)=>acc +totalBaseManagementFeeByMonth(month,channel),0)
                  )}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountOnManagementFee}>Campaign discount</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.discountOnManagementFee}>{formatMoney(totalCampaignDiscountByMonth(month,channel))}</StyledTableCell>)}
                  <StyledTableCell className={classes.discountOnManagementFee}>{formatMoney(monthsSorted.reduce((acc,month)=>acc +totalCampaignDiscountByMonth(month,channel),0))}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountOnManagementFee}>Campaign discount</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.discountOnManagementFee}>{formatMoney(totalExtraDiscountByMonth(month,channel))}</StyledTableCell>)}
                  <StyledTableCell className={classes.discountOnManagementFee}>{formatMoney(monthsSorted.reduce((acc,month)=>acc +totalExtraDiscountByMonth(month,channel),0))}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow></StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={classes.discountedManagementFee}>Management fee</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.discountedManagementFee}>{formatMoney(totalManagementFeeByMonth(month,channel))}</StyledTableCell>)}
                  <StyledTableCell className={classes.discountedManagementFee}>{formatMoney(
                    monthsSorted.reduce((acc,month)=>acc+totalManagementFeeByMonth(month,channel),0)
                  )}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                <StyledTableCell className={classes.totalBilled}>Total Billed</StyledTableCell>
                  {monthsSorted.map(month=><StyledTableCell key={month} className={classes.totalBilled}>{formatMoney(totalBilledByMonth(month,channel))}</StyledTableCell>)}
                  <StyledTableCell className={classes.totalBilled}>{formatMoney(monthsSorted.reduce((acc,month)=>{
                    return acc + totalBilledByMonth(month,channel)
                  },0))}</StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
            <StyledTableRow>
              <TotalTableCell>Total Media Spend</TotalTableCell>
                {MONTHS.map(month=><TotalTableCell key={month}>{formatMoney(totalMediaSpendByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(MONTHS.reduce((acc,month)=>{
                return acc + totalMediaSpendByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TotalTableCell>Total Management Fee</TotalTableCell>
              {MONTHS.map(month=><TotalTableCell key={month}>{formatMoney(totalManagementFeeSumByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(MONTHS.reduce((acc,month)=>{
                return acc + totalManagementFeeSumByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TotalTableCell>Total discount</TotalTableCell>
              {MONTHS.map(month=><TotalTableCell key={month}>{formatMoney(totalDiscountsSumByMonth(month))}</TotalTableCell>)}
              <TotalTableCell>{formatMoney(MONTHS.reduce((acc,month)=>{
                return acc + totalDiscountsSumByMonth(month)
              },0))}</TotalTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TotalTableCell >Total Billed</TotalTableCell>
              {MONTHS.map(month=><TotalTableCell key={month}>{formatMoney(totalBilledSumByMonth(month))}</TotalTableCell>)}
                <TotalTableCell>{formatMoney(MONTHS.reduce((acc,month)=>{
                  return acc + totalBilledSumByMonth(month)
                },0))}</TotalTableCell>
              </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default OverviewByChannels
