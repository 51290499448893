import {combineReducers}     from "redux"
import User                  from "./User"
import Error                 from "./Error"
import LoggingIn             from "./LoggingIn"
import ForgotPasswordMessage from "./ForgotPasswordMessage"
import ResettingPassword     from "./ResettingPassword"
import AccountPermissions    from "./AccountPermissions"
import SystemPermissions     from "./SystemPermissions"
import Retrieving            from "./Retrieving"

const Login = combineReducers({
  User,
  Error,
  LoggingIn,
  ForgotPasswordMessage,
  ResettingPassword,
  AccountPermissions,
  SystemPermissions,
  Retrieving,
})

export default Login
