
import React                         from 'react'
import { TickDataNumber }            from '../../../../model/graphs/CustomComponents'
import theme                         from '../../../../Theme/Main'


export const CustomTick = (tickData: TickDataNumber) => {
  return (
    <g transform={`translate(${tickData.x},${tickData.y})`}>
      <text
        y={-8}
        x={25}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: theme.palette.primary.light,
          fontSize: 12,
          fontWeight: "bold",
        }}
      >
        {
          tickData.payload &&
          `${(tickData.payload.value).toFixed(0)}%`
        }
        </text>
    </g>
  )
}
