import React                       from 'react'
import { Paper }                   from '@material-ui/core'
import { StatPerAccount }          from '../../../model/store/statistics/PacingStats'
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ScatterChart,
  Scatter
}                                  from 'recharts'
import mainTheme                   from '../../../Theme/Main'
import { CustomTick }              from './CustomChartComponents/CustomTick'
import CustomTooltipBudgetTier     from './CustomChartComponents/CustomTooltipBudgetTier'
import { commanderTheme }          from '../../../Theme/Theme'


interface PacingByBudgetOwnProps {
  data: StatPerAccount[]
}

interface WorkaroundData extends StatPerAccount {
  realPacing ?: number
}

const CustomShape = (data: {cx: number, cy: number}) => {
  return (
    <circle
      cx={data.cx}
      cy={data.cy}
      r={7} strokeWidth={3}
      fill={commanderTheme.orange}
      fillOpacity={.75}
    />
  )
}

const formatData = (data: StatPerAccount[]): WorkaroundData[] => {
  return data.map((item)=> {
    const datum: WorkaroundData = {...item}
    if (datum.pacing > 150) {
      datum.realPacing = datum.pacing
      datum.pacing = 150
    }
    return datum
  })
}

const PacingByBudget = (props: PacingByBudgetOwnProps) => {

  return (
    <Paper elevation={0} style={{ height: 400, marginLeft: "-60px" }} >
      <ResponsiveContainer width={"99%"}>
        <ScatterChart
          data={formatData(props.data)}
          margin={{ top: 90, bottom: 10, left: 0, right: 20 }}
          barCategoryGap={50}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="budget"
            type="number"
            padding={{ left: 80, right: 80 }}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            tick={{ stroke: mainTheme.palette.grey[500], strokeWidth: .8, fontSize: 12 }}
          />
          <YAxis
            dataKey="pacing"
            padding={{ top: 0, bottom: 0 }}
            axisLine={false}
            tickLine={false}
            ticks={[0, 25, 50, 75, 100, 125]}
            domain={[0, 150]}
            allowDataOverflow={false}
            scale="linear"
            tick={<CustomTick />}
          />
          <Tooltip content={<CustomTooltipBudgetTier />} />
          <Scatter shape={CustomShape} />
        </ScatterChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default PacingByBudget
