import { createReducer } from "../../../../utils/Reducers"
import ActionTypes       from "../../../../model/constant/actions"
import SalesforceAccount from "../../../../model/Salesforce/Account"


const Accounts = createReducer<SalesforceAccount[]>(null,{
  [ActionTypes.GET_SALESFORCE_ACCOUNTS] : (state,action) => {
    return action.payload
  }
})

export default Accounts
