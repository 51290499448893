import React                from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Breadcrumbs
}                           from "@material-ui/core"

interface OurBreadcrumbsOwnProps {
  agency   : string
  section  : string
  page    ?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text : {
      fontWeight : 'normal',
      fontSize : 15,
      color : '#666'
    }
  })
)
const OurBreadcrumbs = (props:OurBreadcrumbsOwnProps) => {
  const classes = useStyles()
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Typography color="textPrimary" className={classes.text} variant="h6">
        {props.agency}
      </Typography>
      <Typography color="textPrimary" className={classes.text} variant="h6">
        {props.section}
      </Typography>
      {
        props.page &&
        <Typography color="textPrimary" className={classes.text} variant="h6">
          {props.page}
        </Typography>
      }
    </Breadcrumbs>
  )
}

export default OurBreadcrumbs
