import * as React                 from "react"
import {connect}                  from "react-redux"
import {ThunkDispatch}            from "redux-thunk"
import {State}                    from "../../redux/reducers"
import {Action}                   from "../../model/constant/actions"
import Loading                    from "../loadings/CircularLoading"
import ErrorPage                  from "../ErrorPage"
import { translationPaths }       from "../../translations/translations"


interface AccountsDataLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  loadingAdAccounts  : boolean
}

const mapStateToProps = (state:State, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    failedRetrieving        : state.Facebook.Failed.unusedAdAccounts,
    retrieving              : state.Facebook.Retrieving.unusedAdAccounts,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  if (props.failedRetrieving) {
    return <ErrorPage errorMessage={translationPaths.loaders.facebookStrategies.failed} doTranslate={true} />
  }
  if(!props.letThrough && props.retrieving){
    return <Loading loadingMessage={translationPaths.loaders.facebookUnusedAdAccounts.loading} doTranslate={true} />
  }
  return props.render({loadingAdAccounts:props.retrieving})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresFacebookUnusedAdAccounts:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresFacebookUnusedAdAccounts
