import React                            from 'react'
import {
  Card,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  InputAdornment,
}                                       from '@material-ui/core'
import useStyles, {
  StyledTableCell,
  StyledTableRow
}                                       from './styles'
import MONTHS                           from '../../../model/constant/Months'
import SummaryTemplate                  from '../../../model/budgetEstimator/Summary'
import {
  handleNewVehicleBlur,
  handleUsedVehicleBlur,
  handleBudgetUpdateBlur,
  handleNewVehiclesTargetUpdate,
  handleUsedVehiclesTargetUpdate,
  handleAdditionalBudgetUpdate
}                                       from '../../../model/budgetEstimator/Handlers/VehiclesAndAdditionalBudgetHandlers'
import { formatMoney }                  from '../../../utils/MoneyFormatting'

interface VehiclesOwnProps {
  summary                         : SummaryTemplate
  usedTarget                      : number
  newTarget                       : number
  additionalBudget                : number
  disabled                        : boolean
  handleUpdateSummary             : (summary:SummaryTemplate)=>void
  handleGetBreakdown              : (summary:SummaryTemplate)=>void
}

const Vehicles = (props:VehiclesOwnProps) => {
  const classes = useStyles()
  const {summary,handleUpdateSummary,handleGetBreakdown} ={...props}

  //HANDLERS
  const handleNewVehicleOnBlur = (month:string) => {
    const newSummary = handleNewVehicleBlur(month,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  const handleUsedVehicleOnBlur = (month:string) => {
    const newSummary = handleUsedVehicleBlur(month,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  const handleAddionalBudgetOnBlur = (month:string) => {
    const newSummary = handleBudgetUpdateBlur(month,summary)
    handleUpdateSummary(newSummary)
    handleGetBreakdown(newSummary)
  }
  const handleNewVehiclesTarget = (event:React.ChangeEvent<HTMLInputElement>,month:string) => {
    const value = parseInt(event.target.value)
    const newSummary = handleNewVehiclesTargetUpdate(value || 0,month,summary)
    handleUpdateSummary(newSummary)
  }
  const handleUsedVehiclesTarget = (event:React.ChangeEvent<HTMLInputElement>,month:string) => {
    const value = parseInt(event.target.value)
    const newSummary = handleUsedVehiclesTargetUpdate(value || 0,month,summary)
    handleUpdateSummary(newSummary)
  }
  const handleAdditionalBudget = (event:React.ChangeEvent<HTMLInputElement>,month:string) => {
    const value = parseInt(event.target.value)
    const newSummary = handleAdditionalBudgetUpdate(value || 0,month,summary)
    handleUpdateSummary(newSummary)
  }

  return (
    <Card className={classes.vehicles}>
      <Typography variant="button" color="primary" className={classes.title}>Calendar</Typography>
      <div className={classes.table}>
        <TableContainer style={{borderRadius : '10px 10px 0px 0px'}} className={classes.container} component={Paper}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell style={{width:'22%'}} align="left">Month</StyledTableCell>
                <StyledTableCell style={{width:'22%'}} align="center">Sales target for New</StyledTableCell>
                <StyledTableCell style={{width:'22%'}} align="center">Sales target for Used</StyledTableCell>
                <StyledTableCell style={{width:'36%'}} align="center">Additional Budget</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {MONTHS.map((month) => (
                <StyledTableRow key={month}>
                  <StyledTableCell component="th" scope="row">
                    {month.toUpperCase()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TextField disabled={props.disabled} size="small" variant="outlined" type="number" style={{width:70}} value={props.summary.targets.NEW[month] || ''}
                      onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleNewVehiclesTarget(event,month)}
                      onBlur={(event)=>handleNewVehicleOnBlur(month)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TextField disabled={props.disabled} size="small" variant="outlined" type="number" style={{width:70}} value={props.summary.targets.USED[month] || ''}
                      onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleUsedVehiclesTarget(event,month)}
                      onBlur={(event)=>handleUsedVehicleOnBlur(month)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <TextField disabled={props.disabled} size="small" variant="outlined" type="number" style={{width:130}} value={props.summary.targets.NONE[month] || ''}
                      onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleAdditionalBudget(event,month)}
                      onBlur={(event)=>handleAddionalBudgetOnBlur(month)}
                      InputProps = {{
                        endAdornment : (
                          <InputAdornment position="end"><strong>$</strong></InputAdornment>
                        )
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer style={{width:'100%',borderRadius:'0px 0px 10px 10px'}}>
          <Table >
            <TableHead>
              <TableRow >
                <StyledTableCell style={{width:'22%'}} align="left">Total ({props.newTarget + props.usedTarget})</StyledTableCell>
                <StyledTableCell style={{width:'22%'}} align="center">{props.newTarget}</StyledTableCell>
                <StyledTableCell style={{width:'22%'}} align="center">{props.usedTarget}</StyledTableCell>
                <StyledTableCell style={{width:'36%'}} align="center" >{formatMoney(props.additionalBudget)}$</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </div>
    </Card>
  )
}

export default Vehicles
