import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import AdTemplate        from "../../../model/Facebook/AdTemplate"

interface FacebookAdTemplates {
  [accountId:number] : AdTemplate[]
}

const AdTemplates = createReducer<FacebookAdTemplates>({},{
  [ActionTypes.GET_FACEBOOK_AD_TEMPLATES] : (state,action) => {
    return {...state,
      [action.payload.accountId] : action.payload.adTemplates
    }
  }
})

export default AdTemplates
