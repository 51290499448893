import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  TableCell,
  TableRow,
  fade
}                 from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    products : {
      padding : 20,
      width : '100%'
    },
    header : {
      width : '100%',
      display : 'flex',
      justifyContent : 'space-between',
      alignItems : 'center'
    },
    info : {
      color : theme.palette.info.main
    },
    title : {
      fontSize : 20,
      marginBottom : 15
    },
    budgetEstimated : {
      fontSize : 15,
      display : 'flex',
      alignItems : 'center'
    },
    table : {
      borderRadius : '5px 5px 0px 0px',
      marginTop : 20,
    },
    addButton : {
      display:'flex',
      alignItems : 'center',
    },
    button : {
      display :'flex',
      justifyContent : 'center',
      alignItems : 'center',
      backgroundColor : '#e0e0e0',
      height : 70,
      borderRadius : '0px 0px 5px 5px'
    },
    budgets : {
      display : 'flex'
    },
    actionCell : {
      width : '5%',
      alignItems : 'center',
    },
    descriptionCell : {
      width:'30%'
    },
    channelCell : {
      width:'20%'
    },
    valueCell : {
    },
    allocationCell : {},
    error : {
      backgroundColor : `${fade(theme.palette.secondary.light,0.3)}!important`
    }
  })
)
export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight : 'bold',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)
export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow)

export default useStyles
