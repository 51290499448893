import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Retrieving {
  accountsSummary           : boolean
  breakdown                 : boolean
  products                  : boolean
  campaigns                 : boolean
  salesforceAccounts        : boolean
  salesforceAccountContacts : boolean
}
const initial = {
  accountsSummary           : false,
  breakdown                 : false,
  campaigns                 : false,
  products                  : false,
  salesforceAccounts        : false,
  salesforceAccountContacts : false,
}

const Pages = createReducer<Retrieving>(initial,{
  [ActionTypes.RETRIEVING_SUMMARY] : (state,action) => {
    return {
      ...state,
      accountsSummary : action.payload
    }
  },
  [ActionTypes.RETRIEVING_BREAKDOWN] : (state,action) => {
    return {
      ...state,
      breakdown : action.payload
    }
  },
  [ActionTypes.RETRIEVING_SALES_CAMPAIGNS] : (state,action) => {
    return {
      ...state,
      campaigns : action.payload
    }
  },
  [ActionTypes.RETRIEVING_SALES_PRODUCTS] : (state,action) => {
    return {
      ...state,
      products : action.payload
    }
  },
  [ActionTypes.RETRIEVING_SALESFORCE_ACCOUNTS] : (state,action) => {
    return {
      ...state,
      salesforceAccounts : action.payload
    }
  },
  [ActionTypes.RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS] : (state,action) => {
    return {
      ...state,
      salesforceAccountContacts : action.payload
    }
  },
})

export default Pages
