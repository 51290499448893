import * as React              from "react"
import {connect}               from "react-redux"
import {State}                 from "../redux/reducers/"
import {DispatchableAction}    from "../model/constant/actions"
import {
  Switch,
  Route,
  Redirect,
}                              from "react-router-dom"
import ApplicationBar          from "../components/ApplicationBar"
import AgencyRouting           from "./Agency"
import DesignSystem            from "../pages/Design System"
import { ThunkDispatch }       from "redux-thunk"
import User                    from "../pages/User"

interface LoggedRoutingOwnProps{
  path ?: string
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:LoggedRoutingOwnProps)=>{
    return {
      selectedAgency : state.Selection.Agency
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:LoggedRoutingOwnProps)=>{
  return {}
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:LoggedRoutingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoggedRoutingProps = ReturnType<typeof mergeProps>
const LoggedRouting:React.SFC<LoggedRoutingProps> = (props:LoggedRoutingProps) => {
  const previousPath = ("/"+props.path+"/").replace("//","/")
  return (
    <div>
      <ApplicationBar>
        <Switch>
          <Route path={previousPath+"Design"} component={DesignSystem} />
          <Route
            path={previousPath+"Agency/:agencyId"}
            render={(props)=>
              <AgencyRouting path={previousPath+"Agency/:agencyId"} pathAgency={props.match.params.agencyId}/>
            }
          />
          <Route
            path={previousPath+"User"} render={(props)=><User />}
          />
          <Redirect to={previousPath+"Agency/Selection"} />
        </Switch>
      </ApplicationBar>
    </div>
  )
}
LoggedRouting.defaultProps = {
  path : ""
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(LoggedRouting)
