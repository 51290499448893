import React            from 'react'
import styles           from './AccountInformationsStyles'
import {
  Card,
  Typography,
  TextField
}                       from '@material-ui/core'
import { Account }      from '../../../model/accounts/Account'
import {
  withLocalize,
  LocalizeContextProps
}                       from 'react-localize-redux'

interface AccountInformationsOwnProps extends LocalizeContextProps {
  account : Account | undefined
}

const AccountInformations = (props:AccountInformationsOwnProps) => {
  const classes = styles()
  return (
    <Card className={classes.accountInformations}>
      <div>
        <Typography variant="h6" style={{fontSize:'1.5rem'}} >{props.translate('accounts.accountsDetails.basicInformation.title')}</Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.inputsDiv}>
          <TextField className={classes.input} variant="outlined" label={props.translate('accounts.accountsDetails.basicInformation.dealerName')}      value={props.account && props.account.name}/>
          <TextField className={classes.input} variant="outlined" label={props.translate('accounts.accountsDetails.basicInformation.websiteUrl')}      value={props.account && props.account.website}/>
        </div>
        <div className={classes.inputsDiv}>
          <TextField className={classes.input} variant="outlined" label={props.translate('accounts.accountsDetails.basicInformation.dealerAddress')}   value={props.account && props.account.street}/>
          <TextField className={classes.input} variant="outlined" label={props.translate('accounts.accountsDetails.basicInformation.city')}            value={props.account && props.account.city }/>
          <TextField className={classes.input} variant="outlined" label={props.translate('accounts.accountsDetails.basicInformation.stateOrProvince')} value={props.account && props.account.province}/>
          <TextField className={classes.input} variant="outlined" label={props.translate('accounts.accountsDetails.basicInformation.postalCode')}      value={props.account && props.account.zipCode }/>
        </div>
      </div>
    </Card>
  )
}

export default withLocalize(AccountInformations)
