import React                         from 'react'
import {
  Menu,
  MenuItem,
  Divider,
  IconButton,
  //Badge,
  useTheme,
}                                    from '@material-ui/core'
//import NotificationsIcon             from '@material-ui/icons/Notifications'
import AccountCircle                 from '@material-ui/icons/AccountCircle'
import PersonIcon                    from '@material-ui/icons/Person'
import LogoutIcon                    from '@material-ui/icons/ExitToApp'
import ProfileAndNotificationsStyles from './ProfileAndNotificationsStyles'
//import Notifications                 from './Notifications'
import LanguageIcon                  from '@material-ui/icons/Language'
import LanguageMenu                  from '../LanguageMenu'
import Cookies                       from 'js-cookie'
import { Translate }                 from "react-localize-redux"
import {translationPaths}            from '../../../translations/translations'

interface ProfileAndNotificationProps {
  onProfileClick : () => void
}

const ProfileAndNotifications  = (props:ProfileAndNotificationProps) => {
  const theme = useTheme()
  const classes = ProfileAndNotificationsStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  //const [anchorNotif,setAnchorNotif] = React.useState<null | HTMLElement>(null)
  //const isNotifMenuOpen = Boolean(anchorNotif)
  const [anchorLanguage,setAnchorLanguage] = React.useState<null | HTMLElement>(null)
  const isLanguageMenuOpen = Boolean(anchorLanguage)

  /*const handleNotificationsMenuOpen = (event:React.MouseEvent<HTMLElement>) => {
    setAnchorNotif(event.currentTarget)
  }
  const handleCloseNotificationsMenu = () => {
    setAnchorNotif(null)
  }
  */
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleLanguageMenuOpen =(event:React.MouseEvent<HTMLElement>) => {
    setAnchorLanguage(event.currentTarget)
  }
  const handleCloseLanguageMenu =() => {
    setAnchorLanguage(null)
  }

  const handleLogout = () => {
    Cookies.remove("UID")
    document.location.reload()
  }

  // Profile Menu
  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      transformOrigin={{vertical:'bottom',horizontal:'center'}}
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem className={classes.profileMenu} onClick={()=>{handleMenuClose();props.onProfileClick()}}><PersonIcon className={classes.profileIcons} /><Translate id={translationPaths.user.profile} /></MenuItem>
      <Divider />
      <MenuItem className={classes.profileMenu} onClick={handleLogout}><LogoutIcon className={classes.profileIcons}/>
        <Translate id={translationPaths.appBar.profileAndNotifications.logOut}/>
      </MenuItem>
    </Menu>
  )

  return (
    <div>
    {/*
      <IconButton
        className={classes.iconButton}
        edge="end"
        aria-haspopup="true"
        onClick={handleNotificationsMenuOpen}
        style={{color:theme.palette.primary.dark}}
      >
        <Badge badgeContent={2} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    */}
      <IconButton
        className={classes.iconButton}
        edge="end"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        style={{color:theme.palette.primary.dark}}
      >
        <AccountCircle />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        edge="end"
        aria-haspopup="true"
        onClick={handleLanguageMenuOpen}
        style={{color:theme.palette.primary.dark}}
      >
        <LanguageIcon />
      </IconButton>
      <LanguageMenu anchor={anchorLanguage} open={isLanguageMenuOpen} handleClose={handleCloseLanguageMenu} />
      {renderMenu}
      {/*<Notifications anchor={anchorNotif} open={isNotifMenuOpen} handleClose={handleCloseNotificationsMenu} />*/}
    </div>
  )
}

export default ProfileAndNotifications
