import React                    from 'react'
import {
  Button,
  Icon,
  Divider,
  withStyles
}                               from '@material-ui/core'
import { FaFacebookSquare}      from 'react-icons/fa'
import {DiBingSmall}            from 'react-icons/di'
import GoogleG                  from '../../assets/images/google-g.svg'
import {
  withLocalize,
  LocalizeContextProps
}                               from 'react-localize-redux'
import useStyles                from './IconActivateButtonStyles'


interface IconActivateButtonOwnProps extends LocalizeContextProps {
  activated : boolean
  media     : 'facebook' | 'bing' | 'google'
  activeEnglish ?: boolean
  activeFrench  ?: boolean
  disabled ?: boolean
  customTitle ?: string
}

const ActivationButton = withStyles({
  contained : {
    backgroudColor : 'white',
    width : 300,
    display : 'flex',
    justifyContent : 'flex-start',
    '&:hover' : {
      backgroundColor : '#e5e5e5',
    }
  },
})(Button)

const IconActivateButton = (props:IconActivateButtonOwnProps) => {
  const classes = useStyles()
  return (
    <div>
      <ActivationButton style={{width:props.media==="facebook" ? 350 : 290}} size="large" variant="contained" disabled={props.disabled}>
        <div style={{marginRight:15,paddingTop:5}}>
          {
            props.media ==='facebook' ?
            <FaFacebookSquare className={classes.icon} style={{fill:'#3b5998'}} />
            : props.media === 'google' ?
            <img src={GoogleG} style={{width: "30px"}} alt="Google G Logo" />
            : props.media === 'bing' ?
            <DiBingSmall className={classes.icon} style={{fill:"#6A0DAD"}} />
            : null
          }
        </div>
        <div className={classes.buttonText}>
         {props.customTitle && <div>{props.customTitle}</div>}
          {!props.customTitle && <div style={{color:props.activated ? 'green' : ''}}>
            {props.activated ? props.translate('accounts.accountsDetails.activations.activated') : props.translate('accounts.accountsDetails.activations.activate')}
          </div>}
          <Divider className={classes.divider} />
          <div className={classes.languages} style={{fontSize:'0.9em'}}>
            <div className={classes.language}>
              EN
              {!props.disabled && (props.activeEnglish ? <Icon  style={{marginLeft:5,color:'green'}}>done</Icon> : <Icon  style={{marginLeft:5,color:'red'}}>clear</Icon>)}
            </div>
            <div className={classes.language}>
              FR
              {!props.disabled && (props.activeFrench ? <Icon  style={{marginLeft:5,color:'green'}}>done</Icon> : <Icon  style={{marginLeft:5,color:'red'}}>clear</Icon>)}
            </div>
          </div>
        </div>
      </ActivationButton>
    </div>
  )
}

export default withLocalize(IconActivateButton)
