import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    marginBottom: 20,
  },
  appBar : {
    color: theme.palette.primary.light,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  tabsParent: {
    maxWidth: 700,
    "& > div": {
      "& > div": {
        "& button": {
          borderRight: "1px solid rgba(0, 0, 0, .1)",
          borderBottom: "1px solid rgba(0, 0, 0, .1)",
          letterSpacing: "-0.5px",
        },
        "& :last-child": {
          borderRight: "0px solid transparent"
        }
      }
    }
  },
  checkboxesContainer: {
    paddingRight: 30,
  },
  panel: {
    borderTop: "1px solid rgba(0, 0, 0, .1)",
    marginTop: 10,
  },


  '@media (max-width: 1250px)': {
    appBar: {
      flexDirection: "column"
    },
    tabsParent: {
      maxWidth: ""
    },
    checkboxesContainer: {
      marginTop: 10,
      paddingLeft: 20,
    },
  },


}))

export default useStyles
