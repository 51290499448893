import {
  makeStyles,
  Theme,
  createStyles,
}                 from '@material-ui/core'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsParent: {
      maxWidth: 700,
      "& > div": {
        "& > div": {
          "& button": {
            borderRight: "1px solid rgba(0, 0, 0, .1)",
            borderBottom: "1px solid rgba(0, 0, 0, .1)",
            letterSpacing: "-0.5px",
          },
          "& :last-child": {
            borderRight: "0px solid transparent"
          }
        }
      }
    },
    pacingTable : {
      marginTop: "-30px",
      minWidth: '99%',
      "& th": {
        backgroundColor: "inherit",
        borderBottom: "0px solid transparent",
      },

      // This removes the underline from the search field
      // as well as the search icon and delete button
      "& > div": {
        // empty div
        "& > div": {
          // MTableToolbar outer
          "& :nth-child(3)": {
            // searchField outer
            "& > div": {
              // the div with the ::before and ::after
              "&::before": {
                content: "none"
              },
              "&::after": {
                content: "none"
              },
              "& :first-child": {
                display: "none",
              }
            },
          },
        },
      },
    },
    title: {
      height: 10,
      width: 10,
    },
    dealerName : {
      overflow : 'hidden',
      textOverflow : 'ellipsis',
      whiteSpace : 'nowrap'
    },
    total : {
      height: 100,
      marginTop: 20,
      display: "flex",
      flexDirection: "row",
      boxSizing: "border-box",
      margin: "auto",
      "& :first-child": {
        minWidth: 300,
      },
      "& p": {
        color: "gray",
        fontWeight: "bold",
        padding: 16,
      },
    },
    totalChild: {
      width: "calc(25% + 0px)",
    },
    paper: {
      padding: theme.spacing(1),
    },
    '@media (max-width: 1000px)': {
      total: {
        "& :nth-child(2)": {
          width: "calc(26% + 0px)"
        }
      },
    },
    popover: {
      pointerEvents: 'none',
      transform: 'scale(.6)',
      width: 700,
    },
    popoverInner: {
      padding: 30,
    },
    cursor: {
      '& :hover': {
        cursor: 'pointer',
      }
    },
  })
)

export default useStyles
