import { createReducer } from "../../../../utils/Reducers"
import ActionTypes       from "../../../../model/constant/actions"
import SalesforceContact from "../../../../model/Salesforce/Contact"


const Contacts = createReducer<SalesforceContact[]>(null,{
  [ActionTypes.GET_SALESFORCE_ACCOUNT_CONTACTS] : (state,action) => {
    return action.payload
  }
})

export default Contacts
