import React                            from 'react'
import styles                           from './ActivationsStyles'
import {
  Card,
  Typography,
  FormHelperText,
  Modal,
  Icon,
}                                       from '@material-ui/core'
import IconActivateButton               from '../../../components/buttons/IconActivateButton'
import {
  LocalizeContextProps,
  withLocalize,
  Translate
}                                       from 'react-localize-redux'
import FacebookActivation               from './Modals/FacebookActivation'
import { Account }                      from '../../../model/accounts/Account'
import requiresFacebookStrategies       from '../../../components/loaders/loadFacebookStrategies'
import Strategy                         from '../../../model/Facebook/Strategy'
import requiresFacebookIdentityVariants from '../../../components/loaders/loadFacebookIdentityVariants'
import requiresFacebookAdTemplates      from '../../../components/loaders/loadFacebookAdTemplates'
import AdTemplate                       from '../../../model/Facebook/AdTemplate'
import IdentityVariants                 from '../../../model/Facebook/IdentityVariants'
import { translationPaths }             from '../../../translations/translations'
import FacebookIdSetupController        from './Modals/FacebookIdSetupController'
import requiresAccountInfoFirestone     from '../../../components/loaders/loadAccountInfoFirestone'
import { FacebookAccountInfo }          from '../../../redux/reducers/Facebook/AccountInfo'

interface ActivationsOwnProps extends LocalizeContextProps{
  account          ?: Account
  strategies        : Strategy[]
  adTemplates       : AdTemplate[]
  identityVariants  : IdentityVariants[]
  accountId         : number
  resetFailedSubmitAccountId : () => void
  resetFailedCreateStrategy  : () => void
  accountInfo : FacebookAccountInfo
}

const Activations = (props:ActivationsOwnProps) => {
  const classes = styles()
  const [openFacebookActivationNewRemarketing,setOpenFacebookActivationNewRemarketing]   = React.useState<boolean>(false)
  const [openFacebookActivationNewProspecting,setOpenFacebookActivationNewProspecting]   = React.useState<boolean>(false)
  const [openFacebookActivationUsedRemarketing,setOpenFacebookActivationUsedRemarketing] = React.useState<boolean>(false)
  const [openFacebookActivationUsedProspecting,setOpenFacebookActivationUsedProspecting] = React.useState<boolean>(false)
  const [openFacebookAdsId,setOpenFacebookAdsId] = React.useState<boolean>(false)
  const handleOpenFacebookAdsId                      = () => setOpenFacebookAdsId(true)
  const handleCloseFacebookAdsId                     = () => {setOpenFacebookAdsId(false);props.resetFailedSubmitAccountId()}
  const handleOpenFacebookActivationUsedRemarketing  = () => setOpenFacebookActivationUsedRemarketing(true)
  const handleOpenFacebookActivationNewProspecting   = () => setOpenFacebookActivationNewProspecting(true)
  const handleOpenFacebookActivationNewRemarketing   = () => setOpenFacebookActivationNewRemarketing(true)
  const handleOpenFacebookActivationUsedProspecting  = () => setOpenFacebookActivationUsedProspecting(true)
  const handleCloseFacebookActivationNewProspecting  = () => {
    setOpenFacebookActivationNewProspecting(false)
    props.resetFailedCreateStrategy()
  }
  const handleCloseFacebookActivationNewRemarketing  = () => {
    setOpenFacebookActivationNewRemarketing(false)
    props.resetFailedCreateStrategy()
  }
  const handleCloseFacebookActivationUsedProspecting = () => {
    setOpenFacebookActivationUsedProspecting(false)
    props.resetFailedCreateStrategy()
  }
  const handleCloseFacebookActivationUsedRemarketing = () => {
    setOpenFacebookActivationUsedRemarketing(false)
    props.resetFailedCreateStrategy()
  }

  const facebookNewStrategies        = props.strategies.filter(x=>x.state === 'NEW')
  const facebookUsedStrategies       = props.strategies.filter(x=>x.state === 'USED')
  const facebookNewIdentityVariants  = props.identityVariants.filter(x=>x.vehicleState === 'NEW')
  const facebookUsedIdentityVariants = props.identityVariants.filter(x=>x.vehicleState === 'USED')
  const facebookNewAdTemplates       = props.adTemplates.filter(x=>x.vehicleState === 'NEW')
  const facebookUsedAdTemplates      = props.adTemplates.filter(x=>x.vehicleState === 'USED')
  const noFacebookAccountId          = !props.account?.facebookAccountId || props.accountInfo[props.accountId] === "NON_EXISTING"

  return (
    <Card className={classes.activations}>
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <Typography variant="h6" style={{fontSize:'1.5rem'}}>{props.translate('accounts.accountsDetails.activations.title')}</Typography>
      </div>
      <div className={classes.content}>
        {noFacebookAccountId && (
          <Typography variant="caption" className={classes.facebookAdsIdMessage}>
            <Icon style={{marginRight:5,fontSize:'1.5em',marginBottom:5}}>emoji_objects</Icon>
            <Translate id={translationPaths.accounts.index.errors.facebookAdsId} />
            <Typography onClick={handleOpenFacebookAdsId} variant="caption" className={classes.setUpAction}>
              <Icon style={{marginRight:1,fontSize:'1.2em'}}>settings</Icon>
              <span className={classes.setUpText}><Translate id={translationPaths.general.setUpNow} /></span>
            </Typography>
          </Typography>
        )}
        <div className={classes.row}>
          <div className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="google" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.newCarsGoogle')}</FormHelperText>
          </div>
          <div className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="bing" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.newCars')}</FormHelperText>
          </div>
          <div onClick={handleOpenFacebookActivationNewRemarketing} >
            <IconActivateButton
              customTitle={props.translate(translationPaths.accounts.accountsDetails.activations.newRemarketing) as string}
              disabled = {noFacebookAccountId}
              media="facebook"
              activated={facebookNewStrategies.some(x=>x.active===true && x.audienceGoal === 'REMARKETING')}
              activeEnglish={facebookNewStrategies.some(strategy=>strategy.lang === 'EN' && strategy.audienceGoal === 'REMARKETING' && strategy.active)}
              activeFrench={facebookNewStrategies.some(strategy=>strategy.lang === 'FR' && strategy.audienceGoal === 'REMARKETING' && strategy.active)}
            />
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.newRemarketingCarsFacebook')}</FormHelperText>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="google" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.usedCarsGoogle')}</FormHelperText>
          </div>
          <div className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="bing" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.usedCars')}</FormHelperText>
          </div>
          <div onClick={handleOpenFacebookActivationUsedRemarketing}>
            <IconActivateButton
              customTitle={props.translate(translationPaths.accounts.accountsDetails.activations.usedRemarketing) as string}
              disabled = {noFacebookAccountId}
              activated={facebookUsedStrategies.some(x=>x.active===true && x.audienceGoal === 'REMARKETING')}
              media="facebook"
              activeEnglish={facebookUsedStrategies.some(strategy=>strategy.lang === 'EN' && strategy.audienceGoal === 'REMARKETING' && strategy.active)}
              activeFrench={facebookUsedStrategies.some(strategy=>strategy.lang === 'FR' && strategy.audienceGoal === 'REMARKETING' && strategy.active)}
            />
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.usedRemarketingCarsFacebook')}</FormHelperText>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="google" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.conquestGoogle')}</FormHelperText>
          </div>
          <div  className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="bing" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.conquest')}</FormHelperText>

          </div>
          <div onClick={handleOpenFacebookActivationNewProspecting} >
            <IconActivateButton
              customTitle={props.translate(translationPaths.accounts.accountsDetails.activations.newProspecting) as string}
              disabled = {noFacebookAccountId}
              media="facebook"
              activated={facebookNewStrategies.some(x=>x.active===true && x.audienceGoal === 'PROSPECTING')}
              activeEnglish={facebookNewStrategies.some(strategy=>strategy.lang === 'EN' && strategy.audienceGoal === 'PROSPECTING' && strategy.active)}
              activeFrench={facebookNewStrategies.some(strategy=>strategy.lang === 'FR' && strategy.audienceGoal === 'PROSPECTING' && strategy.active)}
            />
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.newProspectingCarsFacebook')}</FormHelperText>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="google" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.brandingGoogle')}</FormHelperText>
          </div>
          <div  className={classes.buttonContainer}>
            <IconActivateButton activated={false} media="bing" disabled/>
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.branding')}</FormHelperText>
          </div>
          <div onClick={handleOpenFacebookActivationUsedProspecting} >
            <IconActivateButton
              customTitle={props.translate(translationPaths.accounts.accountsDetails.activations.usedProspecting) as string}
              disabled = {noFacebookAccountId}
              activated={facebookUsedStrategies.some(x=>x.active===true && x.audienceGoal==="PROSPECTING")}
              media="facebook"
              activeEnglish={facebookUsedStrategies.some(strategy=>strategy.lang === 'EN' && strategy.audienceGoal === 'PROSPECTING' && strategy.active)}
              activeFrench={facebookUsedStrategies.some(strategy=>strategy.lang === 'FR' && strategy.audienceGoal === 'PROSPECTING' && strategy.active)}
            />
            <FormHelperText>{props.translate('accounts.accountsDetails.activations.usedProspectingCarsFacebook')}</FormHelperText>
          </div>
        </div>
        <Typography variant="caption" className={classes.announcement}>
          <Icon style={{marginRight:5}}>announcement</Icon> <Translate id={translationPaths.accounts.index.errors.notAvailable} />
        </Typography>
      </div>
      <Modal className={classes.facebookActivationModal} open={!noFacebookAccountId &&openFacebookActivationNewRemarketing} onClose={handleCloseFacebookActivationNewRemarketing}>
        <>
          <FacebookActivation
            title={props.translate(translationPaths.accounts.accountsDetails.activations.newRemarketing) as string}
            strategies={facebookNewStrategies}
            accountName={props.account ? props.account.name : ''}
            identityVariants={facebookNewIdentityVariants}
            adTemplates={facebookNewAdTemplates}
            vehicleState="NEW"
            audienceGoal="REMARKETING"
            accountId={props.accountId}
          />
        </>
      </Modal>
      <Modal className={classes.facebookActivationModal} open={!noFacebookAccountId && openFacebookActivationUsedRemarketing} onClose={handleCloseFacebookActivationUsedRemarketing}>
        <>
          <FacebookActivation
            title={props.translate(translationPaths.accounts.accountsDetails.activations.usedRemarketing) as string}
            strategies={facebookUsedStrategies}
            accountName={props.account ? props.account.name : ''}
            identityVariants={facebookUsedIdentityVariants}
            adTemplates={facebookUsedAdTemplates}
            vehicleState="USED"
            audienceGoal="REMARKETING"
            accountId={props.accountId}
          />
        </>
      </Modal>
      <Modal className={classes.facebookActivationModal}
        open={!noFacebookAccountId && openFacebookActivationNewProspecting}
        onClose={handleCloseFacebookActivationNewProspecting}
      >
        <>
          <FacebookActivation
            title={props.translate(translationPaths.accounts.accountsDetails.activations.newProspecting) as string}
            strategies={facebookNewStrategies}
            accountName={props.account ? props.account.name : ''}
            identityVariants={facebookNewIdentityVariants}
            adTemplates={facebookNewAdTemplates}
            vehicleState="NEW"
            audienceGoal="PROSPECTING"
            accountId={props.accountId}
          />
        </>
      </Modal>
      <Modal
        className={classes.facebookActivationModal}
        open={!noFacebookAccountId && openFacebookActivationUsedProspecting}
        onClose={handleCloseFacebookActivationUsedProspecting}
      >
        <>
          <FacebookActivation
            title={props.translate(translationPaths.accounts.accountsDetails.activations.usedProspecting) as string}
            strategies={facebookUsedStrategies}
            accountName={props.account ? props.account.name : ''}
            identityVariants={facebookUsedIdentityVariants}
            adTemplates={facebookUsedAdTemplates}
            vehicleState="USED"
            audienceGoal="PROSPECTING"
            accountId={props.accountId}
          />
        </>
      </Modal>
      <Modal className={classes.facebookIdSetupModal} open={openFacebookAdsId} onClose={handleCloseFacebookAdsId}>
        <>
          <FacebookIdSetupController adAccountIdAuth={props.account?.facebookAccountId} handleClose={handleCloseFacebookAdsId} />
        </>
      </Modal>
    </Card>
  )
}

export default requiresAccountInfoFirestone()(
    requiresFacebookStrategies()(
    requiresFacebookIdentityVariants()(
      requiresFacebookAdTemplates()(
        withLocalize(Activations)
      )
    )
  )
)
