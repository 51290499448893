import { combineReducers } from "redux"
import Summary             from './Summary'
import Retrieving          from './Retrieving'
import Updating            from './Updating'
import Creating            from './Creating'
import Failed              from './Failed'
import Breakdown           from './Breakdown'
import DraftSummary        from './DraftSummary'
import Campaigns           from './Campaigns'
import Products            from './Products'
import Salesforce          from './Salesforce'


const Sales = combineReducers({
  Summary,
  Retrieving,
  Failed,
  Creating,
  Updating,
  Breakdown,
  DraftSummary,
  Campaigns,
  Products,
  Salesforce,
})

export default Sales
