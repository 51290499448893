import { combineReducers }      from "redux"
import Strategies               from './Strategies'
import Failed                   from './Failed'
import Retrieving               from './Retrieving'
import AdTemplates              from './AdTemplates'
import IdentityVariants         from './IdentityVariants'
import Businesses               from './Businesses'
import Pages                    from './Pages'
import UnusedAdAccounts         from './UnusedAdAccounts'
import FacebookDefaultAdSets    from './FacebookDefaultAdSets'
import Updating                 from './Updating'
import Creating                 from './Creating'
import AccountInfo              from './AccountInfo'

const Facebook = combineReducers({
  Strategies,
  AdTemplates,
  IdentityVariants,
  AccountInfo,
  Businesses,
  Pages,
  UnusedAdAccounts,
  FacebookDefaultAdSets,
  Failed,
  Retrieving,
  Updating,
  Creating,
})

export default Facebook
