import { createStyles }    from "@material-ui/styles"
import {
  makeStyles,
  Theme
}                          from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      bulletGraph : {
        display : 'grid',
        gridTemplateColumns : '3fr 1fr',
      },
      firstBar : {
        marginBottom:20,
        height:30,
        borderTopLeftRadius:5,
        borderBottomLeftRadius:5,
      },
      secondBar : {
        height:30,
        borderTopRightRadius:5,
        borderBottomRightRadius:5,
      },
      value : {
        position : 'absolute',
        float : 'left',
        marginTop : 6,
        marginLeft : 10,
        color : "#FFFFFF",
        fontWeight : 'bold'
      }
    })
  )

export default useStyles
