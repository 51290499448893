import ActionTypes, {
  ActionType,
  ActionPayload,
  ActionObject,
  Action
}                     from "../model/constant/actions"
import {State}        from "../redux/reducers/"
import {ThunkAction}  from "redux-thunk"

export type StandardThunk = ThunkAction<void, State, null, Action>

const createActionCreator = <Type extends ActionType>(type:Type) =>
  (payload:ActionPayload<Type>):ActionObject<Type> => ({type, payload})

export const createLogin = createActionCreator(ActionTypes.LOGIN)
export const createFailedLogin = createActionCreator(ActionTypes.FAILED_LOGIN)
export const createLoggingIn = createActionCreator(ActionTypes.LOGGING_IN)
export const createGetAgencyAccounts = createActionCreator(ActionTypes.GET_AGENCY_ACCOUNTS)
export const createRetrievingAgencyAccounts = createActionCreator(ActionTypes.RETRIEVING_AGENCY_ACCOUNTS)
export const createFailedRetrievingAgencyAccounts = createActionCreator(ActionTypes.FAILED_RETRIEVING_AGENCIES_ACCOUNTS)
export const createFailedRequest = createActionCreator(ActionTypes.FAILED_REQUEST)
export const createRemoveFailedRequest = createActionCreator(ActionTypes.REMOVE_FAILED_REQUEST)
export const createGetAgencies = createActionCreator(ActionTypes.GET_AGENCIES)
export const createRetrievingAgencies = createActionCreator(ActionTypes.RETRIEVING_AGENCIES)
export const createFailedRetrievingAgencies = createActionCreator(ActionTypes.FAILED_RETRIEVING_AGENCIES)
export const createSelectAgency = createActionCreator(ActionTypes.SELECT_AGENCY)
export const createSelectDealer = createActionCreator(ActionTypes.SELECT_DEALER)
export const createGetPacingStats = createActionCreator(ActionTypes.GET_PACING_STATS)
export const createPacingDataToCompile = createActionCreator(ActionTypes.CREATE_PACING_DATA_TO_COMPILE)
export const createRetrievingPacingStats = createActionCreator(ActionTypes.RETRIEVING_PACING_STATS)
export const createResettingPassword = createActionCreator(ActionTypes.RESETTING_PASSWORD)
export const createStoreMessage = createActionCreator(ActionTypes.FORGOT_PASSWORD_RETRIEVED)
export const createGetGoogleTrendsStats = createActionCreator(ActionTypes.GET_GOOGLE_TRENDS_STATS)
export const createRetrievingGoogleTrendsStats = createActionCreator(ActionTypes.RETRIEVING_GOOGLE_TRENDS_STATS)
export const createFailedRetrievingGoogleTrendsStats = createActionCreator(ActionTypes.FAILED_RETRIEVING_GOOGLE_TRENDS_STATS)
export const createRetrievingInventoryStats = createActionCreator(ActionTypes.RETRIEVING_INVENTORY_STATS)
export const createGetInventoryStats = createActionCreator(ActionTypes.GET_INVENTORY_STATS)
export const createFailedRetrievingInventoryStats = createActionCreator(ActionTypes.FAILED_RETRIEVING_INVENTORY_STATS)
export const createRetrievingTrackingStats = createActionCreator(ActionTypes.RETRIEVING_TRACKING_STATS)
export const createGetTrackingStats = createActionCreator(ActionTypes.GET_TRACKING_STATS)
export const createFailedRetrievingTrackingStats = createActionCreator(ActionTypes.FAILED_RETRIEVING_TRACKING_STATS)
export const createGetAdAccountsData = createActionCreator(ActionTypes.GET_AD_ACCOUNTS_DATA)
export const createRetrievingAdAccountsData = createActionCreator(ActionTypes.RETRIEVING_AD_ACCOUNTS_DATA)
export const createFailedRetrievingAdAccountsData = createActionCreator(ActionTypes.FAILED_RETRIEVING_AD_ACCOUNTS_DATA)
export const createGetAccountFeeds = createActionCreator(ActionTypes.GET_ACCOUNT_FEEDS)
export const createClearAccountFeeds = createActionCreator(ActionTypes.CLEAR_ACCOUNT_FEEDS)
export const createRetrievingAccountFeeds = createActionCreator(ActionTypes.RETRIEVING_ACCOUNT_FEEDS)
export const createFailedRetrievingAccountFeeds = createActionCreator(ActionTypes.FAILED_RETRIEVING_ACCOUNT_FEEDS)
export const createGetFacebookAccountStrategies = createActionCreator(ActionTypes.GET_FACEBOOK_ACCOUNT_STRATEGIES)
export const createRetrievingFacebookAccountStrategies = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES)
export const createFailedRetrievingFacebookAccountStrategies = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES)
export const createGetFacebookDefaultAdSet = createActionCreator(ActionTypes.GET_FACEBOOK_DEFAULT_ADSET)
export const createRetrievingFacebookDefaultAdSet = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_DEFAULT_ADSET)
export const createFailedRetrievingFacebookDefaultAdSet = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_DEFAULT_ADSET)
export const createCreatingFacebookStrategy = createActionCreator(ActionTypes.CREATING_FACEBOOK_STRATEGY)
export const createFailedCreatingFacebookStrategy  = createActionCreator(ActionTypes.FAILED_CREATING_FACEBOOK_STRATEGY)
export const createUpdatingFacebookStrategy = createActionCreator(ActionTypes.UPDATING_FACEBOOK_STRATEGY)
export const createFailedUpdatingFacebookStrategy  = createActionCreator(ActionTypes.FAILED_UPDATING_FACEBOOK_STRATEGY)
export const createGetFacebookAdTemplates  = createActionCreator(ActionTypes.GET_FACEBOOK_AD_TEMPLATES)
export const createRetrievingFacebookAdTemplates  = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_AD_TEMPLATES)
export const createFailedRetrievingFacebookAdTemplates  = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_AD_TEMPLATES)
export const createCreatingFacebookAdTemplates = createActionCreator(ActionTypes.CREATING_FACEBOOK_AD_TEMPLATES)
export const createFailedCreatingFacebookAdTemplates  = createActionCreator(ActionTypes.FAILED_CREATING_FACEBOOK_AD_TEMPLATES)
export const createUpdatingFacebookAdTemplates = createActionCreator(ActionTypes.UPDATING_FACEBOOK_AD_TEMPLATES)
export const createFailedUpdatingFacebookAdTemplates  = createActionCreator(ActionTypes.FAILED_UPDATING_FACEBOOK_AD_TEMPLATES)
export const createGetFacebookIdentityVariants  = createActionCreator(ActionTypes.GET_FACEBOOK_IDENTITY_VARIANTS)
export const createRetrievingFacebookIdentityVariants  = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_IDENTITY_VARIANTS)
export const createFailedRetrievingFacebookIdentityVariants  = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_IDENTITY_VARIANTS)
export const createCreatingFacebookIdentityVariants = createActionCreator(ActionTypes.CREATING_FACEBOOK_IDENTITY_VARIANTS)
export const createFailedCreatingFacebookIdentityVariants  = createActionCreator(ActionTypes.FAILED_CREATING_FACEBOOK_IDENTITY_VARIANTS)
export const createUpdatingFacebookIdentityVariants = createActionCreator(ActionTypes.UPDATING_FACEBOOK_IDENTITY_VARIANTS)
export const createFailedUpdatingFacebookIdentityVariants  = createActionCreator(ActionTypes.FAILED_UPDATING_FACEBOOK_IDENTITY_VARIANTS)
export const createRetrievingFacebookBusiness = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_BUSINESS)
export const createGetFacebookBusiness = createActionCreator(ActionTypes.GET_FACEBOOK_BUSINESS)
export const createFailedRetrievingFacebookBusiness = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_BUSINESS)
export const createRetrievingFacebookPages = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_PAGES)
export const createGetFacebookPages = createActionCreator(ActionTypes.GET_FACEBOOK_PAGES)
export const createFailedRetrievingFacebookPages = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_PAGES)
export const createRetrievingFacebookUnusedAdAccounts = createActionCreator(ActionTypes.RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS)
export const createGetFacebookUnusedAdAccounts = createActionCreator(ActionTypes.GET_FACEBOOK_UNUSED_AD_ACCOUNTS)
export const createFailedRetrievingFacebookUnusedAdAccounts = createActionCreator(ActionTypes.FAILED_RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS)
export const createSubmittingFacebookAccountId = createActionCreator(ActionTypes.SUBMITTING_FACEBOOK_ACCOUNT_ID)
export const createFailedSubmittingFacebookAccountId = createActionCreator(ActionTypes.FAILED_SUBMITTING_FACEBOOK_ACCOUNT_ID)
export const createSubmittingFacebookConfiguration = createActionCreator(ActionTypes.SUBMITTING_FACEBOOK_CONFIGURATION)
export const createFailedSubmittingFacebookConfiguration = createActionCreator(ActionTypes.FAILED_SUBMITTING_FACEBOOK_CONFIGURATION)
export const createRerievingAccountInfoFirestone = createActionCreator(ActionTypes.RETRIEVING_ACCOUNT_INFO_FIRESTONE)
export const createGetAccountInfofireStone = createActionCreator(ActionTypes.GET_ACCOUNT_INFO_FIRESTONE)
export const createFailedRetrievingAccountInfoFirestone = createActionCreator(ActionTypes.FAILED_RETRIEVING_ACCOUNT_INFO_FIRESTONE)
export const createRetrievingAccountsSummary = createActionCreator(ActionTypes.RETRIEVING_SUMMARY)
export const createGetAccountsSummary = createActionCreator(ActionTypes.GET_SUMMARY)
export const createFailedRetrievingAccountsSummary = createActionCreator(ActionTypes.FAILED_RETRIEVING_SUMMARY)
export const createRetrievingBreakdown = createActionCreator(ActionTypes.RETRIEVING_BREAKDOWN)
export const createGetBreakdown = createActionCreator(ActionTypes.GET_BREAKDOWN)
export const createFailedRetrievingBreakdown = createActionCreator(ActionTypes.FAILED_RETRIEVING_BREAKDOWN)
export const createCreatingSummary = createActionCreator(ActionTypes.CREATING_SUMMARY)
export const createFailedCreatingSummary = createActionCreator(ActionTypes.FAILED_CREATING_SUMMARY)
export const createUpdatingSummary = createActionCreator(ActionTypes.UPDATING_SUMMARY)
export const createFailedUpdatingSummary = createActionCreator(ActionTypes.FAILED_UPDATING_SUMMARY)
export const createCreateDraftSummary = createActionCreator(ActionTypes.CREATE_DRAFT_SUMMARY)
export const createUpdateDraftSummary = createActionCreator(ActionTypes.UPDATE_DRAFT_SUMMARY)
export const createResetDraftSummary = createActionCreator(ActionTypes.RESET_DRAFT_SUMMARY)
export const createRetrievingSalesCampaigns = createActionCreator(ActionTypes.RETRIEVING_SALES_CAMPAIGNS)
export const createGetSalesCampaigns = createActionCreator(ActionTypes.GET_SALES_CAMPAIGNS)
export const createFailedRetrievingSalesCampaigns = createActionCreator(ActionTypes.FAILED_RETRIEVING_SALES_CAMPAIGNS)
export const createRetrievingSalesProducts = createActionCreator(ActionTypes.RETRIEVING_SALES_PRODUCTS)
export const createGetSalesProducts = createActionCreator(ActionTypes.GET_SALES_PRODUCTS)
export const createFailedRetrievingSalesProducts = createActionCreator(ActionTypes.FAILED_RETRIEVING_SALES_PRODUCTS)
export const createRetrievingSalesforceAccounts = createActionCreator(ActionTypes.RETRIEVING_SALESFORCE_ACCOUNTS)
export const createFailedRetrievingSalesforceAccounts = createActionCreator(ActionTypes.FAILED_RETRIEVING_SALESFORCE_ACCOUNTS)
export const createGetSalesforceAccounts = createActionCreator(ActionTypes.GET_SALESFORCE_ACCOUNTS)
export const createRetrievingSalesforceAccountContacts = createActionCreator(ActionTypes.RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS)
export const createFailedRetrievingSalesforceAccountContacts = createActionCreator(ActionTypes.FAILED_RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS)
export const createGetSalesforceAccountContacts = createActionCreator(ActionTypes.GET_SALESFORCE_ACCOUNT_CONTACTS)
export const createGetAccountPermissions = createActionCreator(ActionTypes.GET_ACCOUNT_PERMISSIONS)
export const createRetrievingAccountPermissions = createActionCreator(ActionTypes.RETRIEVING_ACCOUNT_PERMISSIONS)
export const createGetSystemsPermissions = createActionCreator(ActionTypes.GET_SYSTEMS_PERMISSIONS)
export const createRetrievingSystemsPermissions = createActionCreator(ActionTypes.RETRIEVING_SYSTEMS_PERMISSIONS)

