import React           from 'react'
import {
  Modal,
  makeStyles,
  Theme,
  createStyles,
}                      from '@material-ui/core'
import AgencySelection from './index'

interface AgencySelectionModalProps {
  open           : boolean
  onClose       ?: () => void
  onSelection   ?: () => void
}

const useStyles = makeStyles((theme:Theme)=>
  createStyles({
    modal : {
      display        : 'flex',
      alignItems     : 'center',
      justifyContent : 'space-around',
    },

  })
)

const AgencySelectionModal = (props:AgencySelectionModalProps) => {
  const classes = useStyles()
  return (
    <Modal
      className={classes.modal}
      open={props.open}
    >
      <AgencySelection
        onClose={props.onClose}
        onSelection={props.onSelection}
      />
    </Modal>
  )
}

export default AgencySelectionModal
