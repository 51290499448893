import {
  makeStyles,
  Theme,
  createStyles
}               from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adAccountsInformations : {
      padding : 20
    },
    header : {

    },
    content : {
      marginTop : 20
    },
    input : {
      width : 300,
      margin : 10,
    },
    modal : {
      display : 'flex',
      justifyContent : 'space-around',
      alignItems : 'center',
    }
  })
)
export default useStyles
