import Languages        from "../constant/Languages"
import VehicleState     from "../constant/VehicleState"
import IdentityVariants from "./IdentityVariants"
import AdTemplate       from "./AdTemplate"
import AudienceGoal     from "../constant/AudienceGoal"

interface Strategy {
  active : boolean
  startedOn ?: {
    date : string
    timezone_type : number
    timezone : string
  }
  lang : Languages
  state :  VehicleState
  audienceGoal : AudienceGoal
  feedUrl ?: string
  radius : number
  lastModifiedBy ?: number
  lastModifiedOn ?: {
    date : string
    timezone_type : number
    timezone : string
  }
}

export interface FacebookStrategyConfigs {
  strategy : Strategy
  adTemplates: AdTemplate[]
  identityVariants : IdentityVariants
}

export type FacebookStrategyModel = Record<Languages,FacebookStrategyConfigs>

export const createFacebookStrategyModel = (accountName:string,vehicleState:VehicleState,audienceGoal:AudienceGoal):FacebookStrategyModel => ({
  EN : {
    strategy : {
      active : true,
      lang : 'EN',
      state : vehicleState,
      radius : 25,
      audienceGoal
    },
    adTemplates : [],
    identityVariants : {
      lang : 'EN',
      makes : [],
      vehicleState : vehicleState,
      vehicleType : 'CAR',
      names : [accountName],
    }
  },
  FR : {
    strategy : {
      active : true,
      lang : 'FR',
      state : vehicleState,
      radius : 25,
      audienceGoal
    },
    adTemplates : [],
    identityVariants : {
      lang : 'FR',
      makes : [],
      vehicleState : vehicleState,
      vehicleType : 'CAR',
      names : [accountName],
    }
  },
})


export default Strategy
