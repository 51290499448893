import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import AdAccounts        from "../../../model/Facebook/AdAccounts"

interface FacebookUnusedAdAccounts {
  [businessId:number] : AdAccounts[]
}

const UnusedAdAccounts = createReducer<FacebookUnusedAdAccounts>({},{
  [ActionTypes.GET_FACEBOOK_UNUSED_AD_ACCOUNTS] : (state,action) => {
    return {...state,
      [action.payload.businessId] : action.payload.adAccounts
    }
  }
})

export default UnusedAdAccounts
