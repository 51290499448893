import {
  makeStyles,
  Theme,
  fade
}                 from "@material-ui/core"

const useStyles = makeStyles((theme:Theme)=>({
  pacing : {
    marginRight : 40,
    marginLeft : 40
  },
  header :{
    display: 'flex',
    justifyContent : 'space-between',
  },
  title : {
    marginBottom : 10,
    display : 'flex',
    alignItems : 'center',
    fontWeight : 'normal',
    fontSize : 25,
    color : '#666'
  },
  noData : {
    display : 'flex',
    width : '100%',
    height : 50,
    alignItems : 'center',
    justifyContent : 'center',
    backgroundColor : theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    fontWeight : 'bold',
    marginTop : 20,
    borderTopLeftRadius : 10,
    borderTopRightRadius : 10
  },
  noDataMessage : {
    height : 25,
    fontWeight : 'bold',
    marginLeft : 10,
    marginTop: 5
  },
  disabled : {
    backgroundColor : fade(theme.palette.warning.light,0.1) ,
    padding : 10,
    zIndex : 10000
  }
}))

export default useStyles
