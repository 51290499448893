import React                           from 'react'
import styles                          from './AdAccountsInformationsStyles'
import {
  Typography,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Modal,
}                                      from '@material-ui/core'
import OutlinedInput                   from '@material-ui/core/OutlinedInput'
import { Account }                     from '../../../model/accounts/Account'
import { AccountsData }                from '../../../model/accounts/AccountsData'
import {
  withLocalize,
  LocalizeContextProps
}                                      from 'react-localize-redux'
import FacebookIdSetupController       from './Modals/FacebookIdSetupController'
import requiresAgencyAccounts          from '../../../components/loaders/loadAgencyAccounts'
import requiresAccountInfoFirestone    from '../../../components/loaders/loadAccountInfoFirestone'
import { FacebookAccountInfo }         from '../../../redux/reducers/Facebook/AccountInfo'

interface AdAccountsInformationsProps extends LocalizeContextProps {
  account   ?: Account
  adAccount ?: AccountsData
  resetFailedSubmitAccountId : () => void
  accountInfo : FacebookAccountInfo
}

const AdAccountsInformations = (props:AdAccountsInformationsProps) => {
  const classes = styles()
  const [openFacebookId,setOpenFacebookId] = React.useState<boolean>(false)
  const handleOpenFacebookIdModal = () => setOpenFacebookId(true)
  const handleCloseFacebookIdModal = () =>{setOpenFacebookId(false);props.resetFailedSubmitAccountId()}

  return (
    <Card className={classes.adAccountsInformations}>
      <div className={classes.header}>
        <Typography variant="h6" style={{fontSize:'1.5rem'}}>{props.translate('accounts.accountsDetails.adsData.title')}</Typography>
      </div>
      <div className={classes.content}>
        <TextField
          variant="outlined"
          value={props.account && (props.account.AWCustomerId || 'N/A')}
          label={props.translate('accounts.accountsDetails.adsData.googleAdsId')}
          className={classes.input}
        />
        {props.account && props.account.facebookAccountId && props.accountInfo[props.account.id] !== 'NON_EXISTING' ?
          <TextField
            variant="outlined"
            value={props.account.facebookAccountId}
            label={props.translate('accounts.accountsDetails.adsData.facebookAdsId')}
            className={classes.input}
          />
          :
          <OutlinedInput
            onClick={handleOpenFacebookIdModal}
            className={classes.input}
            value={props.translate('accounts.accountsDetails.adsData.facebookAdsId')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleOpenFacebookIdModal}>
                  <Icon>build</Icon>
                </IconButton>
              </InputAdornment>
            }
          />
        }
        <TextField
          variant="outlined"
          value={props.adAccount ? props.adAccount.CallRailID : 'N/A'}
          label={props.translate('accounts.accountsDetails.adsData.callrailId')}
          className={classes.input}
        />
        <TextField
          variant="outlined"
          value={props.adAccount ? props.adAccount.GoogleAnalyticsID : 'N/A'}
          label={props.translate('accounts.accountsDetails.adsData.googleAnalyticsId')}
          className={classes.input}
        />
        <TextField
          variant="outlined"
          value={props.account && (props.account.bingAccountId || 'N/A')}
          label={props.translate('accounts.accountsDetails.adsData.microsoftAdsId')}
          className={classes.input}
        />
        <TextField
          variant="outlined"
          value="98765422048742-1"
          label={props.translate('accounts.accountsDetails.adsData.displayVideo360Id')}
          className={classes.input}
        />
        <TextField
          variant="outlined"
          value={props.adAccount ? props.adAccount.GTMContainerName : 'N/A'}
          label={props.translate('accounts.accountsDetails.adsData.gtm')}
          className={classes.input}
        />
      </div>
      <Modal className={classes.modal} open={openFacebookId} onClose={handleCloseFacebookIdModal}>
        <>
          <FacebookIdSetupController handleClose={handleCloseFacebookIdModal} adAccountIdAuth={props.account?.facebookAccountId}  />
        </>
      </Modal>
    </Card>
  )
}

export default requiresAccountInfoFirestone()(
  requiresAgencyAccounts()(withLocalize(AdAccountsInformations))
)
