import {
  makeStyles,
  Theme,
  createStyles
}                 from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activations : {
      padding : 20
    },
    content : {
      marginTop : 20,
      padding : 20
    },
    row : {
      marginTop : 10,
      marginBottom : 40,
      display : 'flex',
    },
    buttonContainer : {
      marginRight : 40
    },
    facebookActivationModal : {
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
    },
    announcement : {
      color : theme.palette.info.main,
      display : 'flex',
      alignItems : 'center'
    },
    facebookAdsIdMessage : {
      display:'flex',
      alignItems:'center',
      color : theme.palette.info.main,
      marginBottom : 20
    },
    setUpAction : {
      display : 'flex',
      alignItems : 'center',
      marginLeft : 5,
      color : theme.palette.primary.dark,
      '&:hover' : {
        color : theme.palette.primary.light,
      },
    },
    setUpText : {
      '&:hover' : {
        cursor : "pointer",
        textDecoration : 'underline'
      },
      fontStyle : 'italic',
    },
    facebookIdSetupModal : {
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
    },
  })
)
export default useStyles
