import {
  StandardThunk,
  createRetrievingTrackingStats,
  createGetTrackingStats,
  createFailedRetrievingTrackingStats,
}                                 from "../creators"
import axios                      from "axios"

import Papa                       from 'papaparse'
import { buildCompiledReportUrl } from "../../model/http/Reporting/CompiledReport"
import { TrackingStats }          from "../../model/store/insights/TrackingStats"


export const getTrackingStats = (agencyId:string,dateFrom:string,dateTo:string):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingTrackingStats(true))
  axios.get(buildCompiledReportUrl(agencyId,"AGENCY_T2_TRENDS"),{
    headers : {
      Authorization : `Bearer ${token}`,
    },
    params : {
      dateFrom : dateFrom,
      dateTo : dateTo
    }
  })
  .then( response => {
    const parsedData:TrackingStats[] = Papa.parse(response.data.trim(),{header:true, dynamicTyping:true}).data
    dispatch(createGetTrackingStats({agencyId,trackingStats :parsedData}))
  })
  .catch(error=>{
    console.log(error)
    dispatch(createFailedRetrievingTrackingStats(true))
  })
  dispatch(createRetrievingTrackingStats(false))
}
