import styles                  from './AdAccountsPermissionsErrorStyles'
import React                   from 'react'
import { Translate }           from 'react-localize-redux'
import {
  Typography,
  Card,
  Button,
}                              from '@material-ui/core'
import {Block}                 from '@material-ui/icons'
import { push }                from "connected-react-router"
import { State }               from '../../../redux/reducers'
import { ThunkDispatch }       from 'redux-thunk'
import { DispatchableAction }  from '../../../model/constant/actions'
import { connect }             from 'react-redux'


interface AdAccountsPermissionsErrorOwnProps {
  selectedAgency: string
}

const mapStateToProps = (state:State, ownProps:AdAccountsPermissionsErrorOwnProps)=>{
  return {
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:AdAccountsPermissionsErrorOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AdAccountsPermissionsErrorOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type AdAccountsPermissionsErrorProps = ReturnType<typeof mergeProps>

const AdAccountsPermissionError = (props: AdAccountsPermissionsErrorProps) => {
  const classes = styles()

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.contentContainer}>
          <div className={classes.iconContainer}>
            <Block className={classes.icon} />
          </div>
          <div className={classes.text}>
            <Typography
              variant="h6"
            >
              <Translate id="accounts.errors.permissionDenied" />
            </Typography>
            <Typography
              variant="subtitle1"
            >
              <Translate id="accounts.errors.permissionDeniedSubtitle" />
            </Typography>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => props.push(`/Agency/${props.selectedAgency}/accounts` as any)} // "string" not assignable to "LocationDescriptorObject<unknown>"
          >
            <Translate id="accounts.errors.back" />
          </Button>
        </div>
      </Card>
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AdAccountsPermissionError)
