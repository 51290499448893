import {headstone}  from './index'
import AudienceGoal from '../../constant/AudienceGoal'

export default (accountId:number) => {
  return headstone+"/"+accountId+"/Facebook/AdTemplate"
}

export interface Success {
  id            : number
  active        : boolean
  lang          : string
  vehicleType   : string
  vehicleState  : 'USED' | 'NEW'
  template      : string
  audienceGoal  : AudienceGoal
}
