import * as React                from "react"
import {connect}                 from "react-redux"
import {State}                   from "../redux/reducers/"
import {
  createSelectDealer,
  createFailedRetrievingAdAccountsData
}                                from "../actions/creators"
import {Action}                  from "../model/constant/actions"
import {
  Switch,
  Route,
  Redirect,
}                                from "react-router-dom"
import Details                   from "../pages/Accounts/Details"
import requiresAgencyAccounts    from "../components/loaders/loadAgencyAccounts"
import { ThunkDispatch }         from "redux-thunk"
import { Account }               from "../model/accounts/Account"

interface DealerRoutingOwnProps{
  path       ?: string
  pathDealer ?: string
  accounts    : Account[]
}

const mapStateToProps = (state:State, ownProps:DealerRoutingOwnProps)=>{
  return {
    selectedDealer : state.Selection.Dealer,
    history : state.router.location.pathname,
    failed : state.AdAccountsData.Failed
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:DealerRoutingOwnProps)=>{
  return {
    selectDealer : (...args:Parameters<typeof createSelectDealer>)=>dispatch(createSelectDealer(...args)),
    setFailedToFalse : () => dispatch(createFailedRetrievingAdAccountsData(false))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:DealerRoutingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type DealerRoutingProps = ReturnType<typeof mergeProps>

const DealerRouting:React.SFC<DealerRoutingProps> = (props:DealerRoutingProps) => {
  if(props.pathDealer && parseInt(props.pathDealer) !== props.selectedDealer){
    //Redirect to accounts selection if account not valid for the selected agency
    if(!props.accounts || !props.accounts.some(account=>account.id+"" === props.pathDealer)){
      return <Redirect to={props.history.replace(`/${props.pathDealer}`,"")} />
    }
    if(props.failed) {
      props.setFailedToFalse()
    }
    props.selectDealer(parseInt(props.pathDealer))
    return null //keep path, just need to rerender with the correct account selected
  }
  const previousPath = ("/"+props.path+"/").replace("//","/")
  const selectedDealer = props.selectedDealer
  return (
    <Switch>
      <Route path={previousPath} render={(props)=><Details id={selectedDealer} />} />
    </Switch>
  )
}
DealerRouting.defaultProps = {
  path : ""
}

export default requiresAgencyAccounts({letThrough:false})(connect(mapStateToProps,mapDispatchToProps,mergeProps)(DealerRouting))
