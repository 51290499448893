import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import Strategy          from "../../../model/Facebook/Strategy"

interface FacebookStrategies {
  [agencyId:string] : {
    [accountId:number] : Strategy[]
  }
}

const Strategies = createReducer<FacebookStrategies>({},{
  [ActionTypes.GET_FACEBOOK_ACCOUNT_STRATEGIES] : (state,action) => {
    if(state[action.payload.agencyId]){
      return {
        ...state,
        [action.payload.agencyId] : {
          ...state[action.payload.agencyId],
          [action.payload.accountId] : action.payload.strategies
        }
      }
    } else {
      return {
        ...state,
        [action.payload.agencyId] : {
          [action.payload.accountId] : action.payload.strategies
        }
      }
    }
  }
})

export default Strategies
