import React    from 'react'
import {
  Slider,
  makeStyles,
  Grid,
  Input,
}               from '@material-ui/core'
import VolumeUp from '@material-ui/icons/VolumeUp'


interface SlidersOwnProps {}
interface SlidersProps extends SlidersOwnProps {}

const useStyles = makeStyles({
  root: {
    width: 400,
  },
  roo : {
    width:250
  },
  input : {
    width: 42
  },
  slider : {
    marginTop:10
  }
})

const Sliders = (props:SlidersProps) => {
  const classes = useStyles()
  const [value, setValue] = React.useState([20, 37])
  const [value2, setValue2] = React.useState(30)

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue)
  }
  const handleSliderChange = (event:any, newValue:any) => {
    setValue2(newValue)
  }

  const handleInputChange = (event:any) => {
    setValue2(event.target.value === '' ? '' : Number(event.target.value) as any)
  }
  const handleBlur = () => {
    if (value2 < 0) {
      setValue2(0)
    } else if (value2 > 100) {
      setValue2(100)
    }
  }
  return (
    <div className={classes.root}>
      <Slider />
      <Slider
        className={classes.slider}
        color="secondary"
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
      <Grid className={classes.slider} container spacing={2} alignItems="center">
        <Grid item>
          <VolumeUp />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value2 === 'number' ? value2 : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={value2}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Sliders
