const getPreviousYearMonth = (currentYearMonth: string): string => {
  const year = currentYearMonth.slice(0, 4)
  const month = currentYearMonth.slice(5)
  const newMonth = (parseInt(month)-1).toString()

  if (month === "01" || month === "1") {
    return `${(parseInt(year)-1).toString()}-12`
  }

  if (newMonth.length === 1) {
    return `${year}-0${newMonth}`
  }

  return `${year}-${newMonth}`
}
export default getPreviousYearMonth
