import {
  createRetrievingSalesCampaigns,
  StandardThunk,
  createGetSalesCampaigns,
  createFailedRetrievingSalesCampaigns
}                                          from "../creators"
import getCampaignUrl, {
  Success
}                                          from "../../model/http/Trffk-salestool/GetCampaigns"
import axios                               from 'axios'

export const getSalesCampaigns = ():StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingSalesCampaigns(true))
  axios.get<Success>(getCampaignUrl(),{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response=>{
    dispatch(createGetSalesCampaigns(response.data))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingSalesCampaigns({
      failed:true,
      message : 'Error retrieving Summary'
    }))
  })
  .then(()=>{
    dispatch(createRetrievingSalesCampaigns(false))
  })
}
