
export default function returnMonths(border: string) {
  const MONTHS = [
    {value:'01'  ,name:'Jan' ,style : { borderRight  : border,borderBottom:border}},
    {value:'02'  ,name:'Feb' ,style : { borderRight  : border,borderBottom:border}},
    {value:'03'  ,name:'Mar' ,style : { borderBottom : border}},
    {value:'04'  ,name:'Apr' ,style : { borderRight  : border,borderBottom:border}},
    {value:'05'  ,name:'May' ,style : { borderRight  : border,borderBottom:border}},
    {value:'06'  ,name:'Jun' ,style : { borderBottom : border}},
    {value:'07'  ,name:'Jul' ,style : { borderRight  : border,borderBottom:border}},
    {value:'08'  ,name:'Aug' ,style : { borderRight  : border,borderBottom:border}},
    {value:'09'  ,name:'Sep' ,style : { borderBottom : border}},
    {value:'10' ,name:'Oct' ,style : { borderRight  : border}},
    {value:'11' ,name:'Nov' ,style : { borderRight  : border}},
    {value:'12' ,name:'Dec' ,style : { }},
  ]
  return MONTHS
}
