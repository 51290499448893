import * as React                       from "react"
import {connect}                        from "react-redux"
import {ThunkDispatch}                  from "redux-thunk"
import {State}                          from "../../redux/reducers"
import {Action}                         from "../../model/constant/actions"
import Loading                          from "../loadings/CircularLoading"
import { getFacebookIdentityVariants }  from "../../actions/Headstone/FacebookIndentityVariants"
import ErrorPage                        from "../ErrorPage"
import IdentityVariants                 from "../../model/Facebook/IdentityVariants"
import { translationPaths }             from "../../translations/translations"

interface AccountsDataLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  identityVariants    : IdentityVariants[]
  loadingFeeds        : boolean
}

const mapStateToProps = (state:State, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    selectedDealer          : state.Selection.Dealer,
    identityVariants        : state.Facebook.IdentityVariants,
    failedRetrieving        : state.Facebook.Failed.identityVariants,
    retrieving              : state.Facebook.Retrieving.identityVariants,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    getFacebookIdentityVariants : (...args:Parameters<typeof getFacebookIdentityVariants>)=>dispatch(getFacebookIdentityVariants(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingFeeds = false
  if(props.retrieving && !props.letThrough){
    return <Loading loadingMessage={translationPaths.loaders.facebookIdentityVariants.loading} doTranslate={true} />
  }
  if (props.failedRetrieving) {
    return <ErrorPage errorMessage={translationPaths.loaders.facebookIdentityVariants.failed} doTranslate={true} />
  }
  if(!props.identityVariants[props.selectedDealer]){
    loadingFeeds = true
    if(!props.retrieving){
      props.getFacebookIdentityVariants(props.selectedDealer)
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.facebookIdentityVariants.loading} doTranslate={true} />
    }
  }
  return props.render({loadingFeeds, identityVariants: props.identityVariants[props.selectedDealer]})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresFacebookIdentityVariants:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresFacebookIdentityVariants
