import {combineReducers}              from "redux"
import Data                           from "./Data"
import Retrieving                     from "./Retrieving"
import Failed                         from './Failed'

const AccountsData = combineReducers({
  Data,
  Retrieving,
  Failed
})

export default AccountsData
