import {
  makeStyles,
  Theme,
  createStyles,
}                            from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
      color: theme.palette.primary.light
    },
    tabsParent: {
      maxWidth: 700,
      "& > div": {
        "& > div": {
          "& button": {
            borderRight: "1px solid rgba(0, 0, 0, .1)",
            borderBottom: "1px solid rgba(0, 0, 0, .1)",
            letterSpacing: "-0.5px",
          },
          "& :last-child": {
            borderRight: "0px solid transparent"
          }
        }
      }
    },

  })
)

export default useStyles
