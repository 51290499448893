import React                      from 'react'
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Card,
  CardHeader,
  Typography,
  Icon,
}                                 from '@material-ui/core'
import FacebookParams             from './FacebookParams'
import {connect}                  from 'react-redux'
import { State }                  from '../../../../redux/reducers'
import { ThunkDispatch }          from 'redux-thunk'
import { Action }                 from 'redux'
import {
  createFacebookStrategy,
  updateFacebookStrategy
}                                 from '../../../../actions/Headstone/FacebookStrategies'
import {
  createFacebookIdentityVariants
}                                 from '../../../../actions/Headstone/FacebookIndentityVariants'
import {
  createFacebookAdTemplates,

}                                                      from '../../../../actions/Headstone/FacebookAdTemplates'
import AdTemplate                                      from '../../../../model/Facebook/AdTemplate'
import IdentityVariants                                from '../../../../model/Facebook/IdentityVariants'
import Strategy,{
  createFacebookStrategyModel,
  FacebookStrategyModel
}                                                      from '../../../../model/Facebook/Strategy'
import {
  withLocalize,
  LocalizeContextProps
}                                                      from 'react-localize-redux'
import { translationPaths }                            from '../../../../translations/translations'
import VehicleState                                    from '../../../../model/constant/VehicleState'
import Languages                                       from '../../../../model/constant/Languages'
import AudienceGoal                                    from '../../../../model/constant/AudienceGoal'
import {useTheme}                                      from '@material-ui/core/styles'
import FacebookIcon                                    from '@material-ui/icons/Facebook'
import useStyles                                       from './FacebookActivationStyles'
import { AccountPermission }                           from '../../../../model/users/Permissions'
import { createFacebookConfiguration }                 from '../../../../actions/Headstone/FacebookConfiguration'
import { createFailedSubmittingFacebookConfiguration } from '../../../../actions/creators'
import useLoadAccountPermissions                       from '../../../../components/hooks/useLoadAccountPermissions'

interface FaceBookNewActivationOwnProps extends LocalizeContextProps {
  accountName : string
  strategies  : Strategy[]
  identityVariants : IdentityVariants[]
  adTemplates : AdTemplate[]
  vehicleState : VehicleState
  audienceGoal : AudienceGoal
  title ?: string
  accountId : number
}

interface TabPanelProps {
  children? : React.ReactNode
  index     : number
  value     : number
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`language-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

const mapStateToProps = (state:State, ownProps:FaceBookNewActivationOwnProps)=>{
  return {
    selectedDealer : state.Selection.Dealer,
    selectedAgency : state.Selection.Agency,
    permissions    : state.Login.AccountPermissions,
    failedCreating : state.Facebook.Failed.facebookConfiguration.failed
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:FaceBookNewActivationOwnProps)=>{
  return {
    createSubmitFacebookConfiguration : (...args:Parameters<typeof createFacebookConfiguration>)=>dispatch(createFacebookConfiguration(...args)),
    createFacebookStrategy : (...args:Parameters<typeof createFacebookStrategy>)=>dispatch(createFacebookStrategy(...args)),
    createFacebookAdTemplates : (...args:Parameters<typeof createFacebookAdTemplates>) => dispatch(createFacebookAdTemplates(...args)),
    createFacebookIndentityVariants : (...args:Parameters<typeof createFacebookIdentityVariants>) => dispatch(createFacebookIdentityVariants(...args)),
    updateFacebookStrategy : (...args:Parameters<typeof updateFacebookStrategy>) => dispatch(updateFacebookStrategy(...args)),
    resetFailedCreateFacebookStrategy : () => dispatch(createFailedSubmittingFacebookConfiguration({failed:false,message:''}))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:FaceBookNewActivationOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type FacebookNewActivationProps = FaceBookNewActivationOwnProps & ReturnType<typeof mergeProps>

const FacebookNewActivation = (props:FacebookNewActivationProps) => {

  const {hasPermission: facebookCreatePermission} = useLoadAccountPermissions(props.accountId,[AccountPermission.Keystone_CreateStrategy,AccountPermission.Inventory_Strategy_Edit,AccountPermission.Headstone_ChangeIdentityVariant])
  const {hasPermission: facebookUpdatePermission } = useLoadAccountPermissions(props.accountId,[AccountPermission.Inventory_Strategy_Edit])

  const classes = useStyles()
  const theme = useTheme()
  const [value,setValue] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    if(props.failedCreating) {
      props.resetFailedCreateFacebookStrategy()
    }
  }
  const frenchStrategy          = props.strategies.find(x=>x.lang==="FR" && x.audienceGoal === props.audienceGoal)
  const englishStrategy         = props.strategies.find(x=>x.lang==='EN' && x.audienceGoal === props.audienceGoal)
  const frenchIdentityVariants  = props.identityVariants.find(x=>x.lang==="FR" && x.vehicleState === props.vehicleState)
  const englishIdentityVariants = props.identityVariants.find(x=>x.lang==='EN' && x.vehicleState === props.vehicleState)
  const frenchAdTemplate        = props.adTemplates.filter(x=>x.lang==="FR")
  const englishAdTemplate       = props.adTemplates.filter(x=>x.lang==='EN')

  const [inputs,setInputs] = React.useState<FacebookStrategyModel>(createFacebookStrategyModel(props.accountName,props.vehicleState,props.audienceGoal))
  const handleRadiusChange =(lang:Languages,radius:number)=>{
    setInputs(prevState=>({
      ...prevState,
      [lang] : {
        ...prevState[lang],
        strategy : {
          ...prevState[lang]["strategy"], radius : radius
        }
      }
    }))
  }
  const handleMakesChange =(lang:Languages,makes:string[])=>{
    setInputs(prevState=>({
      ...prevState,
      [lang] : {
        ...prevState[lang],
        identityVariants : {
          ...prevState[lang]["identityVariants"] ,
          makes : makes
        }
      }
    }))
  }
  const handleNamesChange =(lang:Languages,names:string[])=>{
    setInputs(prevState=>({
      ...prevState,
      [lang] : {
        ...prevState[lang],
        identityVariants : {
          ...prevState[lang]["identityVariants"] ,
          names : names
        }
      }
    }))
  }
  const handleAdTemplateChange = (lang:Languages,adTemplate:AdTemplate) => {
    setInputs(prevState=>({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        adTemplates :
          prevState[lang]["adTemplates"].length === 0 ? [adTemplate]
          : prevState[lang]["adTemplates"].find(x=>x.id === adTemplate.id) ? prevState[lang]['adTemplates'].filter(x=>x.id!==adTemplate.id)
          : [...prevState[lang]['adTemplates'],adTemplate]
      }
    }))
  }

  const handleConfirm = (lang:Languages) => {
    const strategy = {
      active : inputs[lang].strategy.active,
      lang : inputs[lang].strategy.lang,
      radius : inputs[lang].strategy.radius,
      state : inputs[lang].strategy.state,
      audienceGoal : inputs[lang].strategy.audienceGoal
    }
    const adTemplates = inputs[lang].adTemplates
    const identityVariant = inputs[lang].identityVariants
    if(props.identityVariants.length === 0 || !props.identityVariants.some(x=>x.lang === lang && x.vehicleState === props.vehicleState) ) {
      props.createSubmitFacebookConfiguration(
        props.selectedDealer,
        {
         strategy,adTemplates,identityVariant
        }
      )
    } else {
      props.createSubmitFacebookConfiguration(
        props.selectedDealer,
        {
         strategy,adTemplates,
        }
      )
    }
  }
  const handleUpdate = (lang:Languages,checked:boolean,radius:number,state:VehicleState,audienceGoal:AudienceGoal) => {
    props.updateFacebookStrategy(
      props.selectedDealer,checked,lang,state,radius,props.selectedAgency,audienceGoal
    )
  }
  return (
    <Card className={classes.container}>
      {props.title && <CardHeader
        style={{backgroundColor:theme.palette.primary.dark}}
        title={<Typography variant="h6" className={classes.title}><FacebookIcon style={{fontSize:'1.5em',marginRight:10}} />{props.title}</Typography>}
      />}
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={
            <div className={classes.tabLabel}>
              {props.translate(translationPaths.general.english)}
              <Icon style={{marginLeft:5 }}>
                {englishStrategy?.active ? "done" : "clear"}
              </Icon>
            </div>
          }/>
          <Tab label={
            <div className={classes.tabLabel}>
              {props.translate(translationPaths.general.french)}
              {frenchStrategy?.active ? <Icon style={{marginLeft:5}}>done</Icon> : <Icon style={{marginLeft:5 }}>clear</Icon>}
            </div>
          }/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FacebookParams
          language="EN"
          vehicleState={props.vehicleState}
          strategy={englishStrategy}
          configs={inputs['EN']}
          existingAdTemplates={englishAdTemplate}
          identityVariants={englishIdentityVariants}
          handleRadiusChange={handleRadiusChange}
          handleMakesChange={handleMakesChange}
          handleNamesChange={handleNamesChange}
          handleChangeAdTemplate={handleAdTemplateChange}
          handleConfirm={handleConfirm}
          handleUpdate={handleUpdate}
          audienceGoal={props.audienceGoal}
          updatePermission={facebookUpdatePermission}
          createPermission={facebookCreatePermission}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FacebookParams
          language="FR"
          vehicleState={props.vehicleState}
          strategy={frenchStrategy}
          existingAdTemplates={frenchAdTemplate}
          identityVariants={frenchIdentityVariants}
          configs={inputs['FR']}
          handleRadiusChange={handleRadiusChange}
          handleMakesChange={handleMakesChange}
          handleNamesChange={handleNamesChange}
          handleChangeAdTemplate={handleAdTemplateChange}
          handleConfirm={handleConfirm}
          handleUpdate={handleUpdate}
          audienceGoal={props.audienceGoal}
          updatePermission={facebookUpdatePermission}
          createPermission={facebookCreatePermission}
        />
      </TabPanel>
    </Card>
  )
}

export default withLocalize(connect(mapStateToProps,mapDispatchToProps,mergeProps)(FacebookNewActivation))
