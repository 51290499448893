import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Failed {
  accountStrategies     : boolean
  identityVariants      : boolean
  adTemplates           : boolean
  businesses            : boolean
  unusedAdAccounts      : boolean
  pages                 : boolean
  facebookAdTemplates   : boolean
  facebookAdAccountId   : {failed:boolean,message:string}
  facebookConfiguration : {failed:boolean,message:string}
  accountInfo         : boolean
}

const Failed = createReducer<Failed>(
  {
    facebookAdAccountId   : {failed:false,message:''},
    facebookConfiguration : {failed:false,message:''},
    accountStrategies     : false,
    identityVariants      : false,
    adTemplates           : false,
    unusedAdAccounts      : false,
    businesses            : false,
    pages                 : false,
    facebookAdTemplates   : false,
    accountInfo           : false
  },
  {
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES] : (state,action) => ({
      ...state,
      accountStrategies : action.payload
    }),
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_IDENTITY_VARIANTS] : (state,action) => ({
      ...state,
      identityVariants : action.payload
    }),
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_AD_TEMPLATES] : (state,action) => ({
      ...state,
      adTemplates : action.payload
    }),
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_BUSINESS] : (state,action) => ({
      ...state,
      businesses : action.payload
    }),
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_PAGES] : (state,action) => ({
      ...state,
      pages : action.payload
    }),
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS] : (state,action) => ({
      ...state,
      unusedAdAccounts : action.payload
    }),
    [ActionTypes.FAILED_RETRIEVING_FACEBOOK_DEFAULT_ADSET] : (state,action) => ({
      ...state,
      facebookAdTemplates : action.payload
    }),
    [ActionTypes.FAILED_SUBMITTING_FACEBOOK_ACCOUNT_ID] : (state,action) => ({
      ...state,
      facebookAdAccountId : {failed:action.payload.failed,message:action.payload.message}
    }),
    [ActionTypes.FAILED_SUBMITTING_FACEBOOK_CONFIGURATION] : (state,action) => ({
      ...state,
      facebookConfiguration : {failed:action.payload.failed,message:action.payload.message}
    }),
    [ActionTypes.FAILED_RETRIEVING_ACCOUNT_INFO_FIRESTONE] : (state,action) => ({
    ...state,
    accountInfo : action.payload
  }),
  }
)

export default Failed
