import {
  StandardThunk,
  createLogin,
  createFailedLogin,
  createLoggingIn,
}                                      from "../creators"
import axios                           from "axios"
import Cookies                         from "js-cookie"
import Login, {Success}                from "../../model/http/Auth2/Login"
import RememberMe, {RememberMeSuccess} from "../../model/http/Auth2/RememberMe"
import { getSystemsPermissions } from "./Permissions"

const IS_DEV_ENV = process.env.NODE_ENV === 'development'

export const login = (email:string, password:string, rememberMe:boolean):StandardThunk => (dispatch) => {
  dispatch(createLoggingIn(true))
  const payload = {
    email,
    password,
    rememberMe,
  }
  axios.post<Success>(Login, payload)
  .then((response)=>{
    if(rememberMe && response.data.rememberMeToken){
      Cookies.set("email", email,{sameSite:'strict',secure:!IS_DEV_ENV})
      Cookies.set("UID", response.data.rememberMeToken,{sameSite:'strict',secure:!IS_DEV_ENV})
    }
    dispatch(createLogin(response.data))
  })
  .catch(error=>{
    dispatch(createFailedLogin(error.response.data.error))
  })
  .then(()=>{
    dispatch(createLoggingIn(false))
    dispatch(getSystemsPermissions())
  })
}

export const rememberMeLogin = (email:string, code:string):StandardThunk => (dispatch) => {
  dispatch(createLoggingIn(true))
  const payload = {
    email,
    code,
  }
  axios.post<RememberMeSuccess>(RememberMe, payload)
  .then((response)=>{
    dispatch(createLogin(response.data))
  })
  .catch(error=>{
    // If the remember fails, forget the cookies so to not spam the endpoint
    Cookies.remove("email")
    Cookies.remove("UID")
    dispatch(createFailedLogin("Failed to remember you: "+error.response.data.error))
  })
  .then(()=>{
    dispatch(createLoggingIn(false))
    dispatch(getSystemsPermissions())
  })
}
