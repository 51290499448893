import React                 from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  Typography
}                            from '@material-ui/core'
import InventoryRemovedGraph from './graphs/InventoryRemovedGraph'
import InventoryStats        from '../../model/http/Reporting/InventoryStats'
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                            from 'react-localize-redux'
import {translationPaths}    from '../../translations/translations'

interface InventoryRemovedOwnProps extends LocalizeContextProps{
  stats : InventoryStats[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inventoryRemoved : {
      padding : 30,
      marginBottom : 30
    },
    header : {
      display : 'flex'
    },
    unavailable : {
      height : 300,
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
    }
  })
)

interface DataByDate {
  inventory : number
  dealers : Set<number>
}

interface Data {
  [date:string] : DataByDate
}

const InventoryRemoved = (props:InventoryRemovedOwnProps) => {

  const temp:Data = props.stats.reduce<Data>((acc,curr)=>{
    if(!acc[curr.date]){
      acc[curr.date] = {
        inventory : 0,
        dealers : new Set()
      }
    }
    acc[curr.date].inventory += curr.numberOfSoldVehicles
    acc[curr.date].dealers.add(curr.accountId)
    return acc
  },{})

  const data = Object.keys(temp).map(datum=>{
    return {date:datum,inventory:temp[datum].inventory,dealersCount:temp[datum].dealers.size}
  })

  const classes = useStyles()
  return (
    <Card className={classes.inventoryRemoved}>
      <div className={classes.header}>
        <Typography variant="h6">{props.translate(translationPaths.marketInsights.inventoryRemovedGraph.title)}</Typography>
      </div>
      {
        props.stats.length === 0
        ? <div className={classes.unavailable}><Typography><Translate id={translationPaths.marketInsights.unavailableData} /></Typography></div>
        : <InventoryRemovedGraph data={data} dealersColor="#cc8400" inventoryColor="#4B086B" />
      }
    </Card>
  )
}

export default withLocalize(InventoryRemoved)
