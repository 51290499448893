import baseUrl from './'

export default baseUrl+"/Agencies"

export type Success = Array<{
  id                  : string
  name                : string
  rootAccountGroupId  : string
  rootuserGroupId     : string
}>

export interface Failure {

}
