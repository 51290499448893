import React                        from 'react'
import {
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Select,
  MenuItem,
  TextField,
  Button,
  Icon,
  ClickAwayListener
}                                   from '@material-ui/core'
import useStyles, {
  StyledTableRow,
  StyledTableCell
}                                   from './ProductsStyles'
import SummaryTemplate, {
  ProfitCenter, Product, SummaryProduct, Breakdown,
}                                   from '../../../model/budgetEstimator/Summary'
import clsx                         from 'clsx'
import { formatMoney }              from '../../../utils/MoneyFormatting'
import { Channel }                  from '../../../model/constant/channels'
import SalesProduct                 from '../../../model/budgetEstimator/SalesProduct'
import {
  handleProductAllocationChange,
  handleAddProduct,
  handleDeleteProduct
}                                   from '../../../model/budgetEstimator/Handlers/ProductsHandlers'

interface ProductsOwnProps {
  products                        : SalesProduct[]
  summary                         : SummaryTemplate
  disabled                        : boolean
  breakdown                       : Breakdown
  handleUpdateSummary             : (summary:SummaryTemplate)=>void
  handleGetBreakdown              : (summary:SummaryTemplate)=>void
  handleCreatingProductError      : (creating:boolean) => void
  totalNewAllocation              : number
  totalUsedAllocation             : number
  totalAdditionalBudgetAllocation : number
}


const Products = (props:ProductsOwnProps) => {
  const classes = useStyles()
  const {summary} = {...props}
  const availableChannels = Array.from(new Set(props.products.map(product=>product.channel)))
  const selectedProductsId = summary.products.map(p=>p.id)
  //State
  const [creatingProduct,setCreatingProduct] = React.useState<boolean>(false)
  const [newProductChannel,setnewProductChannel] = React.useState<Channel>(availableChannels[0])
  const [newProductAllocation,setNewProductionAllocation] = React.useState<number>(0)
  const [newProductState,setNewProductState] = React.useState<ProfitCenter|null>(null)
  const [newProductId,setNewProductId] = React.useState<number>(-1)
  const productList = props.products.filter(product=>!selectedProductsId.includes(product.id))
  const newProductAllocationDollars =
    newProductState === 'NEW' && newProductAllocation ?
    formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.NEW * newProductAllocation / 100)
  : newProductState === 'USED' && newProductAllocation ?
    formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.USED * newProductAllocation / 100)
  : newProductState === "NONE" && newProductAllocation ?
    formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.NONE * newProductAllocation / 100)
  : ''
  const createNewSummaryProduct = (productId:number,allocation:number):SummaryProduct => {
    const product = productList.find(p=>p.id === productId) as SummaryProduct
    return {...product,allocation}
  }

  //Handlers
  const onAllocationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,productId:number) => {
    const allocation = parseFloat(event.target.value) || 0
    const newSummary = handleProductAllocationChange(allocation,productId,summary)
    props.handleUpdateSummary(newSummary)
    props.handleGetBreakdown(newSummary)
  }
  const onProductAdd = () => {
    const newProduct = createNewSummaryProduct(newProductId,newProductAllocation)
    const newSummary = handleAddProduct(newProduct,summary)
    props.handleUpdateSummary(newSummary)
    props.handleGetBreakdown(newSummary)
    resetNewProduct()
  }
  const onProductDelete = (product:Product) => {
    const newSummary = handleDeleteProduct(product.id,summary)
    props.handleUpdateSummary(newSummary)
    props.handleGetBreakdown(newSummary)
  }
  //NEW PRODUCT HANDLERS
  const newProductChannelOnChange = (event:React.ChangeEvent<{ value: unknown }>) => {
    const channel = event.target.value as Channel
    if(channel !== newProductChannel) {
      setnewProductChannel(channel)
      setNewProductId(-1)
      setNewProductState(null)
      setNewProductionAllocation(0)
    }
  }
  const newProductDescriptionOnChange = (event:React.ChangeEvent<{ value: unknown }>) => {
    const product = productList.find(p=>parseInt(event.target.value as string) === p.id)
    if(product) {
      setNewProductState(product.state)
      setNewProductId(product.id)
    }
  }
  const newProductAllocationOnChange =(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const allocation = parseFloat(event.target.value)
    setNewProductionAllocation(allocation)
  }
  const resetNewProduct = () => {
    setnewProductChannel(availableChannels[0])
    setNewProductId(-1)
    setNewProductState(null)
    setNewProductionAllocation(0)
    setCreatingProduct(false)
    props.handleCreatingProductError(false)
  }

  //ERRORS
  const allocationError:Record<ProfitCenter,boolean> = {
    NEW : props.breakdown.amountsSummary.budgetByProfitCenter.NEW ? props.totalNewAllocation > 100 : props.totalNewAllocation > 0,
    USED : props.breakdown.amountsSummary.budgetByProfitCenter.USED ? props.totalUsedAllocation > 100 : props.totalUsedAllocation > 0,
    NONE : props.breakdown.amountsSummary.budgetByProfitCenter.NONE ? props.totalAdditionalBudgetAllocation > 100 : props.totalAdditionalBudgetAllocation > 0,
  }

  return (
    <Card className={classes.products}>
      <div className={classes.header}>
        <Typography variant="button" color="primary" className={classes.title}>Products</Typography>
        <Typography variant="caption" className={classes.info} >Make sure your percentage reach 100% on allocated budgets before proceeding to next step</Typography>
      </div>
      <TableContainer style={{borderRadius:5}}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{width:'33%'}}>Budget type</StyledTableCell>
              <StyledTableCell style={{width:'33%'}}>Estimated Budget</StyledTableCell>
              <StyledTableCell style={{width:'33%'}}>Allocation %</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell style={{width:'33%'}}>New</StyledTableCell>
              <StyledTableCell style={{width:'33%'}}><Button style={{marginRight:15,marginLeft:15}} size="large" variant="outlined">{formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.NEW)}</Button></StyledTableCell>
              <StyledTableCell style={{width:'33%'}}><Button color={allocationError.NEW ? "secondary" : 'primary'} style={{marginRight:15,marginLeft:15}} size="large" variant="outlined">{props.totalNewAllocation} %</Button></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell style={{width:'33%'}}>Used</StyledTableCell>
              <StyledTableCell style={{width:'33%'}}><Button style={{marginRight:15,marginLeft:15}} size="large" variant="outlined">{formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.USED)}</Button></StyledTableCell>
              <StyledTableCell style={{width:'33%'}}><Button color={allocationError.USED ? "secondary" : 'primary'} style={{marginRight:15,marginLeft:15}} size="large" variant="outlined">{props.totalUsedAllocation} %</Button></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell style={{width:'33%'}}>Others</StyledTableCell>
              <StyledTableCell style={{width:'33%'}}><Button style={{marginRight:15,marginLeft:15}} size="large" variant="outlined">{formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.NONE)}</Button></StyledTableCell>
              <StyledTableCell style={{width:'33%'}}><Button color={allocationError.NONE ? "secondary" : 'primary'} style={{marginRight:15,marginLeft:15}} size="large" variant="outlined">{props.totalAdditionalBudgetAllocation} %</Button></StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className={classes.channelCell} align="center">Channel</StyledTableCell>
              <StyledTableCell className={classes.descriptionCell} align="center">Product Description</StyledTableCell>
              <StyledTableCell className={classes.allocationCell} align="center">Allocation %</StyledTableCell>
              <StyledTableCell className={classes.valueCell} align="center">Value $</StyledTableCell>
              <StyledTableCell className={classes.actionCell} align="center"></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {props.summary.products.map(product=>( <StyledTableRow className={clsx({[classes.error]:allocationError[product.state]})} key={product.id}>
              <StyledTableCell className={classes.channelCell} align="center">
               {product.channel.toUpperCase()}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.descriptionCell}>
                {product.description.toUpperCase()}
              </StyledTableCell>
              <StyledTableCell className={classes.allocationCell} align="center">
                <TextField
                  style={{width:60}}
                  value={product.allocation || ''}
                  type='numeric'
                  variant="outlined"
                  onChange={(event)=>onAllocationChange(event,product.id)}
                />
              </StyledTableCell>
              <StyledTableCell className={classes.valueCell} align="center">{
                product.state === 'NEW' && product.allocation ?
                  formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.NEW * product.allocation / 100)
                : product.state === 'USED' && product.allocation ?
                  formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.USED * product.allocation / 100)
                : product.state === "NONE" && product.allocation ?
                  formatMoney(props.breakdown.amountsSummary.budgetByProfitCenter.NONE * product.allocation / 100)
                : ''
              }</StyledTableCell>
              <StyledTableCell align="center" className={classes.actionCell}>
                <Button
                  disabled={props.disabled}
                  color="secondary"
                  onClick={(event)=>{onProductDelete(product)}}
                >
                  <Icon style={{marginRight :10,marginBottom:2}}>delete</Icon>
                </Button>
              </StyledTableCell>
            </StyledTableRow>))}
            {creatingProduct && (
              <ClickAwayListener onClickAway={()=>{resetNewProduct()}}>
                <StyledTableRow>
                  <StyledTableCell className={classes.channelCell} align="center">
                  <Select
                    variant="outlined"
                    value={newProductChannel}
                    onChange={newProductChannelOnChange}
                    MenuProps={{disablePortal:true}}
                  >
                    {availableChannels.map((channel,i)=><MenuItem key={i} value={channel}>{channel.toUpperCase()}</MenuItem>)}
                  </Select>
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.descriptionCell}>
                    <Select
                      variant="outlined"
                      value={newProductId}
                      displayEmpty
                      onChange={newProductDescriptionOnChange}
                      MenuProps={{disablePortal:true}}
                    >
                      <MenuItem disabled value={-1}>Select a product..</MenuItem>
                      {productList.map((p,i)=>{
                        if(newProductChannel===p.channel){
                          return <MenuItem key={i} value={p.id}>{p.description.toUpperCase()}</MenuItem>
                        }
                        return null
                      })}
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell className={classes.allocationCell} align="center">
                    <TextField
                      style={{width:60}}
                      value={newProductAllocation || ''}
                      type='numeric'
                      variant="outlined"
                      onChange={(event)=>newProductAllocationOnChange(event)}
                      SelectProps={{
                        MenuProps: { disablePortal: true }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={classes.valueCell} align="center">
                    {newProductAllocationDollars}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.actionCell}>
                    <Button
                      variant="contained"
                      disabled={newProductId === -1 || !newProductAllocation}
                      color="primary"
                      onClick={onProductAdd}
                    >
                      Confirm
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              </ClickAwayListener>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!creatingProduct && (
        <div className={classes.button}>
          <Button disabled={props.disabled} onClick={()=>{setCreatingProduct(true);props.handleCreatingProductError(true)}} className={classes.addButton} size="large" color="primary" variant="contained" >
            <Icon style={{marginRight:10}}>add</Icon>
            Add a product
          </Button>
        </div>
      )}
      {Object.values(allocationError).find(x=>true) && <Typography style={{marginTop:20}} variant="caption" color="secondary">Make sure you have an allocated budget and total allocation is 100%</Typography>}
    </Card>
  )
}

export default Products
