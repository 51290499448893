import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import {
  red,
  green,
}                       from '@material-ui/core/colors'

interface PaletteIntention {
  main          : string
  light        ?: string
  dark         ?: string
  contrastText ?: string
}

export interface ThemeParams {
  primary   : PaletteIntention
  secondary : PaletteIntention
  error     : PaletteIntention
  warning   : PaletteIntention
  info      : PaletteIntention
  success   : PaletteIntention
  type     ?: 'light' | 'dark'
}

export const pacingReportColorsPalette = ["#2978a0","#0095a2","#00aa74","#8cb429","#ffa600"]

export const gaugeColors = {
  bad    : red[500],
  medium : '#ffa600',
  good : '#00aa74',
  veryGood : green[700]
}

export const commanderTheme = {
  red          : '#ED1C24',
  orange       : '#F7941D',
}

export const spectrumColors = ['#1EC6E8', '#00BBFC', '#47ACFF', '#8097FF', '#B27CFB',]

export const diffColors = {
  good : '#8de13a',
  bad  : '#e13a3a',
}

const theme = (params:ThemeParams):ThemeOptions => ({
  palette : {
    primary   : params.primary,
    secondary : params.secondary,
    error     : params.error,
    warning   : params.warning,
    success   : params.success,
    info      : params.info
  },
  spacing : 6,
  overrides : {
    MuiAppBar : {
      colorDefault : {
        backgroundColor : '#FFFFFF'
      }
    },
    MuiCard : {
      root :{
        margin : "10px",
        borderRadius : '10px'
      }
    },
    MuiPaper : {
      root : {
        margin:0,
      }
    },
    MuiButton : {
      contained : {
        color : params.primary.main,
        backgroundColor : params.primary.contrastText,
        '&:hover': {
          backgroundColor: params.primary.main,
          color: params.primary.contrastText
       },
      }
    },
    MuiFab : {
      primary : {
        color : params.primary.main,
        backgroundColor : params.primary.contrastText,
        '&:hover' : {
          backgroundColor : params.primary.main,
          color : params.primary.contrastText
        }
      }
    },
    MuiDrawer : {
      paper : {backgroundColor : params.primary.contrastText}
    },
  },
  typography: {
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    body1 : {
      fontFamily : ['Open Sans','sans-serif'].join(','),
      color  : '#454545',
      letterSpacing : 1
    },
    button : {
      fontFamily : ['Open Sans','sans-serif'].join(','),
      letterSpacing : 1,
      fontWeight : 900,
    },
    body2 : {
      fontFamily : ['Open Sans','sans-serif'].join(','),
      color  : '#454545',
      letterSpacing : 1
    },
    subtitle1 : {
      fontFamily : ['Open Sans','sans-serif'].join(','),
      color  : '#454545',
      letterSpacing : 1,
      fontStyle : 'italic'
    },
    subtitle2 : {
      fontFamily : ['Open Sans','sans-serif'].join(','),
      color  : '#454545',
      letterSpacing : 1,
      fontStyle : 'italic'
    },
    h1 : {
      fontFamily : ['Roboto'].join(','),
      fontSize : '4.5rem',
      color : '#565656'
    },
    h2 : {
      fontFamily : ['Roboto'].join(','),
      fontSize : '4rem',
      color : '#565656'
    },
    h3 : {
      fontFamily : ['Roboto'].join(','),
      fontSize : '3.5rem',
      color : '#565656'
    },
    h4 : {
      fontFamily : ['Roboto'].join(','),
      fontSize : '3rem',
      color : '#565656'
    },
    h5 : {
      fontFamily : ['Roboto'].join(','),
      fontSize : '2.5rem',
      color : '#565656'
    },
    h6 : {
      fontFamily : ['Roboto'].join(','),
      fontSize : '2rem',
      color : '#565656'
    }
   },
})

export default theme
