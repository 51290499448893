import SummaryTemplate from "../Summary"

export const handleUpdateSummaryAccountId = (accountId:string,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    salesforceAccountId:accountId
  }
}
export const handleUpdateSummaryContactId = (contactId:string,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    contactId
  }
}
export const handleUpdateSummaryDealerName = (dealerName:string,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    dealerName
  }
}

