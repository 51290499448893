import {
  StandardThunk,
  createRetrievingFacebookAdTemplates,
  createGetFacebookAdTemplates,
  createFailedRetrievingFacebookAdTemplates,
  createCreatingFacebookAdTemplates,
  createFailedCreatingFacebookAdTemplates,
  createUpdatingFacebookAdTemplates,
  createFailedUpdatingFacebookAdTemplates
}                                                     from "../creators"
import AdTemplateUrl,{Success as FacebookAdTemplate}  from "../../model/http/Headstone/GetFacebookAdTemplates"
import axios                                          from "axios"
import AdTemplate                                     from "../../model/Facebook/AdTemplate"

export const getFacebookAdTemplates = (accountId:number):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookAdTemplates(true))
  axios.get<FacebookAdTemplate[]>(AdTemplateUrl(accountId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookAdTemplates({
      accountId,
      adTemplates : response.data,
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookAdTemplates(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookAdTemplates(false))
  })
}

export const createFacebookAdTemplates = (accountId:number,adTemplates:AdTemplate[]):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createCreatingFacebookAdTemplates(true))
  axios.post(AdTemplateUrl(accountId),adTemplates,{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookAdTemplates(accountId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedCreatingFacebookAdTemplates(true))
  })
  .then(()=>{
    createCreatingFacebookAdTemplates(false)
  })
}

export const updateFacebookAdTemplates = (accountId:number,adTemplates:AdTemplate[]):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createUpdatingFacebookAdTemplates(true))
  axios.put(AdTemplateUrl(accountId),adTemplates,{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookAdTemplates(accountId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedUpdatingFacebookAdTemplates(true))
  })
  .then(()=>{
    dispatch(createUpdatingFacebookAdTemplates(false))
  })
}
