import {
  Theme,
  createStyles
}                         from "@material-ui/core"

const styles            = (theme: Theme) =>
  createStyles({
    header : {
      backgroundColor   : theme.palette.primary.main,
      color             : theme.palette.primary.contrastText,
    },
    card: {
      width             : "100vw",
      height            : "100vh",
      display           : "flex",
      flexDirection     : "column",
      justifyContent    : "center",
      margin            : 0,
      borderRadius      : 0,
      backgroundColor   : "white"
    },
    content : {
      height            : 500,
      width             : 700,
      marginLeft        : "auto",
      marginRight       : "auto",
      padding           : 80,
      paddingTop        : 50,
      borderRadius      : 10,
      boxShadow         : "0",
      backgroundColor   : "white",
    },
    title: {
      marginBottom      : 30,
    },
    buttonContainer: {
      width             : "100%",
      display           : "flex",
      justifyContent    : "flex-end",
      position          : "fixed",
      top               : 50,
      right             : 50,
    },
    button: {
      backgroundColor   : "rgba(0, 0, 0, .1)",
      borderRadius      : 999,
      color             : "gray",
      height            : 40,
      width             : 40,
    },
    list: {
      height            : 350,
      overflow          : "auto",
    },
    listItem : {
      textAlign         : 'center',
      borderRadius      : 10,
      '&:hover' : {
        backgroundColor : theme.palette.common.white
      }
    },
    focused : {
      backgroundColor   : theme.palette.primary.contrastText,
      color             : theme.palette.primary.main,
      border            : `1px solid ${theme.palette.primary.main}`
    },
  })

export default styles
