import * as React                       from "react"
import {connect}                        from "react-redux"
import {ThunkDispatch}                  from "redux-thunk"
import {State}                          from "../../redux/reducers"
import {Action}                         from "../../model/constant/actions"
import Loading                          from "../loadings/CircularLoading"
import { getFacebookAdTemplates }       from "../../actions/Headstone/FacebookAdTemplates"
import ErrorPage                        from "../ErrorPage"
import AdTemplate                       from "../../model/Facebook/AdTemplate"
import { translationPaths }             from "../../translations/translations"

interface AccountsDataLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  adTemplates    : AdTemplate[]
  loadingFeeds   : boolean
}

const mapStateToProps = (state:State, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    selectedDealer          : state.Selection.Dealer,
    adTemplates             : state.Facebook.AdTemplates,
    failedRetrieving        : state.Facebook.Failed.adTemplates,
    retrieving              : state.Facebook.Retrieving.adTemplates,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    getFacebookAdTemplates : (...args:Parameters<typeof getFacebookAdTemplates>)=>dispatch(getFacebookAdTemplates(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>

const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingFeeds = false
  if(props.retrieving && !props.letThrough){
    return <Loading loadingMessage={translationPaths.loaders.facebookAdTemplates.loading} doTranslate={true} />
  }
  if (props.failedRetrieving) {
    return <ErrorPage errorMessage={translationPaths.loaders.facebookAdTemplates.failed} doTranslate={true} />
  }
  if(!props.adTemplates[props.selectedDealer]){
    loadingFeeds = true
    if(!props.retrieving){
      props.getFacebookAdTemplates(props.selectedDealer)
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.facebookAdTemplates.loading} doTranslate={true} />
    }
  }
  return props.render({loadingFeeds, adTemplates: props.adTemplates[props.selectedDealer]})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresFacebookAdTemplates:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresFacebookAdTemplates
