import styles                  from './DealerTableStyles'
import React                   from 'react'
import {
  Typography,
  useTheme,
  Tooltip,
  makeStyles,
  Theme,
  createStyles,
  Button,
}                              from '@material-ui/core'
import Table                   from 'material-table'
import {
  StatPerAccount,
  StatPerChannelPerAccount
}                              from '../../../model/store/statistics/PacingStats'
import BulletGraphMui          from '../../../components/pacing/BulletGraphMui'
import PacingPerChannelHover   from '../../../components/pacing/PacingPerChannel/perChannelHover'
import { commanderTheme }      from '../../../Theme/Theme'
import {CSVLink}               from 'react-csv'
import { SaveAlt }             from '@material-ui/icons'
import {
  withLocalize,
  LocalizeContextProps,
}                              from 'react-localize-redux'
import {translationPaths}      from '../../../translations/translations'

interface DealerTableProps extends LocalizeContextProps {
  data                      : StatPerAccount[]
  statsPerChannelPerAccount : StatPerChannelPerAccount[]
}

const tooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: 'transparent',
    }
  })
)


// NOTE: As of 03/13/2020 there is no support for a summary row.
// Check back here for updates: https://github.com/mbrn/material-table/issues/27
const DealerTable = (props: DealerTableProps) => {
  const totalBudget = props.data.reduce((acc, entry) => acc + entry.budget, 0)
  const totalSpent = props.data.reduce((acc, entry) => acc + entry.spent, 0)
  const theme = useTheme()
  const classes = styles()
  const tooltipClasses = tooltipStyles()
  const cellPadding = 5

  const GraphTooltip = (accountId: {accountId: number}) => {
    const accountData = props.statsPerChannelPerAccount.find(obj => obj.accountId === accountId.accountId)
    return (
      <div>
        {accountData && <PacingPerChannelHover data={accountData.channels} />}
      </div>
    )
  }
  const csvData:object[] = []
  props.data.forEach(stat=>{
    const pacingPerChannel:StatPerChannelPerAccount | undefined = props.statsPerChannelPerAccount.find(x=>stat.accountId===x.accountId)
    if(pacingPerChannel) {
      const temp = pacingPerChannel.channels.reduce((acc,curr)=>{
        if(curr.channel === 'Search') {
          acc.searchBudget = parseFloat(curr.budget.toFixed(2))
          acc.searchSpent = parseFloat(curr.spent.toFixed(2))
          acc.searchPacing = parseFloat(curr.pacing.toFixed(2))
        }
        if(curr.channel === 'Display') {
          acc.displayBudget = parseFloat(curr.budget.toFixed(2))
          acc.displaySpent = parseFloat(curr.spent.toFixed(2))
          acc.displayPacing = parseFloat(curr.pacing.toFixed(2))
        }
        if(curr.channel === 'Social') {
          acc.socialBudget = parseFloat(curr.budget.toFixed(2))
          acc.socialSpent = parseFloat(curr.spent.toFixed(2))
          acc.socialPacing = parseFloat(curr.pacing.toFixed(2))
        }
        if(curr.channel === 'Video') {
          acc.videoBudget = parseFloat(curr.budget.toFixed(2))
          acc.videoSpent = parseFloat(curr.spent.toFixed(2))
          acc.videoPacing = parseFloat(curr.pacing.toFixed(2))
        }
        if(curr.channel === 'Geofencing') {
          acc.geoFencingBudget = parseFloat(curr.budget.toFixed(2))
          acc.geoFencingSpent = parseFloat(curr.spent.toFixed(2))
          acc.geoFencingPacing = parseFloat(curr.pacing.toFixed(2))
        }
        return acc
      },{
          searchBudget      : 0,
          searchSpent       : 0,
          searchPacing      : 0,
          displayBudget     : 0,
          displaySpent      : 0,
          displayPacing     : 0,
          socialBudget      : 0,
          socialSpent       : 0,
          socialPacing      : 0,
          videoBudget       : 0,
          videoSpent        : 0,
          videoPacing       : 0,
          geoFencingBudget  : 0,
          geoFencingSpent   : 0,
          geoFencingPacing  : 0,
        }
      )
      csvData.push({...stat,...temp,budget:stat.budget.toFixed(2),pacing:stat.pacing.toFixed(2),spent:stat.spent.toFixed(2)})
    }
  })
  return (
    <div className={classes.pacingTable}>
      <Table
        // Replaces the <Paper /> container that <Table /> creates
        components={{
          Container: props => <div>{props.children}</div>,
          Action : ownProps => (
            <Tooltip title={props.translate(translationPaths.pacing.pacingTable.exportAsCsv)}>
              <Button size='large'>
                <CSVLink filename="dealers.csv" style={{color:'gray',display:'flex',justifyContent:'space-between'}} data={csvData}>
                  <SaveAlt />
                </CSVLink>
              </Button>
            </Tooltip>
            )
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'exportCsv',
            isFreeAction: true,
            onClick: (event) => {},
          }
        ]}
        title={<></>}
        columns={[
          {
            headerStyle: {minWidth: 300,},
            title : props.translate(translationPaths.pacingOverview.table.DEALERS) as string,
            field:'dealer',
            cellStyle: { borderBottom: "0px solid transparent", paddingTop: cellPadding,paddingBottom: cellPadding,},
            render:(params)=>(<div className={classes.dealerName}>{params.dealer}</div>)
          },
          {
            title: props.translate(translationPaths.pacingOverview.table.BUDGET) as string,
            field:'budget',
            type:'currency',
            cellStyle: {textAlign: "left", borderBottom: "0px solid transparent", paddingTop: cellPadding,paddingBottom: cellPadding,}
          },
          {
            title: props.translate(translationPaths.pacingOverview.table.SPENT) as string,
            field:'spent',
            type:'currency',
            cellStyle: {textAlign: "left", borderBottom: "0px solid transparent",paddingTop: cellPadding,paddingBottom: cellPadding,}
          },
          {
            title: props.translate(translationPaths.pacingOverview.table.PACING) as string,
            field:'pacing',
            cellStyle: { borderBottom: "0px solid transparent", paddingTop: cellPadding,paddingBottom: cellPadding,margin: "auto"},
            render:(params)=>{
              // The div below is necessary for hovering - has to do with refs
              return (
                <Tooltip
                  classes={tooltipClasses}
                  placement="top"
                  title={<GraphTooltip accountId={params.accountId} />}
                >
                  <div className={classes.cursor}><BulletGraphMui color={commanderTheme.orange} value={parseFloat(params.pacing.toFixed(2))} /></div>
                </Tooltip>
              )
            }
          },
        ]}
        data={props.data}
        options={{
          emptyRowsWhenPaging: false,
          detailPanelColumnAlignment: "left",
          headerStyle :{
            color : theme.palette.primary.light,
            fontSize: 18,
            fontWeight: "bold",
          },
          rowStyle:{
            fontWeight: "bold",
            color: theme.palette.primary.light,
            fontSize: 500,
            paddingTop: 0,
            paddingBottom: 0,
          },
          searchFieldStyle: {
            border: `1px solid ${theme.palette.primary.light}`,
            width: 325,
            borderRadius: 10,
            padding: 10,
            paddingLeft: 22,
            marginRight: 15,
            fontWeight: "bold",
          },
          showTitle : true,
          filterCellStyle : {
            backgroundColor : "#ccc"
          },
          initialPage : 0,
          pageSize : 25,
          pageSizeOptions: [10, 25, 50],
          search: true,
          draggable : false,
        }}
        localization={{
          pagination: {
            labelRowsSelect: props.translate(translationPaths.pacingOverview.table.rows) as string,
            labelDisplayedRows: `{from}-{to} ${props.translate(translationPaths.pacingOverview.table.of) as string} {count}`
          },
          toolbar: {
            searchPlaceholder: `${props.translate(translationPaths.pacingOverview.table.search)}`
          }
        }}
      />
      <div className={classes.total}>
        <Typography className={classes.totalChild}>{props.translate(translationPaths.pacingOverview.table.TOTAL)}</Typography>
        <Typography className={classes.totalChild}>${totalBudget.toLocaleString('en-CA', {maximumFractionDigits:2})}</Typography>
        <Typography className={classes.totalChild}>${totalSpent.toLocaleString('en-CA', {maximumFractionDigits:2})}</Typography>
        <div className={classes.totalChild} />
      </div>
    </div>
  )
}

export default withLocalize(DealerTable)
