import { createReducer } from "../../../utils/Reducers"
import ActionTypes                            from "../../../model/constant/actions"
import { SummaryWithPresentationByAccountId } from "../../../model/budgetEstimator/Summary"

const Summary = createReducer<Record<string,SummaryWithPresentationByAccountId>>({},{
  [ActionTypes.GET_SUMMARY] : (state,action) => {
    return {
      ...state,
      [action.payload.agencyId] : action.payload.contracts
    }
  }
})

export default Summary
