import * as React           from "react"
import {connect}            from "react-redux"
import {ThunkDispatch}      from "redux-thunk"
import {State}              from "../../redux/reducers"
import {Action}             from "../../model/constant/actions"
import Loading              from "../loadings/CircularLoading"
import {getPacingStats}     from "../../actions/Reporting/pacing"
import moment               from 'moment'
import AgencySelectionModal from "../../pages/AgencySelection/AgencySelectionModal"
import getPreviousYearMonth from "../../utils/getPreviousYearMonth"
import { translationPaths } from "../../translations/translations"

interface PacingStatsOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}

const mapStateToProps = (state:State, ownProps:PacingStatsOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    reportingStats          : state.Statistics.PacingStats,
    retrievingPacingReport  : state.Statistics.RetrievingPacingStats,
    loadedMonths            : state.Statistics.CurrentLoadedMonths
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:PacingStatsOwnProps)=>{
  return {
    getPacingStats : (...args:Parameters<typeof getPacingStats>)=>dispatch(getPacingStats(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:PacingStatsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingPacingStats = false
  if(props.selectedAgency === null){
    return (
      <AgencySelectionModal open={true} />
    )
  }
  if(!props.reportingStats[props.selectedAgency]){
    loadingPacingStats = true
    if(!props.retrievingPacingReport){
      const yearMonth = moment().format('YYYY-MM')
      const previousYearMonth = getPreviousYearMonth(yearMonth)

      props.getPacingStats(props.selectedAgency,[yearMonth, previousYearMonth])
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.pacingStats.loading} doTranslate={true} />
    }
  }
  return props.render({loadingPacingStats, pacingStats: props.reportingStats[props.selectedAgency]})
})

interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  pacingStats : State["Statistics"]["PacingStats"][0]
  loadingPacingStats : boolean
}

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresPacingStats:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresPacingStats
