import baseUrl from './'

export default (agencyId:string) => {
  return baseUrl+"/Agencies/"+agencyId+"/Accounts"
}

export type Success = Array<{
  id                : number,
  name              : string,
  currency          : string
  timezone          : string
  website           : string
  street            : string
  city              : string
  province          : string
  country           : string
  zipCode           : string
  latitude          : number
  longitude         : number
  AWCustomerId      : number
  bingAccountId     : number
  facebookAccountId : number
}>
