import { createStyles }   from "@material-ui/styles"
import {
  makeStyles,
  Theme
}                         from "@material-ui/core"

const useStyles = makeStyles((theme:Theme)=>(
  createStyles({
    pacingPerChannel : {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      height: 180,
      width: 350,
    },
    legends : {
      display : 'flex',
      alignItems : 'center',
      marginBottom : 20,
      marginLeft : 20,
      marginRight : 40,
      color: theme.palette.grey[500],
      letterSpacing: 0,
      width: 100,
      fontSize: 20,
    },
    percentages: {
      display : 'flex',
      alignItems : 'center',
      marginBottom : 20,
      marginLeft : 40,
      marginRight : 20,
      color: theme.palette.grey[500],
      letterSpacing: 0,
      fontSize: 20,
      width: 50,
    },
    graphs: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      transform: 'scale(.6)',
      marginTop: 10,
    },
    rowContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 5fr 1fr',
      width: 500,
    },
  })
))

export default useStyles
