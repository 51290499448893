import {
  createResettingPassword,
  createStoreMessage,
}                                      from "../creators"
import axios, {
  AxiosError,
}                                      from "axios"
import auth                            from "../../model/http/Auth"
import {Success, Failure}              from "../../model/http/Auth/Login"
import { Action }                      from "../../model/constant/actions"
import { ThunkDispatch }               from "redux-thunk"
import { State }                       from "../../redux/reducers"

const createMessage = (error: string | string[]) => {
  let givenToReducer = ""
  if (error instanceof Array) {
    if (error.length) { givenToReducer = error.join(" - ") }
    else { givenToReducer = "OK" }
  }
  else { givenToReducer = String(error) }

  return givenToReducer
}

export const resetMessage = () => (dispatch: ThunkDispatch<State, null, Action>) => {
  dispatch(createStoreMessage(createMessage("")))
}

export const resetPassword = (email: string) => (dispatch: ThunkDispatch<State, null, Action>) => {
  dispatch(createResettingPassword(true))
  const payload = {
    action : "ForgotPassword",
    email: email,
  }
  axios.get<Success>(auth, {params: payload})
  .then((response)=>{
    if(response.data.error && response.data.error.length){
      dispatch(createStoreMessage(createMessage(response.data.error)))
      dispatch(createResettingPassword(false))
    }
    else {
      dispatch(createStoreMessage(response.data.error ? createMessage(response.data.error) : ""))
      dispatch(createResettingPassword(false))
    }
  })
  .catch((response:AxiosError<Failure>)=>{
    dispatch(createStoreMessage(
      "Unable to process your request, please contact technical support at 1-888-912-0668"
    ))
    dispatch(createResettingPassword(false))
  })
}
