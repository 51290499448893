import React                         from 'react'
import {
  Paper,
}                                    from '@material-ui/core'
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
}                                    from 'recharts'
import { pacingReportColorsPalette } from '../../../Theme/Theme'
import { StatPerChannel }            from '../../../model/store/statistics/PacingStats'
import { CustomTick }                from './CustomChartComponents/CustomTick'
import CustomTooltipChannels         from './CustomChartComponents/CustomTooltipChannels'
import { commanderTheme }            from '../../../Theme/Theme'
import CustomTickChannelsX           from './CustomChartComponents/customTickChannelsX'


interface ChannelsOwnProps {
  data: StatPerChannel[]
}

const ChannelsChart = (props:ChannelsOwnProps) => {
  return (
    <Paper elevation={0} style={{height:400, marginLeft: "-60px"}} >
      <ResponsiveContainer width={"99%"}>
        <BarChart
          data={props.data}
          margin={{top: 90, bottom: 10, left: 0, right: 20}}
          barCategoryGap={"20%"}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="channel"
            padding={{left: 80, right: 80}}
            tickLine={false}
            axisLine={false}
            tickMargin={10}
            tick={<CustomTickChannelsX />}
          />
          <YAxis
            dataKey="pacing"
            padding={{top: 0, bottom: 0}}
            axisLine={false}
            tickLine={false}
            ticks={[0, 25, 50, 75, 100, 125]}
            domain={[0, 150]}
            allowDataOverflow={true}
            tick={<CustomTick />}
          />
          <Tooltip cursor={{fill: "transparent"}} content={<CustomTooltipChannels colors={pacingReportColorsPalette} />}/>
          <Bar radius={[7, 7, 7, 7]} dataKey="pacing" fill={commanderTheme.orange} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}


export default ChannelsChart
