import {headstone}  from './index'
import VehicleState from '../../constant/VehicleState'
import Languages    from '../../constant/Languages'
import AudienceGoal from '../../constant/AudienceGoal'

export default (accountId:number,lang:Languages,vehicleState:VehicleState,vehicleType:string,audienceGoal:AudienceGoal) => {
  return headstone+"/"+accountId+"/DefaultAdSet/Facebook/Inventory/"+lang+"/"+vehicleType+"/"+vehicleState+"/"+audienceGoal
}

export interface Success {
  id            : number
  active        : boolean
  lang          : Languages
  vehicleType   : string
  vehicleState  : VehicleState
  template      : string
  audienceGoal  : AudienceGoal
}
