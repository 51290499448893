import {createReducer}    from "../../../utils/Reducers"
import ActionTypes        from "../../../model/constant/actions"
import {RequestFailure}   from "../../../model/store/RequestFailure"

interface idedFailure extends RequestFailure {
  id : number
}
let id = 0
const UserReducer = createReducer<idedFailure[]>([{
  id : 1,
  level : "MAJOR",
  name : "Get Agencies",
  description : "Unknown error with the API",
  actionCreator : ()=>{console.log(1)},
  args : []
},{
  id:2,
  level : "MAJOR",
  name : "Get Agencies",
  description : "Unknown error with the API",
  actionCreator : ()=>{console.log(2)},
  args : []
}], {
  [ActionTypes.FAILED_REQUEST] : (state, action)=>[...state, {id: id++, ...action.payload}],
  [ActionTypes.REMOVE_FAILED_REQUEST] : (state, action)=>state.filter(x=>x.id !== action.payload)
})

export default UserReducer
