import "./index.css"
import React                   from "react"
import ReactDOM                from "react-dom"
import {Provider}              from "react-redux"
import buildStore, {history}   from "./redux/"
import {ConnectedRouter}       from "connected-react-router"
import App                     from "./App"
import {LocalizeProvider}      from "react-localize-redux"

const store = buildStore()

export const WholeApp = () => (
  <Provider store={store}>
    <LocalizeProvider>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </LocalizeProvider>
  </Provider>
)

// root div doesn't exist in test env
if(document.getElementById("root")){
  ReactDOM.render(<WholeApp/>, document.getElementById("root"))
}
