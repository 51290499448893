import {createReducer} from "../../../utils/Reducers"
import ActionTypes     from "../../../model/constant/actions"

interface Retrieving {
  accountPermissions : boolean
  systemPermissions  : boolean
}

const initialState:Retrieving = {
  accountPermissions : false,
  systemPermissions  : false
}
const Retrieving = createReducer<Retrieving>(initialState,{
  [ActionTypes.RETRIEVING_ACCOUNT_PERMISSIONS] : (state,action) => ({...state,accountPermissions:action.payload}),
  [ActionTypes.RETRIEVING_SYSTEMS_PERMISSIONS] : (state,action) => ({...state,systemPermissions:action.payload})
})

export default Retrieving

