import { AccountsData } from "../../accounts/AccountsData"

export const baseUrl = (function (): string {
  return process.env.REACT_APP_TRACKING_URL ?? "https://dealer-leads.ca/tracking/index.php"
})()

export type Success = {
  data: {
    information : AccountsData,
    error      ?: string[]
    status     ?: string
  }
  error  ?: string[]
  status ?: string
}
