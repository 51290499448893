import {
  makeStyles,
  Theme,
  createStyles
}                 from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overview : {
      display : 'flex',
      alignItems : 'center',
      marginTop : 20,
      marginBottom : 20,
      height : 260,
      width : '99%'
    },
    pacingRate : {
      display : 'flex',
      height : '100%',
      alignItems : 'center',
      minWidth: 180,
    },
    pacingPerChannel : {
      flexGrow : 1,
      height : '100%'
    },
    kpis : {
      height : '100%',
      display : 'flex',
      flexDirection : 'column',
      justifyContent : 'space-between',
    }
  })
)
export default useStyles
