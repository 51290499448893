import * as React             from "react"
import {connect}              from "react-redux"
import {Dispatch}             from "redux"
import {State}                from "../redux/reducers/"
import {makeIsLogged}         from "../redux/accessors/Login"
import {Action}               from "../model/constant/actions"
import {
  HashRouter,
  Switch,
  Route,
}                             from "react-router-dom"
import Login                  from "../pages/Login/"
import ForgotPassword         from "../pages/Login/forgotPassword"
import LoggedRouting          from "./Logged"

interface EntryRoutingOwnProps{
}

const makeMapStateToProps = () => {
  const isLogged = makeIsLogged()
  return (state:State, ownProps:EntryRoutingOwnProps)=>{
    return {
      logged : isLogged(state)
    }
  }
}
const mapDispatchToProps = (dispatch:Dispatch<Action>, ownProps:EntryRoutingOwnProps)=>{
  return {}
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:EntryRoutingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type EntryRoutingProps = ReturnType<typeof mergeProps>

const EntryRouting = (props:EntryRoutingProps) => {
  return (
    <HashRouter>
      {!props.logged
        ? <Switch>
            <Route path="/ForgotPassword" component={ForgotPassword} />
            <Route path="/" component={Login}/>
          </Switch>
        : <LoggedRouting/>
      }
    </HashRouter>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(EntryRouting)
