import "./index.css"
import * as React             from "react"
import {useHistory}           from "react-router-dom"
import Agencies               from "../../components/AgencySelection"
import { connect }            from "react-redux"
import { State }              from "../../redux/reducers"
import { ThunkDispatch }      from "redux-thunk"
import { DispatchableAction } from "../../model/constant/actions"
import {
  makeStyles,
  Theme,
  createStyles
}                             from "@material-ui/core"
import {push}                 from "connected-react-router"

interface AgencySelectionOwnProps{
  onSelection ?: ()=>void
  onClose     ?: ()=>void
}
const makeMapStateToProps = () => {
  return (state:State, ownProps:AgencySelectionOwnProps)=>{
    return {}
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:AgencySelectionOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AgencySelectionOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

const useStyles = makeStyles((theme:Theme)=>
  createStyles({
    agencySelection : {
      minWidth : 600,
    }
  })
)

type AgencySelectionProps = ReturnType<typeof mergeProps>

const AgencySelection = (props:AgencySelectionProps) => {
  const classes = useStyles()
  const history = useHistory()
  const URL_REGEX = /(agency\/)([^/]+)(.*)/i
  return (
    <div className={classes.agencySelection}>
      <Agencies
        onClose={props.onClose}
        onSelect={
          (id)=>{
            const currentUrl = history.location.pathname
            if(props.onSelection) {
              props.onSelection()
            }
            if(currentUrl.match(URL_REGEX)) {
              props.push(currentUrl.replace(URL_REGEX,`$1${id}$3`) as any)
            }
            else {
              props.push(("/Agency/"+id) as any)
            }
          }
        }
      />
    </div>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(AgencySelection)
