import {
  StandardThunk,
  createSubmittingFacebookConfiguration,
  createFailedSubmittingFacebookConfiguration
}                                             from "../creators"
import { getFacebookAdTemplates }             from "./FacebookAdTemplates"
import url                                    from "../../model/http/Headstone/PostFacebookConfigurations"
import axios                                  from "axios"
import { getFacebookIdentityVariants }        from "./FacebookIndentityVariants"
import { getFacebookStrategies }              from "./FacebookStrategies"
import Configuration                          from "../../model/Facebook/Configuration"

export const createFacebookConfiguration = (accountId:number,configuration:Configuration):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  const agencyId = getState().Selection.Agency
  dispatch(createSubmittingFacebookConfiguration(true))
  axios.post(url(accountId),configuration,{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(getFacebookAdTemplates(accountId))
    dispatch(getFacebookIdentityVariants(accountId))
    dispatch(getFacebookStrategies(accountId,agencyId))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedSubmittingFacebookConfiguration({failed:true,message:error.response.data.error}))
  })
  .then(()=>{
    dispatch(createSubmittingFacebookConfiguration(false))
  })
}
