import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

interface CircularProgressComponentOwnProps {}
interface CircularProgressComponentProps extends CircularProgressComponentOwnProps {}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))


const CircularProgressComponent = (props:CircularProgressComponentProps) => {
  const classes = useStyles()
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    function tick() {
      // reset when reaching 100%
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1))
    }

    const timer = setInterval(tick, 20)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <div className={classes.root}>
      <CircularProgress variant="determinate" value={progress} />
      <CircularProgress color="secondary" />
    </div>
  )
}

export default CircularProgressComponent
