import baseUrl from './index'

export default (accountId:number) => baseUrl+"/Account/"+accountId

interface Existing {
  accountId   : number
  businessId  : number
  adAccountId : number
  pageId      : number
  name        : string
}


export type Success = Existing | "NON_EXISTING"
