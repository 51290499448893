import React        from 'react'
import {
  LinearProgress,
  withStyles
}                   from '@material-ui/core'
import useStyles    from './indexStyles'

interface BulletGraphMuiOwnProps {
  value      : number
  color      : string
  hideValue ?: boolean
}

const BulletGraphMui = (props:BulletGraphMuiOwnProps) => {
  const dividerColor = props.value > 100 ? props.color : '#FFFFFF'
  const firstBarValue = props.value < 100 ? props.value : 100
  let secondBarValue = -1
  if(props.value>100) {
    if (props.value<150) {
      secondBarValue = (props.value - 100) * 2
    } else {
      secondBarValue = 100
    }
  }
  const ColorLinearProgress = withStyles({
    colorPrimary : {
      backgroundColor : '#DCDCDC'
    },
    barColorPrimary : {
      backgroundColor : props.color
    },
    bar : {
      borderRadius : firstBarValue === 100 ? 0 : 5
    }
  })(LinearProgress)

  const SecondColorLinearProgress = withStyles({
    colorPrimary : {
      backgroundColor : '#DCDCDC'
    },
    barColorPrimary : {
      backgroundColor : props.color
    },
    bar : {
      borderTopRightRadius : 5,
      borderBottomRightRadius : 5,
    }
  })(LinearProgress)

  const classes = useStyles()

  return (
    <div className={classes.bulletGraph}>
      <ColorLinearProgress
        style={{borderRight :`2px dotted ${dividerColor}`}}
        className={classes.firstBar}
        value={firstBarValue}
        variant="determinate"
      />
      <SecondColorLinearProgress
        className={classes.secondBar}
        value={secondBarValue}
        variant="determinate"
      />
      {
        !props.hideValue &&
        <div className={classes.value}> {props.value}% </div>
      }
    </div>
  )
}

export default BulletGraphMui
