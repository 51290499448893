import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Failed {
  retrieving                : {status:boolean,message:string}
  creating                  : {status:boolean,message:string}
  updating                  : {status:boolean,message:string}
  breakdown                 : {status:boolean,message:string}
  campaigns                 : {status:boolean,message:string}
  products                  : {status:boolean,message:string}
  salesforceAccounts        : {status:boolean,message:string}
  salesforceAccountContacts : {status:boolean,message:string}
}
const initial = {
  retrieving                : {status:false,message:''},
  creating                  : {status:false,message:''},
  updating                  : {status:false,message:''},
  breakdown                 : {status:false,message:''},
  campaigns                 : {status:false,message:''},
  products                  : {status:false,message:''},
  salesforceAccounts        : {status:false,message:''},
  salesforceAccountContacts : {status:false,message:''}
}

const Failed = createReducer<Failed>(initial,{
  [ActionTypes.FAILED_RETRIEVING_SUMMARY] : (state,action) => {
    return {
      ...state,
      retrieving : {status:action.payload.failed,message:action.payload.message},
    }
  },
  [ActionTypes.FAILED_CREATING_SUMMARY] : (state,action) => {
    return {
      ...state,
      creating : {status:action.payload.failed,message:action.payload.message},
    }
  },
  [ActionTypes.FAILED_UPDATING_SUMMARY] : (state,action) => {
    return {
      ...state,
      updating : {status:action.payload.failed,message:action.payload.message},
    }
  },
  [ActionTypes.FAILED_RETRIEVING_BREAKDOWN] : (state,action) => {
    return {
      ...state,
      breakdown : {status:action.payload.failed,message:action.payload.message},
    }
  },
  [ActionTypes.FAILED_RETRIEVING_SALES_CAMPAIGNS] : (state,action) => {
    return {
      ...state,
      campaigns : {status:action.payload.failed,message:action.payload.message}
    }
  },
  [ActionTypes.FAILED_RETRIEVING_SALES_PRODUCTS] : (state,action) => {
    return {
      ...state,
      products : {status:action.payload.failed,message:action.payload.message}
    }
  },
  [ActionTypes.FAILED_RETRIEVING_SALESFORCE_ACCOUNTS] : (state,action) => {
    return {
      ...state,
      salesforceAccounts : {status:action.payload.failed,message:action.payload.message}
    }
  },
  [ActionTypes.FAILED_RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS] : (state,action) => {
    return {
      ...state,
      salesforceAccountContacts : {status:action.payload.failed,message:action.payload.message}
    }
  },
})

export default Failed
