import {
  makeStyles,
  Theme,
  createStyles
}               from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  googleTrends : {
    padding : 30,
    marginBottom : 30
  },
  header : {
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'space-between',
  },
  button : {
    display: 'flex',
    justifyContent : 'space-evenly'
  },
  brands : {
    padding : 10,
    borderRadius : 10
  },
  listItem : {
    padding : 0
  },
  makesList : {
    height : 200,
    overflowY: 'scroll'
  },
  unavailable : {
    height : 300,
    display : 'flex',
    alignItems : 'center',
    justifyContent : 'space-around',
  }
})
)

export default useStyles
