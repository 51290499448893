import "./App.css"
import React                  from "react"
import EntryRouting           from "./routing/"
import CssBaseline            from "@material-ui/core/CssBaseline" // https://material-ui.com/components/css-baseline/
import {
  MuiThemeProvider
}                             from "@material-ui/core/styles"
import Main                   from './Theme/Main'
import {
  withLocalize,
  LocalizeContextProps
}                             from "react-localize-redux";
import translations           from "./translations/translations"
import {renderToStaticMarkup} from "react-dom/server"

interface AppProps extends AppOwnProps{}
interface AppOwnProps extends LocalizeContextProps{}

class App extends React.Component<AppProps,any>{
  constructor(props:AppProps){
    super(props)
    this.state = {}
    const availableLangs = ["en","fr"]
    let defaultLang = "en"
    for(let lang of navigator.languages){
      const shortLang = lang.substring(0,2).toLowerCase()
      if(availableLangs.indexOf(shortLang)!== -1){
        defaultLang = shortLang
        break
      }
    }
    this.props.initialize({
      languages : [
        {name : "English", code : "en"},
        {name : "French", code : "fr"}
      ],
      translation : translations,
      options : {
        renderToStaticMarkup,
        defaultLanguage : defaultLang
      }
    })
  }
  render() {
    return (
      <MuiThemeProvider theme={Main}>
        <>
          <CssBaseline/>
          <EntryRouting/>
        </>
      </MuiThemeProvider>
    )
  }
}

export default withLocalize(App)
