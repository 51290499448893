import React                 from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
}                            from '@material-ui/core'
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip
}                            from 'recharts'
import {
  withLocalize,
  LocalizeContextProps
}                            from 'react-localize-redux'
import {translationPaths}    from '../../../translations/translations'

interface GraphDataFormat {
  date  : string
  trends : number
}

interface GoogleTrendsGraphOwnProps extends LocalizeContextProps{
  data : GraphDataFormat[]
  lineColor : string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    googleTrendsGraph : {
      marginTop    : 30,
      marginBottom : 30
    }
  })
)

const GoogleTrendsGraph = (props:GoogleTrendsGraphOwnProps) => {
  const classes = useStyles()
  return (
    <div className={classes.googleTrendsGraph}>
      <ResponsiveContainer height={300} width="99%" >
        <LineChart
          data={props.data}
          margin={{top: 5, right: 30, left: 20, bottom: 5}}
        >
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis tickMargin={15} tick={{fill:'#999'}} stroke="#ccc" height={30} dataKey="date"/>
          <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="left" />
          <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="right" orientation="right" />
          <Tooltip/>
          <Line
            dot={false}
            strokeWidth={4}
            name={props.translate(translationPaths.marketInsights.googleTrendsGraph.trends) as string}
            yAxisId="left"
            type="linear"
            dataKey="trends"
            stroke={props.lineColor}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default withLocalize(GoogleTrendsGraph)
