import * as React                 from "react"
import {connect}                  from "react-redux"
import {ThunkDispatch}            from "redux-thunk"
import {State}                    from "../../redux/reducers"
import {Action}                   from "../../model/constant/actions"
import Loading                    from "../loadings/CircularLoading"
import {getInventoryStats}        from "../../actions/Reporting/inventory"
import moment                     from 'moment'
import AgencySelectionModal       from "../../pages/AgencySelection/AgencySelectionModal"
import { translationPaths }       from "../../translations/translations"

interface PacingStatsOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}

const mapStateToProps = (state:State, ownProps:PacingStatsOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    inventoryStats          : state.Insights.InventoryStatsReducer,
    retrieving              : state.Insights.Retrieving.inventoryStats,
    failedRetrieving        : state.Insights.FailedRetrieving.inventoryStats,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:PacingStatsOwnProps)=>{
  return {
    getInventoryStats : (...args:Parameters<typeof getInventoryStats>)=>dispatch(getInventoryStats(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:PacingStatsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingInventoryStats = false
  if(props.selectedAgency === null){
    return (
      <AgencySelectionModal open={true} />
    )
  }
  if(!props.inventoryStats[props.selectedAgency]){
    loadingInventoryStats = true
    if(!props.retrieving){
      props.getInventoryStats(
        props.selectedAgency,
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      )
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.inventoryStats.loading} doTranslate={true} />
    }
  }
  return props.render({loadingInventoryStats, inventoryStats: props.inventoryStats[props.selectedAgency]})
})

interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  inventoryStats : State["Insights"]["InventoryStatsReducer"][0]
  loadingInventoryStats : boolean
}

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresInventoryStats:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresInventoryStats
