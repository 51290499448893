import React                            from 'react'
import useStyles                        from './indexStyles'
import {
  Typography,
  Modal,
  TextField,
  FormHelperText,
  FormControl,
  Button,
  CardActions,
  Card,
}                                       from '@material-ui/core'
import requiresGoogleTrendsStats        from '../../components/loaders/loadGoogleTrendsStats'
import requiresInventoryStats           from '../../components/loaders/loadInventoryStats'
import requiresTrackingStats            from '../../components/loaders/loadTrackingStats'
import { getInventoryStats }            from '../../actions/Reporting/inventory'
import { getGoogleTrendsStats }         from '../../actions/Reporting/googleTrends'
import { getTrackingStats }             from '../../actions/Reporting/tracking'
import DealersWebsites                  from './DealersWebsites'
import InventoryCount                   from './InventoryCount'
import InventoryRemoved                 from './InventoryRemoved'
import GoogleTrends                     from './GoogleTrends'
import { State }                        from '../../redux/reducers'
import { ThunkDispatch }                from 'redux-thunk'
import { DispatchableAction }           from '../../model/constant/actions'
import { GoogleTrendsStats }            from '../../model/store/insights/GoogleTrendsStats'
import InventoryStats                   from '../../model/store/insights/InventoryStats'
import { connect }                      from 'react-redux'
import CircularLoading                  from '../../components/loadings/CircularLoading'
import { TrackingStats }                from '../../model/store/insights/TrackingStats'
import moment                           from "moment"
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                                       from 'react-localize-redux'
import DateRangeIcon                    from '@material-ui/icons/DateRange'
import DateRangePicker, { ReturnRange } from '../../components/pickers/RangeDatePicker'

import {translationPaths}               from '../../translations/translations'

interface InsightsOwnProps extends LocalizeContextProps {
  googleTrendsStats : GoogleTrendsStats[]
  inventoryStats    : InventoryStats[]
  trackingStats     : TrackingStats[]
}

const mapStateToProps = (state:State, ownProps:InsightsOwnProps)=>{
  return {
    selectedAgency : state.Selection.Agency
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:InsightsOwnProps)=>{
  return {
    getGoogleTrendsStat : (...args:Parameters<typeof getGoogleTrendsStats>)=>dispatch(getGoogleTrendsStats(...args)),
    getInventoryStats : (...args:Parameters<typeof getInventoryStats>)=>dispatch(getInventoryStats(...args)),
    getTrackingStats : (...args:Parameters<typeof getTrackingStats>)=>dispatch(getTrackingStats(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:InsightsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type InsightProps = ReturnType<typeof mergeProps> & InsightsOwnProps & LocalizeContextProps

const Insights = (props:InsightProps) => {
  const classes = useStyles()
  const date = new Date()
  const [open,setOpen] = React.useState<boolean>(false)
  const [startDate,setStartDate] = React.useState<Date>(new Date(date.getFullYear(), date.getMonth(), 1))
  const [endDate,setEndDate] = React.useState<Date>(new Date())

  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
  }
  const resetDateChange = () => {
    setStartDate(new Date(date.getFullYear(), date.getMonth(), 1))
    setEndDate(new Date())
  }
  const onDateChange = (range:ReturnRange) => {
    setStartDate(range.selection.startDate)
    setEndDate(range.selection.endDate)
  }
  const confirmDateChange = () => {
    props.getGoogleTrendsStat(props.selectedAgency,startDate.toISOString().split('T')[0],endDate.toISOString().split('T')[0])
    props.getInventoryStats(props.selectedAgency,startDate.toISOString().split('T')[0],endDate.toISOString().split('T')[0])
    props.getTrackingStats(props.selectedAgency,startDate.toISOString().split('T')[0],endDate.toISOString().split('T')[0])
    handleCloseModal()
  }
  const fullDate = moment(startDate).lang(props.activeLanguage.code).format('LL')+" - "+moment(endDate).lang(props.activeLanguage.code).format('LL')

  if(!props.googleTrendsStats || !props.inventoryStats || !props.trackingStats ){
    return <CircularLoading loadingMessage={translationPaths.pacingOverview.loader} />
  }

  return (
    <div className={classes.insights}>
      <div className={classes.header}>
        <Typography variant="h6" color="textPrimary"><Translate id={translationPaths.marketInsights.marketInsights}/></Typography>
        <FormControl>
          <div style={{display:'flex'}}>
            <TextField
              onFocus={handleOpenModal}
              onClick={handleOpenModal}
              value={fullDate}
              style={{width:(fullDate.length)*9+'px'}}
              variant="outlined"
            />
            <Button onClick={handleOpenModal}><DateRangeIcon/></Button>
          </div>
          <FormHelperText><Translate id={translationPaths.marketInsights.pickDate}/></FormHelperText>
        </FormControl>
      </div>
      <DealersWebsites trackingStats={props.trackingStats}/>
      <InventoryCount stats={props.inventoryStats} />
      <InventoryRemoved stats={props.inventoryStats} />
      <GoogleTrends stats={props.googleTrendsStats}/>
      <Modal style={{display : 'flex',alignItems : 'center',justifyContent : 'space-around',}} onClose={handleCloseModal} open={open}>
        <Card>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDateChange={onDateChange}
          />
          <CardActions style={{display:'flex',justifyContent:'flex-end'}}>
            <Button color="secondary" onClick={()=>{handleCloseModal();resetDateChange()}}><Translate id={translationPaths.commonButtons.cancel} /></Button>
            <Button color="primary" onClick={confirmDateChange}>Ok</Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  )
}

export default withLocalize(
  requiresInventoryStats({letThrough:false})(
    requiresGoogleTrendsStats({letThrough:false})(
      requiresTrackingStats()(
        connect(mapStateToProps,mapDispatchToProps,mergeProps)(
          Insights
        )
      )
    )
  )
)
