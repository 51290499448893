import {
  StandardThunk,
  createRetrievingAgencyAccounts,
  createGetAgencyAccounts,
  createFailedRetrievingAgencyAccounts
}                                         from "../creators"
import baseUrl,{Success}                  from "../../model/http/Auth2/Accounts"
import axios                              from "axios"

export const getAccounts = (agencyId:string):StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingAgencyAccounts(true))
  axios.get<Success>(baseUrl(agencyId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetAgencyAccounts({
      agencyId,
      accounts : response.data
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingAgencyAccounts(true))
  })
  .then(()=>{
    dispatch(createRetrievingAgencyAccounts(false))
  })

}
