import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import {
  Breakdown
}                        from "../../../model/budgetEstimator/Summary"


const BreakdownReducer = createReducer<Breakdown>(null,{
  [ActionTypes.GET_BREAKDOWN] : (state,action) => {
    return action.payload.breakdown
  }
})

export default BreakdownReducer
