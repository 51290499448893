import React from 'react'
import {
  Select,
  makeStyles,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
}            from '@material-ui/core'

interface PaginationsOwnProps {}
interface PaginationsProps extends PaginationsOwnProps {}

const useStyles = makeStyles({
  root : {
    width:200,
  },
  container : {
    display:'flex',
    flexDirection : 'column',
    minHeight : 300,
    justifyContent: 'space-around'
  },
  inputLabel : {
    padding:5
  }
})

const Selects = (props:PaginationsProps) => {
  const [value,setValue] = React.useState('db')
  const handleSelectChange = (event:any) => {
    setValue(event.target.value)
  }
  const classes = useStyles()
  return (
    <div className={classes.container}>
        <FormControl className={classes.root}>
          <InputLabel>Input Label</InputLabel>
          <Select displayEmpty className={classes.root} onChange={handleSelectChange} value={value}>
            <MenuItem value="db">DealerBreacher</MenuItem>
            <MenuItem value="trffk">TRFFK</MenuItem>
            <MenuItem value="trader">Trader</MenuItem>
          </Select>
          <FormHelperText>Placeholder</FormHelperText>
        </FormControl>
        <FormControl className={classes.root}>
          <Select displayEmpty className={classes.root} onChange={handleSelectChange} variant="outlined" value={value}>
            <MenuItem value="db">DealerBreacher</MenuItem>
            <MenuItem value="trffk">TRFFK</MenuItem>
            <MenuItem value="trader">Trader</MenuItem>
          </Select>
          <FormHelperText>Placeholder without Label</FormHelperText>
        </FormControl>
        <FormControl className={classes.root}>
          <InputLabel className={classes.inputLabel}>Input Labelr</InputLabel>
          <Select displayEmpty className={classes.root} onChange={handleSelectChange} variant="filled" value={value}>
            <MenuItem value="db">DealerBreacher</MenuItem>
            <MenuItem value="trffk">TRFFK</MenuItem>
            <MenuItem value="trader">Trader</MenuItem>
          </Select>
        </FormControl>
    </div>
  )
}

export default Selects
