import {
  withStyles,
  Theme,
  createStyles,
  TableCell,
  TableRow,
  makeStyles
}                 from "@material-ui/core"

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight : 'bold'
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)
export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vehicles : {
      padding : 20,
      minWidth : 700,
    },
    title : {
      fontSize : 20,
    },
    table : {
    },
    tableBody : {
    },
    container : {
      marginTop : 20
    },
    display : {
      marginTop : 20
    },
    titleTwo : {
      marginTop : 20,
      fontSize : 20
    }
  })
)

export default useStyles
