import styles                  from './NoPermissionErrorStyles'
import React                   from 'react'
import { Translate }           from 'react-localize-redux'
import {
  Typography,
  Card,
  Divider,
}                              from '@material-ui/core'
import PanToolIcon             from '@material-ui/icons/PanTool'
import { translationPaths }    from '../../../translations/translations'


interface NoPermissionErrorProps {
}

const NoPermissionError = (props: NoPermissionErrorProps) => {
  const classes = styles()
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <div className={classes.contentContainer}>
          <div className={classes.iconContainer}>
            <PanToolIcon className={classes.icon} />
          </div>
          <div className={classes.text}>
            <Typography
              variant="h5"
            >
              <Translate id={translationPaths.permissionError.accessForbidden}/>
            </Typography>
            <Divider />
            <Typography
              variant="subtitle1"
              style={{marginTop:10}}
            >
              <Translate id={translationPaths.permissionError.helpMessage} />
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  )
}
export default NoPermissionError
