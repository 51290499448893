import {
  StandardThunk,
  createRetrievingAdAccountsData,
  createFailedRetrievingAdAccountsData
}                                         from "./creators"
import axios, {
}                                         from "axios"
import {
  Success,
  baseUrl
}                                         from "../model/http/Tracking/index"
import { createGetAdAccountsData }        from "./creators"

export const getAccountsData = (dealerId:number,agencyId:string):StandardThunk => (dispatch, getState) => {
  dispatch(createRetrievingAdAccountsData(true))
  axios.get<Success>(baseUrl, {
    params : {
      accountId: dealerId,
      action : "GetTrackingInformation",
    },
    headers : {Authorization: "Bearer "+getState().Login.User.token}
  })
  .then((response)=>{
    if(response.data.error && response.data.error.length !== 0 ) {
      dispatch(createFailedRetrievingAdAccountsData(true))
    }
    if(response.data.status === "PERMISSION_ERROR") {
      dispatch(createFailedRetrievingAdAccountsData(true))
    }
    else {
      dispatch(createGetAdAccountsData({
        accountsData : response.data.data.information, agencyId:agencyId, accountId:dealerId
      }))
    }
  })
  .catch((error)=>{
    createFailedRetrievingAdAccountsData(true)
  })
  .then(()=>{
      dispatch(createRetrievingAdAccountsData(false))
  })
}
