import { Month }                 from "../constant/Months"
import {Channel}                 from "../constant/channels"


export enum ProfitCenter {
  "NEW"  = "NEW",
  "USED" = "USED",
  "NONE" = "NONE",
}

export const profitCenterArray:ProfitCenter[] = [ProfitCenter.NEW,ProfitCenter.USED,ProfitCenter.NONE]

export interface SummaryProduct extends Product {
  allocation  : number
}
export interface Product {
  channel     : Channel
  description : string | ''
  state       : ProfitCenter
  active     ?: boolean
  id          : number
}

export type BudgetType = 'annual' | 'byCar'

export type FlatManagementFee = {
  name    : string
  slug    : string
  amount  : number
}

export type VariableManagementFee = {
  description : string
  name        : string
  slug        : string
  schedule    : Record<Month,number>
}

export enum Status {
  "ACTIVE"    = "ACTIVE",
  "OUTDATED"  = "OUTDATED",
  "DRAFT"     = "DRAFT",
  "NEW"       = "NEW",
}

export type ManagementFee = FlatManagementFee | VariableManagementFee

export default interface SummaryTemplate {
  budgetType          : BudgetType
  budget              : Record<ProfitCenter,number>
  budgetShare         : Record<ProfitCenter,number>
  includeFee          : boolean
  targets             : Record<ProfitCenter,Record<Month,number>>
  products            : SummaryProduct[]
  managementFee       : FlatManagementFee | VariableManagementFee
  discount            : number
  startingMonth       : Month
  status              : Status
  startingYear        : number
  summaryId           : number
  repName             : string
  salesforceAccountId : string
  contactId           : string
  dealerName          : string
  createdOn          ?: string
}

export type SummaryTemplateWithPresentation = SummaryTemplate&{presentation:string}

export interface SummaryByAccountId {
  [accountId:string]   : SummaryTemplate[]
}

export interface SummaryWithPresentationByAccountId {
  [accountId:string] : SummaryTemplateWithPresentation[]
}

export interface Discount {
  campaignDiscount : number
  extraDiscount : number
}

export interface Breakdown {
  startingMonth : Month
  year:number
  amountsSummary : {
    totalBilled : number
    totalMediaSpend : number
    totalBudget : number
    totalTargets : Record<ProfitCenter,number>
    budgetByProfitCenter : Record<ProfitCenter,number>
    mediaSpendByProfitCenter : Record<ProfitCenter,number>
    totalExtraDiscount : number
    totalManagementFee : number
  }
  amounts : Record<Month, {
    [productName:string] : {
      mediaSpend            : number
      discounts             : Discount
      managementFeeCurrency : number
      totalBilled           : number
      channel               : Channel
      profitCenter          : ProfitCenter
    }
  }>
}

export const createSummaryTemplate = (repName:string):SummaryTemplate => ({
  budgetType : 'annual',
  budget : {
    NEW   : 0,
    USED  : 0,
    NONE  : 0
  },
  budgetShare : {
    NEW   : 100,
    USED  : 100,
    NONE  : 100
  },
  startingYear : 2021,
  includeFee : false,
  targets : {
    [ProfitCenter.NEW] : {
      "january"   : 0,
      "february"  : 0,
      "march"     : 0,
      "april"     : 0,
      "may"       : 0,
      "june"      : 0,
      "july"      : 0,
      "august"    : 0,
      "september" : 0,
      "october"   : 0,
      "november"  : 0,
      "december"  : 0
    },
    [ProfitCenter.USED] : {
      "january"   : 0,
      "february"  : 0,
      "march"     : 0,
      "april"     : 0,
      "may"       : 0,
      "june"      : 0,
      "july"      : 0,
      "august"    : 0,
      "september" : 0,
      "october"   : 0,
      "november"  : 0,
      "december"  : 0
    },
    [ProfitCenter.NONE] : {
      "january"   : 0,
      "february"  : 0,
      "march"     : 0,
      "april"     : 0,
      "may"       : 0,
      "june"      : 0,
      "july"      : 0,
      "august"    : 0,
      "september" : 0,
      "october"   : 0,
      "november"  : 0,
      "december"  : 0
    }
  },
  products      : [],
  managementFee : {
    name        : '',
    amount      : 0,
    slug        : ''
  },
  discount      : 10,
  startingMonth : Month.january,
  status        : Status.DRAFT,
  summaryId     : -1,
  repName       : repName,
  salesforceAccountId : '',
  contactId     : '',
  dealerName    : '',
})

export const createNewProduct = (id:number):SummaryProduct => ({
  channel     : Channel.search,
  description : '',
  state       : ProfitCenter.NEW,
  allocation  : 0,
  id
})

export const isManagementFeeFlat = (managementFee:ManagementFee):managementFee is FlatManagementFee => {
  return (managementFee as FlatManagementFee).amount !== undefined
}
export const isManagementFeeVariable = (managementFee:ManagementFee):managementFee is VariableManagementFee => {
  return (managementFee as VariableManagementFee).schedule !== undefined
}

