import {
  createHashHistory,

}                               from "history"
import {
  createStore,
  applyMiddleware,
  compose,
  Dispatch
}                               from "redux"
import createRootReducer, {
  State
}                               from "./reducers/"
import thunkMiddleware          from "redux-thunk"
import loggerMiddleware         from "./middlewares/logger"
import {routerMiddleware}       from "connected-react-router"
import monitorEnhancer          from "./enhancers/monitor"
import {Action}                 from "../model/constant/actions"
import pacingStatsMiddleware    from "../redux/middlewares/pacingStatsCompilation"

export const history = createHashHistory()

export default (preloadedState?:State) => {
  const middlewares = [
    thunkMiddleware,
    routerMiddleware(history), //Maybe not necessary
    pacingStatsMiddleware,
    loggerMiddleware,
  ]

  const composedEnhancers = compose<any>( //TODO type this... how?!
    applyMiddleware<Dispatch<Action>, State>(...middlewares),
    monitorEnhancer,
  )

  const rootReducer = createRootReducer(history)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)
/*
  if(process.env.NODE_ENV !== "production" && module.hot){
    module.hot.accept("redux/reducers", ()=>store.replaceReducer(rootReducer))
  }
*/
  return store
}
