import styles                from './indexStyles'
import * as React            from "react"
import {connect}             from "react-redux"
import {ThunkDispatch}       from "redux-thunk"
import {State}               from "../../redux/reducers/"
import {Action}              from "../../model/constant/actions"
import {Agency}              from "../../model/agencies/Agency"
import {createSelectAgency}  from "../../actions/creators"
import Card                  from "@material-ui/core/Card"
import CardContent           from "@material-ui/core/CardContent"
import requiresAgencies      from "../loaders/loadAgencies"
import List                  from "@material-ui/core/List"
import ListItem              from "@material-ui/core/ListItem"
import ListItemText          from "@material-ui/core/ListItemText"
import {
  withStyles,
  WithStyles,
  Button,
  Typography,
}                            from "@material-ui/core"
import keydown               from 'react-keydown'
import CircularLoading       from "../loadings/CircularLoading"
import {Close}               from "@material-ui/icons"
import { Translate }         from 'react-localize-redux'
import { translationPaths }  from '../../translations/translations'
import {
  withLocalize,
  LocalizeContextProps
}                            from "react-localize-redux"

interface AgencySelectionOwnProps extends LocalizeContextProps{
  agencies  : Agency[]
  onSelect ?: (id:Agency["id"])=>void
  onClose  ?: ()=>void
}
interface AgencySelectionWrapperProps extends WithStyles<typeof styles> {
  loadingAgencies : boolean
}
interface AgencySelectionState {
  selected : string
}
const mapStateToProps = (state:State, ownProps:AgencySelectionOwnProps)=>{
  return {
    selectedAgency : state.Selection.Agency,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AgencySelectionOwnProps)=>{
  return {
    selectAgency : (...args:Parameters<typeof createSelectAgency>)=>dispatch(createSelectAgency(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AgencySelectionOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type AgencySelectionProps =  ReturnType<typeof mergeProps> & AgencySelectionWrapperProps

class AgencySelection extends React.Component<AgencySelectionProps,AgencySelectionState>{

  constructor(props:AgencySelectionProps) {
    super(props)
    this.state = {
      selected : props.agencies[0].id
    }
  }
  handleScroll() {
    const agencyElem = document.getElementById(this.state.selected)
    const container =  document.getElementById('agencySelectionList')
    if (agencyElem && container) {
      // Handle downward scroll
      if (agencyElem.offsetTop + agencyElem.clientHeight > container.scrollTop + container.clientHeight) {
        container.scrollTop += agencyElem.clientHeight
      }
      // Handle upward scroll
      if (agencyElem.offsetTop < container.scrollTop) {
        container.scrollTop -= agencyElem.clientHeight
      }
    }
  }
  createOnClick = (id:Agency["id"])=>()=>{
    this.props.selectAgency(id)
    if(this.props.onSelect){this.props.onSelect(id)}
  }
  handleCloseButton = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  setSelected(x:string) {
    this.setState({selected : x})
  }
  @keydown('down')
  targetLowerRow() {
    let currentIndex = 0

    for (let i = 0; i < this.props.agencies.length; i++) {
      if (this.props.agencies[i].id === this.state.selected) {
        currentIndex = i
      }
    }

    let _selected = this.props.agencies[currentIndex + 1]

    if (_selected !== undefined) {
      this.setSelected(_selected.id)
    }

    this.handleScroll()
  }
  @keydown('up')
  targetUpperRow() {
    let currentIndex = 0

    for (let i = 0; i < this.props.agencies.length; i++) {
      if (this.props.agencies[i].id === this.state.selected) {
        currentIndex = i
      }
    }

    let _selected = this.props.agencies[currentIndex - 1]

    if (_selected !== undefined) {
      this.setSelected(_selected.id)
    }

    this.handleScroll()
  }
  @keydown('enter')
  confirmSelect() {
    if(this.props.onSelect){
      this.props.onSelect(this.state.selected)
    }
    this.props.selectAgency(this.state.selected)
  }
  @keydown('esc')
  closeModal() {
    if (this.props.onClose)
      this.props.onClose()
  }

  renderAgenciesList() {
    const classes = this.props.classes
    if(this.props.agencies.length === 1) {
      this.props.selectAgency(this.props.agencies[0].id)
    }
    return (
      <List id="agencySelectionList" component="nav" className={classes.list}>
        {
          this.props.agencies.map((agency,i)=> {
            return (
              <ListItem
                id={agency.id}
                onMouseOver={()=>this.setSelected(agency.id)}
                className={classes.listItem + " " + (agency.id === this.state.selected ? classes.focused : '')}
                key={agency.id}
                button
                onClick={this.createOnClick(agency.id)}
              >
                <ListItemText
                  primaryTypographyProps={{color:'inherit'}}
                  primary={agency.name}
                  secondary={agency.rootuserGroupId}
                />
              </ListItem>
            )}
          )
        }
      </List>
    )
  }
  render(){
    const classes = this.props.classes
    return (
      <div className="AgencySelection">
        <Card className={classes.card}>
            <div className={classes.buttonContainer}>
              {
                this.props.selectedAgency &&
                  <Button
                    onClick={this.handleCloseButton}
                    className={classes.button}
                  >
                    <Close />
                  </Button>
              }
            </div>
          <CardContent id="agenciesSelection" className={classes.content}>
          <Typography
            className={classes.title}
            variant="h5"
            align="center"
          >
            <Translate id={translationPaths.agencySelection.title}/>
          </Typography>
            {this.props.loadingAgencies ?
              <CircularLoading timeOut={150} loadingMessage={this.props.translate(translationPaths.agencySelection.loadingAgencies) as string}/>
            :
              this.renderAgenciesList()
            }
          </CardContent>
        </Card>
      </div>
    )
  }
}
export default withLocalize(
  requiresAgencies({letThrough:false})(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(withStyles(styles)(AgencySelection)
  ))
)
