import React                from 'react'
import useStyles            from './indexStyles'
import BulletGraphMui       from '../BulletGraphMui'
import {StatPerChannel}     from '../../../model/store/statistics/PacingStats'
import { spectrumColors }   from '../../../Theme/Theme'
import {
  withLocalize,
  LocalizeContextProps
}                           from 'react-localize-redux'

interface PacingPerChannelOwnProps extends LocalizeContextProps {
  data : StatPerChannel[]
}

const PacingPerChannel = (props:PacingPerChannelOwnProps) => {
  const classes = useStyles()

  return (
    <div className={classes.pacingPerChannel}>

      <div className={classes.graphs}>
        {
          props.data.map((stat, index:number)=>(
            <div key={stat.channel} className={classes.rowContainer}>
              <div className={classes.legends}>
                <span className={classes.legend}>{props.translate(`pacingOverview.pacingPerChannel.${[stat.channel.toLowerCase()]}`)}</span>
              </div>
              <BulletGraphMui key={stat.channel} value={Math.round(stat.pacing*100)/100} color={spectrumColors[index]} />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default withLocalize(PacingPerChannel)
