import theme, { ThemeParams } from './Theme'
import { createMuiTheme }     from '@material-ui/core'
import {
  red,
  green,
  orange,
  lightBlue,
}                             from '@material-ui/core/colors'


const themeParams:ThemeParams = {
  primary : {
    main : '#585858',
    dark : '#3d3c3c',
    light : '#969eae',
    contrastText : '#FFF'
  },
  secondary : {
    main : 	"#b20000",
    dark : "#660000",
    contrastText : '#FFFFFF',
  },
  error : {
    main : red[500],
    dark : red[700],
    contrastText : '#FFFFFF'
  },
  warning : {
    main : orange[700],
    dark : orange[900],
    contrastText : '#FFFFFF'
  },
  info : {
    main : lightBlue[600],
    dark : lightBlue[800],
    contrastText : '#FFFFFF'
  },
  success : {
    main : green[500],
    dark : green[700],
    contrastText : '#FFFFFF'
  },
}

const Main_Theme = createMuiTheme(theme(themeParams))

export default Main_Theme
