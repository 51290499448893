import {User}      from "../../model/users/User"
import menuButtons from "../../model/constant/menuButtons"
export interface MenuModel {
  id          : string
  name        : menuButtons
  path        : string
  icon        : string
  hide       ?: boolean
  expandable  : boolean
  subMenu    ?: MenuModel[]
  isSpread   ?: boolean
  renderName ?: () => string
}
export interface MenuOptions {
  agencyId : string
  user : User
  hideSalestool : boolean
}
const MENU: (options:MenuOptions)=>MenuModel[] = options => [
    {
      id          : "Home",
      path        : `/Agency/${options.agencyId}/`,
      name        : menuButtons.HOME,
      icon        : "home",
      expandable  : false
    },
    /*{
        id          : "Agency",
        path        : "Agency",
        name        : menuButtons.AGENCY_PROFILE,
        icon        : "perm_contact_calendar",
        expandable  : false
    },*/
    {
        id          : "Accounts",
        path        : `/Agency/${options.agencyId}/Accounts`,
        name        : menuButtons.ACCOUNTS,
        icon        : "recent_actors",
        expandable  : false,
    },
    {
        id          : "Reporting",
        path        : "Report",
        name        : menuButtons.REPORTING,
        icon        : "bar_chart",
        expandable  : true,
        subMenu     : [
          {
            id          : "Pacing",
            path        : `/Agency/${options.agencyId}/Report/Pacing`,
            name        : menuButtons.PACING,
            icon        : "speed",
            expandable  : false,
          }
        ]
    },
    {
      id          : "Insights",
      path        : `/Agency/${options.agencyId}/Insights`,
      name        : menuButtons.INSIGHTS,
      icon        : "emoji_objects",
      expandable  : false
    },
    {
        id          : "User",
        path        : "/User",
        name        : menuButtons.USERS,
        icon        : "",
        expandable  : false,
        renderName  : () => `${options.user.user.firstName} ${options.user.user.lastName}`
    },
     /*subMenu: [
          {
            id          : "Profile",
            path        : "Profile",
            name        : menuButtons.PROFILE,
            icon        : "account_box",
            expandable  : false,
          },
          {
            id          : "Settings",
            path        : "Settings",
            name        : menuButtons.SETTINGS,
            icon        : "settings",
            expandable  : false,
          }
        ]*/
    {
        id          : "Support",
        path        : "Support",
        name        : menuButtons.SUPPORT,
        icon        : "help_outline",
        expandable  : false
    },
    {
        id          : "BudgetCalculator",
        path        : `/Agency/${options.agencyId}/BudgetCalculator`,
        name        : menuButtons.BUDGET_CALCULATOR,
        icon        : "calculate",
        expandable  : false,
        hide        : options.hideSalestool
    },
    /*{
        id          : "Billing",
        path        : "Billing",
        name        : menuButtons.BILLING,
        icon        : "receipt",
        expandable  : false
    },
    {
        id          : "Users",
        path        : "Users",
        name        : menuButtons.USERS,
        icon        : "face",
        expandable  : false
    },
    {
      id: 'Design',
      path: '/Design',
      name : menuButtons.DESIGN_SYSTEM,
      icon : 'extension',
      expandable : false
    }*/
]

export default MENU
