import React                            from 'react'
import useStyles                        from './FacebookIdSetupStyles'
import {
  CardHeader,
  CardContent,
  Select,
  CardActions ,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Typography,
  Divider,
  TextField,
  Icon,
}                                       from '@material-ui/core'
import requiresFacebookBusinesses       from '../../../../components/loaders/loadFacebookBusinesses'
import Business                         from '../../../../model/Facebook/Business'
import requiresFacebookUnusedAdAccounts from '../../../../components/loaders/loadFacebookUnusedAdAccounts'
import { State }                        from '../../../../redux/reducers'
import { ThunkDispatch }                from 'redux-thunk'
import { DispatchableAction }           from '../../../../model/constant/actions'
import { connect }                      from 'react-redux'
import requiresFacebookPages            from '../../../../components/loaders/loadFacebookPages'
import { Translate }                    from 'react-localize-redux'
import { translationPaths }             from '../../../../translations/translations'
import Autocomplete                     from '@material-ui/lab/Autocomplete'
import CircularLoading                  from '../../../../components/loadings/CircularLoading'
import ErrorPage                        from '../../../../components/ErrorPage'

interface FacebookIdSetupOwnProps {
  businesses          : Business[]
  selectedBusinessId  : number | null
  selectedAdAccountId : number | null
  selectedPageId      : number | null
  onBusinessIdChange  : (event:React.ChangeEvent<{value:unknown}>) => void
  onAdAccountChange   : (adAccount:AdAccountOptionType | null) => void
  onPageIdChange      : (facebookPage : FacebookPageOptionType | null) => void
  onClose             : ()=>void
  onConfirm           : ()=>void
  disabled            : boolean
  showAdAccountMessage: boolean
  adAccountIdAuth    ?: number
}

export interface AdAccountOptionType {
  name : string
  id : number
}
export interface FacebookPageOptionType {
  name : string
  id : number
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:FacebookIdSetupOwnProps)=>{
    return {
      unusedAdAccounts : state.Facebook.UnusedAdAccounts,
      pages : state.Facebook.Pages,
      submitting : state.Facebook.Creating.facebookAdAccountId,
      failed : state.Facebook.Failed.facebookAdAccountId.failed,
      errorMessage : state.Facebook.Failed.facebookAdAccountId.message
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:FacebookIdSetupOwnProps)=>{
  return {}
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:FacebookIdSetupOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type FacebookSetupProps = FacebookIdSetupOwnProps & ReturnType<typeof mergeProps>

const FacebookIdSetup = (props:FacebookSetupProps) => {
  const classes = useStyles()
  const facebookAdAccounts = props.selectedBusinessId && props.unusedAdAccounts[props.selectedBusinessId].map(adAccount=>{
    return {name : `${adAccount.name} ${adAccount.adAccountId}`, id:adAccount.adAccountId}
  })
  const facebookPages = props.selectedBusinessId && props.pages[props.selectedBusinessId].map(facebookPage=>{
    return {name:facebookPage.name,id:facebookPage.id}
  })
  const selectedAdAccount = props.selectedAdAccountId && facebookAdAccounts && facebookAdAccounts.find(adAccount=>adAccount.id === props.selectedAdAccountId)
  const selectedPage = props.selectedPageId && facebookPages && facebookPages.find(page=>page.id === props.selectedPageId)

  if(props.submitting) {
    return (
      <div className={classes.container}>
        <CircularLoading loadingMessage={translationPaths.accounts.accountsDetails.loaders.submitId}  doTranslate/>
      </div>
    )
  }
  if(props.failed) {
    return (
      <div className={classes.container} style={{minHeight:200,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'space-around'}}>
        <ErrorPage errorMessage={translationPaths.accounts.accountsDetails.loaders.failedSubmitId} doTranslate />
        {props.errorMessage}
      </div>
    )
  }
  return (
    <div className={classes.container}>
      {props.adAccountIdAuth ? <Typography><Translate id={translationPaths.accounts.accountsDetails.activations.currentlyExistingAccountId} /> {props.adAccountIdAuth}</Typography> : null}
      <CardHeader
        title={
          <Typography className={classes.title} variant="h6">
            <Translate id={translationPaths.accounts.accountsDetails.activations.assignFacebookAccountId}/>
          </Typography>
        }
      />
      <CardContent className={classes.content}>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="facebook-businesId-label">Facebook Business </InputLabel>
          <Select
            labelId="facebook-businesId-label"
            value={props.selectedBusinessId || 0}
            onChange={props.onBusinessIdChange}
          >
            {
              props.businesses.map(business=>{
                return <MenuItem key={business.businessId} value={business.businessId}>{business.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="facebook-accountId-label"></InputLabel>
          <Autocomplete
            value={selectedAdAccount || null}
            onChange={(event: React.ChangeEvent<{}>, newValue: AdAccountOptionType | null) => {
              props.onAdAccountChange(newValue)
            }}
            disabled={!props.selectedBusinessId || props.selectedBusinessId === 0 }
            options={facebookAdAccounts || []}
            getOptionLabel={(option)=>option.name}
            renderInput={(params) => <TextField {...params} label="Facebook Ad Account" variant="outlined" />}
            renderOption={(option)=>(<div style={{width:'100%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>{option.name}</div>)}
          />
        </FormControl>
        <FormControl variant="filled" className={classes.formControl}>
          <Autocomplete
            value={selectedPage || null}
            onChange={(event: React.ChangeEvent<{}>, newValue: FacebookPageOptionType | null) => {
              props.onPageIdChange(newValue)
            }}
            disabled={!props.selectedBusinessId || props.selectedBusinessId === 0 }
            getOptionLabel={(option)=>option.name}
            options={facebookPages || []}
            renderInput={(params) => <TextField {...params} label="Facebook page id" variant="outlined" />}
            renderOption={(option)=>(<div style={{width:'100%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>{option.name}</div>)}
            filterOptions={(options, state) =>
              options.filter(option => option.name.includes(state.inputValue) || option.id.toString().includes(state.inputValue))
            }
          />
          <FormHelperText id="page-searchable-by-id-helper-text">
            <Translate id={translationPaths.accounts.accountsDetails.activations.searchableById} />
          </FormHelperText>
        </FormControl>
      </CardContent>
      {props.showAdAccountMessage &&
        <Typography  className={classes.adAccountMessage}>
          <Icon style={{marginRight:5,marginBottom:3}}>info</Icon><Translate id={translationPaths.accounts.accountsDetails.activations.sameAdAccountId}/>
        </Typography>
      }
      <Divider />
      <CardActions className={classes.footer}>
        <Button onClick={props.onClose} size="large" variant="outlined" color="secondary"><Translate id={translationPaths.commonButtons.cancel} /></Button>
        <Button disabled={props.disabled} onClick={props.onConfirm} size="large" variant="contained" color="primary"><Translate id={translationPaths.commonButtons.save}/></Button>
      </CardActions>
    </div>
  )
}

export default requiresFacebookBusinesses()(
  requiresFacebookUnusedAdAccounts()(
    requiresFacebookPages()(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
        FacebookIdSetup
      )
    )
  )
)
