import {headstone}  from './index'
import Languages    from '../../constant/Languages'
import VehicleState from '../../constant/VehicleState'

export default (accountId:number) => {
  return headstone+"/"+accountId+"/IdentityVariant/Facebook"
}

export interface Success {
  lang : Languages
  vehicleState : VehicleState
  vehicleType : string
  makes : string[]
  names : string[]
  lastModifiedBy : number
  lastModifiedOn : {
    date : string
    timezone_type : number
    timezone : string
  }
}
