import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"
import { diffColors }  from "../../Theme/Theme"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    percentage: {
      fontSize: "2.5vw",
      fontWeight: "bolder"
    },
    label: {
      fontSize: "1rem",
      color: theme.palette.grey[500],
      fontWeight: "bold",
    },
    container: {
      padding : 20,
      width : 250,
      height : "100%",
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    // Via https://stackoverflow.com/a/48962278
    // Parent must be position: relative
    triangleOverlay: {
      borderWidth: '0 60px 60px 0',
      borderColor: `transparent transparent ${diffColors.good} transparent`,
      borderStyle: 'solid',
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
    triangleOverlayRed: {
      borderWidth: '0 60px 60px 0',
      borderColor: `transparent transparent ${diffColors.bad} transparent`,
      borderStyle: 'solid',
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
    progress : {
      width: "100%",
      height: "auto",
    },
    contentText : {
      display : 'flex',
      flexDirection : 'column',
      alignItems : 'center',
      lineHeight : '1px'
    },
    difference : {
      position: "absolute",
      bottom: 5,
      left: 3,
      color: "white",
      letterSpacing: "-0.5px",
      fontSize: 14,
    },

    '@media (max-width: 1250px)': {
      percentage: {
        fontSize: "2rem"
      },
      label: {
        fontSize: ".75rem"
      }
    },

  })
)

export default useStyles
