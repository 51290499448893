import {
  StandardThunk,
  createRetrievingSalesforceAccountContacts,
  createGetSalesforceAccountContacts,
  createFailedRetrievingSalesforceAccountContacts
}                                          from "../creators"
import getAccountContactsUrl, {
  Success
}                                          from "../../model/http/Salesforce-etl/GetContacts"
import axios                               from 'axios'

export const getSalesforceAccountContacts = (accountId:string):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingSalesforceAccountContacts(true))
  axios.get<Success>(getAccountContactsUrl(accountId),{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response=>{
    dispatch(createGetSalesforceAccountContacts(response.data))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingSalesforceAccountContacts({
      failed:true,
      message : 'Failed retrieving salesforce accounts'
    }))
  })
  .then(()=>{
    dispatch(createRetrievingSalesforceAccountContacts(false))
  })
}
