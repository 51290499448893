import {
  StandardThunk,
  createRetrievingAccountsSummary,
  createGetAccountsSummary,
  createRetrievingBreakdown,
  createGetBreakdown,
  createFailedRetrievingAccountsSummary,
  createFailedRetrievingBreakdown,
  createCreatingSummary,
  createFailedCreatingSummary,
  createUpdatingSummary,
  createFailedUpdatingSummary,
  createResetDraftSummary
}                                                     from "../creators"
import getSummaryUrl, { Success }                     from "../../model/http/Trffk-salestool/GetSummary"
import getBreakdownUrl, { Success as Breakdown }      from "../../model/http/Trffk-salestool/DryPostSummary"
import getPostSummaryUrl, { Success as PostResponse } from "../../model/http/Trffk-salestool/PostSummary"
import getPutSummaryUrl, { Success as PutResponse }   from "../../model/http/Trffk-salestool/PutSummary"
import axios                                          from "axios"
import SummaryTemplate                                from "../../model/budgetEstimator/Summary"

export const getSummary = ():StandardThunk => (dispatch,getState) => {
  const agency = getState().Selection.Agency
  const token = getState().Login.User.token
  dispatch(createRetrievingAccountsSummary(true))
  axios.get<Success>(getSummaryUrl(),{
    headers : {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response=>{
    dispatch(createGetAccountsSummary({
      contracts : response.data,
      agencyId :agency
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingAccountsSummary({
      failed:true,
      message : 'Error retrieving Summary'
    }))
  })
  .then(()=>{
    dispatch(createRetrievingAccountsSummary(false))
  })
}

export const getBreakdown = (summary:SummaryTemplate):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingBreakdown(true))
  axios.post<Breakdown>(getBreakdownUrl(),summary,{
    headers: {
      Authorization : `Bearer ${token}`
    },
  })
  .then(response => {
    dispatch(createGetBreakdown({
      breakdown : response.data,
    }))
  })
  .catch(error=>{
    console.log(error)
    dispatch(createFailedRetrievingBreakdown({
      failed:true,
      message:'Failed retrieving breakdown'
    }))
  })
  .then(()=>{
    dispatch(createRetrievingBreakdown(false))
  })
}

export const createSummary = (summary:SummaryTemplate):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createCreatingSummary(true))
  axios.post<PostResponse>(getPostSummaryUrl(),summary,{
    headers: {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response => {
    if(response.status === 202) {
      setTimeout(() => {
        dispatch(createSummary(summary))
      }, 5000)
    } else {
      dispatch(getSummary())
      dispatch(createResetDraftSummary({}))
      dispatch(createCreatingSummary(false))
    }
  })
  .catch(error=>{
    console.log(error)
    dispatch(createFailedCreatingSummary({
      failed:true,
      message:'Failed Creating Summary'
    }))
    dispatch(createCreatingSummary(false))
  })
  .then(()=>{

  })
}

export const updateSummary = (summary:SummaryTemplate,summaryId:string):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createUpdatingSummary(true))
  axios.put<PutResponse>(getPutSummaryUrl(summaryId),summary,{
    headers: {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response => {
    if(response.status === 202) {
      setTimeout(() => {
        dispatch(updateSummary(summary,summaryId))
      }, 5000)
    } else {
      dispatch(getSummary())
      dispatch(createUpdatingSummary(false))
    }
  })
  .catch(error=>{
    dispatch(createFailedUpdatingSummary({
      failed:true,
      message:'Failed Updating Summary'
    }))
    dispatch(createUpdatingSummary(false))
  })
}
