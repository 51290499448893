export enum Channel {
 "search"     = "search",
 "display"    = "display",
 "social"     = "social",
 "geoFencing" = "geoFencing",
 "video"      = "video",
 "support"    = "support",
}
export const CHANNELS_ARRAY:Channel[] = [
  Channel.search,
  Channel.display,
  Channel.social,
  Channel.geoFencing,
  Channel.video,
  Channel.support,
]
