import styles                       from './indexStyles'
import React                        from 'react'
import {
  useTheme,
  Card,
  Typography
}                                   from '@material-ui/core'
import Table                        from 'material-table'
import {
  withLocalize,
  LocalizeContextProps,
}                                   from 'react-localize-redux'
import { State }                    from '../../redux/reducers'
import { ThunkDispatch }            from 'redux-thunk'
import { DispatchableAction }       from '../../model/constant/actions'
import { connect }                  from 'react-redux'
import Breadcrumbs                  from "../../components/breadcrumbs/Breadcrumbs"
import requiresPacingStats          from '../../components/loaders/loadAgencyPacingStats'
import requiresAgencyAccounts       from '../../components/loaders/loadAgencyAccounts'
import { push }                     from 'connected-react-router'
import {
  createSelectDealer,
  createFailedRetrievingAdAccountsData,
  createFailedRetrievingAccountInfoFirestone
}                                   from '../../actions/creators'


interface AccountsListOwnProps extends LocalizeContextProps {}

const makeMapStateToProps = () => {
  return (state:State, ownProps:AccountsListOwnProps)=>{
    const agency = state.Agencies.List.find(a => a.id === state.Selection.Agency)
    const currentAgency = state.Selection.Agency

    return {
      accounts            : state.Accounts.Agency[currentAgency],
      selectedAgencyName  : agency && agency.name,
      history             : state.router.location.pathname,
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:AccountsListOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args)),
    selectDealer : (...args:Parameters<typeof createSelectDealer>)=>dispatch(createSelectDealer(...args)),
    setFailedToFalse : () => {dispatch(createFailedRetrievingAdAccountsData(false));dispatch(createFailedRetrievingAccountInfoFirestone(false))},
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsListOwnProps)=>{
  return {...SP,...DP,...ownProps}
}


type AccountsListProps = ReturnType<typeof mergeProps>

const AccountsList = (props:AccountsListProps) => {
  const classes = styles()
  const theme = useTheme()

  const selectDealer = (id:number) => {
    props.selectDealer(id)
    props.push(props.history+"/"+id as any)
  }
  return (
    <div className={classes.accounts}>
      <Breadcrumbs
        agency={props.selectedAgencyName ? props.selectedAgencyName : ""}
        section="Accounts"
      />

      <Card className={classes.pacingTable}>
        <Table
          // Replaces the <Paper /> container that <Table /> creates
          onRowClick={(event,params)=>{props.setFailedToFalse();selectDealer(params?.id || 0)}}
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          data={props.accounts}
          title={
            <Typography
              variant="h6"
              className={classes.title}
            >
              {props.translate('accounts.index.title')}
            </Typography>
          }
          columns={[
            {
              title : 'ID',
              field : 'id',
              cellStyle: {
                fontWeight : 'bold',
                overflow : 'hidden',
                textOverflow : 'ellipsis',
                whiteSpace : 'nowrap',
                height: 30,
                color : '#666',
              },
              render:(params)=> <div>{params.id}</div>
            },
            {
              headerStyle: {minWidth: 300,},
              title : props.translate(`accounts.index.table.DEALERS`) as string,
              field:'name',
              cellStyle: {
                fontWeight : 'bold',
                overflow : 'hidden',
                textOverflow : 'ellipsis',
                whiteSpace : 'nowrap',
                height: 30,
                color : '#666',
               },
              render:(params)=> <div>{params.name}</div>
            },
            {
              title: props.translate(`accounts.index.table.REGION`) as string,
              field:'province',
              cellStyle: {textAlign: "left", }
            },
            // {
            //   title: props.translate(`accounts.index.table.TEAM`) as string,
            //   field:'team',
            //   cellStyle: {textAlign: "left", borderBottom: "0px solid transparent",paddingTop: cellPadding,paddingBottom: cellPadding,}
            // },
            // {
            //   title: props.translate(`accounts.index.table.STRATEGIST`) as string,
            //   field:'strategist',
            //   cellStyle: {textAlign: "left", borderBottom: "0px solid transparent",paddingTop: cellPadding,paddingBottom: cellPadding,}
            // },
            // {
            //   title: 'checkboxes',
            //   field:'checkboxes',
            //   cellStyle: {textAlign: "left", borderBottom: "0px solid transparent",paddingTop: cellPadding,paddingBottom: cellPadding,}
            // },
            // {
            //   title: props.translate(`accounts.index.table.STATUS`) as string,
            //   field:'status',
            //   cellStyle: {textAlign: "left", borderBottom: "0px solid transparent",paddingTop: cellPadding,paddingBottom: cellPadding,}
            // },
          ]}
          options={{
            emptyRowsWhenPaging: false,
            detailPanelColumnAlignment: "left",
            headerStyle :{
              backgroundColor : '#EEEEEE',
              color : theme.palette.primary.main,
              fontWeight: "bold",
              fontSize : '1.1em',
              marginTop : 10,
              boxShadow : '5px',
            },
            rowStyle:{
              fontWeight: "bold",
              color: theme.palette.primary.light,
              fontSize: 500,
              paddingTop: 0,
              paddingBottom: 0,
            },
            searchFieldStyle: {
              border: `1px solid ${theme.palette.primary.light}`,
              width: 325,
              borderRadius: 10,
              padding: 10,
              paddingLeft: 22,
              marginRight: 15,
              fontWeight: "bold",
              marginBottom : 10
            },
            showTitle : true,
            filterCellStyle : {
              backgroundColor : "#ccc"
            },
            initialPage : 0,
            pageSize : 25,
            pageSizeOptions: [10, 25, 50],
            draggable : false,
          }}
          localization={{
            pagination: {
              labelRowsSelect: props.translate(`accounts.index.table.rows`) as string,
              labelDisplayedRows: `{from}-{to} ${props.translate('accounts.index.table.of') as string} {count}`
            },
            toolbar: {
              searchPlaceholder: `${props.translate(`accounts.index.table.search`)}`
            }
          }}
        />
      </Card>
    </div>
  )
}

export default requiresAgencyAccounts()(
  requiresPacingStats()(
    withLocalize(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(AccountsList)
    )
  )
)
