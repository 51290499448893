import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import SalesProduct      from "../../../model/budgetEstimator/SalesProduct"


const Products = createReducer<SalesProduct[]>(null,{
  [ActionTypes.GET_SALES_PRODUCTS] : (state,action) => {
    return action.payload
  }
})

export default Products
