import {createReducer}         from "../../../utils/Reducers"
import ActionTypes             from "../../../model/constant/actions"
import { AccountsData }        from "../../../model/accounts/AccountsData"

export interface AdAccountsDataByAgencyId {
  [agencyId:string] : AdAccountsDataByAccountId
}
export interface AdAccountsDataByAccountId {
  [accountId:number] : AccountsData
}

const Agency = createReducer<AdAccountsDataByAgencyId>({}, {
  [ActionTypes.GET_AD_ACCOUNTS_DATA] : (state, action)=>{
    return {
      ...state,
      [action.payload.agencyId] : {
        ...state[action.payload.agencyId],
        [action.payload.accountId] : action.payload.accountsData
      }
    }
  }
})

export default Agency
