import React                  from 'react'
import useStyles, {
  StyledTableCell,
  StyledTableRow,
}                             from './styles'
import {
  Paper,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  CardActions,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Zoom,
}                             from '@material-ui/core'
import { State }              from '../../redux/reducers'
import { ThunkDispatch }      from 'redux-thunk'
import { DispatchableAction } from '../../model/constant/actions'
import { connect }            from 'react-redux'
import {
  Visibility,
  VisibilityOff
}                             from '@material-ui/icons'
import FileCopyIcon           from '@material-ui/icons/FileCopy'
import { Translate }          from 'react-localize-redux'
import { translationPaths }   from '../../translations/translations'
import Cookies                from 'js-cookie'
import DoneIcon               from '@material-ui/icons/Done'

interface UserOwnProps {

}
const makeMapStateToProps = () => {
  return (state:State, ownProps:UserOwnProps)=>{
    return {
      user               : state.Login.User
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:UserOwnProps)=>{
  return {}
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:UserOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type UserProps = UserOwnProps & ReturnType<typeof mergeProps>

const User = (props:UserProps) => {
  const classes = useStyles()
  const {user} ={...props}
  const [showToken,setShowToken] = React.useState<boolean>(false)
  const [showCopyMessage,setShowCopyMessage] = React.useState<boolean>(false)
  const handleLogout = () => {
    Cookies.remove("UID")
    document.location.reload()
  }
  //Auto Hide the copy message after 3s
  if(showCopyMessage) {
    setTimeout(() => {
      setShowCopyMessage(false)
    }, 3000)
  }
  return (
    <div className={classes.container}>
      <Card className={classes.user}>
        <CardHeader
          title={<Translate id={translationPaths.user.profile} />}
          titleTypographyProps={{variant:"h6"}}
        />
        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell><Translate id={translationPaths.user.firstName} /></StyledTableCell>
                  <StyledTableCell>{user.user.firstName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell><Translate id={translationPaths.user.lastName} /></StyledTableCell>
                  <StyledTableCell>{user.user.lastName}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell><Translate id={translationPaths.user.email} /></StyledTableCell>
                  <StyledTableCell>{user.user.email}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell><Translate id={translationPaths.user.cellPhone} /></StyledTableCell>
                  <StyledTableCell>{user.user.cellPhone}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell><Translate id={translationPaths.user.officePhone}/></StyledTableCell>
                  <StyledTableCell>{user.user.officePhone}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>{user.user.id}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <FormControl style={{marginTop:30}} fullWidth variant="outlined">
            <InputLabel>Token</InputLabel>
            <OutlinedInput
              type={showToken ? 'text' : 'password'}
              value={user.token}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={()=>{setShowToken(prevState=>!prevState)}}
                    edge="end"
                  >
                    {showToken ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <Divider orientation="horizontal" />
                  <IconButton
                    style={{marginLeft:5}}
                    onClick={()=>{navigator.clipboard.writeText(user.token);setShowCopyMessage(true)}}
                    edge="end"
                  >
                    {!showCopyMessage ? <FileCopyIcon /> : <Zoom timeout={250} in={showCopyMessage}><DoneIcon /></Zoom>}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText style={{height:10}}>{showCopyMessage && <Translate id={translationPaths.user.copied} />}</FormHelperText>
          </FormControl>
        </CardContent>
        <CardActions>
          <Button onClick={handleLogout} size="large" variant="contained" color="secondary">
            <Translate id={translationPaths.appBar.profileAndNotifications.logOut}/>
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(User)
