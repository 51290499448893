import React                from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Icon
}                           from '@material-ui/core'
import { translationPaths } from '../../translations/translations'
import { Translate }        from 'react-localize-redux'

interface PermissionBannerOwnProps {
  message ?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner : {
      display : 'flex',
      alignItems : 'center',
      justifyContent : 'space-around',
      backgroundColor:theme.palette.warning.dark,
      padding : 15,
      borderRadius : 5,
    },
    icon : {
      marginRight : 5,
      marginBottom : 3
    },
    text : {
      display:'flex',
      alignItems:'center',
      color : theme.palette.common.white
    }
  })
)

const PermissionBanner = (props:PermissionBannerOwnProps) => {
  const classes = useStyles()
  return (
    <div className={classes.banner}>
      <Typography className={classes.text}>
        <Icon className={classes.icon}>warning</Icon>
        <strong><Translate id={translationPaths.general.permissions.readOnly} /></strong> : {props.message}
      </Typography>
    </div>
  )
}

export default PermissionBanner
