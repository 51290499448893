import React                from 'react'
import useStyles            from './perChannelHoverStyles'
import BulletGraphMui       from '../BulletGraphMui'
import {StatPerChannel}     from '../../../model/store/statistics/PacingStats'
import { spectrumColors }   from '../../../Theme/Theme'
import {
  withLocalize,
  LocalizeContextProps
}                           from 'react-localize-redux'


interface PacingPerChannelOwnProps extends LocalizeContextProps {
  data : StatPerChannel[]
}

const PacingPerChannelHover = (props:PacingPerChannelOwnProps) => {
  const classes = useStyles()

  return (
    <div className={classes.pacingPerChannel}>
      <div className={classes.graphs}>
        {
          props.data &&
            props.data.map((stat, index)=>(
              <div key={stat.channel} className={classes.rowContainer}>
                <div className={classes.legends}>
                  {props.translate(`pacingOverview.pacingPerChannel.${[stat.channel.toLowerCase()]}`)}
                </div>
                <BulletGraphMui
                  hideValue={true}
                  key={stat.channel}
                  value={Math.round(stat.pacing*100)/100}
                  color={spectrumColors[index]}
                />
                <div className={classes.percentages}>
                  {(Math.round(stat.pacing)).toFixed(0)}%
                </div>
              </div>
            ))
        }
      </div>
    </div>
  )
}

export default withLocalize(PacingPerChannelHover)
