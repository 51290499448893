import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"

interface Retrieving {
  strategies          : boolean
  identityVariants    : boolean
  adTemplates         : boolean
  businesses          : boolean
  unusedAdAccounts    : boolean
  pages               : boolean
  facebookAdTemplates : boolean
  accountInfo         : boolean
}

const Retrieving = createReducer<Retrieving>(
  {
    identityVariants      : false,
    strategies            : false,
    adTemplates           : false,
    unusedAdAccounts      : false,
    businesses            : false,
    pages                 : false,
    facebookAdTemplates   : false,
    accountInfo           : false,
  },
  {
    [ActionTypes.RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES] : (state,action) => ({
      ...state,
      strategies : action.payload
    }),
    [ActionTypes.RETRIEVING_FACEBOOK_AD_TEMPLATES] : (state,action) => ({
      ...state,
      adTemplates : action.payload
    }),
    [ActionTypes.RETRIEVING_FACEBOOK_IDENTITY_VARIANTS] : (state,action) => ({
      ...state,
      identityVariants : action.payload
    }),
    [ActionTypes.RETRIEVING_FACEBOOK_BUSINESS] : (state,action) => ({
      ...state,
      businesses : action.payload
    }),
    [ActionTypes.RETRIEVING_FACEBOOK_PAGES] : (state,action) => ({
      ...state,
      pages : action.payload
    }),
    [ActionTypes.RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS] : (state,action) => ({
      ...state,
      unusedAdAccounts : action.payload
    }),
    [ActionTypes.RETRIEVING_FACEBOOK_DEFAULT_ADSET] : (state,action) => ({
      ...state,
      facebookAdTemplates : action.payload
    }),
  [ActionTypes.RETRIEVING_ACCOUNT_INFO_FIRESTONE] : (state,action) => ({
    ...state,
    facebookAdTemplates : action.payload
  })
})

export default Retrieving
