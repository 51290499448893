import * as React                    from "react"
import {connect}                     from "react-redux"
import {Dispatch}                    from "redux"
import {State}                       from "../redux/reducers/"
import {
  createSelectAgency,
  createSelectDealer
}                                    from "../actions/creators"
import {Action}                      from "../model/constant/actions"
import {Agency}                      from "../model/agencies/Agency"
import {
  Switch,
  Route,
}                                    from "react-router-dom"
import requiresAgencies              from "../components/loaders/loadAgencies"
import TempAppContent                from "../pages/TempAppContent"
import Pacing                        from "../pages/Pacing"
import Accounts                      from "../pages/Accounts"
import AgencySelectionModal          from "../pages/AgencySelection/AgencySelectionModal"
import Insights                      from "../pages/Insights"
import AccountDetails                from "./AccountDetails"
import BudgetCalculatorContainer     from "../pages/BudgetCalculator/Container"

interface AgencyRoutingOwnProps{
  path ?: string
  pathAgency ?: string
  agencies : Agency[]
}

const mapStateToProps = (state:State, ownProps:AgencyRoutingOwnProps)=>{
  return {
    selectedAgency : state.Selection.Agency,
  }
}
const mapDispatchToProps = (dispatch:Dispatch<Action>, ownProps:AgencyRoutingOwnProps)=>{
  return {
    selectAgency : (...args:Parameters<typeof createSelectAgency>)=>dispatch(createSelectAgency(...args)),
    selectDealer : (...args:Parameters<typeof createSelectDealer>)=>dispatch(createSelectDealer(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AgencyRoutingOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type AgencyRoutingProps = ReturnType<typeof mergeProps>
const AgencyRouting:React.SFC<AgencyRoutingProps> = (props:AgencyRoutingProps) => {
  if(props.pathAgency && props.pathAgency !== props.selectedAgency){
    //Redirect to agency selection if invalid or unknown agency id in path
    if(!props.agencies || !props.agencies.some(agency=>agency.id+"" === props.pathAgency)){
      return <AgencySelectionModal open={true} />
    }
    props.selectAgency(props.pathAgency)
    return null //keep path, just need to rerender with the correct agency selected
  }
  const previousPath = ("/"+props.path+"/").replace("//","/")

  return (
    <Switch>
      <Route path={previousPath+"BudgetCalculator"} component={BudgetCalculatorContainer} />
      <Route path={previousPath+"Report/Pacing"} render={(props)=><Pacing />} />
      <Route
        path={previousPath+"Accounts/:accountId"}
        render={(props)=><AccountDetails pathDealer={props.match.params.accountId}/>}
      />
      <Route path={previousPath+"Accounts"} render={(props)=><Accounts />} />
      <Route
        path={previousPath+"Insights"} render={(props)=><Insights />}
      />
      <Route path={previousPath} render={(props)=><TempAppContent/>}/>
    </Switch>
  )
}
AgencyRouting.defaultProps = {
  path : ""
}

export default requiresAgencies({letThrough:false})(connect(mapStateToProps,mapDispatchToProps,mergeProps)(AgencyRouting))
