import SummaryTemplate from "../Summary"
import { Month }       from "../../constant/Months"

export const handleNewVehicleBlur = (month:string,summary:SummaryTemplate):SummaryTemplate => {
  if(month === 'january') {
    return {
      ...summary,
      targets : {
        ...summary.targets,
        NEW : {
          ...summary.targets.NEW,
          february : summary.targets.NEW.february || summary.targets.NEW.january,
          march : summary.targets.NEW.march || summary.targets.NEW.january,
          april : summary.targets.NEW.april || summary.targets.NEW.january,
          may : summary.targets.NEW.may || summary.targets.NEW.january,
          june : summary.targets.NEW.june || summary.targets.NEW.january,
          july : summary.targets.NEW.july || summary.targets.NEW.january,
          august : summary.targets.NEW.august || summary.targets.NEW.january,
          september : summary.targets.NEW.september || summary.targets.NEW.january,
          october : summary.targets.NEW.october || summary.targets.NEW.january,
          november : summary.targets.NEW.november || summary.targets.NEW.january,
          december : summary.targets.NEW.december || summary.targets.NEW.january,
        }
      }
    }
  }
  return summary
}
export const handleUsedVehicleBlur = (month:string,summary:SummaryTemplate):SummaryTemplate => {
  if(month === 'january') {
    return {
      ...summary,
      targets : {
        ...summary.targets,
        USED : {
          ...summary.targets.USED,
          february : summary.targets.USED.february || summary.targets.USED.january,
          march : summary.targets.USED.march || summary.targets.USED.january,
          april : summary.targets.USED.april || summary.targets.USED.january,
          may : summary.targets.USED.may || summary.targets.USED.january,
          june : summary.targets.USED.june || summary.targets.USED.january,
          july : summary.targets.USED.july || summary.targets.USED.january,
          august : summary.targets.USED.august || summary.targets.USED.january,
          september : summary.targets.USED.september || summary.targets.USED.january,
          october : summary.targets.USED.october || summary.targets.USED.january,
          november : summary.targets.USED.november || summary.targets.USED.january,
          december : summary.targets.USED.december || summary.targets.USED.january,
        }
      }
    }
  }
  return summary
}
export const handleBudgetUpdateBlur = (month:string,summary:SummaryTemplate):SummaryTemplate => {
  if(month === 'january') {
    return {
      ...summary,
      targets : {
        ...summary.targets,
        NONE : {
          ...summary.targets.NONE,
          february : summary.targets.NONE.february || summary.targets.NONE.january,
          march : summary.targets.NONE.march || summary.targets.NONE.january,
          april : summary.targets.NONE.april || summary.targets.NONE.january,
          may : summary.targets.NONE.may || summary.targets.NONE.january,
          june : summary.targets.NONE.june || summary.targets.NONE.january,
          july : summary.targets.NONE.july || summary.targets.NONE.january,
          august : summary.targets.NONE.august || summary.targets.NONE.january,
          september : summary.targets.NONE.september || summary.targets.NONE.january,
          october : summary.targets.NONE.october || summary.targets.NONE.january,
          november : summary.targets.NONE.november || summary.targets.NONE.january,
          december : summary.targets.NONE.december || summary.targets.NONE.january,
        }
      }
    }
  }
  return summary
}
export const handleNewVehiclesTargetUpdate = (value:number,month:string,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    targets : {
      ...summary.targets,
      NEW : {
        ...summary.targets.NEW,
        [month] : Math.abs(value) || 0
      }
    }
  }
}

export const handleUsedVehiclesTargetUpdate = (value:number,month:string,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    targets : {
      ...summary.targets,
      USED : {
        ...summary.targets.USED,
        [month] : Math.abs(value) || 0
      }
    }
  }
}
export const handleAdditionalBudgetUpdate = (value:number,month:string,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    targets : {
      ...summary.targets,
      NONE : {
        ...summary.targets.NONE,
        [month] : Math.abs(value) || 0
      }
    }
  }
}
export const handleStartingMonthUpdate = (value:Month,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    startingMonth : value
  }
}
