import {
  makeStyles,
  Theme,
  createStyles
}                   from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container : {
      minWidth : 500
    },
    title : {
      fontSize:24,
      textAlign : 'center'
    },
    content :{
      display : 'flex',
      width : '100%',
      flexDirection : 'column',
      alignItems : 'center',
      justifyContent : 'space-around'
    },
    footer : {
      display:'flex',
      justifyContent:'flex-end'
    },
    select : {
    },
    formControl : {
      minWidth : 300,
      margin : theme.spacing(1)
    },
    adAccountMessage : {
      marginBottom:10,
      color : theme.palette.info.main,
      display : 'flex',
      alignItems:'center'
    }
  })
)

export default useStyles
