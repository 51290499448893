import {
  Dispatch,
  Middleware
}                          from "redux"
import {State}             from "../reducers/"
import {Action}            from "../../model/constant/actions"

const reduceLogs = parseInt(process.env.REDUCE_LOGS+"",10)

const logger:Middleware<Dispatch<Action>, State> = (store) => (next) => (action) => {
  if(!reduceLogs){
    console.group(action.type)
    console.info('dispatching', action)
  }
  const result = next(action)
  if(!reduceLogs){
    console.log('next state', store.getState())
    console.groupEnd()
  }
  return result
}

export default logger
