import React                            from 'react'
import {
  Card,
  TextField,
  Typography,
  InputAdornment,
  Icon
}                                       from '@material-ui/core'

import Autocomplete, {
  createFilterOptions
}                                       from '@material-ui/lab/Autocomplete'
import useStyles                        from './ContactInfoStyles'
import requiresSalesforceAccounts       from '../../../components/loaders/Sales/loadSalesforceAccounts'
import { State }                        from '../../../redux/reducers'
import { ThunkDispatch }                from 'redux-thunk'
import { DispatchableAction }           from '../../../model/constant/actions'
import SalesforceAccount                from '../../../model/Salesforce/Account'
import { connect }                      from 'react-redux'
import DealerContact                    from './DealerContact'
import { getSalesforceAccountContacts } from '../../../actions/Salesforce-etl/Contacts'
import { createUpdateDraftSummary }     from '../../../actions/creators'
import {
  handleUpdateSummaryAccountId,
  handleUpdateSummaryContactId,
  handleUpdateSummaryDealerName
}                                       from '../../../model/budgetEstimator/Handlers/ContactInfo'
import SummaryTemplate                  from '../../../model/budgetEstimator/Summary'

interface ContactInfoOwnProps {
  salesforceAccounts : SalesforceAccount[]
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:ContactInfoOwnProps)=>{
    return {
      user         : state.Login.User.user,
      contactsInfo : state.Sales.Salesforce.Contacts,
      summary      : state.Sales.DraftSummary as SummaryTemplate
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:ContactInfoOwnProps)=>{
  return {
    getSalesforceAccountContacts : (...args:Parameters<typeof getSalesforceAccountContacts>)=>dispatch(getSalesforceAccountContacts(...args)),
    updateDraftSummary : (...args:Parameters<typeof createUpdateDraftSummary>)=>dispatch(createUpdateDraftSummary(...args))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:ContactInfoOwnProps)=>{
  return {...SP,...DP,...ownProps}
}
type ContactInfoProps = ReturnType<typeof mergeProps>

const ContactInfo = (props:ContactInfoProps) => {
  const updateDraftSummary = (summary:SummaryTemplate) => {
    props.updateDraftSummary({summary})
  }
  const summaryAccount = props.salesforceAccounts.find(s=>s.SalesforceId===props.summary.salesforceAccountId)
  if(summaryAccount && !props.contactsInfo) {
    props.getSalesforceAccountContacts(summaryAccount.SalesforceId)
  }
  const [selectedAccount, setSelectedAccount] = React.useState<SalesforceAccount | null>(summaryAccount || null)
  const classes = useStyles()
  const onAccountSelect = (event: any, newValue: SalesforceAccount | null) => {
    if(newValue) {
      props.getSalesforceAccountContacts(newValue.SalesforceId)
      setSelectedAccount(newValue)
      const newSummary = handleUpdateSummaryContactId('',props.summary)
      const summaryWithDealerName = handleUpdateSummaryDealerName(newValue.Name,newSummary)
      updateDraftSummary(handleUpdateSummaryAccountId(newValue.SalesforceId,summaryWithDealerName))
    }
  }
  const onContactSelect = (contactId:string) => {
    updateDraftSummary(handleUpdateSummaryContactId(contactId,props.summary))
  }
  return (
    <Card className={classes.contactInfo}>
      <Typography variant="button" color="primary" className={classes.title}>Contact information & Budget</Typography>
      <div className={classes.form}>
        <div className={classes.formControl}>
          <TextField
            fullWidth
            className={classes.input}
            label="TRFFK Rep Name"
            variant="outlined"
            disabled
            value={props.summary.repName}
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.adornment} position="start">
                  <Icon>account_box</Icon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.formControl}>
          <Autocomplete
            value={selectedAccount}
            onChange={onAccountSelect}
            filterOptions={createFilterOptions({
              limit : 200
            })}
            options={props.salesforceAccounts}
            getOptionLabel={(option) => option.Name}
            renderInput={(params) => <TextField fullWidth {...params} label="Account Name" variant="outlined" />}
          />
        </div>
        <DealerContact
          onContactSelect={onContactSelect}
          selectedAccount={selectedAccount}
          contactInfos={props.contactsInfo}
          selectedDealerGroup={selectedAccount ? props.salesforceAccounts.find(account=>selectedAccount.Dealer_Group === account.SalesforceId)?.Name : undefined}
          summary={props.summary}
        />
        {!props.summary.contactId && <Typography style={{width:'100%',display:'flex',justifyContent:'flex-end'}} variant="caption" color="secondary">Make sure to assign an account and contact</Typography>}
      </div>
    </Card>
  )
}

export default requiresSalesforceAccounts()(
  connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
    ContactInfo
  )
)
