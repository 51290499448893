import {combineReducers}     from "redux"
import {connectRouter}       from "connected-react-router"
import {History}             from "history"
import Login                 from "./Login/Login"
import RequestFailures       from "./RequestFailures/RequestFailures"
import Accounts              from "./Accounts/Accounts"
import AdAccountsData        from './AdAccountsData/AdAccountsData'
import Agencies              from "./Agencies/Agencies"
import Selection             from "./Selection/Selection"
import Statistics            from "./Statistics"
import Insights              from "./Insights"
import Feeds                 from "./Feeds"
import Facebook              from "./Facebook"
import Sales                 from "./Sales"

const createRootReducer = (history:History) => combineReducers({
  router : connectRouter(history),
  Login,
  RequestFailures,
  Accounts,
  AdAccountsData,
  Agencies,
  Selection,
  Statistics,
  Insights,
  Feeds,
  Facebook,
  Sales,
})
export default createRootReducer

export type State = ReturnType<ReturnType<typeof createRootReducer>>
