import { combineReducers }             from "redux"
import GoogleTrendsStatsReducer        from './GoogleTrendsStats'
import InventoryStatsReducer           from './InventoryStats'
import TrackingStatsReducer            from './TrackingStats'
import Retrieving                      from './Retrieving'
import FailedRetrieving                from './FailedRetrieving'


const Insights = combineReducers({
GoogleTrendsStatsReducer,
InventoryStatsReducer,
TrackingStatsReducer,
Retrieving,
FailedRetrieving
})

export default Insights
