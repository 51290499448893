import {
  makeStyles,
  Theme,
  createStyles
}               from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileMenu : {
      alignItems : 'center',
      display : 'flex',
    },
    profileIcons : {
      marginRight : 10
    },
    iconButton : {
      marginRight:5
    },
    menu : {
      maxHeight : 600,
      overflow: 'scroll'
    }
  })
)

export default useStyles
