import * as React                 from "react"
import {connect}                  from "react-redux"
import {ThunkDispatch}            from "redux-thunk"
import {State}                    from "../../redux/reducers"
import {Action}                   from "../../model/constant/actions"
import Loading                    from "../loadings/CircularLoading"
import {getTrackingStats}         from "../../actions/Reporting/tracking"
import moment                     from 'moment'
import AgencySelectionModal       from "../../pages/AgencySelection/AgencySelectionModal"
import { translationPaths }       from "../../translations/translations"

interface PacingStatsOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}

const mapStateToProps = (state:State, ownProps:PacingStatsOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    trackingStats           : state.Insights.TrackingStatsReducer,
    retrieving              : state.Insights.Retrieving.trackingStats,
    failedRetrieving        : state.Insights.FailedRetrieving.trackingStats,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:PacingStatsOwnProps)=>{
  return {
    getTrackingStats : (...args:Parameters<typeof getTrackingStats>)=>dispatch(getTrackingStats(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:PacingStatsOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingTrackingStats = false
  if(props.selectedAgency === null){
    return (
      <AgencySelectionModal open={true} />
    )
  }
  if(!props.trackingStats[props.selectedAgency]){
    loadingTrackingStats = true
    if(!props.retrieving){
      props.getTrackingStats(
        props.selectedAgency,
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      )
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.trackingStats.loading} doTranslate={true} />
    }
  }
  return props.render({loadingTrackingStats, trackingStats: props.trackingStats[props.selectedAgency]})
})

interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  trackingStats : State["Insights"]["TrackingStatsReducer"][0]
  loadingTrackingStats : boolean
}

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresTrackingStats:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresTrackingStats
