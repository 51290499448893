import {
  makeStyles,
  Theme,
}                         from "@material-ui/core"


const useStyles = makeStyles((theme:Theme)=>({
  login: {
    height: "100vh",
    overflow: 'hidden',
  },
  container: {
    display: "grid",
    height: "100vh",
    gridTemplateColumns: "44% 56%",
  },
  rightImage: {
    background: theme.palette.primary.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: '100%',
    background: 'white',
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: 450,
    width: 350,
    justifyContent: "space-around",
  },
  title: {
    lineHeight: 1.35,
    letterSpacing: 7,
    fontSize: 57,
    marginBottom: 10,
  },
  information: {
    width: 450,
  },
  messageContainer: {
    height: 40,
    marginBottom: 30,
  },
  successContainer: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  success: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  successCheck: {
    color: theme.palette.success.light,
    fontSize: 100,
  },
  informationContainer: {
    marginTop: "-20px",
    marginBottom: 10,
  },
  successInformation: {
    margin: 10,
  },
  doneButton: {
    padding: 12,
    width: 130,
    '& span': {
      fontWeight: 600,
    },
  },
  error: {
    position: "fixed",
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: theme.palette.error.light,
    color: "white",
    borderRadius: 5,
    padding: 10,
    width: 350,
  },
  errorText: {
    transition: ".2s",
    color: theme.palette.error.light,
    padding: 2,
    borderRadius: 2,
    display: "inline-block"
  },
  errorTextActive: {
    transition: ".2s",
    backgroundColor: theme.palette.error.light,
    color: "white",
  },
  email: {
    marginTop: 16,
    height: 120,
  },
  inputText: {
    letterSpacing: 0,
    '& fieldset': {
      border: "1px solid #7f7f7f",
    }
  },
  inputError: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: "12px!important",
    width: 190,
    '& span': {
      fontWeight: 600,
    },
  }
}))

export default useStyles
