import { Action as BaseAction }          from "redux"
import {User}                            from "../users/User"
import {RequestFailure}                  from "../store/RequestFailure"
import {Account}                         from "../accounts/Account"
import {Agency}                          from "../agencies/Agency"
import {CallHistoryMethodAction}         from "connected-react-router"
import PacingStats                       from "../http/Reporting/PacingStats"
import { PacingDataToCompile }           from "../store/statistics/PacingStats"
import GoogleTrendsStats                 from "../http/Reporting/GoogleTrendsStats"
import InventoryStats                    from "../http/Reporting/InventoryStats"
import TrackingStats                     from "../http/Reporting/TrackingStats"
import { AccountsData }                  from "../accounts/AccountsData"
import Feed                              from "../feeds/Feed"
import {Success as Strategy}             from "../http/Headstone/GetFacebookStrategies"
import {Success as FacebookAdTemplates } from "../http/Headstone/GetFacebookAdTemplates"
import {Success as IdentityVariants}     from "../http/Headstone/GetFacebookIdentityVariants"
import {Success as AccountInfoFireStone} from "../http/Firestone/GetAccountInfo"
import Business                          from "../Facebook/Business"
import Pages                             from "../Facebook/Pages"
import AdTemplate                        from "../Facebook/AdTemplate"
import AdAccounts                        from "../Facebook/AdAccounts"
import VehicleState                      from "./VehicleState"
import Languages                         from "./Languages"
import SummaryTemplate, {
  Breakdown,
  SummaryWithPresentationByAccountId
}                                        from "../budgetEstimator/Summary"
import SalesCampaign                     from "../budgetEstimator/SalesCampaign"
import SalesProduct                      from "../budgetEstimator/SalesProduct"
import SalesforceAccount                 from "../Salesforce/Account"
import SalesforceContact                 from "../Salesforce/Contact"
import {
  AccountPermission,
  PermissionsBySystem
}                                        from "../users/Permissions"

enum ActionTypes {
  LOGIN                                                            = "LOGIN",
  FAILED_LOGIN                                                     = "FAILED_LOGIN",
  LOGGING_IN                                                       = "LOGGING_IN",
  GET_AGENCY_ACCOUNTS                                              = "GET_AGENCY_ACCOUNTS",
  RETRIEVING_AGENCY_ACCOUNTS                                       = "RETRIEVING_AGENCY_ACCOUNTS",
  FAILED_RETRIEVING_AGENCIES_ACCOUNTS                              = "FAILED_RETRIEVING_AGENCIES_ACCOUNTS",
  FAILED_REQUEST                                                   = "FAILED_REQUEST",
  REMOVE_FAILED_REQUEST                                            = "REMOVE_FAILED_REQUEST",
  GET_AGENCIES                                                     = "GET_AGENCIES",
  RETRIEVING_AGENCIES                                              = "RETRIEVING_AGENCIES",
  FAILED_RETRIEVING_AGENCIES                                       = "FAILED_RETRIEVING_AGENCIES",
  SELECT_AGENCY                                                    = "SELECT_AGENCY",
  SELECT_DEALER                                                    = "SELECT_DEALER",
  GET_PACING_STATS                                                 = "GET_PACING_STATS",
  RETRIEVING_PACING_STATS                                          = "RETRIVEING_PACING_STATS",
  CREATE_PACING_DATA_TO_COMPILE                                    = "CREATE_PACING_DATA_TO_COMPILE",
  FORGOT_PASSWORD_RETRIEVED                                        = "FORGOT_PASSWORD_RETRIEVED",
  RESETTING_PASSWORD                                               = "RESETTING_PASSWORD",
  RETRIEVING_GOOGLE_TRENDS_STATS                                   = "RETRIEVING_GOOGLE_TRENDS_STATS",
  GET_GOOGLE_TRENDS_STATS                                          = "GET_GOOGLE_TRENDS_STATS",
  FAILED_RETRIEVING_GOOGLE_TRENDS_STATS                            = "FAILED_RETRIEVING_GOOGLE_TRENDS_STATS",
  RETRIEVING_INVENTORY_STATS                                       = "RETRIEVING_INVENTORY_STATS",
  GET_INVENTORY_STATS                                              = "GET_INVENTORY_STATS",
  FAILED_RETRIEVING_INVENTORY_STATS                                = "FAILED_RETRIEVING_INVENTORY_STATS",
  RETRIEVING_TRACKING_STATS                                        = "RETRIEVING_TRACKING_STATS",
  GET_TRACKING_STATS                                               = "GET_TRACKING_STATS",
  FAILED_RETRIEVING_TRACKING_STATS                                 = "FAILED_RETRIEVING_TRACKING_STATS",
  GET_AD_ACCOUNTS_DATA                                             = "GET_AD_ACCOUNTS_DATA",
  RETRIEVING_AD_ACCOUNTS_DATA                                      = "RETRIEVING_AD_ACCOUNTS_DATA",
  FAILED_RETRIEVING_AD_ACCOUNTS_DATA                               = "FAILED_RETRIEVING_AD_ACCOUNTS_DATA",
  GET_ACCOUNT_FEEDS                                                = "GET_ACCOUNT_FEEDS",
  CLEAR_ACCOUNT_FEEDS                                              = "CLEAR_ACCOUNT_FEEDS",
  RETRIEVING_ACCOUNT_FEEDS                                         = "RETRIEVING_ACCOUNT_FEEDS",
  FAILED_RETRIEVING_ACCOUNT_FEEDS                                  = "FAILED_RETRIEVING_ACCOUNT_FEEDS",
  GET_FACEBOOK_ACCOUNT_STRATEGIES                                  = "GET_FACEBOOK_ACCOUNT_STRATEGIES",
  RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES                           = "RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES",
  FAILED_RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES                    = "FAILED_RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES",
  GET_FACEBOOK_DEFAULT_ADSET                                       = "GET_FACEBOOK_DEFAULT_ADSET",
  RETRIEVING_FACEBOOK_DEFAULT_ADSET                                = "RETRIEVING_FACEBOOK_DEFAULT_ADSET",
  FAILED_RETRIEVING_FACEBOOK_DEFAULT_ADSET                         = "FAILED_RETRIEVING_FACEBOOK_DEFAULT_ADSET",
  CREATING_FACEBOOK_STRATEGY                                       = "CREATING_FACEBOOK_STRATEGY",
  FAILED_CREATING_FACEBOOK_STRATEGY                                = "FAILED_CREATING_FACEBOOK_STRATEGY",
  UPDATING_FACEBOOK_STRATEGY                                       = "UPDATING_FACEBOOK_STRATEGY",
  FAILED_UPDATING_FACEBOOK_STRATEGY                                = "FAILED_UPDATING_FACEBOOK_STRATEGY",
  GET_FACEBOOK_AD_TEMPLATES                                        = "GET_FACEBOOK_AD_TEMPLATES",
  RETRIEVING_FACEBOOK_AD_TEMPLATES                                 = "RETRIEVING_FACEBOOK_AD_TEMPLATES",
  FAILED_RETRIEVING_FACEBOOK_AD_TEMPLATES                          = "FAILED_RETRIEVING_FACEBOOK_AD_TEMPLATES",
  CREATING_FACEBOOK_AD_TEMPLATES                                   = "CREATING_FACEBOOK_AD_TEMPLATES",
  FAILED_CREATING_FACEBOOK_AD_TEMPLATES                            = "FAILED_CREATING_FACEBOOK_AD_TEMPLATES",
  UPDATING_FACEBOOK_AD_TEMPLATES                                   = "UPDATING_FACEBOOK_AD_TEMPLATES",
  FAILED_UPDATING_FACEBOOK_AD_TEMPLATES                            = "FAILED_UPDATING_FACEBOOK_AD_TEMPLATES",
  GET_FACEBOOK_IDENTITY_VARIANTS                                   = "GET_FACEBOOK_IDENTITY_VARIANTS",
  RETRIEVING_FACEBOOK_IDENTITY_VARIANTS                            = "RETRIEVING_FACEBOOK_IDENTITY_VARIANTS",
  FAILED_RETRIEVING_FACEBOOK_IDENTITY_VARIANTS                     = "FAILED_RETRIEVING_FACEBOOK_IDENTITY_VARIANTS",
  CREATING_FACEBOOK_IDENTITY_VARIANTS                              = "CREATING_FACEBOOK_IDENTITY_VARIANTS",
  FAILED_CREATING_FACEBOOK_IDENTITY_VARIANTS                       = "FAILED_CREATING_FACEBOOK_IDENTITY_VARIANTS",
  UPDATING_FACEBOOK_IDENTITY_VARIANTS                              = "UPDATING_FACEBOOK_IDENTITY_VARIANTS",
  FAILED_UPDATING_FACEBOOK_IDENTITY_VARIANTS                       = "FAILED_UPDATING_FACEBOOK_IDENTITY_VARIANTS",
  RETRIEVING_FACEBOOK_BUSINESS                                     = "RETRIEVING_FACEBOOK_BUSINESS",
  GET_FACEBOOK_BUSINESS                                            = "GET_FACEBOOK_BUSINESS",
  FAILED_RETRIEVING_FACEBOOK_BUSINESS                              = "FAILED_RETRIEVING_FACEBOOK_BUSINESS",
  RETRIEVING_FACEBOOK_PAGES                                        = "RETRIEVING_FACEBOOK_PAGES",
  GET_FACEBOOK_PAGES                                               = "GET_FACEBOOK_PAGES",
  FAILED_RETRIEVING_FACEBOOK_PAGES                                 = "FAILED_RETRIEVING_FACEBOOK_PAGES",
  RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS                           = "RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS",
  GET_FACEBOOK_UNUSED_AD_ACCOUNTS                                  = "GET_FACEBOOK_UNUSED_AD_ACCOUNTS",
  FAILED_RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS                    = "FAILED_RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS",
  SUBMITTING_FACEBOOK_ACCOUNT_ID                                   = "SUBMITTING_FACEBOOK_ACCOUNT_ID",
  FAILED_SUBMITTING_FACEBOOK_ACCOUNT_ID                            = "FAILED_SUBMITTING_FACEBOOK_ACCOUNT_ID",
  SUBMITTING_FACEBOOK_CONFIGURATION                                = "SUBMITTING_FACEBOOK_CONFIGURATION",
  FAILED_SUBMITTING_FACEBOOK_CONFIGURATION                         = "FAILED_SUBMITTING_FACEBOOK_CONFIGURATION",
  GET_ACCOUNT_INFO_FIRESTONE                                       = "GET_ACCOUNT_INFO_FIRESTONE",
  RETRIEVING_ACCOUNT_INFO_FIRESTONE                                = "RETRIEVING_ACCOUNT_INFO_FIRESTONE",
  FAILED_RETRIEVING_ACCOUNT_INFO_FIRESTONE                         = "FAILED_RETRIEVING_ACCOUNT_INFO_FIRESTONE",
  RETRIEVING_SUMMARY                                               = "RETRIEVING_SUMMARY",
  GET_SUMMARY                                                      = "GET_SUMMARY",
  FAILED_RETRIEVING_SUMMARY                                        = "FAILED_RETRIEVING_SUMMARY",
  GET_BREAKDOWN                                                    = "GET_BREAKDOWN",
  RETRIEVING_BREAKDOWN                                             = "RETRIEVING_BREAKDOWN",
  FAILED_RETRIEVING_BREAKDOWN                                      = "FAILED_RETRIEVING_BREAKDOWN",
  CREATING_SUMMARY                                                 = "CREATING_SUMMARY",
  FAILED_CREATING_SUMMARY                                          = "FAILED_CREATING_SUMMARY",
  UPDATING_SUMMARY                                                 = "UPDATING_SUMMARY",
  FAILED_UPDATING_SUMMARY                                          = "FAILED_UPDATING_SUMMARY",
  CREATE_DRAFT_SUMMARY                                             = "CREATE_DRAFT_SUMMARY",
  UPDATE_DRAFT_SUMMARY                                             = "UPDATE_DRAFT_SUMMARY",
  RESET_DRAFT_SUMMARY                                              = "RESET_DRAFT_SUMMARY",
  CLEAR_CURRENT_BREAKDOWN                                          = "CLEAR_CURRENT_BREAKDOWN",
  RETRIEVING_SALES_CAMPAIGNS                                       = "RETRIEVING_SALES_CAMPAIGNS",
  FAILED_RETRIEVING_SALES_CAMPAIGNS                                = "FAILED_RETRIEVING_SALES_CAMPAIGNS",
  GET_SALES_CAMPAIGNS                                              = "GET_SALES_CAMPAIGNS",
  RETRIEVING_SALES_PRODUCTS                                        = "RETRIEVING_SALES_PRODUCTS",
  FAILED_RETRIEVING_SALES_PRODUCTS                                 = "FAILED_RETRIEVING_SALES_PRODUCTS",
  GET_SALES_PRODUCTS                                               = "GET_SALES_PRODUCTS",
  RETRIEVING_SALESFORCE_ACCOUNTS                                   = "RETRIEVING_SALESFORCE_ACCOUNTS",
  FAILED_RETRIEVING_SALESFORCE_ACCOUNTS                            = "FAILED_RETRIEVING_SALESFORCE_ACCOUNTS",
  GET_SALESFORCE_ACCOUNTS                                          = "GET_SALESFORCE_ACCOUNTS",
  RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS                           = "RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS",
  FAILED_RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS                    = "FAILED_RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS",
  GET_SALESFORCE_ACCOUNT_CONTACTS                                  = "GET_SALESFORCE_ACCOUNT_CONTACTS",
  GET_ACCOUNT_PERMISSIONS                                          = "GET_ACCOUNT_PERMISSIONS",
  RETRIEVING_ACCOUNT_PERMISSIONS                                   = "RETRIEVING_ACCOUNT_PERMISSIONS",
  RETRIEVING_SYSTEMS_PERMISSIONS                                    = "RETRIEVING_SYSTEMS_PERMISSIONS",
  GET_SYSTEMS_PERMISSIONS                                           = "GET_SYSTEMS_PERMISSIONS",
}
export default ActionTypes

type ActionMap = {
  [ActionTypes.LOGIN]                                         : User
  [ActionTypes.FAILED_LOGIN]                                  : string
  [ActionTypes.LOGGING_IN]                                    : boolean
  [ActionTypes.GET_AGENCY_ACCOUNTS]                           : {agencyId: string, accounts: Account[]}
  [ActionTypes.RETRIEVING_AGENCY_ACCOUNTS]                    : boolean
  [ActionTypes.FAILED_REQUEST]                                : RequestFailure
  [ActionTypes.REMOVE_FAILED_REQUEST]                         : number
  [ActionTypes.GET_AGENCIES]                                  : Agency[]
  [ActionTypes.RETRIEVING_AGENCIES]                           : boolean
  [ActionTypes.SELECT_AGENCY]                                 : string
  [ActionTypes.SELECT_DEALER]                                 : number
  [ActionTypes.FAILED_RETRIEVING_AGENCIES]                    : boolean
  [ActionTypes.FAILED_RETRIEVING_AGENCIES_ACCOUNTS]           : boolean
  [ActionTypes.GET_PACING_STATS]                              : {agencyId:string,pacingStats:PacingStats[],months:string[]}
  [ActionTypes.RETRIEVING_PACING_STATS]                       : boolean
  [ActionTypes.CREATE_PACING_DATA_TO_COMPILE]                 : PacingDataToCompile
  [ActionTypes.FORGOT_PASSWORD_RETRIEVED]                     : string
  [ActionTypes.RESETTING_PASSWORD]                            : boolean
  [ActionTypes.RETRIEVING_GOOGLE_TRENDS_STATS]                : boolean
  [ActionTypes.GET_GOOGLE_TRENDS_STATS]                       : {agencyId:string,googleTrendsStats:GoogleTrendsStats[]}
  [ActionTypes.FAILED_RETRIEVING_GOOGLE_TRENDS_STATS]         : boolean
  [ActionTypes.RETRIEVING_INVENTORY_STATS]                    : boolean
  [ActionTypes.GET_INVENTORY_STATS]                           : {agencyId:string,inventoryStats:InventoryStats[]}
  [ActionTypes.FAILED_RETRIEVING_INVENTORY_STATS]             : boolean
  [ActionTypes.RETRIEVING_TRACKING_STATS]                     : boolean
  [ActionTypes.GET_TRACKING_STATS]                            : {agencyId:string,trackingStats:TrackingStats[]}
  [ActionTypes.FAILED_RETRIEVING_TRACKING_STATS]              : boolean
  [ActionTypes.GET_AD_ACCOUNTS_DATA]                          : {agencyId:string, accountId:number, accountsData: AccountsData}
  [ActionTypes.RETRIEVING_AD_ACCOUNTS_DATA]                   : boolean
  [ActionTypes.FAILED_RETRIEVING_AD_ACCOUNTS_DATA]            : boolean
  [ActionTypes.GET_ACCOUNT_FEEDS]                             : {accountId:number,agencyId:string,feeds:Feed[]}
  [ActionTypes.CLEAR_ACCOUNT_FEEDS]                           : {accountId:number,agencyId:string}
  [ActionTypes.RETRIEVING_ACCOUNT_FEEDS]                      : boolean
  [ActionTypes.FAILED_RETRIEVING_ACCOUNT_FEEDS]               : boolean
  [ActionTypes.GET_FACEBOOK_ACCOUNT_STRATEGIES]               : {accountId:number,agencyId:string,strategies:Strategy[]}
  [ActionTypes.RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES]        : boolean
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_ACCOUNT_STRATEGIES] : boolean
  [ActionTypes.GET_FACEBOOK_DEFAULT_ADSET]                    : {accountId:number,AdTemplates:FacebookAdTemplates[],lang:Languages,state:VehicleState}
  [ActionTypes.RETRIEVING_FACEBOOK_DEFAULT_ADSET]             : boolean
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_DEFAULT_ADSET]      : boolean
  [ActionTypes.CREATING_FACEBOOK_STRATEGY]                    : boolean
  [ActionTypes.FAILED_CREATING_FACEBOOK_STRATEGY]             : boolean
  [ActionTypes.UPDATING_FACEBOOK_STRATEGY]                    : boolean
  [ActionTypes.FAILED_UPDATING_FACEBOOK_STRATEGY]             : boolean
  [ActionTypes.GET_FACEBOOK_AD_TEMPLATES]                     : {accountId:number,adTemplates:AdTemplate[]}
  [ActionTypes.RETRIEVING_FACEBOOK_AD_TEMPLATES]              : boolean
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_AD_TEMPLATES]       : boolean
  [ActionTypes.CREATING_FACEBOOK_AD_TEMPLATES]                : boolean
  [ActionTypes.FAILED_CREATING_FACEBOOK_AD_TEMPLATES]         : boolean
  [ActionTypes.UPDATING_FACEBOOK_AD_TEMPLATES]                : boolean
  [ActionTypes.FAILED_UPDATING_FACEBOOK_AD_TEMPLATES]         : boolean
  [ActionTypes.GET_FACEBOOK_IDENTITY_VARIANTS]                : {accountId:number,identityVariants:IdentityVariants[]}
  [ActionTypes.RETRIEVING_FACEBOOK_IDENTITY_VARIANTS]         : boolean
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_IDENTITY_VARIANTS]  : boolean
  [ActionTypes.CREATING_FACEBOOK_IDENTITY_VARIANTS]           : boolean
  [ActionTypes.FAILED_CREATING_FACEBOOK_IDENTITY_VARIANTS]    : boolean
  [ActionTypes.UPDATING_FACEBOOK_IDENTITY_VARIANTS]           : boolean
  [ActionTypes.FAILED_UPDATING_FACEBOOK_IDENTITY_VARIANTS]    : boolean
  [ActionTypes.RETRIEVING_FACEBOOK_BUSINESS]                  : boolean
  [ActionTypes.GET_FACEBOOK_BUSINESS]                         : {businesses : Business[]}
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_BUSINESS]           : boolean
  [ActionTypes.RETRIEVING_FACEBOOK_PAGES]                     : boolean
  [ActionTypes.GET_FACEBOOK_PAGES]                            : {pages : Pages[],businessId:number}
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_PAGES]              : boolean
  [ActionTypes.RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS]        : boolean
  [ActionTypes.GET_FACEBOOK_UNUSED_AD_ACCOUNTS]               : {adAccounts:AdAccounts[],businessId:number}
  [ActionTypes.FAILED_RETRIEVING_FACEBOOK_UNUSED_AD_ACCOUNTS] : boolean
  [ActionTypes.SUBMITTING_FACEBOOK_ACCOUNT_ID]                : boolean
  [ActionTypes.FAILED_SUBMITTING_FACEBOOK_ACCOUNT_ID]         : {failed:boolean,message:string}
  [ActionTypes.SUBMITTING_FACEBOOK_CONFIGURATION]             : boolean
  [ActionTypes.FAILED_SUBMITTING_FACEBOOK_CONFIGURATION]      : {failed:boolean,message:string}
  [ActionTypes.GET_ACCOUNT_INFO_FIRESTONE]                    : {accountId:number,accountInfo:AccountInfoFireStone}
  [ActionTypes.RETRIEVING_ACCOUNT_INFO_FIRESTONE]             : boolean
  [ActionTypes.FAILED_RETRIEVING_ACCOUNT_INFO_FIRESTONE]      : boolean
  [ActionTypes.RETRIEVING_SUMMARY]                            : boolean
  [ActionTypes.FAILED_RETRIEVING_SUMMARY]                     : {failed:boolean,message:string}
  [ActionTypes.GET_SUMMARY]                                   : {agencyId:string,contracts:SummaryWithPresentationByAccountId}
  [ActionTypes.GET_BREAKDOWN]                                 : {breakdown:Breakdown}
  [ActionTypes.RETRIEVING_BREAKDOWN]                          : boolean
  [ActionTypes.FAILED_RETRIEVING_BREAKDOWN]                   : {failed:boolean,message:string}
  [ActionTypes.CREATING_SUMMARY]                              : boolean
  [ActionTypes.FAILED_CREATING_SUMMARY]                       : {failed:boolean,message:string}
  [ActionTypes.UPDATING_SUMMARY]                              : boolean
  [ActionTypes.FAILED_UPDATING_SUMMARY]                       : {failed:boolean,message:string}
  [ActionTypes.CREATE_DRAFT_SUMMARY]                          : {summary:SummaryTemplate}
  [ActionTypes.UPDATE_DRAFT_SUMMARY]                          : {summary:SummaryTemplate}
  [ActionTypes.CLEAR_CURRENT_BREAKDOWN]                       : {}
  [ActionTypes.RESET_DRAFT_SUMMARY]                           : {}
  [ActionTypes.RETRIEVING_SALES_CAMPAIGNS]                    : boolean
  [ActionTypes.FAILED_RETRIEVING_SALES_CAMPAIGNS]             : {failed:boolean,message:string}
  [ActionTypes.GET_SALES_CAMPAIGNS]                           : SalesCampaign[]
  [ActionTypes.RETRIEVING_SALES_PRODUCTS]                     : boolean
  [ActionTypes.FAILED_RETRIEVING_SALES_PRODUCTS]              : {failed:boolean,message:string}
  [ActionTypes.GET_SALES_PRODUCTS]                            : SalesProduct[]
  [ActionTypes.RETRIEVING_SALESFORCE_ACCOUNTS]                : boolean
  [ActionTypes.FAILED_RETRIEVING_SALESFORCE_ACCOUNTS]         : {failed:boolean,message:string}
  [ActionTypes.GET_SALESFORCE_ACCOUNTS]                       : SalesforceAccount[]
  [ActionTypes.RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS]        : boolean,
  [ActionTypes.FAILED_RETRIEVING_SALESFORCE_ACCOUNT_CONTACTS] : {failed:boolean,message:string},
  [ActionTypes.GET_SALESFORCE_ACCOUNT_CONTACTS]               : SalesforceContact[],
  [ActionTypes.GET_ACCOUNT_PERMISSIONS]                       : {accountId:number,permissions:Array<AccountPermission>}
  [ActionTypes.RETRIEVING_ACCOUNT_PERMISSIONS]                : boolean
  [ActionTypes.GET_SYSTEMS_PERMISSIONS]                        : PermissionsBySystem
  [ActionTypes.RETRIEVING_SYSTEMS_PERMISSIONS]                 : boolean

}

export type ActionType = keyof ActionMap
export type ActionPayload<K extends ActionType> = ActionMap[K]
export interface ActionObject<K extends ActionType> extends BaseAction {
  type    : K
  payload : ActionPayload<K>
}
export type Action = {
  [P in ActionType]: ActionObject<P>
}[ActionType]
export type DispatchableAction = Action | CallHistoryMethodAction
