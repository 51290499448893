import React                          from 'react'
import useStyles                      from './indexStyles'
import { Card }                       from '@material-ui/core'
import KPI                            from '../../../components/dataDisplays/KPI'
import MuiGauge                       from '../../../components/dataDisplays/Gauge'
import PacingPerChannel               from '../../../components/pacing/PacingPerChannel'
import { connect }                    from 'react-redux'
import { State }                      from '../../../redux/reducers'
import { ThunkDispatch }              from 'redux-thunk'
import { DispatchableAction }         from '../../../model/constant/actions'
import { push }                       from 'connected-react-router'
import {
  StatPerChannel,
  CompiledStatsPerYearMonth
}                                     from '../../../model/store/statistics/PacingStats'

interface OverviewOwnProps {
  statsPerChannel    : StatPerChannel[]
  previousMonthData  : CompiledStatsPerYearMonth
  thisMonthData      : CompiledStatsPerYearMonth
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:OverviewOwnProps)=>{
    return {
      retrievingPacingStats : state.Statistics.RetrievingPacingStats,
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:OverviewOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:OverviewOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type OverviewProps = OverviewOwnProps & ReturnType<typeof mergeProps>

const KPIFormatter = (number: number): string => {
  return `$${Intl.NumberFormat().format(Math.round(number))}`
}
const EMPTY_DAY = {
  totalInvestedInMonthSoFar : 0,
  totalSpentInMonthSoFar    : 0,
  pacingInMonthSoFar        : 0
}
const Overview = (props:OverviewProps) => {
  const classes = useStyles()
    /*
      If there's no equivalent day in the last month (for example, March 31st => Feb 31st),
      use the last day of the previous month (March 31st => Feb 28th)
    */
    // data series
    const currentMonthTrends     = props.thisMonthData.dailyTrends
    const lastMonthTrends        = props.previousMonthData ? props.previousMonthData.dailyTrends : []
    // currentMonth
    const day                    = currentMonthTrends.length-1
    const dayTrends              = currentMonthTrends[day] ?? EMPTY_DAY
    const budgetSoFar            = dayTrends.totalInvestedInMonthSoFar
    const spentSoFar             = dayTrends.totalSpentInMonthSoFar
    const pacingSoFar            = dayTrends.pacingInMonthSoFar
    // lastMonth 
    const lastMonthSameDayTrends = lastMonthTrends[day]
    const lastMonthLastDayTrends = lastMonthTrends[lastMonthTrends.length-1]
    const lastMonthDayTrends     = lastMonthSameDayTrends ?? lastMonthLastDayTrends ?? EMPTY_DAY
    const budgetSoFarLastMonth   = lastMonthDayTrends.totalInvestedInMonthSoFar
    const spentSoFarLastMonth    = lastMonthDayTrends.totalSpentInMonthSoFar
    const pacingSoFarLastMonth   = lastMonthDayTrends.pacingInMonthSoFar

  return (
    <div className={classes.overview}>
      <Card className={classes.pacingRate}>
        <MuiGauge
          value={pacingSoFar}
          previousValue={pacingSoFarLastMonth}
          retrievingPacingStats={props.retrievingPacingStats}
          displayDiff={true}
        />
      </Card>
      <Card className={classes.pacingPerChannel}>
        <PacingPerChannel data={props.statsPerChannel} />
      </Card>
      <div className={classes.kpis}>
        <KPI
          number={budgetSoFar}
          previousNumber={budgetSoFarLastMonth}
          text="Budget"
          icon="account_balance"
          retrievingPacingStats={props.retrievingPacingStats}
          displayDiff={true}
          formatterFunction={KPIFormatter}
        />
        <KPI
          number={spentSoFar}
          previousNumber={spentSoFarLastMonth}
          text="Spent"
          icon="monetization_on"
          retrievingPacingStats={props.retrievingPacingStats}
          displayDiff={true}
          formatterFunction={KPIFormatter}
        />
      </div>
    </div>
  )
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(Overview)
