import * as React                 from "react"
import {connect}                  from "react-redux"
import {ThunkDispatch}            from "redux-thunk"
import {State}                    from "../../redux/reducers"
import {Action}                   from "../../model/constant/actions"
import Loading                    from "../loadings/CircularLoading"
import { getFeeds }               from "../../actions/Hyperfeed/feeds"
import AdAccountsPermissionError  from "./ErrorComponents/AdAccountsPermissionError"
import Feed                       from "../../model/feeds/Feed"
import { translationPaths }       from "../../translations/translations"

interface AccountsDataLoaderOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}
interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  feeds               : {
    [agencyId:string] : {
      [accountId:number] : Feed[]
    }
  }
  loadingFeeds        : boolean
}

const mapStateToProps = (state:State, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    selectedAgency          : state.Selection.Agency,
    selectedDealer          : state.Selection.Dealer,
    feeds                   : state.Feeds.Feeds,
    retrieving              : state.Feeds.Retrieving,
    failedRetrieving        : state.Feeds.FailedRetrieving,
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {
    getFeeds : (...args:Parameters<typeof getFeeds>)=>dispatch(getFeeds(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AccountsDataLoaderOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingFeeds = false
  if(props.retrieving && !props.letThrough) {
    return <Loading loadingMessage={translationPaths.loaders.accountFeeds.loading} doTranslate />
  }
  if (props.failedRetrieving) {
    return <AdAccountsPermissionError selectedAgency={props.selectedAgency} />
  }
  if(!props.feeds[props.selectedAgency] || !props.feeds[props.selectedAgency][props.selectedDealer]){
    loadingFeeds = true
    if(!props.retrieving){
      props.getFeeds(props.selectedDealer,props.selectedAgency)
    }
    if(!props.letThrough){
      return <Loading loadingMessage={translationPaths.loaders.accountFeeds.loading} doTranslate />
    }
  }
  return props.render({loadingFeeds, feeds: props.feeds})
})

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresAccountFeeds:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}
export default requiresAccountFeeds
