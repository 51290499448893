import {
  StandardThunk,
  createGetAccountFeeds,
  createRetrievingAccountFeeds,
  createFailedRetrievingAccountFeeds
}                                         from "../creators"
import baseUrl,{Success}                  from "../../model/http/Hyperfeed/GetFeeds"
import axios                              from "axios"

export const getFeeds = (accountId:number,agencyId:string):StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingAccountFeeds(true))
  axios.get<Success[]>(baseUrl(accountId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetAccountFeeds({
      agencyId,
      accountId,
      feeds : response.data
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingAccountFeeds(true))
  })
  .then(()=>{
    dispatch(createRetrievingAccountFeeds(false))
  })
}
