import {
  Dispatch,
  Middleware
}                                    from "redux"
import {State}                       from "../reducers/"
import ActionTypes, {
  Action
}                                    from "../../model/constant/actions"
import { createPacingDataToCompile } from "../../actions/creators"
import PacingStats                   from "../../model/http/Reporting/PacingStats"

const pacingStatsCompilation:Middleware<Dispatch<Action>, State> = (store) => (next) => (action) => {
  const state = store.getState()
  const agencyId = action.payload.agencyId
  if(action.type === ActionTypes.GET_AGENCY_ACCOUNTS) {
    if(state.Statistics.PacingStats[agencyId]) {
      store.dispatch(createPacingDataToCompile({
        agencyId ,
        pacingReport : state.Statistics.PacingStats[agencyId],
        accounts     : action.payload.accounts
      }))
      return next(action)
    }
  }
  if(action.type === ActionTypes.GET_PACING_STATS) {
    if(state.Accounts.Agency[agencyId]) {
      store.dispatch(createPacingDataToCompile({
        agencyId,
        pacingReport : action.payload.months.reduce((acc:{},curr:string)=>{
          return {
            ...acc,
            [curr] : action.payload.pacingStats.filter((stats:PacingStats)=>{
              return stats.date.substr(0,7) === curr
            })
          }
        },{}),
        accounts     : state.Accounts.Agency[agencyId]
      }))
      return next(action)
    }
  }
  next(action)
}

export default pacingStatsCompilation
