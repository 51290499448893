import './Typography.css'
import React                   from 'react'
import {
  Typography,
}                              from '@material-ui/core'

interface TypographyOwnProps {
  className : string
}
interface TypographyProps extends TypographyOwnProps {}

const TypographyComponent = (props:TypographyProps) => (
  <div className={props.className}>
    <Typography className="Title" variant="h1">Header exemple</Typography>
    <Typography className="Title" variant="h2">Header exemple</Typography>
    <Typography className="Title" variant="h3">Header exemple</Typography>
    <Typography className="Title" variant="h4">Header exemple</Typography>
    <Typography className="Title" variant="h5">Header exemple</Typography>
    <Typography className="Title" variant="h6">Header exemple</Typography>
  </div>
)

export default TypographyComponent
