import * as React       from "react"
import {connect}        from "react-redux"
import {ThunkDispatch}  from "redux-thunk"
import {State}          from "../../redux/reducers/"
import {Action}         from "../../model/constant/actions"
import {getAgencies}    from "../../actions/Auth2/agencies"
import Loading          from "../loadings/CircularLoading"
import ErrorPage        from "../ErrorPage"
import { translationPaths } from "../../translations/translations"

interface AgencySelectionOwnProps extends LoaderConfig{
  render : (loaderProps:Injected)=>any
}

const mapStateToProps = (state:State, ownProps:AgencySelectionOwnProps)=>{
  return {
    agencies            : state.Agencies.List,
    retrievingAgencies  : state.Agencies.Retrieving,
    selectedAgency      : state.Selection.Agency,
    failed              : state.Agencies.Failed
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:AgencySelectionOwnProps)=>{
  return {
    getAgencies : (...args:Parameters<typeof getAgencies>)=>dispatch(getAgencies(...args)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AgencySelectionOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type LoaderProps = ReturnType<typeof mergeProps>
const Loader = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props:LoaderProps) => {
  let loadingAgencies = false
    if(props.failed) {
      return <ErrorPage errorMessage={translationPaths.loaders.agencies.failed} doTranslate={true} />
    }
    if(!props.agencies){
      loadingAgencies = true
      if(!props.retrievingAgencies){
        props.getAgencies()
      }
      if(!props.letThrough){
        return <Loading loadingMessage={translationPaths.loaders.agencies.loading} doTranslate={true} />
      }
    }
    return props.render({loadingAgencies, agencies: props.agencies})
})

interface LoaderConfig{
  letThrough : boolean
}
const defaultConfig = {
  letThrough : false,
}
interface Injected{
  agencies : State["Agencies"]["List"]
  loadingAgencies : boolean
}

type Loader = (givenConfig?:Partial<LoaderConfig>) => <T extends {}>(Component:React.ComponentType<T>) => (props:Omit<T, keyof Injected>) => React.ReactElement
const requiresAgencies:Loader = (givenConfig={}) => (Component) => (props) => {
  const ComponentAny:any = Component
  const config = {...defaultConfig, ...givenConfig}
  return (
    <Loader
      {...config}
      render={ (loaderProps) => <ComponentAny {...loaderProps} {...props} /> }
    />
  )
}

export default requiresAgencies
