import SummaryTemplate, {
  ProfitCenter,
  SummaryProduct,
}                           from "../Summary"
import { Channel }          from "../../constant/channels"

export const handleAddProduct = (product:SummaryProduct,summary:SummaryTemplate):SummaryTemplate => {
  return {
    ...summary,
    products : [...summary.products,product]
  }
}
export const handleProductDescriptionChange = (description:string,id:number,state:ProfitCenter,summary:SummaryTemplate) => {
  return {
    ...summary,
    products : summary.products.map(product=>{
      if(product.id === id) {
        return {
          ...product,
          description:description,
          state: state,
          id,
        }
      }
      return product
    })
  }
}

export const handleProductAllocationChange = (value:number,id:number,summary:SummaryTemplate) => {
  return {
    ...summary,
    products : summary.products.map(product=>{
      if(product.id === id) {
        return {...product,allocation:value}
      }
      return product
    })
  }
}

export const handleDeleteProduct = (id:number,summary:SummaryTemplate) => {
  return {
    ...summary,
    products : summary.products.filter(product=>{
      return product.id !== id
    })
  }
}
export const handleProductChannelChange = (channel:Channel,id:number,summary:SummaryTemplate) => {
  return {
    ...summary,
    products : summary.products.map(product=>{
      if(product.id === id) {
        return {...product,channel:channel,allocation:0,description:''}
      }
      return product
    })
  }
}
