import React               from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
}                          from '@material-ui/core'
import {
  ResponsiveContainer,
  Line,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
}                          from 'recharts'
import {
  withLocalize,
  LocalizeContextProps
}                          from 'react-localize-redux'
import {translationPaths}  from '../../../translations/translations'

interface GraphDataFormat {
  date  : string
  dealerCount : number
  value : number
}

interface DealersWebsitesGraphOwnProps extends LocalizeContextProps{
  data : GraphDataFormat[]
  valueKeyName   : string
  dealersColor   : string
  inventoryColor : string
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealersWebsitesGraph : {
      marginTop : 50,
      paddingBottom : 50
    }
  })
)

const DealersWebsitesGraph = (props:DealersWebsitesGraphOwnProps) => {
  const classes = useStyles()
  return (
    <div className={classes.dealersWebsitesGraph}>
    <ResponsiveContainer height={300} width="99%" >
      <LineChart
        data={props.data}
        margin={{top: 5, right: 30, left: 20, bottom: 5}}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis tickMargin={15} tick={{fill:'#999'}} stroke="#ccc" height={30} dataKey="date"/>
        <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="left" />
        <YAxis tick={{fill:'#999'}} stroke="#fff" yAxisId="right" orientation="right" />
        <Tooltip/>
        <Line
          dot={false}
          strokeWidth={4}
          name={props.translate(translationPaths.marketInsights.dealersGraph.dealers) as string}
          yAxisId="left"
          type="natural"
          dataKey="dealerCount"
          stroke={props.dealersColor}
        />
        <Line dot={false} strokeWidth={4} name={props.valueKeyName} yAxisId="right" type="linear" dataKey="value" stroke={props.inventoryColor} />
      </LineChart>
    </ResponsiveContainer>
    </div>
  )
}

export default withLocalize(DealersWebsitesGraph)
