import {
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    usedParams : {
    },
    inputs : {
      marginTop : 15,
      display : 'flex'
    },
    table : {
      marginTop : 15,
      height : 300,
      overflow : 'auto',
    },
    errors : {
      padding : 10
    },
    identityVariants : {
      marginTop : 10,
      color : theme.palette.info.main,
      display:'flex',
      alignItems:'center'
    }
  })
)

export default useStyles
