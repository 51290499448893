import {
  makeStyles,
  Theme,
  createStyles
}               from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountInformations : {
      padding : 20
    },
    content : {
      display : 'flex',
      marginTop : 30,
    },
    inputsDiv : {
      display : 'flex',
      flexDirection : 'column',
      marginRight : 20
    },
    input : {
      margin : 10,
      width : 400
    }
  })
)
export default useStyles
