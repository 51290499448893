import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {frCA,enUS}          from 'date-fns/locale'
import React              from 'react'
import {DateRangePicker}  from 'react-date-range'
import {
  useTheme,
}                         from '@material-ui/core'
import { isSameDay }      from 'date-fns'
import moment             from 'moment'
import {
  withLocalize,
  LocalizeContextProps
}                         from 'react-localize-redux'
import {translationPaths} from '../../translations/translations'


export interface ReturnRange {
  selection : {
    startDate : Date,
    endDate   : Date
  }
}

interface RangeDatePickerOwnProps extends LocalizeContextProps {
  startDate     : Date
  endDate       : Date
  onDateChange  : (range:ReturnRange)=>void
}
interface Range {
  startDate : Date
  endDate   : Date
}

const availableLanguagesLocale = {
  en : enUS,
  fr : frCA
}

const RangeDatePicker = (props:RangeDatePickerOwnProps) => {
  const activeLanguage = props.activeLanguage.code as 'en' | 'fr'
  const preDefinedDates = [
    {
      label : props.translate(translationPaths.dateRangePicker.currentMonth),
      range : () => ({
        startDate : moment().startOf('month').toDate(),
        endDate : moment().toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.lastMonth),
      range : () => ({
        startDate : moment().subtract(1,'months').startOf('month').toDate(),
        endDate :  moment().subtract(1,'months').endOf('month').toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.yearToDate),
      range : () => ({
        startDate : moment().startOf('year').toDate(),
        endDate : moment().toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.lastYear),
      range : () => ({
        startDate : moment().subtract(1,'year').startOf('year').toDate(),
        endDate : moment().subtract(1,'year').endOf('year').toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.last365Days),
      range : () => ({
        startDate : moment().subtract(364,'days').toDate(),
        endDate : moment().toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.last90Days),
      range : () => ({
        startDate : moment().subtract(89,'days').toDate(),
        endDate : moment().toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.last30Days),
      range : () => ({
        startDate : moment().subtract(30,'days').toDate(),
        endDate : moment().toDate()

      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
    {
      label : props.translate(translationPaths.dateRangePicker.last7Days),
      range : () => ({
        startDate : moment().subtract(6,'days').toDate(),
        endDate : moment().toDate()
      }),
      isSelected(range:Range) {
        const definedRange = this.range()
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        )
      },
    },
  ]
  const theme = useTheme()
  const ranges = [{
    startDate : props.startDate,
    endDate : props.endDate,
    key : 'selection'
  }]
  return (
    <DateRangePicker
      onChange={props.onDateChange}
      ranges={ranges}
      months={2}
      direction="horizontal"
      showSelectionPreview={false}
      rangeColors={[theme.palette.primary.main]}
      staticRanges={preDefinedDates}
      maxDate={new Date()}
      fixedHeight={true}
      shownDate={moment(props.startDate).subtract(1,'month').toDate()}
      inputRanges={[]}
      locale={availableLanguagesLocale[activeLanguage]}
    />
  )
}

export default withLocalize(RangeDatePicker)
