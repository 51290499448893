import {
  StandardThunk,
  createRetrievingGoogleTrendsStats,
  createGetGoogleTrendsStats,
  createFailedRetrievingGoogleTrendsStats,
}                                     from "../creators"
import axios                          from "axios"

import Papa                           from 'papaparse'
import { buildCompiledReportUrl }     from "../../model/http/Reporting/CompiledReport"


export const getGoogleTrendsStats = (agencyId:string,dateFrom:string,dateTo:string):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingGoogleTrendsStats(true))
  axios.get(buildCompiledReportUrl(agencyId,"GOOGLE_TRENDS_STATS"),{
    headers : {
      Authorization : `Bearer ${token}`,
    },
    params : {
      dateFrom : dateFrom,
      dateTo : dateTo
    }
  })
  .then(response=>{
    const parsedData  = Papa.parse(response.data.trim(),{header:true,dynamicTyping:true}).data
    dispatch(createGetGoogleTrendsStats(
      {agencyId,googleTrendsStats:parsedData}
    ))
  })
  .catch(error=>{
    console.error(error)
    dispatch(createFailedRetrievingGoogleTrendsStats(true))
  })
  .then(()=>{
    dispatch(createRetrievingGoogleTrendsStats(false))
  })
}
