import React                   from 'react'
import { DispatchableAction }  from '../../model/constant/actions'
import { State }               from '../../redux/reducers'
import { ThunkDispatch }       from 'redux-thunk'
import DrawerStyles            from './DrawerStylesMenu'
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  useTheme,
  Icon,
  Avatar,
  Box,
}                              from '@material-ui/core'
import ImportExportIcon        from '@material-ui/icons/ImportExport'
import ExpandMoreIcon          from '@material-ui/icons/ArrowDropDown'
import {push}                  from "connected-react-router"
import { connect }             from 'react-redux'
import { MenuModel }           from '../ApplicationBar/Menu'
import clsx                    from 'clsx'
import AgencySelectionModal    from "../../pages/AgencySelection/AgencySelectionModal"
import { Translate }           from 'react-localize-redux'
import { translationPaths }    from '../../translations/translations'

interface DrawerOwnProps{
  open              : boolean
  menu              : MenuModel[]
  selectedAgency    : string
}

const makeMapStateToProps = () => {
  return (state:State, ownProps:DrawerOwnProps)=>{
    return {
      previousUrl : state.router.location.hash,
      user        : state.Login.User
    }
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, DispatchableAction>, ownProps:DrawerOwnProps)=>{
  return {
    push : (...args:Parameters<typeof push>)=>dispatch(push(...args))
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:DrawerOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type DrawerProps = DrawerOwnProps & ReturnType<typeof mergeProps>


const DrawerMenu  = (props:DrawerProps) => {
  const theme = useTheme()
  const textColor = theme.palette.getContrastText(theme.palette.primary.main)
  const classes = DrawerStyles()
  const [openModal,setOpenModal] = React.useState<boolean>(false)
  const [spreads, setSpreads] = React.useState<{[buttonId:string]:boolean}>(
    props.menu.reduce((acc:{[buttonId:string]:boolean}, button)=>{
      if(button.expandable){
        acc[button.id] = false
      }
      return acc
    },{})
  )
  const agencyNameOnClick = () => {
    handleOpenModal()
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      PaperProps={{
        style : {
          backgroundColor : theme.palette.primary.dark
        }
      }}
    >
      <Box className={classes.drawerHeader}>
      <List
        onClick={agencyNameOnClick}
        className={classes.homeButton}
      >
        <ListItem button>
        <ListItemIcon className={classes.icon}><ImportExportIcon style={{fill:textColor}}/></ListItemIcon>
          <ListItemText primaryTypographyProps={{style:{color:textColor, fontWeight:'bold'}}} primary={props.selectedAgency}/>
        </ListItem>
        <Divider
          style={{
            backgroundColor : textColor
          }}
        />
      </List>
      </Box>
      {props.menu.map(item=>{
        if(item.hide) {return null}
        if(item.expandable) {
          const expandIconClassName = clsx({
            [classes.expandIcon]: !spreads[item.id],
            [classes.expandIconReversed]   : spreads[item.id]
          })
          return (
            <List key={item.id}>
              <ListItem onClick={()=>setSpreads(prev=>({...prev, [item.id]:!prev[item.id]}))} button>
                <ListItemIcon style={{color : '#E5E5E5'}} ><Icon>{item.icon}</Icon></ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{style:{color:textColor}}}
                  primary={
                    <Translate id={translationPaths.buttons[item.name]}/>
                  }
                />
                <ListItemIcon className={classes.expandIconContainer}><ExpandMoreIcon className={expandIconClassName}/></ListItemIcon>
              </ListItem>
              <Collapse in={spreads[item.id]}>
                {item.subMenu && item.subMenu.map(subItem=>
                  <ListItem
                    key={subItem.id}
                    onClick={()=>{
                      props.push(subItem.path as any)
                    }}
                    className={classes.subItem} button
                  >
                    <ListItemIcon style={{color : '#E5E5E5'}} ><Icon>{subItem.icon}</Icon></ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{variant:"body2",style:{color:textColor}}}
                      primary={
                        <Translate id={translationPaths.buttons[subItem.name]} />
                      }
                    />
                  </ListItem>
                )}
              </Collapse>
            </List>
          )
        } else {
          return (
            <>
              <List key={item.id}>
                <ListItem onClick={()=>props.push(item.path as any)} button>
                  {item.id === 'User'
                    ? <Avatar style={{
                        width: theme.spacing(4),
                        height: theme.spacing(4),
                        marginLeft: 2,
                        marginRight: 25,
                        color: '#454F63',
                        backgroundColor: '#E5E5E5'
                      }}>
                        {item.renderName && item.renderName().charAt(0)}
                      </Avatar>

                    : <ListItemIcon style={{color : '#E5E5E5'}} ><Icon>{item.icon}</Icon></ListItemIcon>
                  }
                  <ListItemText
                    key={item.id}
                    primaryTypographyProps={{style:{color:textColor}}}
                    primary={
                      !item.renderName
                      ? <Translate id={translationPaths.buttons[item.name]} />
                      : item.renderName()
                    }
                  />
                </ListItem>
              </List>
            </>
          )
        }
      })}
      <AgencySelectionModal open={openModal} onClose={handleCloseModal} onSelection={handleCloseModal} />
    </Drawer>
  )
}
export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(DrawerMenu)

