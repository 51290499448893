import styles                            from "./forgotPasswordStyles"
import * as React                        from "react"
import {connect}                         from "react-redux"
import {ThunkDispatch}                   from "redux-thunk"
import {State}                           from "../../redux/reducers/"
import {Action}                          from "../../model/constant/actions"
import TextField                         from "@material-ui/core/TextField"
import Button                            from "@material-ui/core/Button"
import CardContent                       from "@material-ui/core/CardContent"
import FormControl                       from "@material-ui/core/FormControl"
import OverlayLoading                    from "../../components/loadings/OverlayLoading"
import {
  Box,
  Typography,
  FormHelperText,
  Fade
}                                        from "@material-ui/core"
import {resetPassword, resetMessage}     from "../../actions/Auth/resetPassword"
import { Link }                          from "react-router-dom"
import {CheckCircleOutlined}             from "@material-ui/icons"
import { Translate }                     from "react-localize-redux"
import {translationPaths}               from "../../translations/translations"

interface LoginOwnProps{
  message       : string
  resetPassword : (email:string)=>void
}
const mapStateToProps = (state:State, ownProps:LoginOwnProps)=>{
  return {
    error             : state.Login.Error,
    loggingIn         : state.Login.LoggingIn,
    message           : state.Login.ForgotPasswordMessage,
    resettingPassword : state.Login.ResettingPassword
  }
}
const mapDispatchToProps = (dispatch:ThunkDispatch<State, null, Action>, ownProps:LoginOwnProps)=>{
  return {
    resetMessage  : () => {dispatch(resetMessage())},
    resetPassword : (email:string)=>{dispatch(resetPassword(email))},
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:LoginOwnProps)=>{
  return {...SP,...DP,...ownProps}
}


type LoginProps = ReturnType<typeof mergeProps>
const ForgotPassword = (props:LoginProps) => {
  const classes = styles()
  const sendRef = React.createRef<HTMLButtonElement>()
  const [email, setEmail] = React.useState<string>("")
  const [success, setSuccess] = React.useState<boolean>(false)
  const [flashError, setFlashError] = React.useState<boolean>(false)

  const onEnterPress = (e:React.KeyboardEvent<HTMLDivElement>)=>{
    if(e.key === "Enter" && sendRef && sendRef.current){
      sendRef.current.click()
    }
  }

  const handleChange = (value: string) => {
    if (props.message !== "") {
      props.resetMessage()
    }
    setEmail(value)
  }

  const handleSubmit = () => {
    // Briefly invert error message colors if the user repeatedly hits send
    if (props.message !== ""){
      setFlashError(true)
      setTimeout(() => {
        setFlashError(false)
      }, 300)
    }
    else {
      props.resetPassword(email)
    }
  }

  const handleDone = () => {
    props.resetMessage()
  }

  if (props.message === "OK" && success === false) {
    setSuccess(true)
  }

  return (
    <Box className={classes.login}>
      <OverlayLoading open={props.loggingIn}>
        <div className={classes.container}>
          <CardContent className={classes.contentContainer}>
            {
              !success &&
              <div className={classes.content}>
                <Typography
                  variant="h3"
                  className={classes.title}
                >
                  <Translate id={translationPaths.login.forgotPassword} />
                </Typography>
                <Typography
                  color="primary"
                  className={classes.information}
                >
                  <Translate id={translationPaths.login.resetPasswordInstruction} />
                </Typography>
                <FormControl className={classes.email} fullWidth>
                  <TextField
                    className={classes.inputText}
                    value={email}
                    onChange={(e)=>handleChange(e.target.value)}
                    onKeyDown={onEnterPress}
                    color="primary"
                    variant="outlined"
                    label="Email"
                    type="email"
                  />
                  <FormHelperText
                    variant="filled"
                    className={classes.inputError}
                    component="div"
                  >
                    <div className={flashError ? `${classes.errorTextActive} ${classes.errorText}` : classes.errorText }>{props.message}</div>
                  </FormHelperText>
                </FormControl>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    ref={sendRef}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    <Translate id={translationPaths.login.submit} />
                  </Button>
                </div>
              </div>
            }
            <Fade in={success} style={ !success?  {display: "none"} : {}}>
              <div className={classes.successContainer}>
                <CheckCircleOutlined
                  className={classes.successCheck}
                />
                <Typography
                  variant="h5"
                  className={classes.success}
                >
                  <Translate id={translationPaths.login.passwordResetSent} />
                </Typography>
                <div className={classes.informationContainer}>
                  <Typography
                    className={classes.successInformation}
                    align="center"
                  >
                    <Translate id={translationPaths.login.passwordResetSentDescription} /> <br/> {email}.
                  </Typography>
                  <Typography
                    className={classes.successInformation}
                    align="center"
                  >
                    <Translate id={translationPaths.login.followInstructions} />
                  </Typography>
                </div>
                <Link
                  to="/"
                  onClick={handleDone}
                >
                  <Button
                    variant="contained"
                    className={classes.doneButton}
                    color="primary"
                  >
                    <Translate id={translationPaths.login.done} />
                  </Button>
                </Link>
              </div>
            </Fade>
          </CardContent>
          <div className={classes.rightImage}></div>
        </div>
      </OverlayLoading>
    </Box>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(ForgotPassword)
