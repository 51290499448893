import { createReducer } from "../../../utils/Reducers"
import ActionTypes       from "../../../model/constant/actions"
import SalesCampaign     from "../../../model/budgetEstimator/SalesCampaign"


const Campaigns = createReducer<SalesCampaign[]>(null,{
  [ActionTypes.GET_SALES_CAMPAIGNS] : (state,action) => {
    return action.payload
  }
})

export default Campaigns
